import styles from "./ClientDetailsLoader.module.css";
import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import moment from "moment/moment";

import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";

import { AuthContext } from "context/AuthContext";
import { getAdminClientDetails } from "utils/irisAuthRequests";
import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import {
  ERROR_MESSAGE,
  handleClient,
  HANDLE_TYPE,
} from "utils/irisAuthRequests";
import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";

import ClientDetailsUI, {
  DEFAULT_ASSIGNEE_DEFECT_OBJECT,
} from "../common/ClientDetails";
import {
  createClientWidget,
  removeClientWidget,
  updateClientWidget,
} from "pages/clientsUsersGroups/api/requests";
import { UPLOAD_SHAPEFILE_REQUEST, updateCSAMsForClient } from "utils/requests";

/** search fields options for search_fields, which is a property of w_o_profile, which is a property of iris_city */
export const SEARCH_FIELDS_OPTIONS = [
  { id: "service_by", fieldName: "service by" },
  { id: "w_o_status", fieldName: "work order status" },
  { id: "assign_to", fieldName: "assign to" },
  { id: "w_o_id", fieldName: "work order id" },
  { id: "category", fieldName: "category" },
  { id: "w_o_type", fieldName: "work order type" },
  { id: "road_region", fieldName: "road region" },
  { id: "ward", fieldName: "ward" },
  { id: "open_datetime", fieldName: "open date time" },
];

/** 3 different messages according to api call */
const UPDATE_CLIENT_MODAL_MESSAGES = {
  /** "No request is sent." */
  notSend: "No request is sent.",
  /** "Update client request is sent. Please wait for a while." */
  sending: "Update customer request is sent. Please wait for a while.",
  /** "Client information is updated successfully." */
  success: "Customer information is updated successfully.",
};

// reducer actions
const ACTIONS = {
  SET_CLIENTNAME: "SET CLIENT NAME",
  SET_DEVICE_COUNT: "SET DEVICE COUNT",
  SET_MONTHLY_LIMIT: "SET MONTHLY LIMIT",
  SET_SHIFTS_START_TIME_END_TIME: "SET SHIFTS START TIME AND END TIME",
  SET_SHIFTS: "SET SHIFTS",
  SET_GEO_ID: "SET GEO ID",
  SET_GEO_NAME: "SET GEO NAME",
  SET_GEO_LAT: "SET GEO LATITUDE",
  SET_GEO_LON: "SET GEO LONGITUDE",
  SET_GEO_PROVINCE: "SET GEO PROVINCE",
  SET_GEO_TIMEZONE: "SET GEO TIMEZONE",
  SET_GEO_FENCING: "SET GEO FENCING",
  SET_SERVICE_ID: "SET SERVICE ID",
  SET_IRIS_CITY_ID: "SET IRIS CITY ID",
  SET_IRIS_CITY_NAME: "SET IRIS CITY NAME",
  SET_WIDGETS: "SET WIDGETS",
  SET_W_O_PROFILE: "SET WORK ORDER PROFILE",
  SET_GROUPLIST: "SET GROUP LIST",
  SET_M_A_PROFILE: "SET MANUAL APP PROFILE",
  SET_LOGO: "SET LOGO",
  SET_SHAPEFILE_URL: "SET SHAPEFILE URL",
  SET_SHAPEFILE: "SET SHAPEFILE",
  SET_CSAMS: "SET CSAMS",
  RESET: "RESET",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    // update clientname input
    case ACTIONS.SET_CLIENTNAME:
      return { ...state, name: payload };
    // update device count
    case ACTIONS.SET_DEVICE_COUNT:
      return { ...state, device_count: payload };
    // update montly limit
    case ACTIONS.SET_MONTHLY_LIMIT:
      return { ...state, monthly_limit: payload }
    // update shifts start time and end time object
    case ACTIONS.SET_SHIFTS_START_TIME_END_TIME:
      return { ...state, shifts_start_time_end_time: payload };
    // update shifts
    case ACTIONS.SET_SHIFTS:
      return { ...state, shifts: payload };
    // update geo name
    case ACTIONS.SET_GEO_NAME:
      return { ...state, geo_name: payload };
    // update geo id
    case ACTIONS.SET_GEO_ID:
      return { ...state, geo_id: payload };
    // update geo province
    case ACTIONS.SET_GEO_PROVINCE:
      return { ...state, geo_province: payload };
    // update geo timezone
    case ACTIONS.SET_GEO_TIMEZONE:
      return { ...state, geo_timezone: payload };
    // update geo latitude
    case ACTIONS.SET_GEO_LAT:
      return { ...state, geo_lat: payload };
    // update geo longitude
    case ACTIONS.SET_GEO_LON:
      return { ...state, geo_lon: payload };
    // update geo fencing
    case ACTIONS.SET_GEO_FENCING:
      return { ...state, geo_fencing: payload };
    // update service id
    case ACTIONS.SET_SERVICE_ID:
      return { ...state, service_id: payload };
    // update iris city id
    case ACTIONS.SET_IRIS_CITY_ID:
      return { ...state, iris_city_id: payload };
    case ACTIONS.SET_IRIS_CITY_NAME:
      return { ...state, city_name: payload };
    // update widgets
    case ACTIONS.SET_WIDGETS:
      return { ...state, widgets: payload };
    // update work order profile
    case ACTIONS.SET_W_O_PROFILE:
      return { ...state, w_o_profile: payload };
    // update group list
    case ACTIONS.SET_GROUPLIST:
      return { ...state, groupList: payload };
    case ACTIONS.SET_M_A_PROFILE:
      return { ...state, m_a_profile: payload };
    case ACTIONS.SET_LOGO:
      return { ...state, logo: payload };
    case ACTIONS.SET_CSAMS:
      return { ...state, CSAMS: payload };
    // reset to previous state, which is stored with useRef
    case ACTIONS.RESET:
      return { ...payload, invalidInput: false };
    default:
      return state;
  }
};

// initial state for reducer
const clientDetailsDefaultState = {
  id: 0,
  name: "",
  city_name: "",
  geo_info: {},
  devices: [],
  available_groups: [],
  device_count: "0",
  monthly_limit: -1,
  shifts: ["00:00-23:59"],
  shifts_start_time_end_time: [["00:00:00", "23:59:00"]],
  geo_id: 0,
  geo_fencing: [],
  geo_name: "",
  geo_lat: "",
  geo_lon: "",
  geo_province: "",
  geo_timezone: "",
  service_id: 0,
  iris_city_id: 0,
  widgets: [],
  w_o_profile: { name: "", search_fields: [], defects: [], assignees: [], shape_file_url: "",shapefile: null,},
  m_a_profile: { shift: "", coverage_layer: "", additional_setting: "" },
  settings: {},
  logo: null,
  CSAMS: [],

  /** invalid if tag name is '', should be remove when sending data */
  invalidInput: false,
};

const ClientDetails = () => {
  const { id: clientID } = useParams();

  // reducer constructor
  const [clientDetailsState, dispatch] = useReducer(
    reducer,
    clientDetailsDefaultState
  );

  // indicate if it is during the api call
  const [isLoading, setIsLoading] = useState(true);

  // get token to make api call
  const { contextToken, awsToken, onLogout } = useContext(AuthContext);

  // state to switch editable or not
  const [isEditMode, setIsEditMode] = useState(false);

  // useRef to store state info before editing, in case restore data by clicking clear button
  const tempStore = useRef();

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // when clicking the submit button, set state, modal will display different info according to the states
  const [getClientListState, setGetClientListState] = useState(SUBMIT_STATE.ok);
  // when there's an error, set state of the error message
  const [errorMessage, setErrorMessage] = useState("");
  // message shown on the modal
  const [modalMessage, setModalMessage] = useState(
    UPDATE_CLIENT_MODAL_MESSAGES.notSend
  );

  const widgetRef = useRef();

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // when there's an error
    if (getClientListState === SUBMIT_STATE.error) {
      // when the token is not valid, click the backdrop to logout
      if (errorMessage === ERROR_MESSAGE.token) {
        onLogout();
      } else {
        setOpenBackdrop(false);
        setGetClientListState(SUBMIT_STATE.notSend);
        setErrorMessage("");
        setModalMessage(UPDATE_CLIENT_MODAL_MESSAGES.notSend);
      }
    } else {
      setOpenBackdrop(false);
      setGetClientListState(SUBMIT_STATE.notSend);
      setErrorMessage("");
      setModalMessage(UPDATE_CLIENT_MODAL_MESSAGES.notSend);
    }
  };

  // to update the client's info thru api
  const handleUpdate = async (submitUpdateClientState) => {
    // triggers the backdrop to open
    setOpenBackdrop(true);
    setGetClientListState(SUBMIT_STATE.sending);
    setModalMessage(UPDATE_CLIENT_MODAL_MESSAGES.sending);
    // when input number of shifts is 1, modify the shifts string
    let submitShifts = [];
    if (submitUpdateClientState.shifts.length === 1) {
      submitShifts = ["00:00-24:00"];
    } else if (submitUpdateClientState.shifts.length > 1) {
      submitShifts = submitUpdateClientState.shifts;
    }
    // api takes an object
    const submitProps = {
      token: awsToken,
      id: Number(clientID),
      clientName: submitUpdateClientState.name,
      deviceCount: submitUpdateClientState.device_count,
      monthlyLimit: submitUpdateClientState.monthly_limit,
      shifts: submitShifts,
      province: submitUpdateClientState.geo_province,
      timezone: submitUpdateClientState.geo_timezone,
      latitude: submitUpdateClientState.geo_lat,
      longitude: submitUpdateClientState.geo_lon,
      geoName: submitUpdateClientState.geo_name,
      geoId: submitUpdateClientState.geo_id,
      service: submitUpdateClientState.service,
      geoFencing: submitUpdateClientState.geo_fencing,
    };
    // Set old as shapefile upload will handle update
    submitProps.service.iris_city.w_o_profile['shape_file_url'] = tempStore.current.w_o_profile.shape_file_url

    const newWidgets = [];
    const remians = [];
    const existIds = [];
    const updatedWidgets = clientDetailsState.widgets;
    const odIds = widgetRef.current.map((widget) => widget.id);
    updatedWidgets.forEach((widget) => {
      const { id = 0 } = widget;
      if (id === 0) {
        newWidgets.push(widget);
      } else {
        remians.push(widget);
        existIds.push(id);
      }
    });

    const removedIds = odIds.filter((id) => existIds.indexOf(id) < 0);

    const createPromise = [];
    const updatePromise = [];
    removedIds.forEach((id) => removeClientWidget(id, awsToken));

    newWidgets.forEach((widget) => {
      createPromise.push(createClientWidget(widget, awsToken));
    });

    remians.forEach((widget) => {
      updatePromise.push(updateClientWidget(widget, awsToken));
    });

    Promise.all([...updatePromise, ...createPromise]).then((results) => {
      dispatch({
        type: ACTIONS.SET_WIDGETS,
        payload: results,
      });

      widgetRef.current = JSON.parse(JSON.stringify(results));
    });

    const clientUpdateRequests = [handleClient(submitProps, HANDLE_TYPE.update),updateCSAMsForClient(awsToken, clientID,submitUpdateClientState.CSAMS)]

    let work_order_ownership = ""
    if(submitProps.clientName === 'Vaughan')
      work_order_ownership="CITY OF VAUGHAN"
    if(submitUpdateClientState.w_o_profile.shapefile !==null)
      clientUpdateRequests.push(UPLOAD_SHAPEFILE_REQUEST(clientID,30,work_order_ownership,submitUpdateClientState.w_o_profile.shapefile,awsToken))

    Promise.all(clientUpdateRequests).then(results=>{
      const payload = {...clientDetailsState.w_o_profile}

      if(submitUpdateClientState.w_o_profile.shapefile !==null)
        payload['shape_file_url'] = results[2].url
      dispatch({
        type: ACTIONS.SET_W_O_PROFILE,
        payload: payload
      }) 
      tempStore.current['w_o_profile'] =  {...payload}
      setGetClientListState(SUBMIT_STATE.ok);
      setModalMessage(UPDATE_CLIENT_MODAL_MESSAGES.success);
    }).catch(err=>{
      setErrorMessage(err.message);
      setModalMessage(err.message);
      setGetClientListState(SUBMIT_STATE.error);
    })
  };

  // load client details when component mounts
  useEffect(() => {
    let isSubscribed = true;

    setIsLoading(true);

    getAdminClientDetails(awsToken, clientID,true)
      .then((result) => {
        const {
          name,
          shifts,
          device_count,
          monthly_limit,
          devices,
          geo_fencing,
          geo_info,
          service,
          CSAMS
        } = result;
        if (isSubscribed) {
          setIsLoading(false);
          // useRef to store the current useReducer state, in case onClear triggers the previous state to cover the new state
          const clientStateCopy = {
            id: clientID,
            name: name,
            device_count: device_count.toString(),
            monthly_limit: monthly_limit.toString(),
            shifts: ["00:00-23:59"],
            shifts_start_time_end_time: [["00:00:00", "23:59:00"]],
            geo_name: "",
            geo_id: 0,
            geo_province: "",
            geo_timezone: "",
            geo_lat: "",
            geo_lon: "",
            geo_fencing: [],
            service_id: 0,
            iris_city_id: 0,
            widgets: [],
            w_o_profile: {
              name: "",
              search_fields: [],
              defects: [],
              assignees: [],
              shape_file_url: "",
              shapefile: null,
            },
            logo:null,
            CSAMS:CSAMS
            // groupList: groupList,
          };

          dispatch({
            type: ACTIONS.SET_CLIENTNAME,
            payload: name,
          });
          dispatch({
            type: ACTIONS.SET_DEVICE_COUNT,
            payload: device_count.toString(),
          });
          dispatch({
            type: ACTIONS.SET_MONTHLY_LIMIT,
            payload: monthly_limit.toString(),
          });
          dispatch({
            type: ACTIONS.SET_CSAMS,
            payload: CSAMS,
          });

          // start time end time array temp
          const STETTemp = [];
          if (shifts.length > 0) {
            shifts.forEach((shift, index) => {
              const temp = shift.split("-");
              if (temp.length === 2) {
                // IrisTimeRangePickers component only accepts HH:mm:ss format
                STETTemp.push([
                  moment(temp[0], "HH:mm").format("HH:mm:ss"),
                  moment(temp[1], "HH:mm").format("HH:mm:ss"),
                ]);
              }
            });
            if (shifts.length > 1) {
              dispatch({
                type: ACTIONS.SET_SHIFTS_START_TIME_END_TIME,
                payload: STETTemp,
              });
              dispatch({ type: ACTIONS.SET_SHIFTS, payload: shifts });
              clientStateCopy.shifts_start_time_end_time = STETTemp;
              clientStateCopy.shifts = shifts;
            }
          }

          dispatch({
            type: ACTIONS.SET_GEO_ID,
            payload: geo_info.id,
          });
          dispatch({
            type: ACTIONS.SET_GEO_NAME,
            payload: geo_info.name,
          });
          dispatch({
            type: ACTIONS.SET_GEO_PROVINCE,
            payload: geo_info.province,
          });
          dispatch({
            type: ACTIONS.SET_GEO_TIMEZONE,
            payload: geo_info.timezone,
          });
          dispatch({
            type: ACTIONS.SET_GEO_LAT,
            payload: geo_info.lat.toString(),
          });
          dispatch({
            type: ACTIONS.SET_GEO_LON,
            payload: geo_info.lon.toString(),
          });
          clientStateCopy.geo_name = geo_info.name;
          clientStateCopy.geo_id = geo_info.id;
          clientStateCopy.geo_province = geo_info.province;
          clientStateCopy.geo_timezone = geo_info.timezone;
          clientStateCopy.geo_lat = geo_info.lat;
          clientStateCopy.geo_lon = geo_info.lon;

          // if geo fencing array is not empty, rebuild the geo fencing object
          if (geo_fencing) {
            if (geo_fencing.length > 0) {
              const geoFencingTemp = [];
              geo_fencing.forEach((oneGeo) => {
                const lat = oneGeo.lat;
                const lon = oneGeo.lon;
                if (lat !== undefined && lon !== undefined) {
                  // in case not both of the "lat" and "lon" property exist
                  const temp = {
                    lat: lat.toString(),
                    lon: lon.toString(),
                  };
                  geoFencingTemp.push(temp);
                }
              });
              dispatch({
                type: ACTIONS.SET_GEO_FENCING,
                payload: geoFencingTemp,
              });
              clientStateCopy.geo_fencing = geoFencingTemp;
            }
          }

          if (service) {
            dispatch({ type: ACTIONS.SET_SERVICE_ID, payload: service.id });
            clientStateCopy.service_id = service.id;
            if (service.iris_city) {
              dispatch({
                type: ACTIONS.SET_IRIS_CITY_ID,
                payload: service.iris_city.id,
              });
              dispatch({
                type: ACTIONS.SET_IRIS_CITY_NAME,
                payload: service.iris_city.name,
              });
              clientStateCopy.iris_city_id = service.iris_city.id;
              if (service.iris_city.widgets) {
                const widgetCopy = JSON.parse(
                  JSON.stringify(service.iris_city.widgets)
                );
                widgetRef.current = widgetCopy;
                dispatch({
                  type: ACTIONS.SET_WIDGETS,
                  payload: service.iris_city.widgets,
                });
                clientStateCopy.widgets = service.iris_city.widgets;
              }
              if (service.iris_city.w_o_profile) {
                // get the search fields from the api result and rebuild them according to SEARCH_FIELDS_OPTIONS
                const defaultSearchFields = [];
                if (
                  Array.isArray(service.iris_city.w_o_profile.search_fields)
                ) {
                  service.iris_city.w_o_profile.search_fields.forEach(
                    (fetchedFieldName) => {
                      const match = SEARCH_FIELDS_OPTIONS.find(
                        (option) => option.id === fetchedFieldName
                      );
                      // in case that fetched field name is one of the hard code option
                      if (match) {
                        defaultSearchFields.push(match);
                      }
                    }
                  );
                }

                //TODO to be removed: make sure one assignee has a dedicated element within service#iris_city#w_o_profile#options#assignee_defect_array for legacy data START
                const {
                  assignees = [],
                  options: { assignee_defect_array = [] },
                } = service.iris_city.w_o_profile;

                const assignDefectArray = Array(assignees.length).fill(null, 0);
                if (assignees.length > assignee_defect_array.length) {
                }
                if (assignees.length > 0) {
                  for (let i = 0; i < assignees.length; i++) {
                    const res = assignee_defect_array.find(
                      (ele) => ele.name === assignees[i]
                    );
                    if (res === undefined || res === null) {
                      assignDefectArray[i] = {
                        ...DEFAULT_ASSIGNEE_DEFECT_OBJECT,
                        name: assignees[i],
                      };
                    } else {
                      assignDefectArray[i] = res;
                    }
                  }
                }
                service.iris_city.w_o_profile.options.assignee_defect_array =
                  assignDefectArray;
                //TODO to be removed: make sure one assignee has a dedicated element within service#iris_city#w_o_profile#options#assignee_defect_array for legacy data END

                dispatch({
                  type: ACTIONS.SET_W_O_PROFILE,
                  payload: {
                    ...service.iris_city.w_o_profile,
                    search_fields: defaultSearchFields,
                    assignees: service.iris_city.w_o_profile.assignees
                      ? service.iris_city.w_o_profile.assignees
                      : [],
                    shape_file_url: service.iris_city.w_o_profile.shape_file_url,
                    shapefile: null
                  },
                });
                clientStateCopy.w_o_profile = {
                  ...service.iris_city.w_o_profile,
                  search_fields: defaultSearchFields,
                  assignees: service.iris_city.w_o_profile.assignees
                    ? service.iris_city.w_o_profile.assignees
                    : [],
                  shapefile: null
                };
              }
              if (service.iris_city.manual_app_profile) {
                const shift = service.iris_city.manual_app_profile.shift.join(',')
                const m_a_profile = {
                  ...service.iris_city.manual_app_profile,
                  'shift': shift,
                  'additional_setting': service.iris_city.manual_app_profile.additional_setting
                }
                dispatch({
                  type: ACTIONS.SET_M_A_PROFILE,
                  payload: m_a_profile,
                });

                clientStateCopy.m_a_profile = m_a_profile;

              }
            }
          }

          tempStore.current = clientStateCopy;
        }
      })
      .catch((error) => {
        setOpenBackdrop(true);
        setErrorMessage(error.message);
        setModalMessage(error.message);
        setGetClientListState(SUBMIT_STATE.error);
      });

    return () => (isSubscribed = false);
  }, [clientID, awsToken]);

  if (isLoading) {
    return (
      <div className={styles.clientDetails}>
        <h2 className={`${styles.pageChild} ${styles.header}`}>
          Client Information
        </h2>
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
        <SubmitBackdrop
          open={openBackdrop}
          onClick={handleClickBackdrop}
          submitState={getClientListState}
          modalMessage={modalMessage}
        />
      </div>
    );
  } else {
    return (
      <ClientDetailsUI
        clientID={clientID}
        pageHeader="Customer Information"
        clientDetailsState={clientDetailsState}
        updateClientName={(clientname) =>
          dispatch({ type: ACTIONS.SET_CLIENTNAME, payload: clientname })
        }
        updateDeviceCount={(deviceCount) =>
          dispatch({ type: ACTIONS.SET_DEVICE_COUNT, payload: deviceCount })
        }
        updateMonthlyLimit={(monthlyLimit) =>
          dispatch({ type: ACTIONS.SET_MONTHLY_LIMIT, payload: monthlyLimit })
        }
        updateStartTimeEndTime={(startTimeEndTime) => {
          dispatch({
            type: ACTIONS.SET_SHIFTS_START_TIME_END_TIME,
            payload: startTimeEndTime,
          });
        }}
        updateShifts={(shifts) =>
          dispatch({ type: ACTIONS.SET_SHIFTS, payload: shifts })
        }
        updateGeoName={(geoName) =>
          dispatch({ type: ACTIONS.SET_GEO_NAME, payload: geoName })
        }
        updateGeoLat={(geoLat) =>
          dispatch({ type: ACTIONS.SET_GEO_LAT, payload: geoLat })
        }
        updateGeoLon={(geoLon) =>
          dispatch({ type: ACTIONS.SET_GEO_LON, payload: geoLon })
        }
        updateGeoProvince={(geoProvince) =>
          dispatch({ type: ACTIONS.SET_GEO_PROVINCE, payload: geoProvince })
        }
        updateTimezone={(timezone) =>
          dispatch({ type: ACTIONS.SET_GEO_TIMEZONE, payload: timezone })
        }
        updateGeoFencing={(geoFencing) =>
          dispatch({ type: ACTIONS.SET_GEO_FENCING, payload: geoFencing })
        }
        updateWidgets={(widgets) =>
          dispatch({ type: ACTIONS.SET_WIDGETS, payload: widgets })
        }
        updateWOP={(wop) =>
          dispatch({ type: ACTIONS.SET_W_O_PROFILE, payload: wop })
        }
        updateGroupList={(groupList) =>
          dispatch({ type: ACTIONS.SET_GROUPLIST, payload: groupList })
        }
        updateManualAppProfile={(m_a_profile) => 
          dispatch({ type: ACTIONS.SET_M_A_PROFILE, payload: m_a_profile })
        }

        updateLogo={(file)=>
          dispatch({ type: ACTIONS.SET_LOGO, payload: file })
        }
        updateCSAMS={(data)=>
          dispatch({ type: ACTIONS.SET_CSAMS, payload: data })
        }
        resetTo={(state) => dispatch({ type: ACTIONS.RESET, payload: state })}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        openBackdrop={openBackdrop}
        submitState={getClientListState}
        modalMessage={modalMessage}
        handleClickBackdrop={handleClickBackdrop}
        handleUpdate={handleUpdate}
        tempStore={tempStore}
      />
    );
  }
};

export default ClientDetails;
