import React, { useState, createContext } from "react";

export const MainContext = createContext();
export const MainContextProvider = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [showData, setShowData] = useState(false);
  const [dataPoints, setDataPoints] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [roadRelatedIssues, setRoadRelatedIssues] = useState([]);
  const [MMSDefects, setMMSDefects] = useState([]);

  const [previousUrl, setPreviousUrl] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);

  const [resetTable, setResetTable] = useState(false);
  const [lookupData, setLookupData] = useState(false);

  const contextValue = {
    loadingData,
    showData,
    dataPoints, // display data points on map
    selectedData, // to get selected data road defect
    previousUrl,
    nextUrl,
    MMSDefects,
    roadRelatedIssues,
    resetTable,
    lookupData,
    setLookupData,
    setLoadingData,
    setPreviousUrl,
    setNextUrl,
    setMMSDefects,
    setShowData,
    setDataPoints,
    setSelectedData,
    setRoadRelatedIssues,
    setResetTable,
  };
  return (
    <MainContext.Provider value={contextValue}>
      {props.children}
    </MainContext.Provider>
  );
};
