import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PostOfficeTablePaginationActions from "./PostOfficeTablePaginationActions";
import PostOfficeEmailRow from "./PostOfficeEmailRow";
import PostOfficeEmailFormModal from "./PostOfficeEmailFormModal";
import { TOOL_BAR_HEIGHT } from "../../utils/theme/constrains";
import { IRIS_POSTOFFICE_API_KEY } from "../../utils/env/keys";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#555",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 150}px)`,
  },
});
const columns = [
  { id: "source", label: "Source", width: "3%" },
  { id: "subject", label: "Subject", width: "20%", align: "left" },
  {
    id: "sender",
    label: "Sender",
    width: "18%",
    align: "left",
  },
  {
    id: "recipient",
    label: "Recipient",
    width: "18%",
    align: "left",
  },
  {
    id: "time_requested",
    label: "Time Requested",
    width: "16%",
    align: "left",
  },
  {
    id: "time_scheduled",
    label: "Time Scheduled",
    width: "16%",
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    width: "9%",
  },
];

const PostOfficeTable = (props) => {
  const url = props.url;
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleEmailClick = (row) => {
    setSelectedRow({ ...row });
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalUpdateClose = () => {
    getItem();
    setModalOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getItem();
  }, [url, modalOpen]);

  const getItem = () => {
    let requestOptions = {
      method: "GET",
      headers: { Authorization: IRIS_POSTOFFICE_API_KEY },
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        const emails = [];
        data.results.map((email, index) => {
          let request_time = new Date(email["request_time"]);
          request_time.setHours(
            request_time.getHours() - request_time.getTimezoneOffset() / 60
          );
          request_time = request_time.toISOString();
          email["request_time"] = request_time;

          let scheduled_time = new Date(email["scheduled_time"]);
          scheduled_time.setHours(
            scheduled_time.getHours() - scheduled_time.getTimezoneOffset() / 60
          );
          scheduled_time = scheduled_time.toISOString();
          email["scheduled_time_text_field"] = scheduled_time;
          emails.push(email);
        });
        setRows(emails);
      });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <PostOfficeEmailFormModal
          row={selectedRow}
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
          handleModalUpdateClose={handleModalUpdateClose}
        />
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((col, index) => (
                <StyledTableCell
                  key={index}
                  align={col.align}
                  width={col.width}
                >
                  {col.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => (
              <PostOfficeEmailRow
                key={index}
                {...{ row }}
                handleEmailClick={handleEmailClick}
              />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 90 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={7}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={PostOfficeTablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default PostOfficeTable;
