import { IRIS_ADMIN_CONSOLE_USER_NAME, ACCESS_TOKEN } from "./keys";

/**
 * @summary get saved access token from the env file, the token is a **suer token**.
 * @returns access token value
 */
const getToken = () => {
  // const token = localStorage.getItem(ACCESS_TOKEN);
  const token = process.env.REACT_APP_SERVER_SUPER_KEY;
  return token || "";
};

/**
 * @summary save access token into local storage
 * @param {string} token access token to be saved
 */
const saveToken = (token) => localStorage.setItem(ACCESS_TOKEN, token);

/**
 * @summary save user name into local storage
 * @param {string} value user name
 */
const saveUserName = (value) =>
  localStorage.setItem(IRIS_ADMIN_CONSOLE_USER_NAME, value);

/**
 * @summary get saved user name from the local storage
 * @returns user name
 */
const getUserName = () => {
  return localStorage.getItem(IRIS_ADMIN_CONSOLE_USER_NAME) || "";
};

export { getToken, saveToken, getUserName, saveUserName };
