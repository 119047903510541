import React from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import ListItemText from "@mui/material/ListItemText";
import PermDeviceInformationIcon from "@mui/icons-material/PermDeviceInformation";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import EmailIcon from "@mui/icons-material/Email";
import ReportIcon from "@mui/icons-material/Report";
import DownloadIcon from "@mui/icons-material/Download";
import GroupIcon from "@mui/icons-material/Group";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { SIDE_MENU_WIDTH, TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  drawer: {
    width: SIDE_MENU_WIDTH,
  },
  drawerPaper: {
    width: SIDE_MENU_WIDTH,
  },
  drawerContainer: {
    marginTop: TOOL_BAR_HEIGHT,
    overflow: "auto",
  },
  listItem: {
    height: 55,
  },
  menuText: {
    weight: 300,
  },
  highlightMenuText: {
    fontWeight: 600,
  },
}));

export default function SideMenu() {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <List>
          <ListItem
            button
            component={Link}
            to="/user"
            className={classes.listItem}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.highlightMenuText }}
              primary="User Profiles"
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/user/city"
            className={classes.listItem}
          >
            <ListItemIcon />
            <ListItemText
              primaryTypographyProps={{ className: classes.menuText }}
              primary="Cities"
            />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/" className={classes.listItem}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.highlightMenuText }}
              primary="Dashboard"
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            component={Link}
            to="/notifications"
            className={classes.listItem}
          >
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.highlightMenuText }}
              primary="Notifications"
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/download"
            className={classes.listItem}
          >
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.highlightMenuText }}
              primary="Download Data"
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            component={Link}
            to="/labels"
            className={classes.listItem}
          >
            <ListItemIcon>
              <BookmarkIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.highlightMenuText }}
              primary="Label Types"
            />
          </ListItem>
          <Divider />

          <ListItem
            button
            component={Link}
            to="/mobile-device"
            className={classes.listItem}
          >
            <ListItemIcon>
              <PermDeviceInformationIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.highlightMenuText }}
              primary="Mobile Devices"
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/mobile/register"
            className={classes.listItem}
          >
            <ListItemIcon />
            <ListItemText
              primaryTypographyProps={{ className: classes.menuText }}
              primary="Register Device"
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/mobile/report"
            className={classes.listItem}
          >
            <ListItemIcon />
            <ListItemText
              primaryTypographyProps={{ className: classes.menuText }}
              primary="Defect Reports"
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            component={Link}
            to="/postoffice"
            className={classes.listItem}
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.highlightMenuText }}
              primary="Post Office"
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            component={Link}
            to="/static"
            className={classes.listItem}
          >
            <ListItemIcon>
              <ReportIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.highlightMenuText }}
              primary="Static Devices"
            />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/static/report"
            className={classes.listItem}
          >
            <ListItemIcon />
            <ListItemText
              primaryTypographyProps={{ className: classes.menuText }}
              primary="Incident Reports"
            />
          </ListItem>
          <Divider />
          <ListItem
            button
            component={Link}
            to="/mobile/usage"
            className={classes.listItem}
          >
            <ListItemIcon>
              <DataUsageIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.highlightMenuText }}
              primary="Usage Reports"
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}
