import React from "react";
import AdminConsole from "./components/AdminConsole";
import Login from "./pages/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { AuthContextProvider } from "./context/AuthContext";
import NotFound from "./pages/PageNotFound/PageNotFound";
import { MainContextProvider } from "./context/MainContext";
import IRISThemeProvider from "./utils/theme/theme";
import LabelTypesTable from "./pages/labelTypePage/LabelTypesFinder";
import GlobalUI from "./UI/global/GlobalUI";
import withReactQuery from "HOC/withReactQuery/withReactQuery";
import SidebarWithHeader from "components/sidebarWithHeader/SideBarWithHeader";
import { routesData, routesPaths } from "routes/routesData";
import ForgetPasswordPage from "pages/forgetPasswordPage/ForgetPasswordPage";

export const RootProvider = (props) => {
  return (
    <IRISThemeProvider>
      <GlobalUI>
        <AuthContextProvider>
          <MainContextProvider>
            <Router>{props.children}</Router>
          </MainContextProvider>
        </AuthContextProvider>
      </GlobalUI>
    </IRISThemeProvider>
  );
};

function App() {
  return (
    <RootProvider>
      <Switch>
        <PrivateRoute
          exact
          path={
            routesPaths
            //   [
            //   "/",
            //   "/mobile-device",
            //   "/mobile-device/:id",
            //   "/labels",
            //   "/mobile/usage",
            //   "/mobile/report",
            //   "/mobile/register",
            //   "/static",
            //   "/static/usage",
            //   "/static/report",
            //   "/download",
            //   "/user",
            //   "/user/signup",
            //   "/user/reset-password",
            //   "/user-details/:id",
            //   "/user/city",
            //   "/user/city-details/:id",
            //   "/postoffice",
            //   "/postoffice/history",
            // ]
          }
          // component={AdminConsole}
          component={() => <SidebarWithHeader routesData={routesData} />}
        />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forget-password" component={ForgetPasswordPage} />
        <Route path="*" component={NotFound} />
      </Switch>
    </RootProvider>
  );
}

export default withReactQuery(App);
