import React, { useContext } from "react";

import { MOBILE_DEVICE_LIST_V3_REQUEST } from "../utils/requests";

import IrisTable from "../UI/muiTable/IrisTable";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { DEVICE_AUG_KEY_MAP } from "../api/apiUtils";
import { Button } from "@mui/material";
import { useQuery } from "react-query";
import { AuthContext } from "context/AuthContext";

export default function MobileDevices(props) {
  const { awsToken } = useContext(AuthContext);
  const { data, isFetching, status, refetch } = useQuery(
    ["GET_MOBILE_DEVICE_LIST"],
    () => MOBILE_DEVICE_LIST_V3_REQUEST(awsToken),
    {
      refetchInterval: 30 * 1000,
    }
  );

  const deviceList = data || [];

  const loadDeviceDetails = (id) => (event) => {
    const temp0 = props.history.location.pathname.trim();
    let temp1 = "";
    // if manually input the url and end it with "/"
    if (temp0.slice(-1) === "/") {
      temp1 = temp0.slice(0, -1);
    } else {
      temp1 = temp0;
    }
    props.history.push(`${temp1}/${id}`);
  };

  const columns = [
    {
      fieldName: DEVICE_AUG_KEY_MAP.ACTIVE,
      headTitle: "Online",
      element: (data, key, index) => {
        const isOnline = data[key];
        if (isOnline) {
          return (
            <CheckIcon
              sx={{
                color: "success.main",
              }}
            />
          );
        }
        return (
          <CloseIcon
            sx={{
              color: "warning.main",
            }}
          />
        );
      },
    },
    { fieldName: "id", headTitle: "Device ID" },
    {
      fieldName: "tag",
      headTitle: "Deivce Name",
      element: (data, key, index) => (
        <Button
          color="primary"
          variant="outlined"
          size="small"
          sx={{
            fontSize: 14,
          }}
          disabled={data[DEVICE_AUG_KEY_MAP.ACTIVE === true]}
          onClick={loadDeviceDetails(data.id)}
        >
          {data[key]}
        </Button>
      ),
    },
    {
      fieldName: DEVICE_AUG_KEY_MAP.CITY_LOCAL,
      headTitle: "Customer",
      element: (data, key, index) => {
        if (data[key]) {
          return data[key];
        }
        return "Unknown";
      },
    },
    { fieldName: "device_sn", headTitle: "Device SN" },
    { fieldName: "serial_number", headTitle: "Serial Number" },
    {
      fieldName: "rotate",
      headTitle: "Orientation",
      element: (data, key, index) => {
        if (data[key]) {
          return "Normal";
        }
        return "Rotated";
      },
    },
    {
      fieldName: DEVICE_AUG_KEY_MAP.COUNT_LOCAL,
      headTitle: "Uploaded Today",
    },
    {
      fieldName: DEVICE_AUG_KEY_MAP.TIMESTAMP_LOCAL,
      headTitle: "Latest Activity",
      element: (data, key) => new Date(data[key]).toLocaleString(),
    },
  ];

  if (status === "error") {
    return <h2>Error in loading device list from server</h2>;
  }
  return (
    <IrisTable
      tableTitle="Mobile Device List"
      onRefresh={refetch}
      data={deviceList}
      isLoading={isFetching}
      columns={columns}
      idField="id"
      ignoreFields={["id"]}
    />
  );
}
