import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import FormControl from "@mui/material/FormControl";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

/**
 * @param {object} props
 * @param {string} props.title title for the time pickers, ie: Schedule, etc
 * @param {boolean} [props.disabled] if true, time picker would not be toggleable, false other wise
 * @param {Date} props.value selected date object
 * @param {{minDate: Date, maxDate: Date}} [props.dateRange] date range for date picker
 * @param {(value: string) => {}} props.onChange callback function when time is updated
 * @param {import("@mui/material").TextFieldProps} props.inputProps MUI InputProps
 *
 * @example
 * // only time part is shown on TextInput as string value
 * const minDate = new Date();
   minDate.setMonth(minDate.getMonth() - 1);
   const [date, setDate] = useState(new Date());
   return (
     <IrisDatePicker
       title="Current Date"
       value={date}
       onChange={(newDate) => {
         setDate(newDate);
       }}
       dateRange={{ minDate: minDate, maxDate: new Date() }}
     />
   );
 */
export default function IrisDatePicker(props) {
  const {
    title,
    value,
    dateRange,
    disabled,
    onChange,
    inputProps: ipProps,
  } = props;

  let inputProps = ipProps || {
    variant: "standard",
    disabled: disabled,
  };

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={title}
          InputProps={{
            readOnly: true,
          }}
          disabled={disabled}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} {...inputProps} />}
          minDate={dateRange?.minDate}
          maxDate={dateRange?.maxDate}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
