const { API_GATEWAY_CLOUD_WATCH_LOG_ROOT_URL } = require("utils/env/urls");

/**
 *
 * @param {LogStreamAPIPayloadInterface} params
 * @param {string} accessToken
 * @param {AbortSignal} [signal]
 *
 * @returns {Promise<LogStreamResolveInterface>}
 */

export const getLogStreams = async (params, accessToken, signal) => {
  const {
    descending,
    limit,
    logGroupName,
    logStreamNamePrefix,
    nextToken,
    orderBy,
  } = params;
  /**@type {LogStreamAPIPayloadInterface} */
  let payload = {
    logGroupName,
  };

  if (descending) {
    payload = { ...payload, descending };
  }
  if (limit) {
    payload = { ...payload, limit };
  }
  if (logStreamNamePrefix) {
    payload = { ...payload, logStreamNamePrefix };
  }
  if (nextToken) {
    payload = { ...payload, nextToken };
  }
  if (orderBy) {
    payload = { ...payload, orderBy };
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload),
    signal,
  };
  return new Promise(async (resolve, reject) => {
    let error;
    const response = await fetch(
      `${API_GATEWAY_CLOUD_WATCH_LOG_ROOT_URL}/logstreams/`,
      options
    ).catch((e) => {
      error = e;
    });
    if (error) {
      reject(error);
    } else if (response.ok) {
      const responseJson = await response.json();

      // error returned from API gateway could be status 200, check whether resovle object have this field to indicate whether such error occurred.
      const errorMessage = responseJson.message;
      if (errorMessage) {
        reject(new Error(errorMessage));
      }
      resolve(responseJson);
    } else {
      const { status } = response;
      const errorObj = await response.json();
      if (status === 401 || status === 403) {
        error = new Error(errorObj.message);
      } else if (status === 404) {
        error = new Error("Page Not Found.");
      } else if (status >= 500) {
        error = new Error("Server Error.");
      } else {
        error = new Error("Unknown Error");
      }
      reject(error);
    }
  });
};

/**
 *
 * @param {LogEventAPIPayloadInterface} params
 * @param {String} accessToken
 * @param {AbortSignal} [signal]
 *
 * @returns {Promise<LogEventAPIReturnPayloadInterface>}
 */
export const getLogEvents = async (params, accessToken, signal) => {
  const {
    endTime,
    limit,
    logGroupName,
    logStreamName,
    nextToken,
    startFromHead,
    startTime,
  } = params;

  const payload = {
    logGroupName,
    logStreamName,
  };

  if (endTime) {
    payload.endTime = endTime;
  }
  if (limit) {
    payload.limit = limit;
  }
  if (nextToken) {
    payload.nextToken = nextToken;
  }
  if (startFromHead) {
    payload.startFromHead = startFromHead;
  }
  if (startTime) {
    payload.startTime = startTime;
  }

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload),
    signal,
  };
  return new Promise(async (resolve, reject) => {
    let error;
    const response = await fetch(
      `${API_GATEWAY_CLOUD_WATCH_LOG_ROOT_URL}/logevents/`,
      options
    ).catch((e) => {
      error = e;
    });

    if (error) {
      reject(error);
    } else if (response.ok) {
      const responseJson = await response.json();

      // error returned from API gateway could be status 200, check whether resovle object have this field to indicate whether such error occurred.
      const errorMessage = responseJson.message;
      if (errorMessage) {
        reject(new Error(errorMessage));
      }
      resolve(responseJson);
    } else {
      const { status } = response;
      const errorObj = await response.json();
      if (status === 401 || status === 403) {
        error = new Error(errorObj.message);
      } else if (status === 404) {
        error = new Error("Page Not Found.");
      } else if (status >= 500) {
        error = new Error("Server Error.");
      } else {
        error = new Error("Unknown Error");
      }
      reject(error);
    }
  });
};
