import React, { useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import { AuthContext } from "../context/AuthContext";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IrisGoLogo from "../assets/IrisGoLogo.png";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

const useStyles = makeStyles((theme) => ({
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
    height: TOOL_BAR_HEIGHT,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: TOOL_BAR_HEIGHT,
  },
  irisGoLogo: {
    objectFit: "contain",
    height: TOOL_BAR_HEIGHT - 20,
  },
  menuButton: {
    borderRadius: "0",
    height: TOOL_BAR_HEIGHT,
  },
  menuText: {
    marginRight: "10px",
    marginLeft: "10px",
  },
}));

export default function Header() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { contextUsername, onLogout } = useContext(AuthContext);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    onLogout();
  };

  return (
    <AppBar position="fixed" className={classes.appbar}>
      <Toolbar className={classes.toolbar}>
        <img src={IrisGoLogo} className={classes.irisGoLogo} alt="IrisGO" />
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          className={classes.menuButton}
        >
          <Typography variant="h6" className={classes.menuText}>
            {contextUsername}
          </Typography>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          // getContentAnchorEl={null}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
