import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { MOBILE_DEVICE_CITY_LOOKUP_REQUEST } from "../utils/requests";
import { AuthContext } from "../context/AuthContext";
import IrisTable from "UI/muiTable/IrisTable";

const useStyles = makeStyles((theme) => ({}));

const header = [
  {
    title: "ID",
    field: "id",
    editable: "never",
  },
  {
    title: "Logo",
    render: (rowData) => (
      <img
        src={rowData.logo}
        width="60px"
        styles={{ objectFit: "contain", margin: "auto" }}
        alt="logo"
      />
    ),
  },
  {
    title: "City",
    field: "name",
    editable: "never",
  },
  {
    title: "Address",
    field: "province",
    editable: "never",
  },
];

export default function MobileDevices() {
  const classes = useStyles();
  const { contextToken } = useContext(AuthContext);
  const [cityList, setCityList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isSubscribed = true;
    MOBILE_DEVICE_CITY_LOOKUP_REQUEST(contextToken)
      .then((result) => {
        if (isSubscribed) {
          let cityList = [];
          Object.entries(result.city).forEach((i) => {
            cityList.push({ id: i[0], ...i[1] });
          });
          setCityList(cityList);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => (isSubscribed = false);
  }, [contextToken, classes]);

  return (
    <IrisTable
      isLoading={isLoading}
      data={cityList}
      idField="id"
      columns={[
        { fieldName: "id", headTitle: "ID" },
        {
          fieldName: "logo",
          headTitle: "Logo",
          element: (data, key, index) => (
            <img
              style={{ maxWidth: 180 }}
              src={data[key]}
              alt={`logo of ${data.name}`}
            />
          ),
        },
        { fieldName: "name", headTitle: "City" },
        { fieldName: "province", headTitle: "Province" },
      ]}
    />
  );
}
