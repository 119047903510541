import React from "react";

import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import LoaderView from "./LoaderView";
import { useLoader } from "../context/LoaderContext";

const LoaderModal = () => {
  const { isLoading } = useLoader();
  return (
    <Modal
      disableEnforceFocus
      id="iris-ui-global-custom-loader"
      aria-labelledby="custom-fullscreen-loading-modal"
      aria-describedby="custom-fullscreen-loading-modal"
      open={isLoading}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isLoading}>
        <LoaderView />
      </Fade>
    </Modal>
  );
};

export default LoaderModal;
