const API_ERROR_MESSAGES = {
  ACCESS_DENIED: "ACCESS DENIED",
  PAGE_NOT_FOUND: "PAGE NOT FOUND",
  GENERAL_SERVER_ERROR: "SERVER ERROR",
  UNDEFINED_ERROR: "UNDEFINED ERROR",
};

// for adding new fields to device list
// for ui purpose
export const DEVICE_AUG_KEY_MAP = {
  ACTIVE: "active",
  CITY_LOCAL: "customer_name",
  COUNT_LOCAL: "daily_upload",
  TIMESTAMP_LOCAL: "active_time",
};

/**
 *
 * @param {Response} response
 */
const getRequestError = (response) => {
  if (!response) return undefined;
  const { status } = response;
  if (status < 400) return undefined;
  else if (status === 401 || status === 403)
    return new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
  else if (status === 404) return new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
  else if (status >= 500)
    return new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
  return new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
};
export { API_ERROR_MESSAGES, getRequestError };
