import { LoadingButton } from "@mui/lab";
import { FormControl, TextField } from "@mui/material";
import { ResetPasswordTextField } from "components/resetPasswordTextField/ResetPasswordTextField";
import {
  AWS_PASSWORD_MIN_LENGTH,
  testAWSPassword,
} from "components/utils/aws/authUtils";
import useInput from "hooks/userInput/userInput";
import React, { forwardRef, useState } from "react";
import { useNotification } from "UI/global/notification/context/NotificationContext";
import Auth from "api/awsCognito/AuthUtils";
import VerificationCodeInput from "components/inputs/verificationCodeInput/VerificationCodeInput";

/**
 *
 * @description
 * A from element suports both `reset password` mode and `forget password` mode.
 *
 * On either mode, `userName` is required.
 *
 * On `forget password` mode, extra input for `verification code` would be provided to user as its value
 * is required.
 *
 * @param {object} props
 * @param {React.HTMLAttributes} props.formProps
 * @param {string} props.userName `userName` that is required to reset password.
 * @param {string} [props.verificationCode] a string, should all be digits, which is required to submit a forget password request.
 * @param {() => {}} props.onResetSuccess callback function which should be called when reset password request is sent successfully.
 * @param {"FORCE_RESET" | "FORGET_PASSWORD"} props.mode if `FORGET_PASSWORD`, verification code input should be show as it is required to send submit forget password request.
 * @param {string} props.value either **session** or **verification code** value
 * @param {string} [props.title] form title
 * @param {string} [props.onResendVerificationCode] callback function for the `buuton` on the verification code `input`, which is design to reqest another `verification code`.
 * @returns
 */
function ResetPasswordForm(props, ref) {
  const { showNotification } = useNotification();

  const { formProps, value, mode, userName, title, onResendVerificationCode } =
    props;
  /*================================================== states START ================================================== */
  const [verificationCode, setVerificationCode] = useState("");
  const [password0, changePassword0, resetPassword0] = useInput("");
  const [password1, changePassword1, resetPassword1] = useInput("");
  const [isResetingPassword, setIsResettingPassword] = useState(false);
  /*==================================================  states END  ================================================== */

  /**
   * test whether the new password matches all requirements.
   * - at least 1 `digit`.
   * - at least 1 `lower` case character.
   * - at least 1 `upper` case character.
   * - at least 1 `special` character.
   *
   * @returns {boolean} if true, confrim password input should be disabled.
   */
  const isPasswordValid = () => {
    const password = password0.trim();
    const { result } = testAWSPassword(password);

    return result;
  };

  const shoulResetButtonDisabled = () => {
    if (mode === "FORGET_PASSWORD" && verificationCode.length < 6) {
      return true;
    }
    const pass0 = password0.trim();
    const pass1 = password1.trim();

    const samelength =
      pass0.length >= AWS_PASSWORD_MIN_LENGTH && pass0 === pass1;

    const { result } = testAWSPassword(pass0);

    return !samelength || !result;
  };

  /**
   * handle feset password request.
   *
   * @description base on different modes, different request would be sent.
   *
   * if `mode` is reset password, `Auth#completeNewPassword` would be send.
   *
   * if `mode` is forget password, `Auth#forgotPasswordSubmit` would be send.
   */
  const handleRestPassword = async () => {
    const newPassword = password0.trim();
    let isResetSuccess = false;
    try {
      setIsResettingPassword(true);
      if (mode === "FORCE_RESET") {
        // no verification code is required.
        await Auth.completeNewPassword(userName, newPassword, value);
      } else if (mode === "FORGET_PASSWORD") {
        await Auth.forgotPasswordSubmit(
          userName,
          newPassword,
          verificationCode
        );
      }
      showNotification("Password is reset", "top-center", "success");
      resetPassword0();
      resetPassword1();
      isResetSuccess = true;
    } catch (error) {
      console.error(error);
      showNotification("Error in reset password", "top-center", "error");
    } finally {
      setIsResettingPassword(false);
      if (isResetSuccess) {
        props.onResetSuccess();
      }
    }
  };

  return (
    <form
      ref={ref}
      {...formProps}
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      {title && <h3>{title}</h3>}
      {/* {verificationCodeInput} */}
      {mode === "FORGET_PASSWORD" && (
        <VerificationCodeInput
          TextFieldProps={{
            placeholder: "Verification code",
            variant: "standard",
            autoFocus: true,
            value: verificationCode,
            onChange: (event) => setVerificationCode(event.target.value),
          }}
          onResendVerificationCode={onResendVerificationCode}
        />
      )}
      <FormControl fullWidth>
        <ResetPasswordTextField
          placeholder="New Password"
          type="password"
          value={password0}
          onChange={changePassword0}
          autoFocus={mode === "FORCE_RESET"}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          autoComplete="off"
          placeholder="Confirm New Password"
          variant="standard"
          type="password"
          disabled={!isPasswordValid()}
          value={password1}
          onChange={changePassword1}
          helperText={
            password1.trim().length > 0 && password1.trim() === password0.trim()
              ? "Matched"
              : password1.trim().length === 0
              ? ""
              : "Mismatched"
          }
        />
      </FormControl>

      <LoadingButton
        style={{ width: 120, marginLeft: "auto" }}
        variant="contained"
        color="primary"
        loading={isResetingPassword}
        disabled={shoulResetButtonDisabled()}
        onClick={handleRestPassword}
        type="button"
      >
        Reset
      </LoadingButton>
    </form>
  );
}

export default forwardRef(ResetPasswordForm);
