import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import { TOOL_BAR_HEIGHT } from "../../utils/theme/constrains";

const options = [
  "Complete the welcome page without signing in",
  "Turn off Emergency Alerts: Settings → Apps & Notifications → Advanced → Allow Alerts switch toggled to off",
  "Installing Hexnode by signing in with the account name afw#hexnodemdm",
  "Entering server name irisradgroup.hexnodemdm.com",
  "Signing in the Hexnode user account for the device",
];

const useStyles = makeStyles(() => ({
  listContainer: {
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 255}px)`,
  },
}));

export default function RegisterDevice({ onNext }) {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [disableNext, setDisableNext] = useState(true);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    if (options.length === checked.length) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
  }, [checked]);

  return (
    <div>
      <DialogTitle>Register a New Device</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Complete all of the following steps on the device and click next
        </DialogContentText>
        <List className={classes.listContainer}>
          {options.map((value) => {
            return (
              <ListItem
                key={value}
                role={undefined}
                dense
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={value} />
              </ListItem>
            );
          })}
        </List>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onNext}
          variant="contained"
          color="primary"
          disabled={disableNext}
        >
          Next
        </Button>
      </DialogActions>
    </div>
  );
}
