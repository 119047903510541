import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import React, { useState } from "react";

import styles from "./components/notifications.module.css";
import { makeStyles } from "@mui/styles";
import TabPanel from "components/mobile/TabPanel";

import SendNotifications from "./sendNotifications";
import NotificationGroup from "./components/NotificationGroup";

const useStyles = makeStyles((theme) => ({
    tabs: {
        color: "#757575",
        "& .MuiTabs-indicator": {
            height: 0,
        },
        "& .MuiTab-root.Mui-selected": {
            color: "#ffff",
            backgroundColor: theme.palette.primary.main,
        },
    },
    tab: {
        fontSize: "1rem",
        "&:hover": {
            backgroundColor: "#e0e0e0",
        },
    },
}));


const Notifications = () => {
    const classes = useStyles();

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const sections = [
        {
            name: 'Send',
            content: (
                <SendNotifications></SendNotifications>
            )

        },
        {
            name: 'Groups',
            content: (
                <NotificationGroup
                ></NotificationGroup>
            )

        },

    ]

    const handleChangeTab = (e, newValue) => {
        setActiveTabIndex(newValue);
    };

    return (
        <div className={styles.clientDetails}>
            <h2 className={`${styles.pageChild} ${styles.header}`}>
                Notifications
            </h2>
            <div className={styles.tabs}>
                <Tabs
                    className={classes.tabs}
                    value={activeTabIndex}
                    onChange={handleChangeTab}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        style: { transition: "none" },
                    }}
                >
                    {sections.map((section, index) => (
                        <Tab key={index} className={classes.tab} label={section.name} />
                    ))}
                </Tabs>
            </div>
            {sections.map((section, index) => (
                <TabPanel
                    key={index}
                    value={activeTabIndex}
                    index={index}
                    className={styles.tabPanel}
                >
                    {section.content}
                </TabPanel>
            ))}


        </div>
    );
};

export default Notifications;