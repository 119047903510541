
import React, { useContext, useEffect, useState } from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Button,
    TextField} from "@mui/material";
import { createNotificationGroup } from "utils/requests";
import { AuthContext } from "context/AuthContext";
import SubmitBackdrop, { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";


const CREATE_NOTIFICATION_MODAL_MESSAGES = {
    notSend: "No request is sent.",
    sending: "Create group request is sent. Please wait for a while.",
    success: "Notification information is create successfully.",
    error: "Notification group create unsuccessful",
};
const ManageNotificationGroup = (props) => {


    const { clientID, deviceList, filterGroupTypes } = props;
    const [selectedGroupType, setSelectedGroupType] = useState({});

    const [selectedDevice, setSelectedDevice] = useState(-1);
    const [throttle, setThrottle] = useState(-1);
    const [alarmType, setAlarmType] = useState('None');
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [submitState, setSubmitState] = useState(SUBMIT_STATE.ok);
    const [modalMessage, setModalMessage] = useState(
        CREATE_NOTIFICATION_MODAL_MESSAGES.notSend
    );



    const { awsToken } = useContext(AuthContext);

    const alarmList = [
        "Check Device Offline",
        "Excessive Camera Angle",
        "Inside Geofence",
        "Schedule Nonconformity",
        "Admin Status",
        "Smart Distance Warning",
        "Smart Distance",
        "Outside Geofence",
        "Insufficient Camera Angle",
        "Battery-Alarm"

    ];

    useEffect(() => {
        if (filterGroupTypes?.length > 0) {
            setSelectedGroupType(filterGroupTypes[0])
        }
    }, [filterGroupTypes])

    const handleClickBackdrop = () => {
        setOpenBackdrop(false);
        setSubmitState(SUBMIT_STATE.notSend);
        setModalMessage(CREATE_NOTIFICATION_MODAL_MESSAGES.notSend);
    };

    const createGroupObject = () => {
        const newNotificationGroup = {
            "filterType": selectedGroupType.id,
            "client": clientID,
            'throttle': Math.trunc(throttle)
        }
        const supportingData = {}

        if (selectedGroupType.name === "Alarm Type")
            supportingData['alarmType'] = alarmType
        else if (selectedGroupType.name === "Device ID")
            supportingData['deviceID'] = selectedDevice
        else if (selectedGroupType.name === "Device ID & Alarm Type") {
            supportingData['alarmType'] = alarmType
            supportingData['deviceID'] = selectedDevice
        }

        newNotificationGroup['supportingData'] = supportingData
        return newNotificationGroup
    }

    const isInvalidGroup = (newNotificationGroup) => {
        const supportingData = newNotificationGroup['supportingData']
        return Object.keys(supportingData).some(key => supportingData[key] === "None" || supportingData[key] === -1) || isNaN(throttle)
    }
    const handleCreateGroup = () => {
        const newNotificationGroup = createGroupObject()
        setOpenBackdrop(true)
        setSubmitState(SUBMIT_STATE.sending)
        setModalMessage(CREATE_NOTIFICATION_MODAL_MESSAGES.sending)
        let error = false
        createNotificationGroup(awsToken, newNotificationGroup)
            .catch((err) => error = true)
            .finally(() => {
                if (!error) {
                    setSubmitState(SUBMIT_STATE.ok)
                    setModalMessage(CREATE_NOTIFICATION_MODAL_MESSAGES.success)
                    setOpenBackdrop(false)
                }
                setSubmitState(SUBMIT_STATE.error)
                setModalMessage(CREATE_NOTIFICATION_MODAL_MESSAGES.error)

            })
    }



    const renderSwitch = (selectedGroupType) => {
        switch (selectedGroupType.code) {
            case 'DI':
                return <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="Device ID">Device ID</InputLabel>
                        <Select
                            id="Device ID"
                            value={selectedDevice}
                            label="Device ID"
                            onChange={(e) => setSelectedDevice(e.target.value)}
                        >
                            <MenuItem value={-1}>None</MenuItem>
                            {deviceList.map((device, index) => <MenuItem value={device.id} key={index}>{device.tag}</MenuItem>)}

                        </Select>
                    </FormControl>
                </Grid>;
            case 'DI&AT':
                return <>
                    <Grid xs={2} style={{ marginRight: "2vw" }}>
                        <FormControl fullWidth>
                            <InputLabel id="Device ID">Device ID</InputLabel>
                            <Select
                                id="Device ID"
                                value={selectedDevice}
                                label="Device ID"
                                onChange={(e) => setSelectedDevice(e.target.value)}
                            >
                                <MenuItem value={-1}>None</MenuItem>
                                {deviceList.map((device, index) => <MenuItem value={device.id} key={index}>{device.tag}</MenuItem>)}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="Alarm Type">Alarm Type</InputLabel>
                            <Select
                                id="Alarm Type"
                                value={alarmType}
                                label="Alarm Type"
                                onChange={(e) => setAlarmType(e.target.value)}
                            >
                                <MenuItem value={'None'}>None</MenuItem>
                                {alarmList.map((alarmName) => <MenuItem value={alarmName}>{alarmName}</MenuItem>)}

                            </Select>
                        </FormControl>
                    </Grid>
                </>;
            default:
                return <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="Alarm Type">Alarm Type</InputLabel>
                        <Select
                            id="Alarm Type"
                            value={alarmType}
                            label="Alarm Type"
                            onChange={(e) => setAlarmType(e.target.value)}
                        >
                            <MenuItem value={'None'}>None</MenuItem>
                            {alarmList.map((alarmName) => <MenuItem value={alarmName}>{alarmName}</MenuItem>)}

                        </Select>
                    </FormControl>
                </Grid>
        }
    }


    return (
        <Grid container rowSpacing={2}>
            <Grid item container>
                <FormControl fullWidth>
                    <TextField
                        id="outlined-number"
                        label="Throttle(Seconds)"
                        type="number"
                        InputProps={{
                            inputProps: { 
                                min: -1 
                            }
                        }}
                        style={{width:"9.5vw"}}
                        value={throttle}
                        onChange={(event) => {
                            const value = event.target.value;
                            if (!isNaN(value) && Number(value)>= -1)
                                setThrottle(value);
                        }}

                    />
                </FormControl>
            </Grid>
            <Grid item container>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="Notification Group Type">Notification Group Type</InputLabel>
                        <Select
                            id="Notification Group Type"
                            value={selectedGroupType}
                            label="Notification Group Type"
                            onChange={(e) => setSelectedGroupType(e.target.value)}
                        >
                            {filterGroupTypes.map((group) => <MenuItem value={group}>{group.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item container>
                {renderSwitch(selectedGroupType)}
            </Grid>
            <Grid item container>
            </Grid>
            <Grid item container>
                <Grid item xs={3} style={{ marginLeft: "12.5vw" }}>
                    <Button disabled={isInvalidGroup(createGroupObject())} onClick={() => { handleCreateGroup() }} variant="contained">Create Group</Button>
                </Grid>
            </Grid>
            <SubmitBackdrop
                open={openBackdrop}
                onClick={handleClickBackdrop}
                submitState={submitState}
                modalMessage={modalMessage}
            />
        </Grid>
    )
}


export default ManageNotificationGroup