import React from "react";
import AlertContextProvider from "./alert/context/AlertContext";
import ConfirmContextProvider from "./confirm/context/ConfirmContext";
import { LoaderContextProvider } from "./loader/context/LoaderContext";
import NotificationView from "./notification/context/NotificationContext";

function GlobalUI({ children }) {
  return (
    <LoaderContextProvider>
      <AlertContextProvider>
        <ConfirmContextProvider>
          <NotificationView>{children}</NotificationView>
        </ConfirmContextProvider>
      </AlertContextProvider>
    </LoaderContextProvider>
  );
}

export default GlobalUI;
