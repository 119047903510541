
import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import Edit from "@mui/icons-material/Edit";
import {
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText
} from "@mui/material";
import EditNotificationGroup from "./EditNotification";
import IrisTable from "UI/muiTable/IrisTable";
import { deleteNotificationGroups, getNotificationGroups, updateNotificationGroup, updateNotificationGroupMemebers } from "utils/requests";
import { AuthContext } from "context/AuthContext";
import SubmitBackdrop, { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";


const UPDATE_NOTIFICATION_MODAL_MESSAGES = {
    notSend: "No request is sent.",
    sending: "Update group request is sent. Please wait for a while.",
    sendingDelete: "Delete group request is sent. Please wait for a while.",
    success: "Notification information is updated successfully.",
    error: "Notification group updated unsuccessful",
};

const NotificationInfo = (props) => {

    const { clientID, clientName, emails, userContacts } = props;
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState({});
    const { awsToken } = useContext(AuthContext);

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [submitState, setSubmitState] = useState(SUBMIT_STATE.ok);
    const [modalMessage, setModalMessage] = useState(
        UPDATE_NOTIFICATION_MODAL_MESSAGES.notSend
    );

    useEffect(() => {
        let isSubscribed = false;
        if (clientID) {
            isSubscribed = true;
            getNotificationGroups(awsToken, 'device')
                .then((res) => {
                    if (isSubscribed) {
                        const groups = res.results.filter(group => group.client === Number(clientID))
                        setGroups(groups)
                    }
                }).catch((err) => {
                    // console.log(err)
                })
        }

        return () => (isSubscribed = false);
    }, [clientID, awsToken])

    const handleClickBackdrop = () => {
        setOpenBackdrop(false);
        setSubmitState(SUBMIT_STATE.notSend);
        setModalMessage(UPDATE_NOTIFICATION_MODAL_MESSAGES.notSend);
    };

    const [groups, setGroups] = useState([]);

    const handleOpenAssign = (data) => {
        setOpenAssignDialog(true)
        setSelectedGroup(data)
    }
    const handleOpenDelete = (data) => {
        setOpenDeleteDialog(true)
        setSelectedGroup(data)
    }
    const handleAssignDialogClose = (data) => {
        if (data) {
            setOpenBackdrop(true)
            setSubmitState(SUBMIT_STATE.sending)
            setModalMessage(UPDATE_NOTIFICATION_MODAL_MESSAGES.sending)
            let error = false

            const groupData = {
                "id": selectedGroup.id,
                "name": selectedGroup.name,
                'throttle': data?.throttle,
                'client': selectedGroup.client
            }
            Promise.all([
                updateNotificationGroup(awsToken, groupData),
                updateNotificationGroupMemebers(awsToken, selectedGroup.id, data?.usernames)
            ]).then(results => {
                console.log(results)
                if (results[0]) {
                    const updatedGroups = groups.filter(group => group.id !== selectedGroup.id);
                    setGroups([...updatedGroups, results[0]])
                }

            }).catch(err => { error = true }).finally(() => {
                if (!error) {
                    setSubmitState(SUBMIT_STATE.ok)
                    setModalMessage(UPDATE_NOTIFICATION_MODAL_MESSAGES.success)
                    setOpenBackdrop(false)
                }
                setSubmitState(SUBMIT_STATE.error)
                setModalMessage(UPDATE_NOTIFICATION_MODAL_MESSAGES.error)
            })
        }
        setOpenAssignDialog(false)
    }
    const handleDeleteDialogClose = (data) => {
        if (data) {
            setOpenBackdrop(true)
            setSubmitState(SUBMIT_STATE.sending)
            setModalMessage(UPDATE_NOTIFICATION_MODAL_MESSAGES.sendingDelete)
            let error = false;
            deleteNotificationGroups(awsToken, selectedGroup.id).then(() => {
                setGroups(groups.filter(group => group.id !== selectedGroup.id))
            }).catch((err) => {
                error = true
            }).finally(() => {
                if (!error) {
                    setSubmitState(SUBMIT_STATE.ok)
                    setModalMessage(UPDATE_NOTIFICATION_MODAL_MESSAGES.success)
                    setOpenBackdrop(false)
                }
                setSubmitState(SUBMIT_STATE.error)
                setModalMessage(UPDATE_NOTIFICATION_MODAL_MESSAGES.error)
            })
        }
        setOpenDeleteDialog(false)
    }


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <IrisTable
                        fallbackValue={''}
                        isLoading={false}
                        columns={[
                            { fieldName: "id", headTitle: "ID", width: 100 },
                            { fieldName: "name", headTitle: "Name" },
                            { fieldName: "throttle", headTitle: "Throttle" },
                            {
                                fieldName: "",
                                headTitle: "",
                                element: (data, key, index) => (
                                    <Button
                                        startIcon={<Edit />}
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            fontSize: 14,
                                        }}
                                        color="primary"
                                        onClick={() => {
                                            handleOpenAssign(data)

                                        }}
                                    >
                                        Edit
                                    </Button>
                                ),
                            },
                            {
                                fieldName: "",
                                headTitle: "",
                                element: (data, key, index) => (
                                    <Button
                                        startIcon={<DeleteIcon />}
                                        variant="contained"
                                        size="small"
                                        color="error"
                                        sx={{
                                            fontSize: 14,
                                        }}
                                        onClick={() => {
                                            handleOpenDelete(data)
                                        }}
                                    >
                                        Delete
                                    </Button>
                                ),
                            },

                        ]}
                        data={groups}
                        idField="id"
                    />
                </Grid>
                <Dialog onClose={() => { handleAssignDialogClose() }} open={openAssignDialog}>
                    <DialogTitle>Edit Group</DialogTitle>
                    <DialogContent>
                        <EditNotificationGroup {...selectedGroup} clientName={clientName}
                            handleClose={handleAssignDialogClose}
                            emails={emails}
                            userContacts={userContacts}
                        ></EditNotificationGroup>
                    </DialogContent>
                </Dialog>
                <Dialog onClose={() => { handleDeleteDialogClose() }} open={openDeleteDialog}>
                    <DialogTitle>Confirm Deleting Group</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Group: {selectedGroup.name} will be deleted.
                        </DialogContentText>

                        <Grid container justifyContent="space-evenly" alignItems="center" marginTop={'5vh'}>
                            <Grid item xs={2} >
                                <Button variant="contained" color="error" onClick={() => { handleDeleteDialogClose(selectedGroup.id) }}>Delete</Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" color="primary" onClick={() => { handleDeleteDialogClose() }} >Cancel</Button>
                            </Grid>
                        </Grid>

                    </DialogContent>
                </Dialog>
                <SubmitBackdrop
                    open={openBackdrop}
                    onClick={handleClickBackdrop}
                    submitState={submitState}
                    modalMessage={modalMessage}
                />

            </Grid>
        </>
    )
}


export default NotificationInfo