import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

/**
 * @summary sort an array of objects by values corresponding to the given key
 * @param {[object]} obj
 * @param {string} key value, object[key], would be used to sort
 * @param {boolean} [acc]
 * @returns {[object]} sorted objects
 */
export const sortObjectByField = (obj, key, acc = true) => {
  obj.sort((a, b) => {
    const fieldA = a[key];
    const fieldB = b[key];
    if (fieldA < fieldB) {
      return acc ? -1 : 1;
    } else if (fieldA > fieldB) {
      return acc ? 1 : -1;
    }
    return 0;
  });

  return obj;
};
/**
 * @param {object} props
 * @param {boolean} [props.fullWidth]
 * @param {string} props.label
 * @param {[object]} props.options
 * @param {boolean} [props.sortOptions]
 * @param {string} props.keyField
 * @param {string} props.titleField
 * @param {import("@mui/material").SelectProps} props.selectProps
 * 
 * @example
 * <IrisSelect
      label="Aage"
      options={[
        { ageKey: 10, ageTitle: "Ten" },
        { ageKey: 20, ageTitle: "Twenty" },
        { ageKey: 30, ageTitle: "Thirty" }
      ]}
      keyField="ageKey"
      titleField="ageTitle"
      selectProps={{
        variant: "standard",
        onChange: (event) => console.log("value", event.target.value),
        value: 20
      }}
    />
 */

export default function IrisSelect(props) {
  const {
    fullWidth = true,
    label,
    options,
    sortOptions = true,
    keyField,
    titleField,
    selectProps,
  } = props;

  const updatedSelectProps = { ...selectProps, label: label };

  let sortedOptions = options;

  if (
    updatedSelectProps.value === undefined && // value is not provided by user -- non controlled
    updatedSelectProps.defaultValue === undefined // default value is not provided by user either
  ) {
    // let select element to use defaultValue with value = ""
    updatedSelectProps.defaultValue = "";
  }

  if (sortOptions) {
    sortedOptions = sortObjectByField(options, titleField);
  }
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel variant={selectProps.variant}>{label}</InputLabel>
      <Select {...updatedSelectProps}>
        {sortedOptions.map((option, index) => (
          <MenuItem key={index} value={option[keyField]}>
            {option[titleField]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
