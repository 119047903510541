import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { MOBILE_DEVICE_GET_API_REQUEST } from "../../utils/requests";

import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CircularProgress } from "@mui/material";

import CurrentInfo from "./CurrentInfo";
import DeviceLogs from "./DeviceLogs";
import DeviceDetails from "./DeviceDetails/DeviceDetails";
import { AuthContext } from "context/AuthContext";

/** Tab panel from material ui 5 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MobileDeviceInfo = () => {
  const { awsToken } = useContext(AuthContext);

  /** active tab */
  const [activeTab, setActiveTab] = useState(0);

  /** route id, equals to data id */
  const { id: routeID } = useParams();

  // the displaying device name
  const [device, setDevice] = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    MOBILE_DEVICE_GET_API_REQUEST(routeID, awsToken)
      .then((result) => {
        if (isSubscribed) {
          // console.log(routeID);

          // when no data coming back from api
          if (result.length === 0) {
            setDevice("Device name is not available.");
          } else {
            setDevice(result);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** switch tabs */
  const handleChangeTab = (e, value) => {
    setActiveTab(value);
  };

  /** tabs */
  const renderTabs = {
    current_info: {
      heading: "Current Information",
      content: <CurrentInfo />,
    },
    general_info: {
      heading: "General Information",
      content: <DeviceDetails />,
    },
    activity_log: {
      heading: "Activity Log",
      content: (
        <DeviceLogs
          device={device}
          logEventType="ACTIVITY_LOG"
          title="Activity Logs"
        />
      ),
    },
    health_check_log: {
      heading: "Health Check Log",
      content: (
        <DeviceLogs
          device={device}
          logEventType="HEALTH_CHECK_LOG"
          title="Health Check Logs"
        />
      ),
    },
  };

  return (
    <div>
      <div style={{ margin: "0 0 0 1rem" }}>
        {device?.tag ? (
          <h2 style={{ margin: "0.5rem 0" }}>{device.tag}</h2>
        ) : (
          <CircularProgress
            sx={{
              margin: "0.4rem 0",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
      </div>
      <div style={{ borderBottom: "#4444 solid 1px" }}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {Object.values(renderTabs).map((tab, index) => (
            <Tab label={tab.heading} {...a11yProps(index)} key={index} />
          ))}
        </Tabs>
      </div>
      {Object.values(renderTabs).map((tabPanel, index) => (
        <TabPanel value={activeTab} index={index} key={index}>
          {tabPanel.content}
        </TabPanel>
      ))}
    </div>
  );
};

export default MobileDeviceInfo;
