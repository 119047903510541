export const dummyUserData = [
  {
    id: "test-user-1",
    username: "test user 1",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_2", "Test_Group_3"],
    groups_info: [
      {
        id: 3,
        geo_info: {
          id: 3,
          name: "Test_Group_3 Geo Info",
          timezone: "America/Toronto",
          lat: 43.2557,
          lon: -79.8711,
          province: "Ontario",
          open_weather_id: 5969785,
          temp_unit: "C",
        },
        service: {
          id: 3,
          iris_city: {
            id: 3,
            widgets: [
              {
                id: 7,
                key: "test widget 7",
                title: "Test Group 3 test widget 7",
                link: "https://www.test-widget-7.com/",
              },
            ],
            w_o_profile: null,
            name: "Test_Group_3",
            patrol_report: null,
          },
          name: "Test Group 3 Services",
        },
        name: "Test_Group_3",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [],
        settings: {},
      },
      {
        id: 2,
        geo_info: {
          id: 2,
          name: "Splice Geo Info",
          timezone: "America/Sao_Paulo",
          lat: 43.3255,
          lon: -79.799,
          province: "Splice",
          open_weather_id: 6322752,
          temp_unit: "C",
        },
        service: {
          id: 2,
          iris_city: {
            id: 2,
            widgets: [
              {
                id: 6,
                key: "test widget 6",
                title: "Test Group 2 test widget 6",
                link: "https://www.test-widget-6.com/",
              },
              {
                id: 5,
                key: "test widget 5",
                title: "Test Group 2 test widget 5",
                link: "https://www.test-widget-5.com/",
              },
            ],
            w_o_profile: {
              id: 2,
              name: "Test_Group_2 Work Order Profile",
              search_fields: [
                "service_by",
                "w_o_status",
                "assign_to",
                "w_o_id",
              ],
              defects: [],
              assignees: ["Test_Group_2 contractor"],
            },
            name: "Test_Group_2",
            patrol_report: null,
          },
          name: "Test_Group_5 Services",
        },
        name: "Test_Group_2",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [
          {
            lat: 0,
          },
          {
            lat: 0,
          },
        ],
        settings: {},
      },
    ],
    client_id: "test-company-1",
    client_name: "test company 1",
    first_name: "test user 1 first name",
    last_name: "test user 1 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-2",
    username: "test user 2",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_2"],
    groups_info: [
      {
        id: 2,
        geo_info: {
          id: 2,
          name: "Splice Geo Info",
          timezone: "America/Sao_Paulo",
          lat: 43.3255,
          lon: -79.799,
          province: "Splice",
          open_weather_id: 6322752,
          temp_unit: "C",
        },
        service: {
          id: 2,
          iris_city: {
            id: 2,
            widgets: [
              {
                id: 6,
                key: "test widget 6",
                title: "Test Group 2 test widget 6",
                link: "https://www.test-widget-6.com/",
              },
              {
                id: 5,
                key: "test widget 5",
                title: "Test Group 2 test widget 5",
                link: "https://www.test-widget-5.com/",
              },
            ],
            w_o_profile: {
              id: 2,
              name: "Test_Group_2 Work Order Profile",
              search_fields: [
                "service_by",
                "w_o_status",
                "assign_to",
                "w_o_id",
              ],
              defects: [],
              assignees: ["Test_Group_2 contractor"],
            },
            name: "Test_Group_5",
            patrol_report: null,
          },
          name: "Test_Group_2 Services",
        },
        name: "Test_Group_2",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [
          {
            lat: 0,
          },
          {
            lat: 0,
          },
        ],
        settings: {},
      },
    ],
    client_id: "test-company-1",
    client_name: "test company 1",
    first_name: "test user 2 first name",
    last_name: "test user 2 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-3",
    username: "test user 3",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_3"],
    groups_info: [
      {
        id: 3,
        geo_info: {
          id: 3,
          name: "Test_Group_3 Geo Info",
          timezone: "America/Toronto",
          lat: 43.2557,
          lon: -79.8711,
          province: "Ontario",
          open_weather_id: 5969785,
          temp_unit: "C",
        },
        service: {
          id: 3,
          iris_city: {
            id: 3,
            widgets: [
              {
                id: 7,
                key: "test widget 7",
                title: "Test Group 3 test widget 7",
                link: "https://www.test-widget-7.com/",
              },
            ],
            w_o_profile: null,
            name: "Test_Group_3",
            patrol_report: null,
          },
          name: "Test Group 3 Services",
        },
        name: "Test_Group_3",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [],
        settings: {},
      },
    ],
    client_id: "test-company-1",
    client_name: "test company 1",
    first_name: "test user 3 first name",
    last_name: "test user 3 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-4",
    username: "test user 4",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_4", "Test_Group_5"],
    groups_info: [
      {
        id: 4,
        geo_info: {
          id: 4,
          name: "Test_Group_4 Geo Info",
          timezone: "America/Toronto",
          lat: 43.2557,
          lon: -79.8711,
          province: "Ontario",
          open_weather_id: 5969785,
          temp_unit: "C",
        },
        service: {
          id: 4,
          iris_city: {
            id: 4,
            widgets: [
              {
                id: 8,
                key: "test widget 8",
                title: "Test Group 4 test widget 8",
                link: "https://www.test-widget-8.com/",
              },
            ],
            w_o_profile: null,
            name: "Test_Group_4",
            patrol_report: null,
          },
          name: "Test Group 4 Services",
        },
        name: "Test_Group_4",
        device_count: 5,
        shifts: ["00:00-24:00"],
        geo_fencing: [],
        settings: {},
      },
      {
        id: 5,
        geo_info: {
          id: 5,
          name: "Splice Geo Info",
          timezone: "America/Sao_Paulo",
          lat: 43.3255,
          lon: -79.799,
          province: "Splice",
          open_weather_id: 6322752,
          temp_unit: "C",
        },
        service: {
          id: 5,
          iris_city: {
            id: 5,
            widgets: [
              {
                id: 9,
                key: "test widget 9",
                title: "Test Group 5 test widget 9",
                link: "https://www.test-widget-9.com/",
              },
              {
                id: 10,
                key: "test widget 10",
                title: "Test Group 5 test widget 10",
                link: "https://www.test-widget-10.com/",
              },
            ],
            w_o_profile: {
              id: 5,
              name: "Test_Group_5 Work Order Profile",
              search_fields: [
                "service_by",
                "w_o_status",
                "assign_to",
                "w_o_id",
              ],
              defects: [],
              assignees: ["Test_Group_5 contractor"],
            },
            name: "Test_Group_5",
            patrol_report: null,
          },
          name: "Test_Group_5 Services",
        },
        name: "Test_Group_5",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [
          {
            lat: 0,
          },
          {
            lat: 0,
          },
        ],
        settings: {},
      },
    ],
    client_id: "test-company-2",
    client_name: "test company 2",
    first_name: "test user 4 first name",
    last_name: "test user 4 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-5",
    username: "test user 5",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_4"],
    groups_info: [
      {
        id: 4,
        geo_info: {
          id: 4,
          name: "Test_Group_4 Geo Info",
          timezone: "America/Toronto",
          lat: 43.2557,
          lon: -79.8711,
          province: "Ontario",
          open_weather_id: 5969785,
          temp_unit: "C",
        },
        service: {
          id: 4,
          iris_city: {
            id: 4,
            widgets: [
              {
                id: 8,
                key: "test widget 8",
                title: "Test Group 4 test widget 8",
                link: "https://www.test-widget-8.com/",
              },
            ],
            w_o_profile: null,
            name: "Test_Group_4",
            patrol_report: null,
          },
          name: "Test Group 4 Services",
        },
        name: "Test_Group_4",
        device_count: 5,
        shifts: ["00:00-24:00"],
        geo_fencing: [],
        settings: {},
      },
    ],
    client_id: "test-company-2",
    client_name: "test company 2",
    first_name: "test user 5 first name",
    last_name: "test user 5 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-6",
    username: "test user 6",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_6"],
    groups_info: [
      {
        id: 6,
        geo_info: {
          id: 6,
          name: "Test_Group_6 Geo Info",
          timezone: "America/Toronto",
          lat: 43.2557,
          lon: -79.8711,
          province: "Ontario",
          open_weather_id: 5969785,
          temp_unit: "C",
        },
        service: {
          id: 6,
          iris_city: {
            id: 6,
            widgets: [
              {
                id: 10,
                key: "test widget 10",
                title: "Test Group 6 test widget 10",
                link: "https://www.test-widget-10.com/",
              },
            ],
            w_o_profile: null,
            name: "Test_Group_6",
            patrol_report: null,
          },
          name: "Test Group 6 Services",
        },
        name: "Test_Group_6",
        device_count: 2,
        shifts: ["00:00-24:00"],
        geo_fencing: [],
        settings: {},
      },
    ],
    client_id: "test-company-3",
    client_name: "test company 3",
    first_name: "test user 6 first name",
    last_name: "test user 6 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-7",
    username: "test user 7",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_7", "Test_Group_8", "Test_Group_9"],
    groups_info: [
      {
        id: 7,
        geo_info: {
          id: 7,
          name: "Test_Group_7 Geo Info",
          timezone: "America/Toronto",
          lat: 43.2557,
          lon: -79.8711,
          province: "Ontario",
          open_weather_id: 5969785,
          temp_unit: "C",
        },
        service: {
          id: 7,
          iris_city: {
            id: 7,
            widgets: [
              {
                id: 11,
                key: "test widget 11",
                title: "Test Group 7 test widget 11",
                link: "https://www.test-widget-11.com/",
              },
            ],
            w_o_profile: null,
            name: "Test_Group_7",
            patrol_report: null,
          },
          name: "Test Group 7 Services",
        },
        name: "Test_Group_7",
        device_count: 5,
        shifts: ["00:00-24:00"],
        geo_fencing: [],
        settings: {},
      },
      {
        id: 8,
        geo_info: {
          id: 8,
          name: "Splice Geo Info",
          timezone: "America/Sao_Paulo",
          lat: 43.3255,
          lon: -79.799,
          province: "Splice",
          open_weather_id: 6322752,
          temp_unit: "C",
        },
        service: {
          id: 8,
          iris_city: {
            id: 8,
            widgets: [
              {
                id: 12,
                key: "test widget 12",
                title: "Test Group 8 test widget 12",
                link: "https://www.test-widget-12.com/",
              },
              {
                id: 13,
                key: "test widget 13",
                title: "Test Group 8 test widget 13",
                link: "https://www.test-widget-13.com/",
              },
            ],
            w_o_profile: {
              id: 8,
              name: "Test_Group_8 Work Order Profile",
              search_fields: [
                "service_by",
                "w_o_status",
                "assign_to",
                "w_o_id",
              ],
              defects: [],
              assignees: ["Test_Group_8 contractor"],
            },
            name: "Test_Group_8",
            patrol_report: null,
          },
          name: "Test_Group_8 Services",
        },
        name: "Test_Group_8",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [
          {
            lat: 0,
          },
          {
            lat: 0,
          },
        ],
        settings: {},
      },
      {
        id: 9,
        geo_info: {
          id: 9,
          name: "Splice Geo Info",
          timezone: "America/Sao_Paulo",
          lat: 43.3255,
          lon: -79.799,
          province: "Splice",
          open_weather_id: 6322752,
          temp_unit: "C",
        },
        service: {
          id: 9,
          iris_city: {
            id: 9,
            widgets: [
              {
                id: 13,
                key: "test widget 13",
                title: "Test Group 9 test widget 13",
                link: "https://www.test-widget-13.com/",
              },
              {
                id: 14,
                key: "test widget 14",
                title: "Test Group 9 test widget 14",
                link: "https://www.test-widget-14.com/",
              },
            ],
            w_o_profile: {
              id: 9,
              name: "Test_Group_9 Work Order Profile",
              search_fields: [
                "service_by",
                "w_o_status",
                "assign_to",
                "w_o_id",
              ],
              defects: [],
              assignees: ["Test_Group_9 contractor"],
            },
            name: "Test_Group_9",
            patrol_report: null,
          },
          name: "Test_Group_8 Services",
        },
        name: "Test_Group_9",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [
          {
            lat: 0,
          },
          {
            lat: 0,
          },
        ],
        settings: {},
      },
    ],
    client_id: "test-company-4",
    client_name: "test company 4",
    first_name: "test user 7 first name",
    last_name: "test user 7 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-8",
    username: "test user 8",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_7", "Test_Group_9"],
    groups_info: [
      {
        id: 7,
        geo_info: {
          id: 7,
          name: "Test_Group_7 Geo Info",
          timezone: "America/Toronto",
          lat: 43.2557,
          lon: -79.8711,
          province: "Ontario",
          open_weather_id: 5969785,
          temp_unit: "C",
        },
        service: {
          id: 7,
          iris_city: {
            id: 7,
            widgets: [
              {
                id: 11,
                key: "test widget 11",
                title: "Test Group 7 test widget 11",
                link: "https://www.test-widget-11.com/",
              },
            ],
            w_o_profile: null,
            name: "Test_Group_7",
            patrol_report: null,
          },
          name: "Test Group 7 Services",
        },
        name: "Test_Group_7",
        device_count: 5,
        shifts: ["00:00-24:00"],
        geo_fencing: [],
        settings: {},
      },
      {
        id: 9,
        geo_info: {
          id: 9,
          name: "Splice Geo Info",
          timezone: "America/Sao_Paulo",
          lat: 43.3255,
          lon: -79.799,
          province: "Splice",
          open_weather_id: 6322752,
          temp_unit: "C",
        },
        service: {
          id: 9,
          iris_city: {
            id: 9,
            widgets: [
              {
                id: 13,
                key: "test widget 13",
                title: "Test Group 9 test widget 13",
                link: "https://www.test-widget-13.com/",
              },
              {
                id: 14,
                key: "test widget 14",
                title: "Test Group 9 test widget 14",
                link: "https://www.test-widget-14.com/",
              },
            ],
            w_o_profile: {
              id: 9,
              name: "Test_Group_9 Work Order Profile",
              search_fields: [
                "service_by",
                "w_o_status",
                "assign_to",
                "w_o_id",
              ],
              defects: [],
              assignees: ["Test_Group_9 contractor"],
            },
            name: "Test_Group_9",
            patrol_report: null,
          },
          name: "Test_Group_8 Services",
        },
        name: "Test_Group_9",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [
          {
            lat: 0,
          },
          {
            lat: 0,
          },
        ],
        settings: {},
      },
    ],
    client_id: "test-company-4",
    client_name: "test company 4",
    first_name: "test user 8 first name",
    last_name: "test user 8 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-9",
    username: "test user 9",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_7"],
    groups_info: [
      {
        id: 7,
        geo_info: {
          id: 7,
          name: "Test_Group_7 Geo Info",
          timezone: "America/Toronto",
          lat: 43.2557,
          lon: -79.8711,
          province: "Ontario",
          open_weather_id: 5969785,
          temp_unit: "C",
        },
        service: {
          id: 7,
          iris_city: {
            id: 7,
            widgets: [
              {
                id: 11,
                key: "test widget 11",
                title: "Test Group 7 test widget 11",
                link: "https://www.test-widget-11.com/",
              },
            ],
            w_o_profile: null,
            name: "Test_Group_7",
            patrol_report: null,
          },
          name: "Test Group 7 Services",
        },
        name: "Test_Group_7",
        device_count: 5,
        shifts: ["00:00-24:00"],
        geo_fencing: [],
        settings: {},
      },
    ],
    client_id: "test-company-4",
    client_name: "test company 4",
    first_name: "test user 9 first name",
    last_name: "test user 9 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-10",
    username: "test user 10",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_8", "Test_Group_9"],
    groups_info: [
      {
        id: 8,
        geo_info: {
          id: 8,
          name: "Splice Geo Info",
          timezone: "America/Sao_Paulo",
          lat: 43.3255,
          lon: -79.799,
          province: "Splice",
          open_weather_id: 6322752,
          temp_unit: "C",
        },
        service: {
          id: 8,
          iris_city: {
            id: 8,
            widgets: [
              {
                id: 12,
                key: "test widget 12",
                title: "Test Group 8 test widget 12",
                link: "https://www.test-widget-12.com/",
              },
              {
                id: 13,
                key: "test widget 13",
                title: "Test Group 8 test widget 13",
                link: "https://www.test-widget-13.com/",
              },
            ],
            w_o_profile: {
              id: 8,
              name: "Test_Group_8 Work Order Profile",
              search_fields: [
                "service_by",
                "w_o_status",
                "assign_to",
                "w_o_id",
              ],
              defects: [],
              assignees: ["Test_Group_8 contractor"],
            },
            name: "Test_Group_8",
            patrol_report: null,
          },
          name: "Test_Group_8 Services",
        },
        name: "Test_Group_8",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [
          {
            lat: 0,
          },
          {
            lat: 0,
          },
        ],
        settings: {},
      },
      {
        id: 9,
        geo_info: {
          id: 9,
          name: "Splice Geo Info",
          timezone: "America/Sao_Paulo",
          lat: 43.3255,
          lon: -79.799,
          province: "Splice",
          open_weather_id: 6322752,
          temp_unit: "C",
        },
        service: {
          id: 9,
          iris_city: {
            id: 9,
            widgets: [
              {
                id: 13,
                key: "test widget 13",
                title: "Test Group 9 test widget 13",
                link: "https://www.test-widget-13.com/",
              },
              {
                id: 14,
                key: "test widget 14",
                title: "Test Group 9 test widget 14",
                link: "https://www.test-widget-14.com/",
              },
            ],
            w_o_profile: {
              id: 9,
              name: "Test_Group_9 Work Order Profile",
              search_fields: [
                "service_by",
                "w_o_status",
                "assign_to",
                "w_o_id",
              ],
              defects: [],
              assignees: ["Test_Group_9 contractor"],
            },
            name: "Test_Group_9",
            patrol_report: null,
          },
          name: "Test_Group_8 Services",
        },
        name: "Test_Group_9",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [
          {
            lat: 0,
          },
          {
            lat: 0,
          },
        ],
        settings: {},
      },
    ],
    client_id: "test-company-4",
    client_name: "test company 4",
    first_name: "test user 10 first name",
    last_name: "test user 10 last name",
    phone_number: "1234567890",
  },
  {
    id: "test-user-11",
    username: "test user 11",
    is_active: true,
    email: null,
    expire_at: "2022-07-12T16:35:46Z",
    issued_at: "2022-07-12T15:35:46Z",
    groups: ["Test_Group_9"],
    groups_info: [
      {
        id: 9,
        geo_info: {
          id: 9,
          name: "Splice Geo Info",
          timezone: "America/Sao_Paulo",
          lat: 43.3255,
          lon: -79.799,
          province: "Splice",
          open_weather_id: 6322752,
          temp_unit: "C",
        },
        service: {
          id: 9,
          iris_city: {
            id: 9,
            widgets: [
              {
                id: 13,
                key: "test widget 13",
                title: "Test Group 9 test widget 13",
                link: "https://www.test-widget-13.com/",
              },
              {
                id: 14,
                key: "test widget 14",
                title: "Test Group 9 test widget 14",
                link: "https://www.test-widget-14.com/",
              },
            ],
            w_o_profile: {
              id: 9,
              name: "Test_Group_9 Work Order Profile",
              search_fields: [
                "service_by",
                "w_o_status",
                "assign_to",
                "w_o_id",
              ],
              defects: [],
              assignees: ["Test_Group_9 contractor"],
            },
            name: "Test_Group_9",
            patrol_report: null,
          },
          name: "Test_Group_8 Services",
        },
        name: "Test_Group_9",
        device_count: 3,
        shifts: ["00:00-24:00"],
        geo_fencing: [
          {
            lat: 0,
          },
          {
            lat: 0,
          },
        ],
        settings: {},
      },
    ],
    client_id: "test-company-4",
    client_name: "test company 4",
    first_name: "test user 11 first name",
    last_name: "test user 11 last name",
    phone_number: "1234567890",
  },
];
