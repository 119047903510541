import styles from "./CreateGroup.module.css";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext } from "context/AuthContext";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import { createGroup, ERROR_MESSAGE } from "utils/irisAuthRequests";
import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";

/** 3 different messages according to api call */
const CREATE_GROUP_MODAL_MESSAGES = {
  /** "No request is sent." */
  notSend: "No request is sent.",
  /** "Create group request is sent. Please wait for a while." */
  sending: "Create group request is sent. Please wait for a while.",
  /** "New group is created successfully." */
  ok: "New group is created successfully.",
};

const CreateGroup = () => {
  const history = useHistory();

  // get token to make api call
  const { awsToken, onLogout } = useContext(AuthContext);

  // groupName, input fields set state
  const [groupName, setGroupName] = useState("");

  // check if all the required fields are filled as required
  const [isReady, setIsReady] = useState(false);

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // when clicking the submit button, set state, modal will display different info according to the states
  const [createGroupState, setCreateGroupState] = useState(
    SUBMIT_STATE.notSend
  );
  // modal content set state
  const [modalMessageContent, setModalMessageContent] = useState(
    CREATE_GROUP_MODAL_MESSAGES.notSend
  );
  // error type, to indicate what operations should be taken when error ocurrs
  const [errorType, setErrorType] = useState("");

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // only when it's not sending request, close the backdrop and modal
    if (createGroupState !== SUBMIT_STATE.sending) {
      // when there's an error
      if (createGroupState === SUBMIT_STATE.error) {
        // when the token is not valid, click the backdrop to logout
        if (errorType === ERROR_MESSAGE.token) {
          onLogout();
        } else {
          setOpenBackdrop(false);
          setCreateGroupState(SUBMIT_STATE.notSend);
          setErrorType("");
        }
      }
      // when there's no error, redirect to /groups
      else {
        history.push(`/groups`);
      }
    }
  };

  // submit the sign up data
  const handleSubmit = () => {
    // triggers the backdrop to open
    setOpenBackdrop(true);
    setCreateGroupState(SUBMIT_STATE.sending);
    setModalMessageContent(CREATE_GROUP_MODAL_MESSAGES.sending);

    const submitProps = { token: awsToken, groupName: groupName };

    createGroup(submitProps)
      .then((result) => {
        setCreateGroupState(SUBMIT_STATE.ok);
        setModalMessageContent(CREATE_GROUP_MODAL_MESSAGES.ok);
      })
      .catch((err) => {
        setErrorType(err.message);
        setModalMessageContent(err.message);
        setCreateGroupState(SUBMIT_STATE.error);
      });
  };

  // text input fields, userName, email, phone number, hard code
  const textInputFields = [
    {
      name: "group name",
      placeHoler: "Group # 1",
      value: groupName,
      type: "text",
      onChange: (e) => setGroupName(e.target.value),
      isMandatory: true,
    },
  ];

  // check if the required fields are filled as required and set state of isReady
  useEffect(() => {
    setIsReady(groupName !== "");
  }, [groupName]);

  return (
    <div className={styles.createGroup}>
      <h2 className={`${styles.pageChild} ${styles.header}`}>
        Create a new group
      </h2>
      <div className={styles.titleDevider}></div>
      <div
        className={`${styles.pageChild} ${styles.bodyContent} ${styles.textInputFields}`}
      >
        {textInputFields.map((field, index) => (
          <div className={styles.textField} key={index}>
            <TextField
              fullWidth
              variant="standard"
              label={field.name}
              placeholder={field.placeHoler}
              required={field.isMandatory}
              value={field.value}
              type={field.type}
              onChange={field.onChange}
              onInput={field?.onInput}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        ))}
      </div>
      <div className={styles.titleDevider}></div>
      <div className={`${styles.pageChild} ${styles.buttons}`}>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          disabled={!isReady}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>

      <SubmitBackdrop
        open={openBackdrop}
        onClick={handleClickBackdrop}
        submitState={createGroupState}
        modalMessage={modalMessageContent}
      />
    </div>
  );
};

export default CreateGroup;
