import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Zoom from "@mui/material/Zoom";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Header from "../sidebarWithHeader/Header";
import SideBar, {
  DrawerHeader,
  DRAWER_OPEN_WIDTH,
  DRAWER_CLOSE_WIDTH,
} from "../sidebarWithHeader/SideBar";

/** anchor text, for ScrollTop function to scroll to top of the page */
const BACK_TO_TOP_ANCHOR = "back-to-top-anchor";

/** back to top function, React component, work together with element with id="back-to-top-anchor"
 * @see {@link https://mui.com/#back-to-top}
 * @param {Object} props
 * @param {React.ReactElement} props.children button element, which is the children wrapped by the component
 */
const ScrollTop = (props) => {
  const { children } = props;

  /** boolean, indicate whether the button of back to top will show or not, controlled by 3 options */
  const trigger = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  /** function to scroll the page to the anchor element */
  const handleClick = () => {
    const anchor = document.querySelector(`#${BACK_TO_TOP_ANCHOR}`);

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16, zIndex: 99 }}
      >
        {children}
      </Box>
    </Zoom>
  );
};

/**
 * React component containing header, sidebar and the render component for the current route
 * @param {Object} props
 * @param {import("routes/routesData").RouteData []} props.routesData all routes data, array of objects
 */
const SidebarWithHeader = (props) => {
  const { routesData } = props;
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} />

      <SideBar open={open} routesData={routesData} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: open
            ? `calc(100% - ${DRAWER_OPEN_WIDTH}px)`
            : `calc(100% - ${DRAWER_CLOSE_WIDTH}px)`,
        }}
      >
        <DrawerHeader id={BACK_TO_TOP_ANCHOR} />
        {routesData.map((routeContent, index) => (
          <Route
            key={index}
            exact
            path={routeContent.path}
            component={routeContent.component}
          />
        ))}
      </Box>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
};

export default SidebarWithHeader;
