
import { Autocomplete, Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import { AuthContext } from "context/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getNotificationGroups, sendNotificationGroup } from "utils/requests";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import SubmitBackdrop, { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const SEND_NOTIFICATION_MODAL_MESSAGES = {
    notSend: "No request is sent.",
    sending: "Send notification request is sent. Please wait for a while.",
    success: "Send notification executed successfully.",
    error: "Send notification was unsuccessful",
};

const SendNotifications = () => {

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [submitState, setSubmitState] = useState(SUBMIT_STATE.ok);
    const [modalMessage, setModalMessage] = useState(
        SEND_NOTIFICATION_MODAL_MESSAGES.notSend
    );

    const { awsToken, contextUsername } = useContext(AuthContext);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([])
    const [message, setMessage] = useState("")
    const [isDisabled, setIsDisabled] = useState(true)

    const [link, setLink] = useState("")
    const [subject, setSubject] = useState("")

    const [isSendingNotification, setIsSendingNotification] = useState(false)



    const today = new Date();

    const [endDate, setEndDate] = useState(today)
    const notificationTypes =
        [
            'web-app',
            'email'
        ]

    const tags = [
        'Marketing/Product',
        'Sales',
        'CS/Support and Technical'
    ]

    const priorities = [
        {
            name: 'Urgent',
            value: 3
        },
        {
            name: 'Warning',
            value: 2
        },
        {
            name: 'Default',
            value: 1
        },
    ]
    const [priority, setPriority] = useState(1)

    const [selectedTags, setSelectedTags] = useState([])

    const [selectedNotificationType, setSelectedNotificationType] = useState([]);

    useEffect(() => {
        let isSubscribed = true;

        getNotificationGroups(awsToken, "notification").then((result) => {
            if (isSubscribed) {
                setGroups(result['results'])
            }
        }).catch(error => { })
        return () => (isSubscribed = false);
    }, [awsToken])


    useEffect(() => {
        if (selectedGroups.length > 0 &&
            selectedNotificationType.length > 0 &&
            message !== "" && message.length > 0
        )
            setIsDisabled(false)
        else
            setIsDisabled(true)
    }, [selectedGroups, selectedNotificationType, message, subject])




    const handleChange = (event, setter) => {
        const {
            target: { value },
        } = event;

        setter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const sendNotification = () => {
        if (isSendingNotification)
            return

        setOpenBackdrop(true)
        setSubmitState(SUBMIT_STATE.sending)
        setModalMessage(SEND_NOTIFICATION_MODAL_MESSAGES.sending)

        setIsSendingNotification(true)
        const requests = []
        selectedGroups.forEach(group => {
            const data = {
                "message": message,
                "endDate": endDate.toISOString(),
                "owner": contextUsername,
                "priority": priority,
                "tags": selectedTags,
                "notification_type": selectedNotificationType,
                "properties": group.supportingData,
                "client_id": group.client,
                "link": link
            }

            if (selectedNotificationType.includes('email')) {
                data['subject'] = subject;
            }


            requests.push(sendNotificationGroup(awsToken, data))
        })

        Promise.all(requests).then(results => {

            setOpenBackdrop(true)
            setSubmitState(SUBMIT_STATE.ok)
            setModalMessage(SEND_NOTIFICATION_MODAL_MESSAGES.success)
        }).catch(error => {
            setOpenBackdrop(true)
            setSubmitState(SUBMIT_STATE.error)
            setModalMessage(SEND_NOTIFICATION_MODAL_MESSAGES.error)
        }).finally(() => { setIsSendingNotification(false) })
    }

    const handleClickBackdrop = () => {
        setOpenBackdrop(false);
        setSubmitState(SUBMIT_STATE.notSend);
        setModalMessage(SEND_NOTIFICATION_MODAL_MESSAGES.notSend);
    };



    return (
        <div>
            {/* <Grid container spacing={2}> */}
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Autocomplete
                        multiple
                        id="groups"
                        options={groups}
                        disableCloseOnSelect
                        value={selectedGroups}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            setSelectedGroups([...newValue]);
                        }}
                        renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            return (
                                <li key={key + option.name} {...optionProps}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </li>
                            );
                        }}
                        // style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Send To" placeholder="Send To" />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl sx={{ width: '100%', maxWidth: '15vw' }}>
                        <InputLabel id="demo-multiple-checkbox-label">Type</InputLabel>
                        <Select style={{ width: "100%" }}
                            labelId="demo-multiple-checkbox-label"
                            multiple
                            value={selectedNotificationType}
                            onChange={(event) => handleChange(event, setSelectedNotificationType)}
                            input={<OutlinedInput label="Type" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            label="Type"
                            placeholder="Type"
                        >
                            {notificationTypes.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={selectedNotificationType.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl sx={{ width: '100%', maxWidth: '15vw' }}>
                        <InputLabel id="multiple-checkbox-label-tags">Tags</InputLabel>
                        <Select style={{ width: "100%" }}
                            labelId="multiple-checkbox-label-tags"
                            multiple
                            value={selectedTags}
                            onChange={(event) => handleChange(event, setSelectedTags)}
                            input={<OutlinedInput label="Tags" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            label="Tags"
                            placeholder="Tags"
                        >
                            {tags.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={selectedTags.indexOf(name) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl sx={{ width: '100%', maxWidth: '15vw' }}>
                        <InputLabel id="multiple-checkbox-label-priority">Priority</InputLabel>
                        <Select style={{ width: "100%" }}
                            labelId="multiple-checkbox-label-priority"
                            value={priority}
                            onChange={(event) => handleChange(event, setPriority)}
                            MenuProps={MenuProps}
                            label="priority"
                        // placeholder="priority"
                        >
                            {priorities.map((prop) => (
                                <MenuItem value={prop.value}>{prop.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField style={{ width: "100%" }} value={link}
                        onChange={(event) => setLink(event.target.value)}
                        label="Link" placeholder="Link" />
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            autoOk
                            value={endDate}
                            disableToolbar
                            minDate={today}
                            onChange={(date) => {
                                setEndDate(date);
                            }}
                            label="End Date"
                            labelFunc={(date) => date.format("dddd, MMMM Do YYYY")}
                            variant={"inline"}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>

                {selectedNotificationType.includes('email') &&
                    <Grid item xs={12}>
                        <TextField style={{ width: "100%" }} onChange={(event) => setSubject(event.target.value)} label="Subject" placeholder="Subject" />
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        style={{ width: "100%" }}
                        id="outlined-multiline-static"
                        label="Message"
                        multiline
                        rows={12}
                        value={message}
                        onChange={(event) => {
                            setMessage(event.target.value)
                        }}
                    />
                </Grid>
                <Grid justifyContent="flex-end" container item xs={12}>
                    <Grid xs={2} item>
                        <Button style={{ width: "100%" }} disabled={isDisabled} onClick={() => { sendNotification() }} variant="contained">Send</Button>
                    </Grid>

                </Grid>

            </Grid>
            <SubmitBackdrop
                open={openBackdrop}
                onClick={handleClickBackdrop}
                submitState={submitState}
                modalMessage={modalMessage}
            />
        </div>
    );
};

export default SendNotifications;