import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

export default (WrappedComponent) => {
  const queryClient = new QueryClient();

  const hocComponent = ({ ...props }) => (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <WrappedComponent {...props} />
    </QueryClientProvider>
  );

  hocComponent.propTypes = {};

  return hocComponent;
};
