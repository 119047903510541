import React, { useState, useContext, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { MainContext } from "../../context/MainContext";
import ReportCardFullscreen from "./ReportCardFullscreen";
import { Grid } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  infoContainer: {
    margin: theme.spacing(0.5),
  },
  infoText: {
    verticalAlign: "center",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(0.5),
  },
  media: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    width: "84%",
  },
  modalImg: {
    width: "100%",
  },
  modalButton: {
    color: theme.palette.background.default,
    position: "fixed",
    top: 15,
    right: 15,
    borderRadius: 0,
  },
  buttonIcon: {
    fontSize: 40,
  },
  avatarLetter: {
    fontSize: "0.75em",
  },
}));

export default function MediaCard({ datapoint }) {
  const cardRef = useRef();
  const classes = useStyles();
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const { lookupData } = useContext(MainContext);

  const getLabelName = (id) => {
    let label = lookupData.label_type.find((label) => label.id === id);
    return label.name;
  };
  const getResolutionName = (resolutionKey) => {
    let resolution = "";
    // get resolution name
    if (resolutionKey.length > 0)
      resolution = lookupData.resolution_type[resolutionKey];
    // add bracket for non-empty resolution name
    if (resolution.length > 0) resolution = `(${resolution})`;
    return resolution;
  };

  const renderCardContent = (datapoint) => {
    const {
      damage_type,
      road_related_issues,
      labels,
      device_sn: { tag },
      create_time,
      address,
    } = datapoint;

    const CARD_DETAILS_MAP = {
      tag: { value: tag, iconElement: <InfoOutlinedIcon fontSize="small" /> },
      id: {
        value: datapoint.id,
        iconElement: <InfoOutlinedIcon fontSize="small" />,
      },
      create_time: {
        value: moment(create_time).format("lll"),
        iconElement: <AccessTimeIcon fontSize="small" />,
      },
      address: {
        value: address,
        iconElement: <RoomOutlinedIcon fontSize="small" />,
      },
    };
    return (
      <CardContent>
        <div className={classes.chipContainer}>
          {damage_type.map((damage) => {
            if (damage.enabled === "Y")
              return (
                <Chip
                  key={damage.id}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  label={damage.name}
                  avatar={
                    <Avatar>
                      <Typography variant="caption">{damage.abbrev}</Typography>
                    </Avatar>
                  }
                />
              );
            else {
              return null;
            }
          })}
          {road_related_issues.map((rri) => {
            if (rri.enabled === "Y")
              return (
                <Chip
                  key={rri.id}
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={rri.issue}
                />
              );
            else {
              return null;
            }
          })}
          {labels.map((label) => {
            return (
              <Chip
                key={label.id}
                variant="outlined"
                color="secondary"
                size="small"
                label={`${getLabelName(label.label_type)}${getResolutionName(
                  label.resolution
                )}`}
              />
            );
          })}
        </div>
        <Grid container className={classes.infoContainer}>
          {Object.values(CARD_DETAILS_MAP).map((obj, index) => {
            const { value, iconElement } = obj;
            return (
              <Grid
                key={index}
                container
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Grid item xs={1}>
                  {iconElement}
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.infoText}
                  >
                    {value}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    );
  };

  return (
    <div>
      <Modal
        className={classes.modal}
        open={imgModalOpen}
        onClose={() => {
          setImgModalOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100,
        }}
      >
        <Fade in={imgModalOpen} tabIndex={-1}>
          <ReportCardFullscreen data={datapoint} close={setImgModalOpen} />
        </Fade>
      </Modal>
      <Card ref={cardRef}>
        <CardActionArea
          onClick={() => {
            setImgModalOpen(true);
          }}
        >
          <CardMedia
            className={classes.media}
            image={datapoint.all_labels_image}
            title={`${datapoint.device_sn.city.name} Data`}
            component="img"
            alt="Datapoint Image"
          />
          {renderCardContent(datapoint)}
        </CardActionArea>
      </Card>
    </div>
  );
}
