import React, { useCallback, useState, useEffect } from "react";
import {
  getToken,
  saveToken,
  saveUserName,
} from "../utils/localStorage/utilFunctions";

import Auth from "api/awsCognito/AuthUtils";

let interval;
const FIFITY_FIVE_MINUITES = 55 * 60 * 1000;

/**
 * @callback OnAuthenticated callback function to save user name, access token and group info into context and (or) local storage
 * @param {string} userName user name to be saved
 * @param {string} accessToken access token to be saved
 * @param {array} group groupd to be saved
 */

/**
 * @typedef Auth
 * @property {string} contextUsername logged in user name
 * @property {string} contextToken access token
 * @property {OnAuthenticated} onAuthenticated callback function when user is logged in
 * @property {() => {}} onLogout callback function when user is logged out, clear save user name, access token and group
 */

/**@type {Auth} */
const authContextTemplate = undefined;
export const AuthContext = React.createContext(authContextTemplate);
export const AuthContextProvider = ({ children }) => {
  const [contextUsername, setContextUsername] = useState("");
  // original server side token
  const [contextToken, setContextToken] = useState(getToken() || ""); // default super key, from .env
  const [awsToken, setAwsToken] = useState("");

  /**@type {OnAuthenticated} */
  const onAuthenticated = useCallback(
    (userName, awsToken) => {
      setContextUsername(userName);

      // token is the super token, stored in env variable
      setContextToken(getToken());
      saveUserName(userName);
      setAwsToken(awsToken);
      interval = setInterval(() => {
        // interval to get token from via Auth every hour so that aws toke nwill not be expired
        Auth.currentAuthenticatedUser().then((res) => {
          const accessToken = res.tokenPayloads.accessToken.token;
          setAwsToken(accessToken);
        });
      }, FIFITY_FIVE_MINUITES);
    },
    [setContextUsername, setContextToken]
  );

  /**
   * @summary whatever
   */
  const onLogout = useCallback(() => {
    if (interval) {
      clearInterval(interval);
    }
    setContextUsername("");
    setContextToken("");
    Auth.signOut();
  }, [setContextUsername, setContextToken]);

  /**
   * @type {Auth}
   */
  const contextValue = {
    contextUsername,
    contextToken,
    awsToken,
    onAuthenticated,
    onLogout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
