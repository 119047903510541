import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, Grid, InputLabel, Box } from "@mui/material";
import IrisTextField from "UI/muiTextField/IrisTextField";

const KeyValueList = (props) => {
  const {
    values,
    title,
    onAdd,
    onDelete,
    onUpdate,
    isEditMode = false,
    disableAddButton = false,
    disableRemoveButton = false
  } = props;

  return (
    <Box border={1} mt={2} borderColor="grey.500">
      <Grid container p={1}>
        <Grid item xs={11}>
          <InputLabel>{title}</InputLabel>
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            disabled={!isEditMode || disableAddButton}
            onClick={() => {
              onAdd();
            }}
          >
            <AddIcon />
          </Button>
        </Grid>
      </Grid>
      {values.map((value, index) => {
        return (
          <Grid key={index} container p={1} alignItems="flex-end">
            <Grid item xs={3} pr={2}>
              <IrisTextField
                disabled={!isEditMode}
                required
                fullWidth
                label="Key"
                variant="standard"
                value={values[index][0]}
                onChange={(e) => onUpdate(e.target.value, index, "KEY")}
              />
            </Grid>
            <Grid item xs={8} pr={2}>
              <IrisTextField
                disabled={!isEditMode}
                required
                fullWidth
                label="Value"
                variant="standard"
                value={values[index][1]}
                onChange={(e) => onUpdate(e.target.value, index, "VALUE")}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                disabled={!isEditMode || disableRemoveButton}
                variant="contained"
                onClick={() => {
                  onDelete(index);
                }}
              >
                <RemoveIcon />
              </Button>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default KeyValueList;
