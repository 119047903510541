import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    maxWidth: 500,
    marginInline: "auto",
    marginTop: "15vh",
  },

  title: {
    textAlign: "center",
  },
  verificationForm: {
    padding: "1em 2em",
    width: "80%",
    marginInline: "auto",
  },

  resetPasswordForm: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: "10px 0",
    },
  },
}));
