import styles from "./ResetUserPassword.module.css";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperText } from "@mui/material";
import Button from "@mui/material/Button";
import LockResetIcon from "@mui/icons-material/LockReset";

import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import PasswordHelperText from "components/passwrodHelperText/PasswordHelperText";

import { AuthContext } from "context/AuthContext";
import { getAdminUserList } from "utils/irisAuthRequests";
import { ERROR_MESSAGE } from "utils/irisAuthRequests";
import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";

/** 3 different messages according to api call */
const RESET_PASSWORD_MODAL_MESSAGES = {
  /** "No request is sent." */
  notSend: "No request is sent.",
  /** "Reset password request is sent. Please wait for a while." */
  sending: "Reset password request is sent. Please wait for a while.",
  /** "Password is reset successfully." */
  ok: "Password is reset successfully.",
};

const ResetUserPassword = () => {
  const history = useHistory();

  const location = useLocation();

  // get token to make api call
  const { contextToken, awsToken, onLogout } = useContext(AuthContext);

  // userName set state
  const [userName, setUserName] = useState("");

  // is userName editable
  const [userNameEditable, setUserNameEditable] = useState(true);

  // user options for select
  const [userOptions, setUserOptions] = useState([]);

  // password set state, select getting password auto or manual, input fields, show password or text
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [passwordOK, setPasswordOK] = useState(false);

  // check if all the required fields are filled as required
  const [isReady, setIsReady] = useState(false);

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // when clicking the submit button, set state, modal will display different info according to the states
  const [resetPasswordState, setResetPasswordState] = useState(
    SUBMIT_STATE.notSend
  );
  // when there's an error, set state of the error message
  const [modalMessage, setModalMessage] = useState("");

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // when there's an error
    if (resetPasswordState === SUBMIT_STATE.error) {
      // when the token is not valid, click the backdrop to logout
      if (modalMessage === ERROR_MESSAGE.token) {
        onLogout();
      }
    }
    // when there's no error, redirect to /users
    else {
      history.push(`/users`);
    }
  };

  // submit reset password request
  const handleSubmit = () => {
    // triggers the backdrop to show redirect message, and useEffect will redirect the page
    setResetPasswordState(SUBMIT_STATE.ok);
    setModalMessage(RESET_PASSWORD_MODAL_MESSAGES.ok);
    setOpenBackdrop(true);
  };

  // check if all the required fields are filled as required and set state of isReady
  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      if (userName !== "" && passwordOK && password1 === password2) {
        setIsReady(true);
      }
    }
    return () => (isSubscribed = false);
  }, [userName, password1, password2, passwordOK]);

  // set the user name options and default user name when component mounts
  useEffect(() => {
    let isSubscribed = true;

    getAdminUserList(awsToken)
      .then((result) => {
        if (isSubscribed) {
          // set state of current user, disable the select field
          if (location.state !== undefined) {
            if (location.state.user !== undefined) {
              const userName = location.state.user;
              const currUser = result.find(
                (user) => user.Username === userName
              );
              setUserOptions([currUser]);
              setUserName(currUser.Username);
              setUserNameEditable(false);
            }
          } else {
            setUserOptions(result);
          }
        }
      })
      .catch((error) => {
        setOpenBackdrop(true);
        setModalMessage(error.message);
        setResetPasswordState(SUBMIT_STATE.error);
      });

    return () => (isSubscribed = false);
  }, [location.state, awsToken]);

  return (
    <div className={styles.resetPassword}>
      <h2 className={`${styles.pageChild} ${styles.header}`}>Reset password</h2>
      <div className={styles.titleDevider}></div>
      <div className={`${styles.pageChild} ${styles.userName}`}>
        <FormControl required sx={{ minWidth: "15rem" }}>
          <InputLabel id="user name">User name</InputLabel>
          <Select
            labelId="user name"
            id="user name"
            label="user name"
            value={userName}
            disabled={!userNameEditable}
            onChange={(e) => setUserName(e.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 45 * 2.5 + 8, // ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP
                },
              },
            }}
          >
            {userOptions.map((option, index) => (
              <MenuItem key={index} value={option.Username}>
                {option.Username}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={`${styles.pageChild} ${styles.password}`}>
        <FormControl variant="standard">
          <InputLabel htmlFor="password1-input" shrink={true}>
            Password
          </InputLabel>
          <Input
            id="password1-input"
            type={showPassword1 ? "text" : "password"}
            value={password1}
            onChange={(e) => {
              setPassword1(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword1((prev) => !prev)}
                >
                  {showPassword1 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            {password1 !== "" && (
              <PasswordHelperText
                inputPassword={password1}
                setPasswordOK={setPasswordOK}
              />
            )}
          </FormHelperText>
        </FormControl>

        <TextField
          variant="standard"
          label="Reenter password"
          type="password"
          disabled={!passwordOK}
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          helperText={
            password2 !== "" && (
              <span
                style={{
                  color: password2 === password1 ? "" : "#c1151f",
                }}
              >
                input password must be identical
              </span>
            )
          }
        />
      </div>
      <div className={styles.titleDevider}></div>
      <div className={`${styles.pageChild} ${styles.buttons}`}>
        <Button
          variant="outlined"
          startIcon={<LockResetIcon />}
          disabled={!isReady}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>

      <SubmitBackdrop
        open={openBackdrop}
        onClick={handleClickBackdrop}
        submitState={resetPasswordState}
        modalMessage={modalMessage}
      />
    </div>
  );
};

export default ResetUserPassword;
