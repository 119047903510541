import React, { useContext, useEffect, useReducer, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IrisTextField from "UI/muiTextField/IrisTextField";
import IrisSelect from "UI/muiSelect/IrisSelect";
import IrisMultiSelect from "UI/muiMulMultiSelect/IrisMultiSelect";
import styles from "./AddOrEditLabelModal.module.css";
import useFetchCities from "reactQuery/useFetchCities";
import { AuthContext } from "context/AuthContext";
import { useNotification } from "UI/global/notification/context/NotificationContext";
import { useMutation } from "react-query";
import { CREATE_LABEL_TYPE, UPDATE_LABEL_TYPE } from "api/labels/request";
import { LoadingButton } from "@mui/lab";

/**root parent id */
const DEFAULT_PARENT_ID = 0;

// export const EDIT_MODE = {
//   EDIT: "EDIT",
//   ADD_NEW: "ADD_NEW",
// };

/**
 * @typedef {"EDIT" | "ADD_NEW"} EDIT_MODE
 */

const ROOT_PARENT_SELECTION = { key: 0, name: "N / A" };

const initialState = {
  name: "",
  cities: [],
  rangeTitle: "",
  RatingRange: 0,
  parent: DEFAULT_PARENT_ID,
  enabled: "Y",
  hasInvalidInput: true,
};

const ACTIONS = {
  SET_NAME: "SET NAME",
  SET_RANGE_TITLE: "SET RANGE TITLE",
  SET_RATING_RANGE: "SET RATING RANGE",
  SET_PARENT: "SET PARENT",
  SET_ENABLED: "SET ENABLED",
  SET_CITIES: "SET CITIES",
  RESET: "RESET",
};

/**
 *
 * @param {initialState} state
 * @param {*} action
 * @returns {initialState}
 */
const reducer = (state, action) => {
  /**@type {initialState} */
  let newState;
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_NAME:
      newState = {
        ...state,
        name: payload,
      };
      break;
    case ACTIONS.SET_RANGE_TITLE:
      newState = {
        ...state,
        rangeTitle: payload,
      };
      break;
    case ACTIONS.SET_RATING_RANGE:
      newState = {
        ...state,
        RatingRange: payload,
      };
      break;
    case ACTIONS.SET_PARENT:
      newState = {
        ...state,
        parent: payload,
      };
      break;
    case ACTIONS.SET_ENABLED:
      newState = { ...state, enabled: payload };
      break;
    case ACTIONS.SET_CITIES:
      newState = {
        ...state,
        cities: payload,
        hasInvalidInput: payload.length === 0,
      };
      break;

    case ACTIONS.RESET:
      newState = { ...initialState };
      break;

    default:
      newState = { ...initialState };
  }

  const { name, cities, rangeTitle } = newState;
  newState.hasInvalidInput =
    name.trim() === "" || rangeTitle.trim() === "" || cities.length === 0;
  process.env.NODE_ENV === "development" &&
    console.log(`"REDUCER[old state]"`, state);
  process.env.NODE_ENV === "development" &&
    console.log(`REDUCER: `, type, payload);
  process.env.NODE_ENV === "development" &&
    console.log(`REDUCER[new state]: `, newState);
  process.env.NODE_ENV === "development" &&
    console.log(`REDUCER[=====================================]`);
  return newState;
};

/**
 *
 * @param {object} props
 * @param {{mode: EDIT_MODE, data: LabelInterface}} props.details
 * @param {(details?: {mode: EDIT_MODE, label: LabelInterface}) => void} props.onClose
 * @returns
 */
export default function AddOrEditLabelModal(props) {
  const { details, onClose } = props;

  const mode = details?.mode || "EDIT";

  const isEditMode = mode === "EDIT";
  const isAddMode = mode === "ADD_NEW";

  const [editedState, dispatch] = useReducer(reducer, initialState);

  const [parents, setParentSelections] = useState([ROOT_PARENT_SELECTION]);

  /**@type {[Object<string, CityInterface>, React.Dispatch<React.SetStateAction<Object<string, CityInterface>>>]} */
  const [cities, setCities] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotification();

  const { contextToken } = useContext(AuthContext);

  /*================================================== react query START ================================================== */
  useFetchCities(contextToken, (data) => {
    process.env.NODE_ENV === "development" &&
      console.log(`onSuccess data: `, data);
    if (data?.result?.city) {
      const cities = data.result.city;
      setCities(cities);
    }
  });

  const { mutate } = useMutation(
    /**
     *
     * @param {{mode: EDIT_MODE, token: string, data: LabelInterface}} variables
     * @returns
     */
    (variables) => {
      const { mode, token, data } = variables;
      // process.env.NODE_ENV === "development" && console.log(`token: `, token);
      // process.env.NODE_ENV === "development" && console.log(`data: `, data);
      if (mode === "EDIT") {
        process.env.NODE_ENV === "development" &&
          console.log(
            `[mutating] -> [EDIT] -> [UPDATE_LABEL_TYPE] would be called`
          );
        return UPDATE_LABEL_TYPE(token, data);
      } else if (mode === "ADD_NEW") {
        process.env.NODE_ENV === "development" &&
          console.log(
            `[mutating] -> [ADD_NEW] [CREATE_LABEL_TYPE] would be called`
          );
        return CREATE_LABEL_TYPE(token, data);
      }
    },
    {
      onMutate: (variables) => {
        setIsLoading(true);
      },
      onSettled: (data, error, variables, context) => {
        const { mode } = variables;
        setIsLoading(false);
        let message = "";
        // ERROR
        if (error) {
          if (mode === "ADD_NEW") {
            message = "Error in adding new label";
          } else {
            message = "Error in updating label";
          }
          showNotification(message, "top-center", "error");
        }
        // SUCCESS
        else if (data) {
          let label;
          if (mode === "ADD_NEW") {
            message = "New label is ADDED";
            label = data.result[0];
          } else {
            label = data.result;
            message = "Label is UPDATED";
          }
          showNotification(message, "top-center", "success");
          onClose({ mode, label });
        }
      },
    }
  );
  /*==================================================  react query END  ================================================== */

  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    onClose();
  };

  const onConfirm = () => {
    const {
      name,
      cities: cityNames,
      rangeTitle,
      RatingRange,
      parent,
      enabled,
    } = editedState;

    const cityIds = [];
    Object.entries(cities).forEach((entry) => {
      const [id, cityObject] = entry;
      const cityName = cityObject.name;

      if (cityNames.indexOf(cityName) > -1) {
        cityIds.push(id);
      }
    });

    /**@type {LabelInterface} */
    const data = {
      // id: "TBD",
      // parent: "TBD",
      name: name,
      range_title: rangeTitle,
      rating_range: RatingRange,
      enabled: enabled,
      city_id: cityIds,
    };

    process.env.NODE_ENV === "development" &&
      console.log(`[onConfirm]: `, data);
    if (isEditMode) {
      data.id = details.data.id;
      data.parent = details.data.parent;
      mutate({ mode: "EDIT", token: contextToken, data: data });
    } else if (isAddMode) {
      data.parent = parent;
      mutate({ mode: "ADD_NEW", token: contextToken, data: data });
    }
  };

  useEffect(() => {
    const data = details?.data;
    const mode = details?.mode;
    if (data) {
      const { id, rating_range, parent, enabled, name, range_title, city_id } =
        data;

      if (mode === "ADD_NEW") {
        setParentSelections([ROOT_PARENT_SELECTION, { key: id, name: name }]);
      } else if (mode === "EDIT") {
        dispatch({
          type: ACTIONS.SET_NAME,
          payload: name,
        });
        dispatch({
          type: ACTIONS.SET_RANGE_TITLE,
          payload: range_title,
        });
        dispatch({
          type: ACTIONS.SET_RATING_RANGE,
          payload: rating_range,
        });
        dispatch({
          type: ACTIONS.SET_ENABLED,
          payload: enabled,
        });
        dispatch({
          type: ACTIONS.SET_CITIES,
          payload: city_id.map((id) => cities[id].name) || [],
        });

        dispatch({
          type: ACTIONS.SET_PARENT,
          payload: parent,
        });
      }
    }

    return () => {
      dispatch({ type: ACTIONS.RESET });
    };
  }, [details, cities]);

  return (
    <div>
      <Modal
        open={details?.data !== null && details?.data !== undefined}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onBackdropClick={(a, b) => {
          process.env.NODE_ENV === "development" && console.log(`a, b: `, a, b);
        }}
      >
        <Box className={styles.modalContentWrapper}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isAddMode ? "Add new label" : "EDIT"}
          </Typography>
          <div className={styles.wrapper}>
            {details?.data?.id && isEditMode && (
              <div>
                <IrisTextField label="Name" disabled value={details.data.id} />
              </div>
            )}
            <div>
              <IrisTextField
                autoFocus
                label="Name"
                value={editedState.name}
                onChange={(event) => {
                  const cityName = event.target.value;
                  dispatch({ type: ACTIONS.SET_NAME, payload: cityName });
                }}
              />
            </div>
            <div>
              <IrisTextField
                label="Range Title"
                value={editedState.rangeTitle}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  dispatch({ type: ACTIONS.SET_RANGE_TITLE, payload: value });
                }}
              />
            </div>
            <div>
              <IrisTextField
                label="Rating Range"
                helperText="Number only"
                value={editedState.RatingRange}
                type="number"
                onBlur={(event) => {
                  if (editedState.RatingRange === "") {
                    dispatch({
                      type: ACTIONS.SET_RATING_RANGE,
                      payload: 0,
                    });
                  }
                }}
                onChange={(event) => {
                  let value = event.target.value;

                  if (value !== "" && Number(value) < 0) return;
                  dispatch({
                    type: ACTIONS.SET_RATING_RANGE,
                    payload: value,
                  });
                }}
              />
            </div>
            {isAddMode && (
              <div>
                <IrisSelect
                  label="Parent"
                  options={parents}
                  sortOptions={false}
                  keyField="key"
                  titleField="name"
                  selectProps={{
                    variant: "standard",
                    onChange: (event) => {
                      process.env.NODE_ENV === "development" &&
                        console.log(`parentValue: `, event.target.value);
                      dispatch({
                        type: ACTIONS.SET_PARENT,
                        payload: event.target.value,
                      });
                    },
                    value: editedState.parent,
                  }}
                />
              </div>
            )}
            <div>
              <IrisSelect
                label="Enabled"
                options={[
                  { key: "Y", title: "True" },
                  { key: "N", title: "False" },
                ]}
                keyField="key"
                titleField="title"
                selectProps={{
                  variant: "standard",
                  onChange: (event) => {
                    const enabled = event.target.value;
                    dispatch({ type: ACTIONS.SET_ENABLED, payload: enabled });
                  },
                  value: editedState.enabled,
                }}
              />
            </div>

            <div>
              <IrisMultiSelect
                label="Apply To"
                options={Object.values(cities).map((city) => city.name)}
                onChange={(values) => {
                  dispatch({ type: ACTIONS.SET_CITIES, payload: values });
                }}
                muiSelectProps={{
                  value: editedState.cities,
                  variant: "standard",
                }}
                renderVariant="chip"
              />
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button onClick={handleClose} color="secondary" variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              onClick={onConfirm}
              loading={isLoading}
              disabled={editedState.hasInvalidInput}
              color="primary"
              variant="contained"
            >
              {isEditMode ? "Update" : "Add"}
            </LoadingButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
