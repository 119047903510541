import React from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  main: {
    padding: "10px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30vh",
  },
  subtitle: { marginTop: "30px", textAlign: "center" },
}));

export default function IncidentReports() {
  const classes = useStyles();

  return (
    <Grid container className={classes.main}>
      <Grid item>
        <Typography variant="h4">Incident Reports Page</Typography>
        <Typography paragraph className={classes.subtitle}>
          Coming Soon...
        </Typography>
      </Grid>
    </Grid>
  );
}
