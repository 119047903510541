import { useAlert } from "./alert/context/AlertContext";
import { useLoader } from "./loader/context/LoaderContext";

function useGlobalUI() {
  const { isLoading, setIsLoading } = useLoader();

  const { showAlert } = useAlert();

  return {
    isLoading,
    setIsLoading,
    /** method to whatever */
    showAlert,
  };
}

export default useGlobalUI;
