import React, { useState } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#555",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const PostOfficeEmailRow = (props) => {
  const {
    source_id,
    recipient_email,
    request_time,
    scheduled_time_text_field,
    sender_email,
    status,
    subject,
  } = props.row;

  const handleEmailClick = props.handleEmailClick;
  return (
    <>
      <StyledTableRow onClick={() => handleEmailClick(props.row)}>
        {/* will refactor everything to make it easier */}
        <StyledTableCell align="center">{source_id}</StyledTableCell>
        <StyledTableCell align="left">{subject}</StyledTableCell>
        <StyledTableCell align="left">{sender_email}</StyledTableCell>
        <StyledTableCell align="left">{recipient_email}</StyledTableCell>
        <StyledTableCell align="left">
          {request_time.substring(0, 19)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {scheduled_time_text_field.substring(0, 19)}
        </StyledTableCell>
        <StyledTableCell align="center">{status}</StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default PostOfficeEmailRow;
