import {
  IRISGO_AUTH_SERVER_UPDATE_CLIENT_WIDGET_URL,
  IRISGO_AUTH_SERVER_CREATE_CLIENT_WIDGET_URL,
  IRISGO_AUTH_SERVER_DELETE_CLIENT_WIDGET_URL,
} from "utils/env/urls";

/**
 *
 * @param {Object} widgetObject
 * @param {number} widgetObject.id
 * @param {string} widgetObject.key
 * @param {string} widgetObject.title
 * @param {string} widgetObject.link
 * @param {number} widgetObject.access_level
 * @param {number} widgetObject.city
 * @param {number} awsToken
 * @returns
 */
export const updateClientWidget = async (widgetObject, awsToken) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${awsToken}`);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(widgetObject);

  const options = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return new Promise(async (resolve, reject) => {
    let error;
    const response = await fetch(
      IRISGO_AUTH_SERVER_UPDATE_CLIENT_WIDGET_URL,
      options
    ).catch((e) => {
      error = e;
    });

    if (error) {
      // caught error due to fetch API
      reject(error);
    } else if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const { status } = response;
      console.log("widgetObject", widgetObject);
      const message = await response.text();
      const errorMessage = `Error status: message`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};

/**
 *
 * @param {Object} widgetObject
 * @param {string} widgetObject.key
 * @param {string} widgetObject.title
 * @param {string} widgetObject.link
 * @param {number} widgetObject.city
 * @param {number} awsToken
 * @returns
 */
export const createClientWidget = async (widgetObject, awsToken) => {
  console.log("CREATING", widgetObject);

  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${awsToken}`);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(widgetObject);

  const options = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return new Promise(async (resolve, reject) => {
    let error;
    const response = await fetch(
      IRISGO_AUTH_SERVER_CREATE_CLIENT_WIDGET_URL,
      options
    ).catch((e) => {
      error = e;
    });

    if (error) {
      // caught error due to fetch API
      reject(error);
    } else if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const { status } = response;
      const message = await response.text();
      const errorMessage = `Error status: message`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};

/**
 *
 * @param {number} id
 * @param {number} awsToken
 * @returns
 */
export const removeClientWidget = async (id, awsToken) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${awsToken}`);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ id: id });

  const options = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return new Promise(async (resolve, reject) => {
    let error;
    const response = await fetch(
      IRISGO_AUTH_SERVER_DELETE_CLIENT_WIDGET_URL,
      options
    ).catch((e) => {
      error = e;
    });

    if (error) {
      // caught error due to fetch API
      reject(error);
    } else if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const { status } = response;
      const message = await response.text();
      const errorMessage = `Error status: message`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};
