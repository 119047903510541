import React from "react";
import styles from "./LoaderView.module.css";

const LoaderView = React.forwardRef((props, ref) => (
  <div tabIndex={0} ref={ref} className={styles.loaderWrapper}>
    <div className={styles.center}>
      <div className={styles.ring}></div>
      <span className={styles.loadingInfo}>loading...</span>
    </div>
  </div>
));
export default LoaderView;
