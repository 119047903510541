import React, { useContext, createContext, useState } from "react";
import LoaderModal from "../view/LoaderModal";
/*================================================== CONTEXT START ================================================== */
/**
 * @typedef LoaderContext
 * @property {boolean} isLoading if true, show loader view, false otherwise
 * @property {(isLoading: boolean) => {}} setIsLoading setter for consumer components to control the show and hide of the loader view
 */

/** @type {LoaderContext} */
const LOADER_CONTEXT_TEMPLATE = undefined;
export const LoaderContext = createContext(LOADER_CONTEXT_TEMPLATE);

export function LoaderContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoaderContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
    >
      <LoaderModal />
      {children}
    </LoaderContext.Provider>
  );
}

/*==================================================  CONTEXT END  ================================================== */

/*================================================== HOOK START ================================================== */

/**
 *
 * @returns {LoaderContext}
 */
export const useLoader = () => {
  //   const { isLoading, setIsLoading } = useContext(LoaderContext);
  const context = useContext(LoaderContext);

  if (!context) {
    throw new Error(
      "Error in use LoaderContext, please wrap the component within [LoaderContextProvider]"
    );
  }

  const { isLoading, setIsLoading } = context;
  return { isLoading, setIsLoading };
};

/*==================================================  HOOK END  ================================================== */
