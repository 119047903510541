import React from "react";
import { makeStyles } from "@mui/styles";
import { TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material/";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 50}px)`,
    padding: theme.spacing(1),
  },
  rootContainer: {
    alignItems: "flex-start",
  },
  moduleContainer: {
    display: "grid",
    minHeight: "150px",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: theme.spacing(1),
  },
  module: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  tallModule: {
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 150}px)`,
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
}));

export default function AdvancedGridList() {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Grid container className={classes.rootContainer} spacing={1}>
        <Grid item xs={8} className={classes.moduleContainer}>
          <div className={classes.module}>
            <Typography variant="h6">Active Portals</Typography>
            <ul>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://iriscity.irisradgroup.com"
                >
                  IrisCity Dashboard
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://portal.irisradgroup.com/login"
                >
                  IrisCity GIS Map
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://orangeville.portal.irisradgroup.com/login"
                >
                  IrisSentry Orangeville
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://techplace.portal.irisradgroup.com/login"
                >
                  IrisSentry Techplace
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://label.irisradgroup.com/login"
                >
                  Labeling Portal
                </Link>
              </li>
            </ul>
          </div>
          <div className={classes.module}>
            <Typography variant="h6">Backend Services</Typography>
            <ul>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://post.api.irisradgroup.com"
                >
                  Django Admin (Production)
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://irisgo.api.irisradgroup.com"
                >
                  Django Admin (Test)
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://post.api.irisradgroup.com/redoc/"
                >
                  Redoc Docs Page
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://post.api.irisradgroup.com/docs/"
                >
                  Swagger Docs Page
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://analytics.google.com/analytics/web/#/p264296662/reports/defaulthome"
                >
                  IrisCity Dashboard Analytics
                </Link>
              </li>
            </ul>
          </div>
          <div className={classes.module}>
            <Typography variant="h6">Internal Resources</Typography>
            <ul>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://irisrad.atlassian.net/wiki/home"
                >
                  IRIS R&D Confluence
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://irisrad.atlassian.net/"
                >
                  IRIS R&D Jira
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://irisrad.signin.aws.amazon.com/console"
                >
                  AWS Console (Account Alias: irisrad)
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/orgs/irisradgroup/dashboard"
                >
                  IRIS R&D GitHub
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://rogers.jasperwireless.com/provision/jsp/login.jsp"
                >
                  Rogers Control Centre
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://irisradgroup.hexnodemdm.com/login/"
                >
                  Hexnode MDM
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://drive.google.com/drive/shared-with-me"
                >
                  IRIS R&D Shared Google Drive
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://mail.google.com/"
                >
                  IRIS R&D Gmail
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://drive.google.com/drive/folders/12e2Pw-WY_OM2Tyy5sggfh3delcgW4gaA?usp=sharing"
                >
                  IRIS R&D Design Assets
                </Link>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.tallModule}>TBD</div>
        </Grid>
      </Grid>
    </Paper>
  );
}
