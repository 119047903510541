export const CLIENT_WIDGETS = {
  ASSETS_INVENTORY: {
    key: "ASSETS_INVENTORY",
    label: "Assets",
  },
  COMPLIANCE_DASHBOARD: {
    key: "COMPLIANCE_DASHBOARD",
    label: "Compliance Dashboard",
  },
  CRACK_ALERTS: {
    key: "CRACK_ALERTS",
    label: "Crack Alerts",
  },
  DETECTION_MODULE: {
    key: "DETECTION_MODULE",
    label: "Detection Module",
  },
  HEAT_MAP: {
    key: "HEAT_MAP",
    label: "Heat Map",
  },
  PCI: {
    key: "PCI",
    label: "PCI",
  },
  ROAD_DEFECT: {
    key: "ROAD_DEFECT",
    label: "Road Defect",
  },
  ROAD_PATROL_REPORT: {
    key: "ROAD_PATROL_REPORT",
    label: "Road Patrol Report",
  },
  ROUTE_COVERAGE: {
    key: "ROUTE_COVERAGE",
    label: "Route Coverage",
  },
  ROW_SIGNS: {
    key: "ROW_SIGNS",
    label: "Row Signs",
  },
  SEGMENT: {
    key: "SEGMENT",
    label: "Segment",
  },
  WASTE_PARTICIPATION: {
    key: "WASTE_PARTICIPATION",
    label: "Waste Participation",
  },
  WASTE_STATISTICS: {
    key: "WASTE_STATISTICS",
    label: "Waste Statistics",
  },
  WORK_ORDER: {
    key: "WORK_ORDER",
    label: "Work Order",
  },
};

export const DEFECTS = [
  {
    type: "Roadway",
    class: 2,
    range: "N/A",
    defect: "Pothole Paved Surface",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 4,
  },
  {
    type: "Roadway",
    class: 3,
    range: "N/A",
    defect: "Pothole Paved Surface",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Roadway",
    class: 4,
    range: "N/A",
    defect: "Pothole Paved Surface",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Roadway",
    class: 5,
    range: "N/A",
    defect: "Pothole Paved Surface",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roadway",
    class: 6,
    range: "N/A",
    defect: "Pothole Paved Surface",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roadway",
    class: 3,
    range: "N/A",
    defect: "Pothole Non-Paved Surface",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Roadway",
    class: 4,
    range: "N/A",
    defect: "Pothole Non-Paved Surface",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Roadway",
    class: 5,
    range: "N/A",
    defect: "Pothole Non-Paved Surface",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roadway",
    class: 6,
    range: "N/A",
    defect: "Pothole Non-Paved Surface",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roadway",
    class: 2,
    range: "N/A",
    defect: "Pothole Shoulder",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Roadway",
    class: 3,
    range: "N/A",
    defect: "Pothole Shoulder",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Roadway",
    class: 4,
    range: "N/A",
    defect: "Pothole Shoulder",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roadway",
    class: 5,
    range: "N/A",
    defect: "Pothole Shoulder",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 60,
  },
  {
    type: "Roadway",
    class: 6,
    range: "N/A",
    defect: "Pothole Shoulder",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 60,
  },
  {
    type: "Roadway",
    class: 2,
    range: "N/A",
    defect: "Shoulder Drop-off",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 4,
  },
  {
    type: "Roadway",
    class: 3,
    range: "N/A",
    defect: "Shoulder Drop-off",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 4,
  },
  {
    type: "Roadway",
    class: 4,
    range: "N/A",
    defect: "Shoulder Drop-off",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Roadway",
    class: 5,
    range: "N/A",
    defect: "Shoulder Drop-off",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Roadway",
    class: 6,
    range: "N/A",
    defect: "Shoulder Drop-off",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roadway",
    class: 2,
    range: "N/A",
    defect: "Cracks",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roadway",
    class: 3,
    range: "N/A",
    defect: "Cracks",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 60,
  },
  {
    type: "Roadway",
    class: 4,
    range: "N/A",
    defect: "Cracks",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 180,
  },
  {
    type: "Roadway",
    class: 5,
    range: "N/A",
    defect: "Cracks",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 180,
  },
  {
    type: "Roadway",
    class: 6,
    range: "N/A",
    defect: "Cracks",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 180,
  },
  {
    type: "Roadway",
    class: 2,
    range: "Number of Items",
    defect: "Debris",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Roadway",
    class: 3,
    range: "Number of Items",
    defect: "Debris",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Roadway",
    class: 4,
    range: "Number of Items",
    defect: "Debris",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Roadway",
    class: 5,
    range: "Number of Items",
    defect: "Debris",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Roadway",
    class: 6,
    range: "Number of Items",
    defect: "Debris",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Roadway",
    class: 2,
    range: "Number of Lights",
    defect: "Luminaire",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Roadway",
    class: 3,
    range: "Number of Lights",
    defect: "Luminaire",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Roadway",
    class: 4,
    range: "Number of Lights",
    defect: "Luminaire",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Roadway",
    class: 5,
    range: "Number of Lights",
    defect: "Luminaire",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Roadway",
    class: 6,
    range: "Number of Lights",
    defect: "Luminaire",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Roadway",
    class: 2,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 2,
  },
  {
    type: "Roadway",
    class: 3,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Roadway",
    class: 4,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 21,
  },
  {
    type: "Roadway",
    class: 5,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 21,
  },
  {
    type: "Roadway",
    class: 6,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 21,
  },
  {
    type: "Sidewalk",
    class: 2,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Sidewalk",
    class: 3,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Sidewalk",
    class: 4,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Sidewalk",
    class: 5,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Sidewalk",
    class: 6,
    range: "N/A",
    defect: "Surface Discontinuities",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Checkerboard",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Checkerboard",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Checkerboard",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Checkerboard",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Checkerboard",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Curve sign with advisory speed tab",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Curve sign with advisory speed tab",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Curve sign with advisory speed tab",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Curve sign with advisory speed tab",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Curve sign with advisory speed tab",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Do Not Enter",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Do Not Enter",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Do Not Enter",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Do Not Enter",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Do Not Enter",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Load Restricted Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Load Restricted Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Load Restricted Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Load Restricted Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Load Restricted Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Low Bridge Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "One Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "One Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "One Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "One Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "One Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "School Zone Speed Limit",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "School Zone Speed Limit",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "School Zone Speed Limit",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "School Zone Speed Limit",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "School Zone Speed Limit",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Stop Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Traffic Signal Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Traffic Signal Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Traffic Signal Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Traffic Signal Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Traffic Signal Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Two-Way Traffic Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Two-Way Traffic Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Two-Way Traffic Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Two-Way Traffic Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Two-Way Traffic Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Wrong Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Wrong Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Wrong Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Wrong Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Wrong Way",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 11",
    category: "Regulatory MMS",
    sub_defect: "Yield Ahead, New",
    service_days: 0,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Illegible Sign",
    service_days: 14,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Illegible Sign",
    service_days: 21,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Illegible Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Illegible Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Illegible Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Imporperly Oriented Sign",
    service_days: 14,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Imporperly Oriented Sign",
    service_days: 21,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Imporperly Oriented Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Imporperly Oriented Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Imporperly Oriented Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Obscured Sign",
    service_days: 14,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Obscured Sign",
    service_days: 21,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Obscured Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Obscured Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Obscured Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 2,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Missing Sign",
    service_days: 14,
  },
  {
    type: "Sign",
    class: 3,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Missing Sign",
    service_days: 21,
  },
  {
    type: "Sign",
    class: 4,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Missing Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 5,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Missing Sign",
    service_days: 30,
  },
  {
    type: "Sign",
    class: 6,
    range: "N/A",
    defect: "Regulatory/Warning Sign Sec 12",
    category: "Regulatory MMS",
    sub_defect: "Missing Sign",
    service_days: 30,
  },
  {
    type: "Weather",
    class: 2,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Plowing Required",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 3,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Plowing Required",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 4,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Plowing Required",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 5,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Plowing Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 6,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Plowing Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 2,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Salt Required",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 3,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Salt Required",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 4,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Salt Required",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 5,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Salt Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 6,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Road Salt Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 2,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Road",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 3,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Road",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 4,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Road",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 5,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Road",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 6,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Road",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 2,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Plowing Required",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 3,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Plowing Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 4,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Plowing Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 5,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Plowing Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 6,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Plowing Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 2,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Salt Required",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 3,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Salt Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 4,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Salt Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 5,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Salt Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 6,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Bicycle Lane Salt Required",
    service_days: 1,
  },
  {
    type: "Weather",
    class: 2,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Bicycle Lane",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 3,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Bicycle Lane",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 4,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Bicycle Lane",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 5,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Bicycle Lane",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 6,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Vehicle Blocking Bicycle Lane",
    service_days: 0,
  },
  {
    type: "Weather",
    class: 2,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Plowing Required",
    service_days: 2,
  },
  {
    type: "Weather",
    class: 3,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Plowing Required",
    service_days: 2,
  },
  {
    type: "Weather",
    class: 4,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Plowing Required",
    service_days: 2,
  },
  {
    type: "Weather",
    class: 5,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Plowing Required",
    service_days: 2,
  },
  {
    type: "Weather",
    class: 6,
    range: "Depth (CM)",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Plowing Required",
    service_days: 2,
  },
  {
    type: "Weather",
    class: 2,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Salt Required",
    service_days: 2,
  },
  {
    type: "Weather",
    class: 3,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Salt Required",
    service_days: 2,
  },
  {
    type: "Weather",
    class: 4,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Salt Required",
    service_days: 2,
  },
  {
    type: "Weather",
    class: 5,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Salt Required",
    service_days: 2,
  },
  {
    type: "Weather",
    class: 6,
    range: "N/A",
    defect: "Snow/Ice Accumulation",
    category: "Regulatory MMS",
    sub_defect: "Sidewalk Salt Required",
    service_days: 2,
  },
  {
    type: "Bridge",
    class: 2,
    range: "N/A",
    defect: "Bridge Deck Spall",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 4,
  },
  {
    type: "Bridge",
    class: 3,
    range: "N/A",
    defect: "Bridge Deck Spall",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Bridge",
    class: 4,
    range: "N/A",
    defect: "Bridge Deck Spall",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Bridge",
    class: 5,
    range: "N/A",
    defect: "Bridge Deck Spall",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Bridge",
    class: 6,
    range: "N/A",
    defect: "Bridge Deck Spall",
    category: "Regulatory MMS",
    sub_defect: "",
    service_days: 7,
  },
  {
    type: "Drainage",
    class: 0,
    range: "N/A",
    defect: "Catch Basin",
    category: "Other",
    sub_defect: "",
    service_days: 21,
  },
  {
    type: "Drainage",
    class: 0,
    range: "N/A",
    defect: "Culvert",
    category: "Other",
    sub_defect: "",
    service_days: 15,
  },
  {
    type: "Drainage",
    class: 0,
    range: "N/A",
    defect: "Curb and Gutter",
    category: "Other",
    sub_defect: "",
    service_days: 365,
  },
  {
    type: "Drainage",
    class: 0,
    range: "N/A",
    defect: "Ditch Inlet",
    category: "Other",
    sub_defect: "",
    service_days: 15,
  },
  {
    type: "Drainage",
    class: 0,
    range: "N/A",
    defect: "Manhole",
    category: "Other",
    sub_defect: "",
    service_days: 15,
  },
  {
    type: "Barrier",
    class: 0,
    range: "N/A",
    defect: "Cable Guiderail",
    category: "Other",
    sub_defect: "",
    service_days: 330,
  },
  {
    type: "Barrier",
    class: 0,
    range: "N/A",
    defect: "Concrete Barrier",
    category: "Other",
    sub_defect: "",
    service_days: 330,
  },
  {
    type: "Barrier",
    class: 0,
    range: "N/A",
    defect: "Fence",
    category: "Other",
    sub_defect: "",
    service_days: 330,
  },
  {
    type: "Barrier",
    class: 0,
    range: "N/A",
    defect: "Steel Beam Guiderail",
    category: "Other",
    sub_defect: "",
    service_days: 330,
  },
  {
    type: "Road Accident",
    class: 0,
    range: "N/A",
    defect: "Road Accident",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Road Condition Change",
    class: 0,
    range: "N/A",
    defect: "Bare & Dry",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Road Condition Change",
    class: 0,
    range: "N/A",
    defect: "Damp / Wet",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Road Condition Change",
    class: 0,
    range: "N/A",
    defect: "Road Snow Packed",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Road Condition Change",
    class: 0,
    range: "Coverage (CM)",
    defect: "Road Snow Coverage By CM",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Road Condition Change",
    class: 0,
    range: "N/A",
    defect: "Road Slushy",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Road Condition Change",
    class: 0,
    range: "N/A",
    defect: "Road ICY",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Road Condition Change",
    class: 0,
    range: "N/A",
    defect: "Road Snow Drifting",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Road Condition Change",
    class: 0,
    range: "N/A",
    defect: "Wheel Track Bare",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Other (Add Notes)",
    class: 0,
    range: "N/A",
    defect: "Other (Add Notes)",
    category: "Journal",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Curb/Sidewalk Repair",
    category: "Other",
    sub_defect: "",
    service_days: 365,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Illegal Dumping",
    category: "Other",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Flooding",
    category: "Other",
    sub_defect: "",
    service_days: 3,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Water Ponding",
    category: "Other",
    sub_defect: "",
    service_days: 330,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Site Restoration",
    category: "Other",
    sub_defect: "",
    service_days: 365,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Street Sweeping",
    category: "Other",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Asphalt Repair",
    category: "Other",
    sub_defect: "",
    service_days: 365,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Blvd Repair -  Interlock/Grass",
    category: "Other",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Water Truck",
    category: "Other",
    sub_defect: "",
    service_days: 5,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Grading Required",
    category: "Other",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Road Closure",
    category: "Other",
    sub_defect: "",
    service_days: 2,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Animal Pickup / Dead Deer",
    category: "Other",
    sub_defect: "",
    service_days: 14,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Graffiti",
    category: "Other",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Blvd Contract Grass Cutting",
    category: "Other",
    sub_defect: "",
    service_days: 30,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "911 Rural Sign",
    category: "Other",
    sub_defect: "",
    service_days: 0,
  },
  // Traffic
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Illegible Sign",
    category: "Traffic",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Missing Sign",
    category: "Traffic",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Improperly Oriented Sign",
    category: "Traffic",
    sub_defect: "",
    service_days: 0,
  },
  {
    type: "Roads Standard",
    class: 0,
    range: "N/A",
    defect: "Obscured Sign",
    category: "Traffic",
    sub_defect: "",
    service_days: 0,
  },
];

export const getHardCodedDefects = () => {
  const all = DEFECTS.map((d) => d.defect);
  const s = new Set(all);
  return [...s].sort();
};
