import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import IrisTable from "UI/muiTable/IrisTable";
import Button from "@mui/material/Button";

import AddIcon from "@mui/icons-material/Add";

import { AuthContext } from "context/AuthContext";

import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import { getAdminGroupList } from "utils/irisAuthRequests";
import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
import { ERROR_MESSAGE } from "utils/irisAuthRequests";

const GroupList = () => {
  const history = useHistory();

  // get token to make api call
  const { awsToken, onLogout } = useContext(AuthContext);

  const [groupList, setGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // modal displays different info according to the states
  const [getGroupListState, setGetGroupListState] = useState(SUBMIT_STATE.ok);
  // when there's an error, set state of the error message
  const [errorMessage, setErrorMessage] = useState("");

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // when there's an error
    if (getGroupListState === SUBMIT_STATE.error) {
      // when the token is not valid, click the backdrop to logout
      if (errorMessage === ERROR_MESSAGE.token) {
        onLogout();
      }
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    setIsLoading(true);

    getAdminGroupList(awsToken)
      .then((result) => {
        const tempGroupList = [];

        if (result.length > 0) {
          result.forEach((group) => {
            const tempGroupInfo = {
              groupName: group.GroupName,
            };
            tempGroupList.push(tempGroupInfo);
          });
          if (isSubscribed) {
            setGroupList(tempGroupList);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        setOpenBackdrop(true);
        setErrorMessage(err.message);
        setGetGroupListState(SUBMIT_STATE.error);
      });

    return () => (isSubscribed = false);
  }, [awsToken]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link
          to="/group/create-new-group"
          style={{ textDecoration: "none", margin: "0.5rem 0 1rem 1rem" }}
        >
          <Button variant="outlined" startIcon={<AddIcon />}>
            create a group
          </Button>
        </Link>
      </div>
      <IrisTable
        isLoading={isLoading}
        columns={[
          {
            fieldName: "groupName",
            headTitle: "Group Name",
            width: 300,
            element: (data, key, index) => (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  fontSize: 14,
                }}
                onClick={() => {
                  history.push(`/group-details/${data[key]}`);
                }}
              >
                {data[key]}
              </Button>
            ),
          },
        ]}
        data={groupList}
        idField="groupName"
      />
      <SubmitBackdrop
        open={openBackdrop}
        onClick={handleClickBackdrop}
        submitState={getGroupListState}
        modalMessage={errorMessage}
      />
    </div>
  );
};

export default GroupList;
