import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import RegisterDeviceChecklist from "../components/mobile/RegisterDeviceChecklist";
import RegisterDeviceForm from "../components/mobile/RegisterDeviceForm";
import RegisterDeviceResult from "../components/mobile/RegisterDeviceResult";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 150}px)`,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function RegisterDevice() {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [activation, setActivation] = useState();

  return (
    <div
      className={classes.root}
      disablebackdropclick
      disableEscapeKeyDown
      maxWidth="xs"
    >
      {page === 1 ? (
        <RegisterDeviceChecklist
          onNext={() => {
            setPage(2);
          }}
        />
      ) : null}
      {page === 2 ? (
        <RegisterDeviceForm
          onNext={(activation) => {
            setPage(3);
            setActivation(activation);
          }}
        />
      ) : null}
      {page === 3 ? (
        <RegisterDeviceResult
          activation={activation}
          onNext={() => {
            setPage(1);
          }}
        />
      ) : null}
    </div>
  );
}
