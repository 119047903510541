import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { TextField } from "@mui/material";
import useInput from "hooks/userInput/userInput";
import {
  AWS_PASSWORD_MIN_LENGTH,
  testAWSPassword,
} from "components/utils/aws/authUtils";
import Auth from "api/awsCognito/AuthUtils";
import { LoadingButton } from "@mui/lab";
import { useNotification } from "UI/global/notification/context/NotificationContext";
import { ResetPasswordTextField } from "components/resetPasswordTextField/ResetPasswordTextField";
import { makeStyles } from "@mui/styles";
import ResetPasswordForm from "components/forms/resetPasswordForm/ResetPasswordForm";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageDiv: {
    zIndex: 1,
    width: 500,
    padding: "1em",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    verticalAlign: "middle",
    border: "10px solid #efefef",
    backgroundColor: "#fff",
    transition: ".3s ease",
    boxShadow: `inset 1px 1px 3px rgba(0, 0, 0, .3),
		  1px 1px 2px rgba(0, 0, 0, .2),
		  40px 35px 70px -10px rgba(0, 0, 0, .5),
		  -20px -20px 100px -10px rgba(255, 255, 255, .8)`,

    "& > *": {
      margin: "10px 0",
    },
  },
}));

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {object} props.tempCognitoUser
 * @param {object} props.tempCognitoUser.userName
 * @param {object} props.tempCognitoUser.session string which used by aws to very the user who desires to change password has a valid session.
 *
 * @returns
 */
export const ResetPasswordModal = (props) => {
  const {
    open: showModal,
    onClose: handleModalClose,
    tempCognitoUser: { userName, session },
  } = props;

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const handleClose = (_, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    if (typeof handleModalClose === "function") {
      handleModalClose();
    }
  };

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <ResetPasswordForm
            title="Reset password"
            formProps={{
              className: `${classes.imageDiv}`,
            }}
            userName={userName}
            session={session}
            mode="FORCE_RESET"
            value={session}
            onResetSuccess={handleClose}
          />
        </Fade>
      </Modal>
    </div>
  );
};
