import React, { useState, useEffect } from "react";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/**
 *
 * @param {Object} props
 * @param {Array<Object>} props.options options to be selected
 * @param {Array<Object>} props.value selected options
 * @param {string} props.keyName key name of the property shown on ui
 * @param {boolean} [props.disabled] component disabled or not
 * @param {string} [props.labelName] label name of the multi select field
 * @param {Function} props.updateSelectedOptions function to update the selected options
 * @param {string} [props.textFieldStyle] indicate the text field style, either "standard" or "outlined"
 */
export default function IrisMultiSelect(props) {
  const {
    options,
    value,
    keyName,
    disabled = false,
    labelName = "",
    updateSelectedOptions,
    textFieldStyle = "outlined",
    sx= null
  } = props;

  const [selectedOptions, setSeletedOptions] = useState([]);

  useEffect(() => {
    setSeletedOptions(value);
  }, [value]);

  return (
    <Autocomplete
      multiple
      //   limitTags={2}
      disabled={disabled}
      options={options}
      value={selectedOptions}
      onChange={(event, value) => {
        updateSelectedOptions(value);
      }}
      isOptionEqualToValue={(options, value) =>
        options[keyName] === value[keyName]
      }
      disableCloseOnSelect
      getOptionLabel={(option) => option[keyName]}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option[keyName]}
        </li>
      )}
      renderInput={(params) => (
        <TextField variant={textFieldStyle} {...params} label={labelName} />
      )}
      sx={sx}
    />
  );
}
