import React from "react";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormControl, TextField } from "@mui/material";
import { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";

/**
 * @param {Object} props
 * @param {string} [props.tableTitle]
 * @param {number} props.numSelected
 * @param {() => {}} props.onRemove
 * @param {(value: string) => {}} props.onSearch
 * @param {Function} [props.onRefresh]
 * @param {Function} [props.onAdd]
 * @param {boolean} props.isLoading if true, table is awaiting incoming data
 */
const IrisTableToolbar = (props) => {
  const {
    numSelected,
    tableTitle = "",
    onRemove = () => {},
    onSearch,
    onRefresh,
    onAdd,
    isLoading,
  } = props;

  const [searchValue, setSearchValue] = useState("");

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableTitle}
          <Tooltip title="Refresh" placement="right">
            <span>
              {/* MUI: You are providing a disabled `button` child to the Tooltip component.
                  A disabled element does not fire events.
                  Tooltip needs to listen to the child element's events to display the title.

                  Add a simple wrapper element, such as a `span`. 
                */}
              <IconButton onClick={onRefresh} disabled={isLoading}>
                <RefreshIcon />
              </IconButton>
            </span>
          </Tooltip>
          {typeof onAdd === "function" && (
            <Tooltip title="Add New" placement="right">
              <span>
                <IconButton onClick={onAdd} disabled={isLoading}>
                  <AddIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={onRemove}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        // <Tooltip title="Filter list">
        //   <IconButton>
        //     <FilterListIcon />
        //   </IconButton>
        // </Tooltip>
        <FormControl>
          <TextField
            type="search"
            variant="standard"
            placeholder="Search"
            onChange={(e) => {
              const value = e.target.value.trim();
              if (value === "") {
                setSearchValue("");
                onSearch("");
              } else {
                setSearchValue(value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && typeof onSearch === "function") {
                onSearch(searchValue);
              }
            }}
          />
        </FormControl>
      )}
    </Toolbar>
  );
};

export default IrisTableToolbar;
