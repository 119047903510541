import React, { useEffect, useState } from "react";
import isSameDay from "date-fns/isSameDay";
import locale from "date-fns/locale/en-US";
import isWithinInterval from "date-fns/isWithinInterval";

import { FormControl, InputLabel, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import { styled } from "@mui/material/styles";
import PickersDay from "@mui/lab/PickersDay";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})((props) => {
  const { theme, dayIsBetween, isFirstDay, isLastDay } = props;

  return {
    ...(dayIsBetween && {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    ...(isFirstDay && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...(isLastDay && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  };
});

export function setToStartOfDate(date) {
  const temp = new Date(date);
  temp.setHours(0);
  temp.setMinutes(0);
  temp.setSeconds(0);
  temp.setMilliseconds(0);
  return temp;
}

if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

const DATE_RANGE_SOURCE = {
  from: "from",
  to: "to",
};

const stackStyle = { display: "flex", flexDirection: "column" };

/**
 * @param {object} props
 * @param {string} props.title title for the date range picker, ie: On line schedule
 * @param {Date} props.startDate
 * @param {boolean} [props.disabled]
 * @param {Date} props.endDate
 * @param {Date} [props.min] min date for both date pickers
 * @param {Date} [props.max] max date for both date pickers
 * @param {(startDate: Date, endDate) => {]}} props.onChange callback function when date range is changed
 * @param {boolean} [props.stack] if true, the date pickers would stack vertically, false otherwise, default is false
 * @param {import("@mui/material").TextFieldProps} [props.inputProps]
 * @param {import("react").CSSProperties} [props.style] style for the outmost wrapper
 * @param {import("react").CSSProperties} [props.datePickerStyle] style for both date pickers
 *
 * 
 * @example
 * <IrisDateRangePickers
        startDate={new Date("2022-03-03")}
        endDate={new Date()}
        min={new Date("2022-03-01")}
        max={new Date()}
        onChange={(startDate, endDate) => {
          console.log("startDate", startDate);
          console.log("endDate", endDate);
        }}
      />
 */
function IrisDateRangePickers(props) {
  const {
    title,
    startDate,
    min,
    disabled = false,
    endDate,
    max,
    stack = false,
    inputProps = { variant: "standard" },
    style = {},
    datePickerStyle = {},
    onChange,
  } = props;
  const [selectedDate, setSelectedDate] = useState({
    startDate: startDate || new Date(),
    endDate: endDate || new Date(),
  });

  useEffect(() => {
    if (Date.parse(startDate)) {
      setSelectedDate((prev) => ({ ...prev, startDate: new Date(startDate) }));
    }
    if (Date.parse(endDate)) {
      setSelectedDate((prev) => ({ ...prev, endDate: new Date(endDate) }));
    }
  }, [startDate, endDate]);

  /**
   * @summary a callback function passed in both date picker
   *
   * @description this method would be called once the user has selected a date from either of the
   * date picker. The source varaibel tells whehter the date is selected from the start date picker
   * or the end date picker so that the corresponding value would be updated properly
   *
   * @param {Date} date
   * @param {DATE_RANGE_SOURCE} source
   */
  const handleDateChange = (date, source) => {
    const isFrom = source === DATE_RANGE_SOURCE.from;
    const copy = new Date(date);
    const { startDate, endDate } = selectedDate;
    const startCopy = new Date(startDate);
    const endCopy = new Date(endDate);
    if (isFrom) {
      // date is selected from start date picker
      setSelectedDate((current) => ({
        ...current,
        startDate: copy,
      }));
    } else {
      // date is selecte dfrom end date picker
      setSelectedDate((current) => ({
        ...current,
        endDate: copy,
      }));
    }

    // send update dates to parent component, withDatePicker.js
    if (typeof onChange === "function") {
      if (isFrom) {
        onChange(copy, endCopy);
      } else {
        onChange(startCopy, copy);
      }
    }
  };

  /**
   /**
   * @summary the overwritten, customized version of the renderWrappedWeekDay of the DatePicker
   *
   * @description by overwriting this method, the dates between the selected start date and end date
   *  would be highlighted, a small touch that notifies the user that the date range he has selected
   * 
   * @param {Date} date date of the month, from date 1 to last date of the month
   * @param {Date} currentDate // the user selected date
   * @param {Boolean} dayInCurrentMonth // whehter or not date is in the current month
   * @param {DATE_RANGE_SOURCE} source  // the date picker is for start date or for end date
   * @returns 
   */
  const renderWrappedWeekDay = (
    date,
    currentDates,
    pickersDayProps,
    source
  ) => {
    const [currentDate] = currentDates;
    const { startDate, endDate } = selectedDate;

    const isStartDate = source === DATE_RANGE_SOURCE.from;
    let start, end;

    if (isStartDate) {
      start = new Date(currentDate);
      end = new Date(endDate);
    } else {
      start = new Date(startDate);
      end = new Date(currentDate);
    }

    start = setToStartOfDate(start);
    end = setToStartOfDate(end);

    const dateClone = new Date(date);

    let dayIsBetween;

    // determine whehter or not the day is within the selected
    // start date and the end date or not

    if (isStartDate) {
      dayIsBetween = isWithinInterval(dateClone, {
        start: currentDate,
        end,
      });
    } else {
      dayIsBetween = isWithinInterval(dateClone, {
        start,
        end: currentDate,
      });
    }

    let isFirstDay, isLastDay;

    isFirstDay = isSameDay(dateClone, start);
    isLastDay = isSameDay(dateClone, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  // style
  let wrapperStyle = style;
  if (stack) {
    wrapperStyle = { ...wrapperStyle, ...stackStyle };
  }
  return (
    <div style={wrapperStyle}>
      <InputLabel>{title}</InputLabel>
      <div style={{ display: "flex", marginTop: "1rem", gap: "1rem" }}>
        <FormControl style={{ margin: "0.5rem 0.375rem" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              style={datePickerStyle}
              minDate={min}
              maxDate={max}
              disabled={disabled}
              label="From"
              inputFormat="yyyy-MM-dd"
              mask="____-__-__"
              value={selectedDate.startDate}
              renderInput={(params) => (
                <TextField {...params} {...inputProps} />
              )}
              onChange={(value) =>
                handleDateChange(value, DATE_RANGE_SOURCE.from)
              }
              renderDay={(date, currentDate, dayInCurrentMonth) =>
                renderWrappedWeekDay(
                  date,
                  currentDate,
                  dayInCurrentMonth,
                  DATE_RANGE_SOURCE.from
                )
              }
              inputProps={{
                readOnly: true,
              }}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl style={{ margin: "0.5rem 0.375rem" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              style={datePickerStyle}
              minDate={min}
              maxDate={max}
              disabled={disabled}
              label="To"
              inputFormat="yyyy-MM-dd"
              mask="____-__-__"
              value={selectedDate.endDate}
              renderInput={(params) => (
                <TextField {...params} {...inputProps} />
              )}
              onChange={handleDateChange}
              renderDay={(date, currentDate, dayInCurrentMonth) =>
                renderWrappedWeekDay(
                  date,
                  currentDate,
                  dayInCurrentMonth,
                  DATE_RANGE_SOURCE.to
                )
              }
              inputProps={{
                readOnly: true,
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </div>
    </div>
  );
}

export default IrisDateRangePickers;
