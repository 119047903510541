import React from "react";
import { createContext, useCallback, useState, useContext } from "react";
import Notification from "../view/NotificationView";

/**
 * @typedef {"top-center" | "top-right" | "bottom-right" | "bottom-center" | "bottom-left" | "top-left"} PositionType predefined position strings for the notification UI
 */

/**
 * @typedef {"information" | "warning" | "success" | "error"} NotificationType
 */
/**
 * @callback showNotificationFunction
 * @param {String} message message to be shown
 * @param {PositionType} [position] position of the message UI, default is **"top-left"**
 * @param {NotificationType} [notificationType] type of the notification, default is **"information"**
 * @returns {void}
 */

/**
 * @typedef {Object} NotificationContext
 * @property {Object} notification  notification object should be used ONLY by the NotificationView component
 * @property {Boolean} notification.show if true, show notification UI, false otherwise
 * @property {String} notification.message message to be shown to user
 * @property {Object} notification.position position of the notification UI, default is "{vertical: "top", horizontal: "left"}"
 * @property {String} notification.position.vertical vertical position of the notification UI
 * @property {String} notification.position.horizontal  horizontal position of the position UI
 * @property {NotificationType} notification.notificationType  notification type, either **"information"**, **"warnning"**, **"success"**, or **"error"**
 * @property {showNotificationFunction} showNotification callback function to show notification
 * @property {() => void} hideNotification callback function to hide notification
 */

/**@type {NotificationContext} */
const DEFAULT_CONTEXT = null;
const NotificationContext = createContext(DEFAULT_CONTEXT);

function NotificationView({ children }) {
  const [notification, setNotification] = useState({
    show: false,
    message: "default message",
    position: {
      vertical: "top",
      horizontal: "left",
    },
    /** @type {NotificationType} */
    notificationType: "information",
  });

  /**
   * @type {showNotificationFunction}
   */
  const showNotification = useCallback(
    (message, position = "top-left", notificationType = "information") => {
      const [vertical, horizontal] = position.split("-");
      setNotification({
        show: true,
        message,
        position: { vertical, horizontal },
        notificationType,
      });
    },
    []
  );

  const hideNotification = useCallback(() => {
    setNotification((prev) => ({ ...prev, show: false }));
  }, []);

  return (
    <NotificationContext.Provider
      value={{ notification, showNotification, hideNotification }}
    >
      <Notification />
      {children}
    </NotificationContext.Provider>
  );
}

function useNotification() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within a NotificationContext"
    );
  }

  const { notification, showNotification, hideNotification } = context;

  return { notification, showNotification, hideNotification };
}

export { NotificationView as default, useNotification };
