import React from "react";
import {
  AWS_PASSWORD_RULES,
  testAWSPassword,
} from "components/utils/aws/authUtils";
import styles from "./ResetPasswordTextField.module.css";
import { TextField } from "@mui/material";

/**
 *
 * @param {boolean} isNeutral if true, render neutral style (-), not check mark or red cross
 * @param {boolean} goodCondition if true, green style (check mark) would be render, otherwise, red style (red cross) style would be rendered
 * @returns {string} class name that represent helper text list style
 */
const getHelperTextListStyle = (isNeutral, goodCondition) => {
  if (isNeutral) {
    return styles.neutral;
  } else if (goodCondition) {
    return styles.green;
  }
  return styles.red;
};
/**
 * @summary text field for password input, with proper helper text set up
 * @param {import("@material-ui/core").TextFieldProps} props
 * @returns
 */
export const ResetPasswordTextField = (props) => {
  const { value, onChange, ...restProps } = props;
  const getHelperText = () => {
    const password = value.trim();

    /** no char got input, there is no right or wrong */
    const isNeutral = password.length === 0;

    const {
      details: {
        hasNumber,
        hasLowercase,
        hasUppercase,
        isLongEnough,
        hasSpecialCharacter,
      },
    } = testAWSPassword(password);

    return (
      <span className={styles.cognitoPasswordHelperTextList}>
        <li className={getHelperTextListStyle(isNeutral, isLongEnough)}>
          {AWS_PASSWORD_RULES.AT_LEAST_EIGHT_CHARACTERS}
        </li>
        <li className={getHelperTextListStyle(isNeutral, hasNumber)}>
          {AWS_PASSWORD_RULES.MUST_HAVE_NUMERIC_CHARACTER}
        </li>
        <li className={getHelperTextListStyle(isNeutral, hasLowercase)}>
          {AWS_PASSWORD_RULES.MUST_HAVE_LOWERCASE_CHARACTER}
        </li>
        <li className={getHelperTextListStyle(isNeutral, hasUppercase)}>
          {AWS_PASSWORD_RULES.MUST_HAVE_UPPERCASE_CHARACTER}
        </li>
        <li className={getHelperTextListStyle(isNeutral, hasSpecialCharacter)}>
          {AWS_PASSWORD_RULES.MUST_HAVE_SPECIAL_SYMBOL}
        </li>
      </span>
    );
  };

  return (
    <TextField
      {...restProps}
      variant="standard"
      type="password"
      autoComplete="off"
      value={value}
      onChange={onChange}
      helperText={getHelperText()}
    />
  );
};
