import React, { useCallback, useEffect, useRef, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
// import TextField from "@mui/material/TextField";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import {
  TextField,
  Button,
  ButtonGroup,
  Link,
  List,
  ListItemIcon,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  createTree,
  DEFAULT_ANCESSTOR_KEY_NAME,
  DEFAULT_CHILDREN_KEY_NAME,
  flatArrayOfObject,
} from "./utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import finderStyle, { LIST_WIDTH } from "./irisFinderMuiStyle";

// constants

const ID_DIRECTORY = "_ID_DIRECTORY";
const NAME_DIRECTORY = "_NAME_DIRECTORY";

const DEFAULT_PARENT_ID = -1;
const DEFAULT_PARENT_NAME = "";

/**
 *
 * @param {object} props
 * @param {[Node]} props.data
 * @param {string} props.idKey key name whose value could be uniquely identify a data point, ie **"product_id"**, **"city_id"**
 * @param {string} props.nameKey key name whose value represents readable information about a data point, ie **"production_name"**, **"city_name"**
 * @param {string} props.parentKey key name that identifies a data's parent, ie: **"parent_id"**, or **"parent_name"**
 * @param {string | number} props.rootCondition condition that tells whether a data point is a root node, ie: if the root condition is **parnet_id = 0**, then pass in **0**, if the root; or it is **parent_name = ""**, then pass in **""**
 * @param {string} [props.childrenField] key name that would be assigned each data object, whose value contains a set of its children, ie: whose parent id is the same as the object's id, the **id** here should be specify via the **idField** prop
 * @param {string} [props.ancesstorField] default is **"ancesstors"**, key name that would be assigned each data object, whose value contains a set of its ancesstors, from the top most to the nearest
 * @param {string} [props.separator] default is **"/"**
 * @param {string | number} [props.highlightId] id that a data point would be highligted, from top most parent to itself, after rendered
 * @param {(item: Object) => void} [props.onAdd] callback function that handles the logic of adding a new data
 * @param {(item: Object) => void} [props.onEdit] callback function that handles the logic of updating a given data
 * @param {(item: Object) => void} [props.onRemove] callback function that handles the logic of removing a given data
 * @param {(item: Object) => void} [props.onToggle] callback function that handles the logic of toggling a given data
 * @param {number} [props.height] height of the finder, default is **400**
 * @param {number} [props.listWidth] width of each list on the finder, default is **280**
 * @param {object} [props.listItemSecondIcon]
 * @param {string} props.listItemSecondIcon.key key that whose pointed value could determined whether or not the desired icon should be shown
 * @param {number | string} props.listItemSecondIcon.condition if key is "enabled" and if data["enabled"] = "Y" should rendered desired icon, pass *"Y"*
 * @param {JSX.Element} props.listItemSecondIcon.onMatchIcon desired icon to be renendered
 * @param {JSX.element} [props.listItemSecondIcon.defaultIcon] default icon to be rendered
 */

export default function IrisFinder(props) {
  const {
    data,
    idKey,
    nameKey,
    parentKey,
    rootCondition,
    separator = "/",
    highlightId,
    onAdd,
    onEdit,
    onRemove,
    onToggle,
    height,
    listWidth,
    listItemSecondIcon,
  } = props;

  const secondaryActionIconKey = listItemSecondIcon?.key;

  const routes = [DEFAULT_PARENT_ID];

  const [augmentedData, setAugmentedData] = useState([]);

  const [parentIds, setParentIds] = useState(routes);
  const [path, setPath] = useState(separator);
  const [selectedIds, setSelectedIds] = useState([separator]);

  /** @type {[ Object<string, [import("./utils").TreeInterface]>, React.Dispatch<React.SetStateAction<Object<string, [import("./utils").TreeInterface]>>>]} */
  const [directoryNodesMapping, setDirectoryNodesMapping] = useState({});

  const [searchText, setSearchText] = useState("");

  /** @type {React.MutableRefObject<HTMLElement>} */
  const finderRef = useRef();

  /**
   * for better UX
   * an object, whose key is the data point id (use to id the html element), while
   * the value is the actual rendered list item
   */
  const listItemsRef = useRef({});

  const classes = finderStyle({
    height: height,
    listWidth: listWidth,
    hasRightHandSideIcon: listItemSecondIcon,
  });

  // if (!data || !Array.isArray(data) || data.length === 0) {
  //   throw new Error(
  //     "no [data] is provided, either it is not an array or it is an empty array"
  //   );
  // }
  // if (!idKey) {
  //   throw new Error("no [idKey] is provided or it is an empty string");
  // }
  // if (!nameKey) {
  //   throw new Error("no [nameKey] is provided or it is an empty string");
  // }
  // if (!parentKey) {
  //   throw new Error("no [parentKey] is provided or it is an empty string");
  // }
  // if (typeof rootCondition !== "number" && typeof rootCondition !== "string") {
  //   throw new Error(
  //     "either [rootCondition] is not provided or it is neither a string ('' is VALID) nor a number (0 is VALID)"
  //   );
  // }

  /**
   *
   * @summary callback for onClick props of ListItem compoment
   * @description when an list item is clicked, some state updates is needed
   * based on the *value*. Also, a new list might be appeneded on the right hand
   * side, which mean that auto scrolling would be desirable
   *
   * @see highlightAssociateItems for state udpates based on *value*
   * @see handleScrollRight for auto scrolling
   */
  const handleListItemClick = (value) => () => {
    highlightAssociateItems(value);
    handleScrollRight();
  };

  const highlightAssociateItems = useCallback(
    /**
     *
     * @param {import("./utils").TreeInterface} value
     */
    (value) => {
      const id = value[idKey];
      const ancesstors = value.__ancesstors__;
      const directory = value[NAME_DIRECTORY];

      // all ancesstors' id should be considered as selected id
      // const selectedIds = ancesstors.map((ancesstor) => ancesstor[idField]);
      // const nextPaths = [];
      const selectedIds = [];

      // list of folders from topmost to the nearest parent
      let nextPaths = [];

      /**sequential names' of ancesstors, from top most to the nearest parent name */
      const ancessotrName = [];
      ancesstors.forEach((ancesstor) => {
        const id = ancesstor[idKey];
        const name = ancesstor[nameKey];
        selectedIds.push(id);
        nextPaths.push(id);
        ancessotrName.push(name);
      });
      // including myself
      selectedIds.push(id);
      // process.env.NODE_ENV === "development" &&
      //   console.log(`selectedIds: `, selectedIds);
      setSelectedIds(selectedIds);

      /**
       * if directory = "/CPU"
       * next path = ["", CPU]
       *
       * for consistency: replacing "" with seperator (default is "/") in next step
       */
      // const nextPaths = directory.split(seperator);

      // as this item is click, append itself as a folder
      nextPaths.push(id);

      // replacing "" (default folder name for top most parent) with seperator in next step

      setParentIds(nextPaths);

      if (ancessotrName.length === 1) {
        ancessotrName.push(DEFAULT_PARENT_NAME);
      }

      // show current directory on UI
      // setPath(ancessotrName.join(seperator));
      setPath(directory);
    },
    [idKey, nameKey]
  );

  /** function that handle the scrolling animation */
  const scrollRight = () => {
    const { current } = finderRef;

    current.scrollTo({
      top: 0,
      left: LIST_WIDTH * parentIds.length, //sloppy calcuation INDEED
      behavior: "smooth",
    });
  };
  /**
   * when the number of lists grows, the list container would overflow, with scrolling bar.
   * this method is designed to auto scroll to the right most end (kind of brutal),
   * so the the newly appended list would be scroll into the view, without manual scrolling
   */
  const handleScrollRight = () => {
    if (window.requestAnimationFrame) {
      // better UX in terms of animation
      window.requestAnimationFrame(scrollRight);
    } else {
      // fallback to set time out
      setTimeout(scrollRight, 1000 / 60);
    }
  };

  const handleScrollRightOnSearchTextCleared = () => {
    setSearchText("");
    setTimeout(scrollRight, 1000 / 60);
  };

  const renderListOfSearchedItems = () => {
    const filteredData = augmentedData.filter(
      (obj) =>
        obj.__children__.length === 0 &&
        obj[nameKey].toLowerCase().includes(searchText.toLocaleLowerCase())
    );

    return (
      <div className={classes.root} ref={finderRef}>
        <List className={classes.list}>
          {filteredData.map((value, index) => {
            const withinSelectedId = selectedIds.indexOf(value[idKey]) > -1;
            return (
              <ListItem
                key={index}
                title="double click to navigate"
                onDoubleClick={handleDirectoryClick} // direct user to this list items, with list hierarchy
                selected={withinSelectedId}
                button
                onClick={handleListItemClick(value)}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FilePresentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={value[nameKey]}
                  secondary={value[NAME_DIRECTORY]}
                />
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  };

  const renderFinder = () => {
    return (
      <div className={classes.root} ref={finderRef}>
        {parentIds.map((parentId, index, parentIdsArray) => {
          /**copy of the subPaths */
          const subPathsCopy = [...parentIdsArray];

          /** subset of the subPaths, [0, index] */
          const parentIdsSubset = subPathsCopy.slice(0, index + 1);

          /** pass to each list item, indicationg sub paths up unti itself */
          /**
           * if parentIdsSubset was ["-1", "rootId0", "rootId1"]
           * which means the list items about to be renendered are
           * children of "-1/rootId0/rootId1".
           *
           * a copy of parentIdsSubset should be kept by each list item
           * so that when each of them is clicked, the subPaths could be
           * updated properly, based on this copy
           */

          /** value = '/' */
          // if parentIdsSubset = ["-1", "rootId0", "rootId1"]

          const rootDirectory = parentIdsSubset.shift();

          const currentDirectory =
            rootDirectory + separator + parentIdsSubset.join(separator);

          // RECALL
          // directoryNodesMapping = {
          //  "-1/": Array(2) -> list of node to be rendered under this directory
          //  "-1/1": Array(2)
          //  "-1/1/4": Array(2)
          //  "-1/1/5": Array(3)
          //  "-1/2": Array(2)
          //  "-1/2/11": Array(2)
          //  "-1/2/11/21": Array(3)
          //  "-1/2/11/22": Array(3)
          //  "-1/2/12": Array(2)
          //  "-1/2/12/14": Array(3)
          //  "-1/2/12/13": Array(3)
          //}
          const children = directoryNodesMapping[currentDirectory] || [];

          // process.env.NODE_ENV === "development" &&
          //   console.log(`check CHILDREN: currentDirectory: `, currentDirectory);
          // process.env.NODE_ENV === "development" &&
          //   console.log(
          //     `check CHILDREN: directoryNodesMapping: `,
          //     directoryNodesMapping
          //   );
          // process.env.NODE_ENV === "development" &&
          //   console.log(`check CHILDREN: children: `, children);
          // process.env.NODE_ENV === "development" &&
          //   console.log(`check CHILDREN: =============================`);

          return (
            <List
              className={classes.list}
              key={index}
              // onClick={handleListClick()}
            >
              {children.map((value, index) => {
                const withinSelectedId = selectedIds.indexOf(value[idKey]) > -1;

                let icon = <FolderIcon />;

                if (value.__children__.length > 0) {
                  // should looks like a folder

                  if (withinSelectedId) {
                    // should looks like an openend folder
                    icon = <FolderOpenIcon />;
                  }
                } else {
                  icon = <FilePresentIcon />;
                }
                return (
                  <ListItem
                    ref={(element) =>
                      (listItemsRef.current[value.id] = element)
                    }
                    selected={withinSelectedId}
                    key={index}
                    button
                    onClick={handleListItemClick(value)}
                    secondaryAction={
                      value[secondaryActionIconKey] ===
                        listItemSecondIcon?.condition &&
                      listItemSecondIcon?.onMatchIcon
                    }
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={value.name} />
                  </ListItem>
                );
              })}
            </List>
          );
        })}
      </div>
    );
  };

  const handleDirectoryClick = () => {
    if (!searchText) {
      return;
    } else {
      handleScrollRightOnSearchTextCleared();
    }
  };

  const handleRemove = () => {
    const target = getLastSelectedData();
    if (target && typeof onRemove === "function") {
      onRemove(target);
    }
  };

  const handleAdd = () => {
    const target = getLastSelectedData();
    if (target && typeof onAdd === "function") {
      onAdd(target);
    }
  };

  const handleEdit = () => {
    const target = getLastSelectedData();
    if (target && typeof onEdit === "function") {
      onEdit(target);
    }
  };

  const handleToggle = () => {
    const target = getLastSelectedData();
    if (target && typeof onToggle === "function") {
      onToggle(target);
    }
  };

  const getLastSelectedData = () => {
    const id = selectedIds[selectedIds.length - 1];
    const target = augmentedData.find((data) => data[idKey] === id);
    return target;
  };

  useEffect(() => {
    // tree structure that each element would contain list of children, if there is any
    const tree = createTree(data, rootCondition, idKey, parentKey, nameKey);

    // one layer array that each eleemnt contain list of children AND list of ancesstors (from root to father (mother))
    const flattenTree = flatArrayOfObject(tree, DEFAULT_CHILDREN_KEY_NAME);

    /**
     * object whose key represents a directory
     * and crresponding value represent a set of nodes (files) that
     * belongs to the current directy
     * @type {Object<string, [import("./Tree").TreeNode]}
     * */
    const directoryNodesMapping = {};

    // add "directory" value to each object in flattenTree
    flattenTree.forEach((tree) => {
      const id = tree[idKey];
      const name = tree[nameKey];
      /**
       * - id = value of *idField*
       * - name = value of *nameKey*
       * @type {[{id: number, name: string}]}
       */
      const ancesstors = [...tree[DEFAULT_ANCESSTOR_KEY_NAME]];

      /**
       * each ancesstors name represent a filder name
       * @type {[number]}
       * */
      const sequentialParentIds = [];

      /**@type {[string]} */
      const sequentialParentNames = [];
      ancesstors.forEach((ancesstor) => {
        const id = ancesstor[idKey];
        const name = ancesstor[nameKey];
        sequentialParentIds.push(id);
        sequentialParentNames.push(name);
      });
      const root = sequentialParentIds.shift();
      const idDirectory =
        root + separator + sequentialParentIds.join(separator);

      // add directory value to obect of flatten tree
      tree[ID_DIRECTORY] = idDirectory + separator + tree[idKey];
      tree[NAME_DIRECTORY] = sequentialParentNames
        .concat(tree[nameKey])
        .join(separator);

      const temp = {
        id,
        name,

        [ID_DIRECTORY]: [...sequentialParentIds, tree[idKey]].join(separator),
        [NAME_DIRECTORY]: [...sequentialParentNames, tree[nameKey]].join(
          separator
        ),
        [DEFAULT_ANCESSTOR_KEY_NAME]: [...tree[DEFAULT_ANCESSTOR_KEY_NAME]],
        [DEFAULT_CHILDREN_KEY_NAME]: JSON.parse(
          JSON.stringify(tree[DEFAULT_CHILDREN_KEY_NAME])
        ),
      };

      if (secondaryActionIconKey) {
        const secondaryIconBoolean = tree[secondaryActionIconKey];
        temp[secondaryActionIconKey] = secondaryIconBoolean;
      }

      directoryNodesMapping[idDirectory] = [
        ...(directoryNodesMapping[idDirectory] || []),
        temp,
      ];
    });

    // process.env.NODE_ENV === "development" && console.log(`flattedTree: `, flattenTree)
    // process.env.NODE_ENV === "development" && console.log(`directoryNodesMapping: `, directoryNodesMapping)
    setAugmentedData(JSON.parse(JSON.stringify(flattenTree)));
    setDirectoryNodesMapping(directoryNodesMapping);
  }, [
    data,
    idKey,
    nameKey,
    rootCondition,
    parentKey,
    separator,
    secondaryActionIconKey,
  ]);

  // listen to updates of selected ids, which mark items to be highlighted
  // for better UX, make sure those items are within the view port of the component
  // IOW, make sure each list itme are shown on the view port of their own list
  useEffect(() => {
    selectedIds.forEach((id) => {
      /**@type {HTMLElement} */
      const selectedListItem = listItemsRef.current[id];
      const finderRect = finderRef.current.getBoundingClientRect();
      const threshold = finderRect.top + finderRect.height;
      if (selectedListItem) {
        const listItemRect = selectedListItem.getBoundingClientRect();
        const isNotInFinderViewPort = listItemRect.top > threshold;
        if (isNotInFinderViewPort) {
          selectedListItem.scrollIntoView({
            block: "end",
            inline: "nearest",
          });
        }
      }
    });
  });

  // listen to passed in highlightId
  useEffect(() => {
    if (highlightId) {
      const found = augmentedData.find((datum) => datum[idKey] === highlightId);
      if (found) {
        highlightAssociateItems(found);
      }
    }
  }, [augmentedData, idKey, highlightId, highlightAssociateItems]);
  return (
    <>
      <Typography variant="subtitle1">{path}</Typography>
      <TextField
        fullWidth
        variant="standard"
        placeholder="Search"
        onKeyDown={(event) => {
          const value = event.target.value.trim();

          switch (event.key.toLocaleLowerCase()) {
            case "escape":
              event.target.value = "";
              handleScrollRightOnSearchTextCleared();
              break;

            case "enter":
              setSearchText(value);
              break;

            default:
              return;
          }
        }}
      />
      {searchText && renderListOfSearchedItems()}
      {!searchText && renderFinder()}

      {!searchText &&
        (typeof onRemove === "function" ||
          typeof onToggle === "function" ||
          typeof onEdit === "function" ||
          typeof onAdd === "function") && (
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            disabled={selectedIds.length < 2}
          >
            {onRemove && (
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleRemove}
                startIcon={<DeleteIcon />}
              >
                Remove
              </Button>
            )}
            {onToggle && (
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleToggle}
                startIcon={<VisibilityIcon />}
              >
                Toggle
              </Button>
            )}
            {onEdit && (
              <Button
                color="success"
                onClick={handleEdit}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
            )}
            {onAdd && (
              <Button
                color="primary"
                onClick={handleAdd}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            )}
          </ButtonGroup>
        )}
    </>
  );
}
