import { COGNITO_AUTH_URL } from "utils/env/urls";

const AuthLocalStorageKeys = {
  AWS_REFRESH_TOKEN: "AWS_REFRESH_TOKEN",
};

/**
 * parse a jwt into a readable object.
 * @param {string} token jwt token
 * @returns {TypeJWTToken | undefined}
 *
 * @see {@link https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library stackoverflow}
 */
const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

  let result, error;
  try {
    result = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  } catch (e) {
    error = e;
  }

  if (error) {
    return result;
  }

  /**@type {TypeJWTToken} */
  const temp = JSON.parse(result);

  const { iat, exp } = temp;
  temp.expLocal = new Date(exp * 1000).toLocaleString();
  temp.iatLocal = new Date(iat * 1000).toLocaleString();
  return temp;
};

/**
 * convert a response from AWS into a readable object
 * @param {TypeAuthenticationResult1} response
 * @returns {TypeAuthPromiseResult}
 */
const getAuthResult = (response) => {
  /**@type {TypeAuthPromiseResult} */
  const authResult = {
    loginSuccess: true,
    tokenPayloads: undefined,
    challengePayload: undefined,
    ChallengeParameters: undefined,
  };
  const { AuthenticationResult, ChallengeParameters } = response;
  authResult.ChallengeParameters = ChallengeParameters;

  const { AccessToken, IdToken, RefreshToken, TokenType, ExpiresIn } =
    AuthenticationResult;

  /**@type {TypeTokenPayloads} */
  const tokenPayloads = {
    expiresIn: ExpiresIn,
    tokenType: TokenType,
  };

  // build token payloads
  tokenPayloads.accessToken = {
    token: AccessToken,
    details: parseJwt(AccessToken),
  };
  tokenPayloads.idToken = {
    token: IdToken,
    details: parseJwt(IdToken),
  };
  tokenPayloads.refreshToken = {
    token: RefreshToken,
    details: parseJwt(RefreshToken),
  };

  authResult.tokenPayloads = tokenPayloads;
  return authResult;
};

const getErrorMessage = (result) => {
  const message = result?.message || "Unknown error message";
  return message;
};

/**
 * @see {@link https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/Welcome.html Amazon Cognito API Reference}
 */
class Auth {
  /**@see {@link https://docs.aws.amazon.com/general/latest/gr/cognito_identity.html Amazon Cognito Identity endpoints and quotas} */
  #authUrl = COGNITO_AUTH_URL;
  #clientId = "";
  #accessToken = "";

  constructor() {
    if (!Auth.instance) {
      Auth.instance = this;
    }

    this.refreshToken =
      localStorage.getItem(AuthLocalStorageKeys.REFRESH_TOKEN) || "";
    return Auth.instance;
  }

  /**
   * set up some properties of the Auth, ie: **auth server url** and **client id**, etc
   * @param {TypeAWSConfig} config
   */
  config(config) {
    const { authUrl, clientId } = config;
    if (authUrl) {
      this.authUrl = authUrl;
    }
    this.#clientId = clientId;
  }

  /**
   *
   * @param {TypeAWSCognitoAction} action
   */
  getCognitoRestAPIHeader(action) {
    return {
      "Content-Type": "application/x-amz-json-1.1",
      "x-amz-target": `AWSCognitoIdentityProviderService.${action}`,
    };
  }

  checkConfig() {
    if (!this.#clientId || !this.#authUrl) {
      throw new Error("Please setup configuration by calling [config] method");
    }
  }

  /**
   *
   * @param {string} userName
   * @param {string} password
   * @see {@link https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html sign in api reference}
   * @returns {Promise<TypeAuthPromiseResult>}
   */
  async signIn(userName, password) {
    this.checkConfig();
    const header = this.getCognitoRestAPIHeader("InitiateAuth");

    const body = {
      AuthParameters: {
        USERNAME: userName,
        PASSWORD: password,
      },
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: this.#clientId,
    };

    const requestOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
      redirect: "follow",
    };

    /**@type {TypeAuthPromiseResult} */
    let authResult = {
      loginSuccess: false,
      tokenPayloads: undefined,
      challengePayload: undefined,
      ChallengeParameters: undefined,
    };

    return new Promise(async (resolve, reject) => {
      let fetchError;
      /**@type {Response} */
      const response = await fetch(this.#authUrl, requestOptions).catch(
        (error) => (fetchError = error)
      );

      if (fetchError) {
        reject(fetchError);
      } else {
        const result = await response.json();
        if (response.ok) {
          if (result.ChallengeName) {
            authResult.challengePayload = result;
          } else {
            // login successfully
            const refreshToken = result.AuthenticationResult.RefreshToken;
            this.#accessToken = result.AuthenticationResult.AccessToken;
            if (refreshToken) {
              localStorage.setItem(
                AuthLocalStorageKeys.AWS_REFRESH_TOKEN,
                refreshToken
              );
            }
            authResult = getAuthResult(result);
          }
          resolve(authResult);
        } else {
          const { message = "Unknown error message" } = result;
          reject(new Error(message));
        }
      }
    });
  }

  /**
   * get another set of token payloads, based on the saved **refresh token**
   * @returns {Promise<TypeAuthPromiseResult>}
   */
  async currentAuthenticatedUser() {
    this.checkConfig();

    const refreshToken =
      localStorage.getItem(AuthLocalStorageKeys.AWS_REFRESH_TOKEN) || "";

    if (refreshToken) {
      const header = this.getCognitoRestAPIHeader("InitiateAuth");
      const body = {
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
        },
        AuthFlow: "REFRESH_TOKEN_AUTH",
        ClientId: this.#clientId,
      };

      /**@type {RequestInit} */
      const options = {
        method: "POST",
        headers: header,
        body: JSON.stringify(body),
      };

      return new Promise(async (resolve, reject) => {
        let fetchError;
        /**@type {Response} */
        const response = await fetch(this.#authUrl, options).catch(
          (error) => (fetchError = error)
        );

        if (fetchError) {
          reject(fetchError);
        } else {
          const statusCode = response.status;
          const result = await response.json();
          if (statusCode === 200) {
            /**@type {TypeAuthenticationResult1} */
            let updatedResult = { ...result };
            updatedResult.AuthenticationResult.RefreshToken = refreshToken;
            const authResult = getAuthResult(result);
            this.#accessToken = authResult.tokenPayloads.accessToken.token;
            resolve(authResult);
          } else if (result?.message && result?.__type) {
            reject(new Error(result.message));
          }
        }
      });
    }

    return undefined;
  }

  /**
   * sign out logged in user
   * @see {@link https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_GlobalSignOut.html AWS golbal signout}
   */
  signOut() {
    const header = this.getCognitoRestAPIHeader("GlobalSignOut");
    const body = {
      AccessToken: this.#accessToken,
    };

    var requestOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
      redirect: "follow",
    };

    localStorage.removeItem(AuthLocalStorageKeys.AWS_REFRESH_TOKEN);

    fetch(this.#authUrl, requestOptions).catch((error) =>
      console.error("error", error)
    );
  }

  /**
   * trigger **forgot password** event, which will send a verification code to a user's email address or phone number
   * @param {string} userName
   * @returns {Promise<TypeForgotPasswordPromiseResult>}
   */
  async forgotPassword(userName) {
    this.checkConfig();
    const header = this.getCognitoRestAPIHeader("ForgotPassword");
    const body = {
      ClientId: this.#clientId,
      Username: userName,
    };

    /**@type {RequestInit} */
    const options = {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
    };

    let fetchError;

    /**@type {Response} */
    const response = await fetch(this.#authUrl, options).catch(
      (error) => (fetchError = error)
    );
    const result = await response.json();

    if (fetchError) {
      return Promise.reject(fetchError);
    } else {
      if (response.status === 200) {
        return Promise.resolve(result);
      } else {
        const message = result?.message || "Unknown error message";
        return Promise.reject(new Error(message));
      }
    }
  }

  /**
   * complete **forgot password** event,
   * @param {string} userName
   * @param {string} newPassword
   * @param {string} verificationCode
   * @returns {Promise<boolean>}
   */
  async forgotPasswordSubmit(userName, newPassword, verificationCode) {
    this.checkConfig();
    const header = this.getCognitoRestAPIHeader("ConfirmForgotPassword");
    const body = {
      ClientId: this.#clientId,
      ConfirmationCode: verificationCode,
      Password: newPassword,
      Username: userName,
    };

    /**@type {RequestInit} */
    const options = {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
    };

    let fetchError;
    let passwordIsReset = false;

    /**@type {Response} */
    const response = await fetch(this.#authUrl, options).catch(
      (error) => (fetchError = error)
    );
    if (fetchError) {
      return Promise.reject(fetchError);
    }

    const result = await response.json();

    if (response.status === 200) {
      passwordIsReset = true;
    } else {
      const errorMessage = getErrorMessage(result);
      fetchError = new Error(errorMessage);
    }

    if (fetchError) {
      return Promise.reject(fetchError);
    } else {
      return Promise.resolve(passwordIsReset);
    }
  }

  /**
   * send request to complete a **reset** password event
   * @param {string} userName
   * @param {string} newPassword
   * @param {string} session obtain from sign in workfow, when user is forced to reset his / her password
   */
  async completeNewPassword(userName, newPassword, session) {
    this.checkConfig();
    const header = this.getCognitoRestAPIHeader("RespondToAuthChallenge");
    const body = {
      ClientId: this.#clientId,
      ChallengeName: "NEW_PASSWORD_REQUIRED",
      ChallengeResponses: {
        USERNAME: userName,
        NEW_PASSWORD: newPassword,
      },
      Session: session,
    };

    /**@type {RequestInit} */
    const options = {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
    };

    let fetchError;

    /**@type {TypeAuthPromiseResult} */
    let authResult;

    /**@type {Response} */
    const response = await fetch(this.#authUrl, options).catch(
      (error) => (fetchError = error)
    );
    if (fetchError) {
      return Promise.reject(fetchError);
    }

    const result = await response.json();

    if (response.status === 200) {
      authResult = getAuthResult(result);
    } else {
      const errorMessage = getErrorMessage(result);
      fetchError = new Error(errorMessage);
    }

    if (fetchError) {
      return Promise.reject(fetchError);
    } else {
      return Promise.resolve(authResult);
    }
  }
}

const singletonAuth = new Auth();

export default singletonAuth;
