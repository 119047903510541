import React from "react";
import { TextField } from "@mui/material";

const LABEL_OVERRIDE = {
  "&.Mui-disabled": {
    color: "#000",
  },
};

const TEXT_FIELD_OVERRIDE = {
  "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
    // "-webkit-text-fill-color": "#000",
    // WebkitTextFillColor: "rgba(0,0,0,0.6)"
  },
};

/**
 *
 * @param {import("@mui/material").TextFieldProps} props
 * @param {boolean} [props.overrideDisable]
 * @returns
 */
export default function IrisTextField(props) {
  const {
    overrideDisable = true,
    disabled,
    InputLabelProps,
    InputProps,
  } = props;

  let updatedInputLabelProps = { ...InputLabelProps };
  let updatedInputProps = { ...InputProps };
  if (overrideDisable && disabled) {
    // textFieldSx = TEXT_FIELD_OVERRIDE;
    // labelSx = LABEL_OVERRIDE;

    if (updatedInputLabelProps) {
      let sx = updatedInputLabelProps.sx || {};
      sx = { ...sx, ...LABEL_OVERRIDE };
      updatedInputLabelProps.sx = sx;
    }
    if (updatedInputProps) {
      let sx = updatedInputProps.sx || {};
      sx = { ...sx, ...TEXT_FIELD_OVERRIDE };
      updatedInputProps.sx = sx;
    }
  }

  return (
    <TextField
      {...props}
      fullWidth
      variant="standard"
      InputLabelProps={updatedInputLabelProps}
      InputProps={updatedInputProps}
    />
  );
}
