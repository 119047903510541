import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import IrisTable from "UI/muiTable/IrisTable";
import Button from "@mui/material/Button";

import AddIcon from "@mui/icons-material/Add";

import { AuthContext } from "context/AuthContext";

import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
import { getAdminClientList } from "utils/irisAuthRequests";
import { ERROR_MESSAGE } from "utils/irisAuthRequests";

const Clients = () => {
  const history = useHistory();

  const [clientList, setClientList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // get token to make api call
  const { contextToken, awsToken, onLogout } = useContext(AuthContext);

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // modal displays different info according to the states
  const [getClientListState, setGetClientListState] = useState(SUBMIT_STATE.ok);
  // when there's an error, set state of the error message
  const [errorMessage, setErrorMessage] = useState("");

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // when there's an error
    if (getClientListState === SUBMIT_STATE.error) {
      // when the token is not valid, click the backdrop to logout
      if (errorMessage === ERROR_MESSAGE.token) {
        onLogout();
      }
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    setIsLoading(true);

    getAdminClientList(awsToken)
      .then((result) => {
        if (result.length > 0) {
          if (isSubscribed) {
            result.forEach((c) => {
              c.monthly_distance /= 1000;
              c.total_distance /= 1000;
            });
            setClientList(result);
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        setOpenBackdrop(true);
        setErrorMessage(error.message);
        setGetClientListState(SUBMIT_STATE.error);
      });

    return () => (isSubscribed = false);
  }, [awsToken]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link
          to="/customer/create-new-customer"
          style={{ textDecoration: "none", margin: "0.5rem 0 1rem 1rem" }}
        >
          <Button variant="outlined" startIcon={<AddIcon />}>
            Create a Customer
          </Button>
        </Link>
      </div>
      <IrisTable
        isLoading={isLoading}
        isDense={true}
        columns={[
          { fieldName: "id", headTitle: "ID", width: 100 },
          {
            fieldName: "name",
            headTitle: "Customer Name",
            width: 200,
            element: (data, key, index) => (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  fontSize: 14,
                }}
                onClick={() => {
                  history.push(`/customer-details/${data.id}`);
                }}
              >
                {data[key]}
              </Button>
            ),
          },
          {
            fieldName: "monthly_limit",
            headTitle: "Monthly Limit (Km)",
            width: 100,
          },
          {
            fieldName: "monthly_distance",
            headTitle: "Monthly Distance (Km)",
            width: 100,
          },
          {
            fieldName: "total_distance",
            headTitle: "Total Distance (1000 Km)",
            width: 100,
          },
        ]}
        data={clientList}
        idField="id"
      />
      <SubmitBackdrop
        open={openBackdrop}
        onClick={handleClickBackdrop}
        submitState={getClientListState}
        modalMessage={errorMessage}
      />
    </div>
  );
};

export default Clients;
