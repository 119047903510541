import { IRISGO_DEVICE_LOGS_URL, IRISGO_DEVICE_UPDATE_URL } from "utils/env/urls";

/**
 *
 * @param {string} accessToken
 * @param {number} deviceId
 * @returns {Promise<[DeviceLogInterface]>}
 */
export const getDeviceHealthCheckLogs = async (accessToken, deviceId) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${accessToken}`,
    },
  };

  const url = new URL(IRISGO_DEVICE_LOGS_URL);
  url.searchParams.append("id", deviceId);
  return new Promise(async (resolve, reject) => {
    const response = await fetch(url, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      const logs = responseJson.result?.logs || [];
      resolve(logs);
    } else {
      const { status } = response;
      const message = await response.text();
      const errorMessage = `Error ${status}: ${message}`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};

/**
 *
 * @param {number} id device which to be updated
 * @param {object} data new device details object
 * @param {string} token access token
 * @returns
 */
export const updateDeviceRequest = (id, data, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${IRISGO_DEVICE_UPDATE_URL}${id}/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify(data),
    })
      .then(async (result) => {
        if (result.status === 200) {
          const data = await result.json();
          resolve(data.result);
        } else {
          reject("iPort Device Update Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};



