import React from "react";
import { FormControl, IconButton, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

/**
 * @description
 * An element that support user to input verification code (digit only).
 *
 * It wraps two elements:
 * - input text that takes only **digits**, and length of value should be less or equal to **8**.
 * - a button which is offer extra feature, ie: press to request a new notification code, etc
 *
 * @param {Object} props
 * @param {import("@mui/material").TextFieldProps} props.TextFieldProps
 * @param {() => {}} props.onResendVerificationCode
 * @returns
 */
export default function VerificationCodeInput(props) {
  const { TextFieldProps, onResendVerificationCode } = props;

  const { fullWidth, onChange, ...rest } = TextFieldProps;
  const { value, placeholder, autoFocus, variant } = rest;

  const handleChange = (event) => {
    const value = event.target.value;
    const eightDigitTester = /^[0-9]{0,8}$/;
    if (eightDigitTester.test(value) && typeof onChange === "function") {
      onChange(event);
    }
  };
  return (
    <FormControl fullWidth={fullWidth}>
      <TextField
        placeholder={placeholder}
        variant={variant}
        autoFocus={autoFocus}
        value={value}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <IconButton
              title="Resend"
              size="small"
              onClick={(event) => {
                if (typeof onResendVerificationCode === "function") {
                  onResendVerificationCode(event);
                }
              }}
            >
              <RefreshIcon />
            </IconButton>
          ),
        }}
      />
    </FormControl>
  );
}
