import styles from "./UploadFileAsChips.module.css";

import React from "react";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

/**
 *
 * @param {Object} props
 * @param {string} [props.acceptFile] to specify what type of file can be recognized when uploading
 * @param {string} [props.buttonContent] text to display on upload button
 * @param {React.ReactElement} [props.helperText] helper text for input
 * @param {Array.<Object>} props.FilesList array of objects to contain the existing uploaded files
 * @param {Function} props.updateFileList handle file change when uploading
 * @param {Function} props.deleteFile handle file change when deleting existing uploaded file
 * @param {boolean} [props.isDisabled] indicate the upload file is disabled or not
 * @returns
 */
const UploadFileAsChips = (props) => {
  const {
    acceptFile = "*",
    buttonContent = "upload file",
    helperText = <></>,
    FilesList,
    updateFileList,
    deleteFile,
    isDisabled = false,
  } = props;

  // function to upload file and update the download reducer state
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      // console.log(e.target.files[0].name);
      const uploadFile = e.target.files[0];

      updateFileList(uploadFile);
    }
  };

  return (
    <div className={styles.upload}>
      <div className={styles.uploadButton}>
        <label>
          <Button
            variant="outlined"
            component="span"
            disabled={isDisabled}
            startIcon={<FileUploadIcon />}
          >
            {buttonContent}
            <input
              type="file"
              accept={acceptFile}
              hidden
              disabled={isDisabled}
              onClick={(e) => (e.target.value = "")}
              onChange={handleFileChange}
            />
          </Button>
        </label>
        <div disabled={isDisabled} className={styles.helperText}>
          {helperText}
        </div>
      </div>
      <div className={styles.uploadChips}>
        {FilesList.map((file, index) => (
          <div className={styles.uploadPerChip} key={index}>
            <Chip label={file.fileName} onDelete={() => deleteFile(file)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadFileAsChips;
