import styles from "./TimeInput.module.css";

import React from "react";
import { useState, useEffect } from "react";

import moment from "moment";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

/**
 *
 * @param {Object} props
 * @param {Object} props.time new Date() object
 * @param {Function} props.handleChange handle change of date and time
 * @param {Function} props.resetTime reset the time to the default value, which is start of a day, local time
 * @param {string} props.dateLabel label text for date picker
 * @param {string} props.timeLabel label text for time picker
 * @param {string} [props.tipsInfo] displays tips infor for the displaying time, local & utc
 * @returns
 */
const TimeInput = (props) => {
  const { time, handleChange, resetTime, dateLabel, timeLabel, tipsInfo } =
    props;

  return (
    <div className={styles.timeInput}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className={styles.datePicker}>
          <MobileDatePicker
            label={dateLabel}
            maxDate={new Date()}
            inputFormat="yyyy-MM-dd"
            value={time}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <div className={styles.timePicker}>
          <MobileTimePicker
            label={timeLabel}
            showToolbar
            value={time}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <Tooltip title="reset time to default" placement="top">
            <IconButton onClick={resetTime}>
              <RestartAltIcon />
            </IconButton>
          </Tooltip>
        </div>
      </LocalizationProvider>
      {/* <div className={styles.tipsInfo}>
        {tipsInfo}
        <div className={styles.tipsInfoTime}>
          Local {moment(time).format("MM/DD/YYYY HH:mm")}
        </div>
        <div className={styles.tipsInfoTime}>
          UTC {moment.utc(time).format("MM/DD/YYYY HH:mm")}
        </div>
      </div> */}
    </div>
  );
};

export default TimeInput;
