
import React, { useContext, useEffect, useState } from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Button,
    TextField
} from "@mui/material";
import { createNotificationGroup } from "utils/requests";
import { AuthContext } from "context/AuthContext";
import SubmitBackdrop, { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
import KeyValueList from "pages/clientsUsersGroups/clients/common/KeyValueList.component";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CREATE_NOTIFICATION_MODAL_MESSAGES = {
    notSend: "No request is sent.",
    sending: "Create group request is sent. Please wait for a while.",
    success: "Notification information is create successfully.",
    error: "Notification group create unsuccessful",
};
const ManageNotificationGroup = (props) => {

    const { filterGroupTypes, customers } = props;
    const [selectedGroupType, setSelectedGroupType] = useState({});

    const [throttle, setThrottle] = useState(-1);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [submitState, setSubmitState] = useState(SUBMIT_STATE.ok);
    const [modalMessage, setModalMessage] = useState(
        CREATE_NOTIFICATION_MODAL_MESSAGES.notSend
    );

    const [selectedCustomer, setSelectedCustomer] = useState(-1);

    const [properties, setProperties] = useState([])



    const { awsToken } = useContext(AuthContext);

    useEffect(() => {
        if (filterGroupTypes?.length > 0) {
            setFilterGroup(filterGroupTypes[0])
        }
        if (customers?.length > 0) {
            setSelectedCustomer(customers[0].id)
        }
    }, [filterGroupTypes, customers])

    const handleClickBackdrop = () => {
        setOpenBackdrop(false);
        setSubmitState(SUBMIT_STATE.notSend);
        setModalMessage(CREATE_NOTIFICATION_MODAL_MESSAGES.notSend);
    };

    const createGroupObject = () => {
        const newNotificationGroup = {
            "filterType": selectedGroupType?.id,
            "client": selectedCustomer,
            'throttle': Math.trunc(throttle)
        }

        newNotificationGroup['supportingData'] = properties.reduce((acc, ele) => {
            acc[ele[0]] = ele[1]
            return acc
        }, {})

        return newNotificationGroup
    }

    const isInvalidGroup = (newNotificationGroup) => {
        const supportingData = newNotificationGroup['supportingData']
        return selectedCustomer === -1 ||
            !supportingData ||
            Object.keys(supportingData).some(key => supportingData[key] === "" || supportingData[key] === -1) ||
            isNaN(throttle)

    }
    const handleCreateGroup = () => {
        const newNotificationGroup = createGroupObject()
        setOpenBackdrop(true)
        setSubmitState(SUBMIT_STATE.sending)
        setModalMessage(CREATE_NOTIFICATION_MODAL_MESSAGES.sending)
        let error = false
        createNotificationGroup(awsToken, newNotificationGroup)
            .catch((err) => error = true)
            .finally(() => {
                if (!error) {
                    setSubmitState(SUBMIT_STATE.ok)
                    setModalMessage(CREATE_NOTIFICATION_MODAL_MESSAGES.success)
                    setOpenBackdrop(false)
                } else {
                    setSubmitState(SUBMIT_STATE.error)
                    setModalMessage(CREATE_NOTIFICATION_MODAL_MESSAGES.error)
                }
            })
    }


    const handleChange = (event, setter) => {
        const {
            target: { value },
        } = event;

        setter(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const setFilterGroup = (filterGroup) => {
        setSelectedGroupType(filterGroup)
        setProperties(filterGroup.requiredParameters.map(key => [key, ""]))
    }

    const onProperties = (value, index, PROP) => {
        if (PROP !== 'VALUE')
            return
        const newArr = [...properties];
        newArr[index][1] = value;
        setProperties(newArr);
    };


    return (
        <Grid container rowSpacing={2}>
            <Grid item container>
                <FormControl fullWidth>
                    <TextField
                        id="outlined-number"
                        label="Throttle(Seconds)"
                        type="number"
                        InputProps={{
                            inputProps: {
                                min: -1
                            }
                        }}
                        style={{ width: "9.5vw" }}
                        value={throttle}
                        onChange={(event) => {
                            const value = event.target.value;
                            if (!isNaN(value) && Number(value) >= -1)
                                setThrottle(value);
                        }}

                    />
                </FormControl>
            </Grid>
            <Grid item container xs={2}>
                <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="checkbox-label-customer">Customer</InputLabel>
                    <Select style={{ width: "100%" }}
                        labelId="checkbox-label-customer"
                        value={selectedCustomer}
                        onChange={(event) => handleChange(event, setSelectedCustomer)}
                        MenuProps={MenuProps}
                        label="customer"
                    >
                        {customers.map((customer) => (
                            <MenuItem value={customer.id}>{customer.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item container>
                <Grid xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="Notification Group Type">Notification Group Type</InputLabel>
                        <Select
                            id="Notification Group Type"
                            value={selectedGroupType}
                            label="Notification Group Type"
                            onChange={(e) => { setFilterGroup(e.target.value) }}
                        >
                            {filterGroupTypes.map((group) => <MenuItem value={group}>{group.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <KeyValueList
                    isEditMode={true}
                    values={properties}
                    title="Group Properties"
                    disableAddButton={true}
                    disableRemoveButton={true}
                    onUpdate={onProperties}
                />
            </Grid>
            <Grid item justifyContent='flex-end' container>
                <Grid item xs={7}  >
                    <Button disabled={isInvalidGroup(createGroupObject())} onClick={() => { handleCreateGroup() }} variant="contained">Create Group</Button>
                </Grid>
            </Grid>
            <SubmitBackdrop
                open={openBackdrop}
                onClick={handleClickBackdrop}
                submitState={submitState}
                modalMessage={modalMessage}
            />
        </Grid>
    )
}


export default ManageNotificationGroup