
import React, { useContext, useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
    Autocomplete,
    Button,
    Checkbox,
    Grid,
    TextField,
} from "@mui/material";
import { AuthContext } from "context/AuthContext";
import { getCognitoCustomerContacts } from "utils/requests";
import { getAdminUserList } from "utils/irisAuthRequests";

const EditNotificationGroup = (props) => {

    const {
        id,
        handleClose,
        throttle,
        client

    } = props

    const { awsToken } = useContext(AuthContext);


    const [selectedEmails, setSelectedEmails] = useState([]);
    const [emails, setEmails] = useState([]);
    const [selectedThrottle, setSelectedThrottle] = useState(throttle);
    const [userContacts, setUserContacts] = useState(new Map())


    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    useEffect(() => {

        let isSubscribed = true;

        Promise.all([
            getAdminUserList(awsToken, null, client),
            getCognitoCustomerContacts(awsToken, id)
        ]).then((results) => {
            if (isSubscribed) {
                const userList = results[0]
                const cognitoCustomerContacts = results[1]
                if (userList) {
                    const emails = [];
                    const userContacts = new Map()
                    results[0].forEach(user => {
                        const username = user.Username
                        const emailAttr = user.Attributes.find(attr => attr.Name === 'email')
                        if (emailAttr) {
                            emails.push(emailAttr.Value)
                            userContacts.set(username, emailAttr.Value)
                        }

                    })
                    setUserContacts(userContacts)
                    setEmails(emails)
                }

                if (cognitoCustomerContacts.results)
                    setSelectedEmails(cognitoCustomerContacts.results.map(user => user.email))
            }
        }).catch((err) => {
            // console.log(err)
        })
        return () => (isSubscribed = false);
    }, [id, awsToken, client])


    const handleCloseWithData = () => {
        const data = [...userContacts].filter(([k, v]) => selectedEmails.includes(v)).map(data => data[0])
        handleClose({
            usernames: data,
            throttle: Math.trunc(selectedThrottle)
        });
    }

    return (
        <Grid container >
            <Grid container justifyContent="flex-start" alignItems="center" marginTop={'2vh'} marginBottom={'2vh'} >
                <TextField
                    id="outlined-number"
                    label="Throttle(Seconds)"
                    type="number"
                    value={selectedThrottle}
                    InputProps={{
                        inputProps: {
                            min: -1
                        }
                    }}
                    onChange={(event) => {
                        const value = event.target.value;
                        if (!isNaN(value) && Number(value) >= -1)
                            setSelectedThrottle(value);
                    }}

                />
            </Grid>
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={emails}
                value={selectedEmails}
                disableCloseOnSelect
                getOptionLabel={(option) => option}

                onChange={(event, newValue) => {
                    setSelectedEmails([
                        ...newValue
                    ]);
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                    </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                    <TextField {...params} label="Assigned Users" placeholder="Users" />
                )}
            />
            <Grid container justifyContent="space-evenly" alignItems="center" marginTop={'5vh'}>
                <Grid item xs={2} >
                    <Button variant="contained" onClick={() => handleCloseWithData()} color="primary" >Confirm</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" onClick={() => handleClose()} color="secondary">Cancel</Button>
                </Grid>

            </Grid>
        </Grid>
    )
}


export default EditNotificationGroup