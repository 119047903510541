import React, { useState, useEffect, useContext, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import MomentUtils from "@date-io/moment";
import { DatePicker } from "@mui/lab";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import moment from "moment";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  MOBILE_REPORT_LOOKUP_REQUEST,
  MOBILE_REPORT_DATAPOINT_REQUEST,
  MOBILE_REPORT_DATAPOINT_NEW_REQUEST,
} from "../utils/requests";
import {
  GET_YESTERDAY,
  PROCESS_DEFECT_DATA,
  PROCESS_LABEL_DATA,
} from "../utils/utils";
import { AuthContext } from "../context/AuthContext";
import { MainContext } from "../context/MainContext";

import TabPanel from "../components/mobile/TabPanel";
import DefectReportCards from "../components/mobile/DefectReportCards";
import LinearProgress from "@mui/material/LinearProgress";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  main: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  naviContainer: {
    padding: theme.spacing(2),
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pickerContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    order: 3,
    [theme.breakpoints.up("lg")]: {
      order: 1,
    },
  },
  title: { margin: theme.spacing(1) },
  picker: {
    margin: "auto",
    width: 320,
  },
  pickerText: {
    margin: "auto",
    textAlign: "center",
    ...theme.typography.h6,
    color: theme.palette.text.primary,
  },
  arrowIcons: {
    fontSize: "40px",
  },
  selectForm: {
    float: "right",
    width: 100,
    margin: "10px",
    paddingBottom: "10px",
  },
  formContainer: {
    order: 2,
  },
  tabsContainer: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  loadingBar: {
    width: "100%",
  },
}));

export default function DefectReports() {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(GET_YESTERDAY);
  const [city, setCity] = React.useState(0);
  const [cityList, setCityList] = React.useState([]);
  const [defectData, setDefectData] = React.useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState();
  const { contextToken } = useContext(AuthContext);
  const { setLookupData } = useContext(MainContext);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) setIsLoading(true);
    MOBILE_REPORT_LOOKUP_REQUEST(contextToken)
      .then((lookupResult) => {
        let selectedDateMoment = moment(selectedDate);
        if (isSubscribed) {
          setCityList(Object.entries(lookupResult.city));
          setLookupData(lookupResult);
        }
        if (selectedDateMoment.isAfter("2020-12-05")) {
          MOBILE_REPORT_DATAPOINT_NEW_REQUEST(
            selectedDateMoment.format("YYYY-MM-DD"),
            city,
            contextToken
          )
            .then((defectDataResult) => {
              if (isSubscribed) {
                setDefectData(
                  PROCESS_LABEL_DATA(defectDataResult, lookupResult.label_type)
                );
                process.env.NODE_ENV === "development" &&
                  console.log(
                    `PROCESS_LABEL_DATA(defectDataResult, lookupResult.label_type): `,
                    PROCESS_LABEL_DATA(
                      defectDataResult,
                      lookupResult.label_type
                    )
                  );
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          MOBILE_REPORT_DATAPOINT_REQUEST(
            selectedDateMoment.format("YYYY-MM-DD"),
            city,
            contextToken
          )
            .then((defectDataResult) => {
              if (isSubscribed) {
                setDefectData(
                  PROCESS_DEFECT_DATA(
                    defectDataResult,
                    lookupResult.damage_type
                  )
                );
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (isSubscribed = false);
  }, [contextToken, city, selectedDate, setLookupData]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper>
        <Grid container className={classes.main}>
          <Grid item xs={12} className={classes.loadingBar}>
            <LinearProgress
              value={100}
              variant={isLoading ? "indeterminate" : "determinate"}
            />
          </Grid>
          <Grid item container className={classes.naviContainer}>
            <Grid item lg={3} xs={6} className={classes.titleContainer}>
              <Typography variant="h6" className={classes.title}>
                Road Defect Reports
              </Typography>
            </Grid>
            <Grid
              item
              container
              lg={6}
              xs={12}
              className={classes.pickerContainer}
            >
              <IconButton
                className={classes.arrowButtons}
                onClick={() => handleDateChange(selectedDate - 3600000 * 24)}
              >
                <KeyboardArrowLeftIcon className={classes.arrowIcons} />
              </IconButton>
              <DatePicker
                className={classes.picker}
                autoOk
                value={selectedDate}
                disableToolbar
                maxDate={GET_YESTERDAY}
                onChange={(date) => {
                  handleDateChange(date);
                }}
                labelFunc={(date) => date.format("dddd, MMMM Do YYYY")}
                variant={"inline"}
                inputProps={{ className: classes.pickerText }}
                renderInput={(params) => <TextField {...params} />}
              />
              <IconButton
                onClick={() => handleDateChange(selectedDate + 3600000 * 24)}
                className={classes.arrowButtons}
                disabled={
                  moment(GET_YESTERDAY).isSameOrBefore(selectedDate, "day")
                    ? true
                    : false
                }
              >
                <KeyboardArrowRightIcon className={classes.arrowIcons} />
              </IconButton>
            </Grid>
            <Grid item lg={2} xs={6} className={classes.formContainer}>
              <FormControl className={classes.selectForm}>
                <InputLabel id="demo-controlled-open-select-label">
                  Select a City
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={city}
                  onChange={(event) => {
                    setCity(event.target.value);
                  }}
                >
                  <MenuItem value={0}>
                    <Typography variant="subtitle2">All Citys</Typography>
                  </MenuItem>
                  {cityList.map((cityEntry) => {
                    return (
                      <MenuItem value={cityEntry[0]} key={cityEntry[0]}>
                        <Typography variant="subtitle2">
                          {cityEntry[1].name}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item className={classes.tabsContainer}>
            <AppBar position="static" color="default">
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {defectData.map((damageEntry) => {
                  return (
                    <Tab
                      key={damageEntry.id}
                      value={damageEntry.id}
                      label={
                        <Typography variant="caption">
                          {`${damageEntry.name} (${damageEntry.count})`}
                        </Typography>
                      }
                    />
                  );
                })}
              </Tabs>
            </AppBar>
            {defectData.map((damageEntry) => {
              return (
                <TabPanel
                  value={tabValue}
                  index={damageEntry.id}
                  key={damageEntry.id}
                >
                  <DefectReportCards datapoints={damageEntry.datapoints} />
                </TabPanel>
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  );
}
