import styles from "./UserDetails.module.css";
import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useContext,
} from "react";
import { useParams, useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import { IconButrtons } from "UI/dataGrid/DataGrid";
import IrisTextField from "UI/muiTextField/IrisTextField";
import IrisSelect from "UI/muiSelect/IrisSelect";

import IrisMultiSelect from "UI/muiSelect/IrisMultiSelect";
import { AuthContext } from "context/AuthContext";
import {
  getAdminUserDetails,
  getAdminGroupList,
  ERROR_MESSAGE,
  addUserToGroup,
} from "utils/irisAuthRequests";
import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";

/** 3 different messages according to api call */
const UPDATE_USER_MODAL_MESSAGES = {
  /** "No request is sent." */
  notSend: "No request is sent.",
  /** "Update user request is sent. Please wait for a while." */
  sending: "Update user request is sent. Please wait for a while.",
  /** "User information is updated successfully." */
  success: "User information is updated successfully.",
};

// initial state for reducer
const userDetailsDefaultState = {
  username: "",
  name:"",
  email: "",
  phone_number: "",
  client_id: [0],
  client_name: [""],
  groups: [],

  /** invalid if tag name is '', should be remove when sending data */
  invalidInput: false,
};

// reducer actions
const ACTIONS = {
  SET_USER_NAME: "SET USER NAME",
  SET_EMAIL: "SET EMAIL",
  SET_PHONE_NUMBER: "SET PHONE NUMBER",
  SET_CLIENT_ID: "SET CLIENT ID",
  SET_CLIENT_NAME: "SET CLIENT NAME",
  SET_GROUPLIST: "SET GROUP LIST",
  SET_NAME: "SET NAME",
  RESET: "RESET",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    // update userName input
    case ACTIONS.SET_USER_NAME:
      return { ...state, username: payload };
    // update clientId
    case ACTIONS.SET_CLIENT_ID:
      return { ...state, client_id: payload };
    // update clientName input
    case ACTIONS.SET_CLIENT_NAME:
      return { ...state, client_name: payload };
    // update email
    case ACTIONS.SET_EMAIL:
      return { ...state, email: payload };
    // update phone number
    case ACTIONS.SET_PHONE_NUMBER:
      return { ...state, phone_number: payload };
    // update group list
    case ACTIONS.SET_GROUPLIST:
      return { ...state, groups: payload };
    case ACTIONS.SET_NAME:
      return { ...state, name: payload };
    // reset to previous state, which is stored with useRef
    case ACTIONS.RESET:
      return { ...payload, invalidInput: false };
    default:
      return userDetailsDefaultState;
  }
};

const UserDetails = () => {
  const history = useHistory();
  const { username } = useParams();

  // reducer constructor
  const [userDetailsState, dispatch] = useReducer(
    reducer,
    userDetailsDefaultState
  );

  // indicate if it is during the api call
  const [isLoading, setIsLoading] = useState(true);
  // indicate if relative api call is at state of ok
  const [getUserDetailsOk, setGetUserDetailsOk] = useState(false);
  const [getGroupListOk, setGetGroupListOk] = useState(false);

  // get token to make api call
  const { contextToken, awsToken, onLogout } = useContext(AuthContext);

  // state to switch editable or not
  const [isEditMode, setIsEditMode] = useState(false);

  // useRef to store state info before editing, in case restore data by clicking clear button
  const tempStore = useRef();

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // modal displays different info according to the states
  const [getUserDetailsState, setGetUserDetailsState] = useState(
    SUBMIT_STATE.notSend
  );
  // set state of the update user details state
  const [updateUserState, setUpdateUserState] = useState(SUBMIT_STATE.notSend);
  // when there's an error, set state of the error type
  const [errorType, setErrorType] = useState("");
  // modal content set state
  const [modalMessageContent, setModalMessageContent] = useState(
    UPDATE_USER_MODAL_MESSAGES.notSend
  );

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // when there's an error
    if (getUserDetailsState === SUBMIT_STATE.error) {
      // when the token is not valid, click the backdrop to logout
      if (errorType === ERROR_MESSAGE.token) {
        onLogout();
      }
    }

    // only when it's not sending request, close the backdrop and modal
    if (updateUserState !== SUBMIT_STATE.sending) {
      // when there's an error
      if (updateUserState === SUBMIT_STATE.error) {
        // when the token is not valid, click the backdrop to logout
        if (errorType === ERROR_MESSAGE.token) {
          onLogout();
        } else {
          setOpenBackdrop(false);
          setUpdateUserState(SUBMIT_STATE.notSend);
          setErrorType("");
        }
      }
      // when there's no error, close backdrop
      else {
        setOpenBackdrop(false);
        setUpdateUserState(SUBMIT_STATE.notSend);
        setErrorType("");
      }
    }
  };

  // function to handle update request, including making 2 api calls, update user and add user to group
  const handleUpdate = () => {
    // build array of string for current selected groups
    const currGroups = [];
    if (userDetailsState.groups.length > 0) {
      userDetailsState.groups.forEach((group) => {
        currGroups.push(group.name);
      });
      currGroups.sort();
    }

    // build array of string for previous selected groups
    const prevGroups = [];
    if (tempStore.current.groups.length > 0) {
      tempStore.current.groups.forEach((group) => {
        prevGroups.push(group.name);
      });
      prevGroups.sort();
    }

    // check if current selected groups are the same as previous
    let groupsSame = true;
    if (currGroups.length !== prevGroups.length) {
      groupsSame = false;
    } else {
      currGroups.forEach((group, index) => {
        groupsSame = groupsSame && group === prevGroups[index];
      });
    }
    // only when these 2 arrays are different, make api call to add user to new groups
    if (!groupsSame) {
      setOpenBackdrop(true);
      setUpdateUserState(SUBMIT_STATE.sending);
      setModalMessageContent(UPDATE_USER_MODAL_MESSAGES.sending);
      const submitProps = {
        token: awsToken,
        userName: username,
        groups: currGroups,
      };
      addUserToGroup(submitProps)
        .then((result) => {
          // triggers the backdrop to show message of submit info
          setUpdateUserState(SUBMIT_STATE.ok);
          setModalMessageContent(UPDATE_USER_MODAL_MESSAGES.success);
        })
        .catch((err) => {
          setErrorType(err.message);
          setModalMessageContent(err.message);
          setUpdateUserState(SUBMIT_STATE.error);
        });
    }
  };

  // group list options
  const [groupListOptions, setGroupListOptions] = useState([]);

  // datagrid data prop
  const userInfoData = {
    username: userDetailsState.username,
    name: userDetailsState.name,
    client_name: userDetailsState.client_name,
    email: userDetailsState.email,
    phone_number: userDetailsState.phone_number,
    groups: userDetailsState.groups,
  };

  // datagrid data model prop
  const infoDataModel = [
    {
      editable: false,
      key: "username",
      label: "User Name",
      type: "input",
      options: {},
      onChange: (value) => {},
    },
    {
      editable: false,
      key: "name",
      label: "Name",
      type: "input",
      options: {},
      onChange: (value) => {},
    },
    {
      editable: false,
      key: "client_name",
      label: "Client Name",
      type: "input",
      options: {},
      onChange: (value) => {},
    },
    {
      editable: false, // update user info api needs to be modified
      key: "email",
      label: "Email",
      type: "input",
      options: {},
      onChange: (value) => {
        dispatch({ type: ACTIONS.SET_EMAIL, payload: value });
      },
    },
    {
      editable: false, // update user info api needs to be modified
      key: "phone_number",
      label: "Phone Number",
      type: "input",
      options: {},
      onChange: (value) => {
        dispatch({ type: ACTIONS.SET_PHONE_NUMBER, payload: value });
      },
    },
    {
      editable: true,
      key: "groups",
      label: "Group List",
      type: "multi-select",
      options: {
        options: [...groupListOptions],
        idKey: "name",
        titleKey: "name",
      },
      onChange: (updatedGroupList) =>
        dispatch({ type: ACTIONS.SET_GROUPLIST, payload: updatedGroupList }),
    },
  ];

  // load the initial values when component mounts
  useEffect(() => {
    let isSubscribed = true;

    setIsLoading(true);
    setGetUserDetailsOk(false);
    setGetGroupListOk(false);

    getAdminUserDetails(awsToken, username)
      .then((result) => {
        const { Username, email,name, clients, cognito_groups, phone_number } =
          result;
        if (isSubscribed) {
          setGetUserDetailsOk(true);
          dispatch({
            type: ACTIONS.SET_USER_NAME,
            payload: Username,
          });
          // update client id and client name according to the property named as "clients"
          const clientIds = [];
          const clientNames = [];
          if (clients.length > 0) {
            clients.forEach((client) => {
              clientIds.push(client.id);
              clientNames.push(client.name);
            });
            dispatch({ type: ACTIONS.SET_CLIENT_ID, payload: clientIds });
            dispatch({
              type: ACTIONS.SET_CLIENT_NAME,
              payload: clientNames,
            });
          }
          // update groups according to the property named as "cognito_groups"
          const groupsArr = [];
          if (cognito_groups.length > 0) {
            cognito_groups.forEach((groupName) => {
              const groupObjTemp = { name: groupName };
              groupsArr.push(groupObjTemp);
            });
            dispatch({ type: ACTIONS.SET_GROUPLIST, payload: groupsArr });
          }
          if (email) {
            dispatch({
              type: ACTIONS.SET_EMAIL,
              payload: email,
            });
          }
          if (name) {
            dispatch({
              type: ACTIONS.SET_NAME,
              payload: name,
            });
          }
          if (phone_number) {
            dispatch({
              type: ACTIONS.SET_PHONE_NUMBER,
              payload: phone_number,
            });
          }
          const userStateCopy = {
            username: Username,
            email: email ? email : "",
            name: name ? name : "",
            phone_number: phone_number ? phone_number : "",
            groups:
              cognito_groups.length > 0 ? groupsArr : userDetailsState.groups,
            client_id:
              clients.length > 0 ? clientIds : userDetailsState.client_id,
            client_name:
              clients.length > 0 ? clientNames : userDetailsState.client_name,
          };
          tempStore.current = userStateCopy;
        }
      })
      .catch((error) => {
        setOpenBackdrop(true);
        setErrorType(error.message);
        setModalMessageContent(error.message);
        setGetUserDetailsState(SUBMIT_STATE.error);
      });

    getAdminGroupList(awsToken)
      .then((result) => {
        if (isSubscribed) {
          setGetGroupListOk(true);
          const groupOptionsTemp = result.map((group) => ({
            name: group.GroupName,
          }));
          setGroupListOptions(groupOptionsTemp);
        }
      })
      .catch((err) => {
        setOpenBackdrop(true);
        setErrorType(err.message);
        setModalMessageContent(err.message);
        setGetUserDetailsState(SUBMIT_STATE.error);
      });

    return () => (isSubscribed = false);
  }, [username, awsToken, onLogout]);

  // indicate if both the get user details and get group list api call are at state of ok
  useEffect(() => {
    setIsLoading(!(getUserDetailsOk && getGroupListOk));
  }, [getGroupListOk, getUserDetailsOk]);

  if (isLoading) {
    return (
      <div className={styles.userDetails}>
        <h2 className={`${styles.pageChild} ${styles.header}`}>
          User Information
        </h2>
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
        <SubmitBackdrop
          open={openBackdrop}
          onClick={handleClickBackdrop}
          submitState={getUserDetailsState}
          modalMessage={modalMessageContent}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.userDetails}>
        <h2 className={`${styles.pageChild} ${styles.header}`}>
          User Information
          <IconButrtons
            isLoading={false}
            isEdit={isEditMode}
            setIsEditMode={setIsEditMode}
            onClear={() => {
              setIsEditMode(false);
              dispatch({ type: ACTIONS.RESET, payload: tempStore.current });
            }}
            onSend={() => {
              setIsEditMode(false);
              handleUpdate();
              // useRef to store the current useReducer state, in case onClear triggers the previous state to cover the new state
              tempStore.current = userDetailsState; // this step is supposed to be done when receiving data from api call
            }}
            disabledSendButton={userDetailsState.invalidInput}
          />
        </h2>
        <div className={`${styles.pageChild} ${styles.content}`}>
          {infoDataModel.map((model, index) => {
            const { editable, key, label, type, options, onChange } = model;

            if (key === "client_name") {
              return (
                <div
                  className={`${styles.eachItem} ${styles.client}`}
                  key={index}
                >
                  {label}:{" "}
                  {userInfoData[key].map((clientName, clientIndex) => (
                    <div className={styles.linkButtonGroup} key={clientIndex}>
                      {clientName === "" ? (
                        ""
                      ) : (
                        <Button
                          className={styles.muiButton}
                          variant="outlined"
                          disabled={isEditMode}
                          onClick={() =>
                            history.push(
                              `/client-details/${userDetailsState.client_id[clientIndex]}`
                            )
                          }
                        >
                          {clientName}
                        </Button>
                      )}
                    </div>
                  ))}
                </div>
              );
            }

            if (key === "groups") {
              return (
                <div
                  className={`${styles.eachItem} ${styles.groups}`}
                  key={index}
                >
                  {isEditMode ? (
                    <IrisMultiSelect
                      disabled={editable ? !isEditMode : true}
                      options={options.options}
                      value={userInfoData[key]}
                      keyName={options.titleKey}
                      labelName={label}
                      updateSelectedOptions={onChange}
                      textFieldStyle="standard"
                    />
                  ) : (
                    <div>
                      {label}:{" "}
                      <div className={styles.linkButtonGroup}>
                        {userInfoData[key].length > 0 &&
                          userInfoData[key].map((oneGroup) => (
                            <Button
                              className={styles.muiButton}
                              key={oneGroup.name}
                              variant="outlined"
                              onClick={() =>
                                history.push(`/group-details/${oneGroup.name}`)
                              }
                            >
                              {oneGroup[options.titleKey]}
                            </Button>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              );
            }

            return (
              <div className={styles.eachItem} key={index}>
                {type === "input" && (
                  <IrisTextField
                    disabled={editable ? !isEditMode : true}
                    fullWidth
                    label={label}
                    variant="standard"
                    value={userInfoData[key]}
                    onChange={(e) => {
                      const value = e.target.value;
                      onChange(value);
                    }}
                  />
                )}
                {type === "select" && (
                  <IrisSelect
                    fullWidth
                    disabled={editable ? !isEditMode : true}
                    options={options.options}
                    keyField={options.idKey}
                    titleField={options.titleKey}
                    selectProps={{
                      value: userInfoData[key],

                      variant: "standard",
                      disabled: !isEditMode,
                      onChange: (event) => {
                        const value = event.target.value;
                        onChange(value);
                      },
                    }}
                    label={label}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className={`${styles.pageChild} ${styles.buttons}`}></div>
        <SubmitBackdrop
          open={openBackdrop}
          onClick={handleClickBackdrop}
          submitState={updateUserState}
          modalMessage={modalMessageContent}
        />
      </div>
    );
  }
};

export default UserDetails;
