import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { TOOL_BAR_HEIGHT } from "../../utils/theme/constrains";
import {
  MOBILE_DEVICE_CITY_LOOKUP_REQUEST,
  MOBILE_DEVICE_NEW_DEVICE_REQUEST,
  MOBILE_DEVICE_NEW_DEVICE_API_REQUEST,
  GET_LOOK_UP,
  LOOKUP_PARAMS,
} from "../../utils/requests";
import { AuthContext } from "../../context/AuthContext";
import { v4 as uuidv4 } from "uuid";
import { getAdminClientList } from "utils/irisAuthRequests";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "auto",
    display: "flex",
    alignContent: "flex-start",
    flexWrap: "wrap",
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 300}px)`,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "38ch",
  },
  checkbox: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "38ch",
  },
}));

export default function RegisterDevice({ onNext }) {
  const { contextToken, awsToken } = useContext(AuthContext);

  const classes = useStyles();
  const [disableNext, setDisableNext] = useState(true);
  const [name, setName] = useState("");
  const [city, setCity] = useState(0);
  const [cityList, setCityList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [serial, setSerial] = useState("");
  const [rotate, setRotate] = useState(false);

  const selectCity = (event) => {
    setCity(event.target.value);
  };

  const submit = () => {
    const sn = uuidv4();

    const cityName = cityList.filter((city2) => city2.id === city)[0].name
    
    let clientData = clientList.filter((client) => client.name.toLowerCase() === cityName.toLowerCase())
  if (clientData.length === 0)
      clientData = clientList.filter((client) => client.name.toLowerCase() === (cityName + "_Admin") .toLowerCase())

    MOBILE_DEVICE_NEW_DEVICE_REQUEST(
      {
        tag: name,
        serial_number: serial,
        device_sn: sn,
        city: city,
        rotate: rotate ? "Y" : "N",
      },
      contextToken
    )
      .then((activeCode) => {
        GET_LOOK_UP(contextToken, [LOOKUP_PARAMS.DEVICE_INFO]).then(
          (devicesInfo) => {
            const devices = devicesInfo.result.device_info;
            if (devices.length > 0) {
              const device = devices.filter(
                (device) => device.activate === activeCode
              );

              if (device.length > 0 && device[0])
                MOBILE_DEVICE_NEW_DEVICE_API_REQUEST(
                  {
                    idv2: device[0].id,
                    tag: name,
                    serial_number: serial,
                    device_sn: sn,
                    IMEI: device[0].IMEI,
                    rotate: rotate ? "Y" : "N",
                    client_id: (clientData.length > 0) ? clientData[0].id : -1
                  },
                  awsToken
                )
                  .then(() => {
                    onNext(activeCode);
                  })
                  .catch((error) => {
                    alert("Could not register device");
                    console.log(error);
                  });
            }
          }
        );
      })
      .catch((error) => {
        alert("Registered hexnode device not found");
      });
  };
  useEffect(() => {
    let isSubscribed = true;
    MOBILE_DEVICE_CITY_LOOKUP_REQUEST(contextToken)
      .then((lookupResult) => {
        const city_result = lookupResult.city;
        let citys = [];
        for (let v in city_result)
          citys.push({ id: v, name: city_result[v].name });
        if (isSubscribed) {
          console.log(citys);
          setCityList(citys);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    getAdminClientList(awsToken).then((result) => {
      if (result.length > 0) {
        if (isSubscribed) {
          setClientList(result);
        }
      }
    }
    ).catch((error) => {
      console.log(error);
    });

    return () => (isSubscribed = false);
  }, [contextToken]);

  useEffect(() => {
    if (city !== 0 && name.length >= 1) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
  }, [name, city, serial]);

  return (
    <div>
      <DialogTitle>Enter Device Info</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Enter the following information for the device and click next
        </DialogContentText>
        <div className={classes.form}>
          <TextField
            className={classes.textField}
            placeholder="City-N"
            helperText="Enter the Device Name"
            margin="normal"
            label="Device Tag"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            className={classes.textField}
            placeholder="XXXXXXXXXX"
            helperText="Enter the 10 Characters Serial Number"
            margin="normal"
            label="Serial Number"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={serial}
            onChange={(event) => setSerial(event.target.value)}
          />
          <FormGroup row>
            <FormControl variant="outlined" margin="normal">
              <InputLabel>Customer</InputLabel>
              <Select
                value={city}
                onChange={selectCity}
                label="City"
                className={classes.textField}
              >
                <MenuItem value={0}>
                  <em>None</em>
                </MenuItem>
                {cityList.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormGroup row>
          </FormGroup>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={rotate}
                  onChange={() => {
                    setRotate(!rotate);
                  }}
                />
              }
              label="Rotate Images"
            />
          </FormGroup>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={submit}
          variant="contained"
          color="primary"
          disabled={disableNext}
        >
          Next
        </Button>
      </DialogActions>
    </div>
  );
}
