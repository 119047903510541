import React, { useEffect, useState, useContext, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import welcomeImg from "../assets/WelcomeIMG.jpg";
// import welcomeLogo from "../assets/WelcomeLogo.png";
import IrisGoLogo from "../assets/IrisGoLogo.png";
import irisLogo from "../assets/irisLogo.png";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockRounded from "@mui/icons-material/LockRounded";
import { getUserName } from "../utils/localStorage/utilFunctions";
import { getIrisUser } from "../api/auth/auth";
import useGlobalUI from "../UI/global/useGlobalUI";
import { LoadingButton } from "@mui/lab";

import Auth from "api/awsCognito/AuthUtils";
import { ResetPasswordModal } from "components/modals/ForceResetPasswordModal/ForceResetPasswordModal";
import {
  AWS_CHALLENGE_NAMES,
  testAWSPassword,
} from "components/utils/aws/authUtils";
import { Link } from "react-router-dom";
import pkg from "../../package.json";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
  },
  welcomeImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  irisLogo: {
    position: "absolute",
    width: "120px",
    top: "1.5%",
    right: "1.5%",
  },
  loginLogo: {
    objectFit: "contain",
    width: "200px",
  },
  imagePanel: {
    position: "relative",
  },
  loginPanel: {
    padding: "10px",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "30vh",
  },
  loginForm: {
    maxWidth: "400px",
    minWidth: "300px",
    display: "flex",
    flexDirection: "column",
  },
  inputText: {
    fontSize: "1.15rem",
    marginLeft: "10px",
    paddingLeft: "10px",
  },
  forgetPasswordLink: {
    marginTop: "0.5rem",
    marginLeft: "auto",
    "&:visited": {
      color: "inherit",
    },
  },
}));

export default function Login({ history }) {
  const { showAlert } = useGlobalUI();
  const classes = useStyles();

  const [username, setUsername] = useState(getUserName());
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
    flag indicate whether a user is required to reset password.
    if a user is required to do so, the login payload will return
    some params, along with a session in string format
   */
  const [resetPasswordFlag, setResetPasswordFlag] = useState({
    userName: "",
    session: "", // a valid session string will be returned after logging in.
  });

  const { onAuthenticated, onLogout } = useContext(AuthContext);
  const passwordRef = useRef();

  const handleLogin = async () => {
    if (!testAWSPassword(password).details.isLongEnough) return;
    let isAuthenticated, irisUser;
    try {
      setIsLoading(true);

      // step 1, login to cognito
      const { loginSuccess, tokenPayloads, challengePayload } =
        await Auth.signIn(username, password);

      if (loginSuccess) {
        const awsToken = tokenPayloads.accessToken.token;
        // step 2, verify
        irisUser = await getIrisUser(awsToken);

        // both step 1 and step 2 pass, can go to next step
        if (!irisUser.is_staff) {
          throw new Error("Please login with a staff account.");
        }
        onAuthenticated(irisUser.username, awsToken);
        isAuthenticated = !!irisUser;
      } else if (challengePayload) {
        const { ChallengeName, Session, ChallengeParameters } =
          challengePayload;

        if (ChallengeName === AWS_CHALLENGE_NAMES.NEW_PASSWORD_REQUIRED) {
          setResetPasswordFlag({
            session: Session,
            userName: ChallengeParameters.USER_ID_FOR_SRP,
          });
        }
      }
    } catch (error) {
      showAlert(error.message);
    } finally {
      setIsLoading(false);
      if (isAuthenticated) {
        history.push("/");
      }
    }
  };

  /**
   * callback function pass to `ResetPasswordModal#onClose` property
   *
   * @description
   * When this function is called
   * - `password` should be set to "".
   * - `resetPasswordFlag` should be reset.
   * - password input textfield should be **focused**.
   */
  const handleResetPasswordModalClose = () => {
    setPassword("");
    setResetPasswordFlag({
      userName: "",
      session: "",
    });
    requestAnimationFrame(() => {
      passwordRef.current.focus();
    });
  };

  useEffect(() => {
    const autoLogin = async () => {
      setIsLoading(true);
      let isAuthenticated = false;
      let error, irisUser;
      try {
        const cachedUser = await Auth.currentAuthenticatedUser();

        // if user log out by himself -> refresh token would be remove from local storage -> calling Auth.currentAuthenticatedUser() return undefined
        if (!cachedUser && getUserName()) {
          requestAnimationFrame(() => {
            passwordRef.current.focus();
          });
        } else {
          const token = cachedUser?.tokenPayloads?.accessToken?.token;
          if (token) {
            irisUser = await getIrisUser(token);
            if (!irisUser.is_staff) {
              throw new Error("Please login with a staff account.");
            }
            onAuthenticated(irisUser.username, token);
            isAuthenticated = !!irisUser;
          }
        }
      } catch (e) {
        error = e;
      } finally {
        setIsLoading(false);
        if (error) {
          showAlert(error.message || "Unknow Error");
          // call signout, to removed any saved but not valid tokens
          Auth.signOut();
        } else if (isAuthenticated) {
          const pathname = history.location.state?.from?.pathname;
          if (pathname) {
            history.replace(pathname);
          } else {
            history.push("/");
          }
        }
      }
    };

    autoLogin();
  }, [showAlert, history, onLogout, onAuthenticated]);

  return (
    <Grid component="form" container className={classes.root}>
      <ResetPasswordModal
        open={
          resetPasswordFlag.userName !== "" && resetPasswordFlag.session !== ""
        }
        tempCognitoUser={{
          userName: resetPasswordFlag.userName,
          session: resetPasswordFlag.session,
        }}
        onClose={handleResetPasswordModalClose}
      />
      <Grid container item sm={6} className={classes.loginPanel}>
        <div className={classes.loginForm}>
          <img src={IrisGoLogo} className={classes.loginLogo} alt="IrisGO TM" />
          <Typography align="right" variant="h6">
            Admin Console
          </Typography>
          <Typography align="right" variant="caption">
            {pkg.version}
          </Typography>
          <TextField
            disabled={isLoading}
            autoFocus
            margin="normal"
            label="Username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
              classes: {
                input: classes.inputText,
              },
            }}
          />
          <TextField
            inputRef={passwordRef}
            disabled={isLoading}
            margin="normal"
            label="Password"
            type="password"
            autoComplete="off"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={(event) => {
              if (event.key.toLowerCase() === "enter") {
                handleLogin();
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockRounded />
                </InputAdornment>
              ),
              classes: {
                input: classes.inputText,
              },
            }}
          ></TextField>
          <LoadingButton
            sx={{
              marginBlock: "0.5rem",
            }}
            color="primary"
            variant="contained"
            loading={isLoading}
            loadingPosition="center"
            onClick={handleLogin}
            disabled={
              !testAWSPassword(password).details.isLongEnough ||
              username.trim().length === 0 // empty user name or user name with too short password should make this disabled
            }
          >
            Login
          </LoadingButton>
          <Typography align="right" variant="body2">
            <Link to="/forget-password" className={classes.forgetPasswordLink}>
              Forget password?
            </Link>
          </Typography>
        </div>
      </Grid>
      <Hidden xsDown>
        <Grid
          container
          item
          sm={6}
          alignItems="center"
          direction="column"
          className={classes.imagePanel}
        >
          <img src={irisLogo} className={classes.irisLogo} alt="IRIS" />
          <img
            src={welcomeImg}
            className={classes.welcomeImg}
            alt="Welcome to IrisGo Admin Console"
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}
