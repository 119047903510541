import styles from "./CreateUser.module.css";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperText } from "@mui/material";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { AuthContext } from "context/AuthContext";

import { dummyUserData } from "pages/clientsUsersGroups/dummyDataOfUsers";
import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import PasswordHelperText from "components/passwrodHelperText/PasswordHelperText";
import {
  addUserToGroup,
  createUser,
  ERROR_MESSAGE,
  getAdminGroupList,
} from "utils/irisAuthRequests";
import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
import IrisMultiSelect from "UI/muiSelect/IrisMultiSelect";

/** 3 different messages according to api call */
const CREATE_USER_MODAL_MESSAGES = {
  /** "No request is sent." */
  notSend: "No request is sent.",
  /** "Create user request is sent. Please wait for a while." */
  sending: "Create user request is sent. Please wait for a while.",
  /** "New user is created successfully." */
  success: "New user is created successfully.",
  groupAssigned: "New user is created and assigned to group(s) successfully",
};

const CreateUser = () => {
  const history = useHistory();

  // get token to make api call
  const { awsToken, onLogout } = useContext(AuthContext);

  // userName, email, phone number, input fields set state
  const [userName, setUserName] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  // set state to indicate whether the input email matches the regex rule
  const [emailVerified, setEmailVerified] = useState(true);
  // set state to indicate whether the input phone number matches the regex rule
  const [phoneVerified, setPhoneVerified] = useState(true);

  // password set state, input fields, show password or text
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [passwordOK, setPasswordOK] = useState(false);

  // client set state, with client id
  const [client, setClient] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  // group set state, with group id
  const [group, setGroup] = useState("");
  const [groupOptions, setGroupOptions] = useState([]);

  // check if all the required fields are filled as required
  const [isReady, setIsReady] = useState(false);

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // when clicking the submit button, set state, modal will display different info according to the states
  const [createUserState, setCreateUserState] = useState(SUBMIT_STATE.notSend);
  // modal content set state
  const [modalMessageContent, setModalMessageContent] = useState(
    CREATE_USER_MODAL_MESSAGES.notSend
  );
  // error type, to indicate what operations should be taken when error ocurrs
  const [errorType, setErrorType] = useState("");

  const [groupOptionObj, setGroupOptionObj] = useState(null);
  const [assignedGroups, setAssignedGroups] = useState([]);

  // triggers when inputting email
  const handleEmailInput = (e) => {
    /** regex to set email input rules  */
    const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.[a-zA-Z]\w{1,})+$/g;

    // only when the input email is not empty string
    if (e.target.value !== "") {
      setEmailVerified(e.target.value.match(emailPattern) !== null);
    } else {
      setEmailVerified(true);
    }

    setEmail(e.target.value);
  };

  // triggers when inputting phone number
  const handlePhoneInput = (e) => {
    /** regex to set phone number input rules  */
    const phonePattern = /^\+[0-9]{3,}$/g;

    // only when the input phone number is not empty string
    if (e.target.value !== "") {
      setPhoneVerified(e.target.value.match(phonePattern) !== null);
    } else {
      setPhoneVerified(true);
    }

    setPhoneNumber(e.target.value);
  };

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // only when it's not sending request, close the backdrop and modal
    if (createUserState !== SUBMIT_STATE.sending) {
      // when there's an error
      if (createUserState === SUBMIT_STATE.error) {
        // when the token is not valid, click the backdrop to logout
        if (errorType === ERROR_MESSAGE.token) {
          onLogout();
        } else {
          setOpenBackdrop(false);
          setCreateUserState(SUBMIT_STATE.notSend);
          setErrorType("");
        }
      }
      // when there's no error, redirect to /users
      else {
        history.push(`/users`);
      }
    }
  };

  // submit the sign up data
  const handleSubmit = () => {
    setCreateUserState(SUBMIT_STATE.sending);
    setOpenBackdrop(true);
    setModalMessageContent(CREATE_USER_MODAL_MESSAGES.sending);

    const submitProps = {
      token: awsToken,
      userName: userName,
      name:name,
      password: password2,
      phoneNumber: phoneNumber,
      email: email,
    };

    createUser(submitProps)
      .then((result) => {
        // triggers the backdrop to show message of submit info

        if (assignedGroups.length > 0) {
          // assign new user to given groups
          const submitProps = {
            token: awsToken,
            userName: result.User.Username,
            groups: assignedGroups.reduce((acc, ele) => {
              acc.push(ele.name);
              return acc;
            }, []),
          };
          addUserToGroup(submitProps)
            .then((result) => {
              setCreateUserState(SUBMIT_STATE.ok);
              setModalMessageContent(CREATE_USER_MODAL_MESSAGES.groupAssigned);
            })
            .catch((error) => {
              console.error(`error in assign new user to group(s): ${error}`);
              const errorMessage = `New user is created but failed to assigned to given group(s), please try later`;
              setErrorType(errorMessage);
              setModalMessageContent(errorMessage);
              setCreateUserState(SUBMIT_STATE.error_in_assign_group);
            });
        } else {
          setCreateUserState(SUBMIT_STATE.ok);
          setModalMessageContent(CREATE_USER_MODAL_MESSAGES.success);
        }
      })
      .catch((err) => {
        setErrorType(err.message);
        setModalMessageContent(err.message);
        setCreateUserState(SUBMIT_STATE.error);
      });
  };

  // get dynamic helper text for email input field
  const emailHelperText = () => {
    return (
      <span>
        {email !== "" && !emailVerified && (
          <span style={{ color: "#c1151f" }}>email address not valid</span>
        )}
      </span>
    );
  };

  // get dynamic helper text for phone number input field
  const phoneHelperText = () => {
    return (
      <>
        {phoneNumber !== "" && (
          <span style={{ color: "#c1151f" }}>
            {phoneNumber[0] !== "+" && (
              <span>
                should start with +
                <br />
              </span>
            )}
            {!phoneVerified && <span>phone number not valid</span>}
          </span>
        )}
      </>
    );
  };

  // text input fields, userName, email, phone number, hard code
  const textInputFields = [
    {
      name: "user name",
      placeHoler: "Mike",
      value: userName,
      type: "text",
      onChange: (e) => setUserName(e.target.value),
      isMandatory: true,
    },
    {
      name: "Name",
      placeHoler: "",
      value: name,
      type: "text",
      onChange: (e) => setName(e.target.value),
      isMandatory: true,
    },
    {
      name: "email",
      placeHoler: "Mike@gmail.com",
      value: email,
      type: "email",
      onChange: handleEmailInput,
      helperText: emailHelperText(),
      isMandatory: false,
    },
    {
      name: "phone number",
      placeHoler: "+1234567890",
      value: phoneNumber,
      type: "text",
      onInput: (e) => (e.target.value = e.target.value.replace(/[^0-9+]/g, "")),
      onChange: handlePhoneInput,
      helperText: phoneHelperText(),
      isMandatory: false,
    },
  ];

  // set client options and default value
  useEffect(() => {
    // traverse dummy data, to get all clients from user list
    const tempClientList = [];
    dummyUserData.forEach((user) => {
      const matchClient = tempClientList.find(
        (client) => client.id === user.client_id
      );
      if (matchClient === undefined) {
        const newClient = { id: user.client_id, name: user.client_name };
        tempClientList.push(newClient);
      }
    });
    // set state the initial value
    setClientOptions(tempClientList);
    setClient(tempClientList[0].id);

    // get group list for new user to be assigned
    getAdminGroupList(awsToken)
      .then((result) => {
        const groupOptionsTemp = result.map((group) => ({
          name: group.GroupName,
        }));

        setGroupOptionObj({
          titleKey: "name",
          options: groupOptionsTemp,
          idKey: "name",
        });
      })
      .catch((err) => {
        console.error(`Error on loading groups: ${err}`);
      });
  }, [awsToken]);

  // set group options and default value
  useEffect(() => {
    if (client !== "") {
      // traverse dummy data, to get group options from selected client
      const tempGroupOptions = [];
      dummyUserData.forEach((user) => {
        if (user.client_id === client) {
          user.groups_info.forEach((oneGroup) => {
            const tempGroupInfo = { id: oneGroup.id, name: oneGroup.name };
            tempGroupOptions.push(tempGroupInfo);
          });
        }
      });
      // remove duplicate groups
      const tempArr1 = tempGroupOptions.map((element) => element.id);
      const tempSet = new Set([...tempArr1]);
      const tempArr2 = [...tempSet];
      const renderGroupOptions = [];
      tempArr2.forEach((oneId) => {
        const match = tempGroupOptions.find((element) => element.id === oneId);
        renderGroupOptions.push(match);
      });

      // set state the initial value
      setGroupOptions(renderGroupOptions);
      setGroup(renderGroupOptions[0].id);
    }
  }, [client]);

  // check if all the required fields are filled as required and set state of isReady
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setIsReady(
        userName !== "" &&
          name != "" &&
          passwordOK &&
          password1 === password2 &&
          client !== "" &&
          group !== "" &&
          emailVerified &&
          phoneVerified
      );
    }
    return () => (isSubscribed = false);
  }, [
    userName,
    emailVerified,
    phoneVerified,
    password1,
    password2,
    client,
    group,
    passwordOK,
  ]);

  return (
    <div className={styles.signup}>
      <h2 className={`${styles.pageChild} ${styles.header}`}>
        Create a new user
      </h2>
      <div className={styles.titleDevider}></div>
      <div
        className={`${styles.pageChild} ${styles.bodyContent} ${styles.textInputFields}`}
      >
        {textInputFields.map((field, index) => (
          <div className={styles.textField} key={index}>
            <TextField
              fullWidth
              variant="standard"
              label={field.name}
              placeholder={field.placeHoler}
              required={field.isMandatory}
              value={field.value}
              type={field.type}
              onChange={field.onChange}
              onInput={field?.onInput}
              helperText={field?.helperText}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        ))}
      </div>
      <div
        className={`${styles.pageChild} ${styles.bodyContent} ${styles.password}`}
      >
        <div className={styles.passwordInputFields}>
          <FormControl variant="standard">
            <InputLabel htmlFor="password1-input" shrink={true}>
              Password
            </InputLabel>
            <Input
              fullWidth
              id="password1-input"
              type={showPassword1 ? "text" : "password"}
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword1((prev) => !prev)}
                  >
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>
              {password1 !== "" && (
                <PasswordHelperText
                  inputPassword={password1}
                  setPasswordOK={setPasswordOK}
                />
              )}
            </FormHelperText>
          </FormControl>

          <TextField
            fullWidth
            variant="standard"
            label="Confirm password"
            type="password"
            value={password2}
            disabled={!passwordOK}
            onChange={(e) => setPassword2(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            helperText={
              password2 !== "" && (
                <span
                  style={{
                    color: password2 === password1 ? "" : "#c1151f",
                  }}
                >
                  input password must be identical
                </span>
              )
            }
          />
          {groupOptionObj && (
            <div
              style={{
                maxWidth: 200,
                margin: assignedGroups.length > 0 ? -7 : null, // small trick for alignment
              }}
            >
              <IrisMultiSelect
                options={groupOptionObj.options}
                value={assignedGroups}
                keyName={groupOptionObj.titleKey}
                labelName="User Group"
                updateSelectedOptions={(values) => {
                  setAssignedGroups(values);
                }}
                textFieldStyle="standard"
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={`${styles.pageChild} ${styles.bodyContent} ${styles.select}`}
      ></div>
      <div className={styles.titleDevider}></div>
      <div className={`${styles.pageChild} ${styles.buttons}`}>
        <Button
          variant="outlined"
          startIcon={<PersonAddIcon />}
          disabled={!isReady}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>

      <SubmitBackdrop
        open={openBackdrop}
        onClick={handleClickBackdrop}
        submitState={createUserState}
        modalMessage={modalMessageContent}
      />
    </div>
  );
};

export default CreateUser;
