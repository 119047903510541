import React from "react";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";

/**
 *
 * @param {Object} props
 * @param {(event: React.ChangeEvent<HTMLInputElement>) => {}} props.onSelectAllClick callback function when the checkbox is toggled
 * @param {"asc" | "desc"} props.sortOrder sorting order for the rows
 * @param {string} props.orderField whose value would be used to sort
 * @param {number} props.numSelected number of rows that are selected, ie: whose checkbox is checked
 * @param {number} props.rowCount number of rows
 * @param {() => {}} props.onRequestSort
 * @param {[TableColumnObject]} props.columns array of [TableColumnObject], each column's value of [headTitle] would be used to render as head cell value.
 * @param {boolean} props.isRowSelectable
 */
function IrisTableHeaderView(props) {
  const {
    onSelectAllClick,
    sortOrder,
    orderField,
    numSelected,
    rowCount,
    onRequestSort,
    columns,
    isRowSelectable,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isRowSelectable && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {columns.map(
          (
            { fieldName: keyName, headTitle: headCellTitle, width = 0 },
            index
          ) => (
            <TableCell
              sx={{
                minWidth: width > 0 ? width : "auto",
                backgroundColor: "primary.main",
                color: "#fff",
              }}
              key={index}
              sortDirection={orderField === keyName ? sortOrder : false}
            >
              <TableSortLabel
                active={orderField === keyName}
                direction={orderField === keyName ? sortOrder : "asc"}
                onClick={createSortHandler(keyName)}
              >
                {headCellTitle}
                {orderField === keyName ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sortOrder === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default IrisTableHeaderView;
