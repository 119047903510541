import { makeStyles } from "@mui/styles";

const FINDER_HEIGHT = 400;
const LIST_WIDTH = 280;
const finderStyle = makeStyles((theme) => {
  return {
    root: {
      // width: 800,
      marginTop: "0.5rem",
      width: "100%",
      height: (props) => props.height || FINDER_HEIGHT,
      backgroundColor: theme.palette.background.paper,
      whiteSpace: "nowrap",
      overflowX: "auto",
      overflowY: "hidden",
    },
    list: {
      boxSizing: "border-box",
      border: "2px solid rgba(0,0,0, 0.3)",
      width: (props) => props.listWidth || LIST_WIDTH,
      height: "100%",
      overflowY: "auto",
      display: "inline-block",

      // for each list item, it is set as "button" in the ListItem element
      // subjective to changes
      "& .MuiButtonBase-root": {
        // less space between icon and text
        padding: "4px 10px",
        "& .MuiListItemIcon-root": {
          minWidth: "2rem",
        },

        // text
        "& .MuiListItemText-root": {
          // out most dive

          "& .MuiTypography-root": {
            // shared class name
            "&.MuiListItemText-primary": {
              // primary text, long name ends with ... on primary text
              overflow: "hidden",
              // width: "80%", // leave some space for 2nd icon on the right hand side
              width: (props) => (props.hasRightHandSideIcon ? "80%" : "100%"),
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            },
            "&.MuiListItemText-secondary": {
              // secondary text
              whiteSpace: "normal", // wrap text properly
              // color: "red"
            },
          },
        },

        // hover style for all non selected list item
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
          color: "#fff",
          "& .MuiSvgIcon-root": {
            // make icon more visible
            color: "#fff",
          },
        },

        // for selected list item
        "&.Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          color: "#fff",

          // more readable secondary text
          "& .MuiListItemText-secondary": {
            color: "rgba(255,255,255, 0.8)",
          },

          // more visible list item icon
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },

          // more redalbe list content on hober
          "&.Mui-selected:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      },
    },
  };
});

export { finderStyle as default, FINDER_HEIGHT, LIST_WIDTH };
