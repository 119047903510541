import React from "react";
import ReactDOM from "react-dom";
import Auth from "api/awsCognito/AuthUtils";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

Auth.config({ clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID });

if (process.env.NODE_ENV === "development") {
  const { worker } = require("test/mocks/browser");
  // worker.start(); // when using real server, comment out this line
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
