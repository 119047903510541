import React, { useState } from "react";
import PostOfficeTable from "../components/postoffice/PostOfficeTable";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { makeStyles } from "@mui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { Tabs, Tab } from "@mui/material/";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { POST_OFFICE_URL } from "../utils/env/urls";

const useStyles = makeStyles({
  headerContainer: {
    display: "flex",
    padding: "0 0 2rem 0",
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
});

const ROUTES = {
  "/postoffice": {
    path: "/postoffice",
    tableUrl: POST_OFFICE_URL + "/email/?status=waitingerror",
  },
  "/postoffice/history": {
    path: "/postoffice/history",
    tableUrl: POST_OFFICE_URL + "/email/?status=sent",
  },
};

const PostOffice = () => {
  const classes = useStyles();

  const [tableUrl, setTableUrl] = useState(ROUTES["/postoffice"].tableUrl);
  const [tabValue, setTabValue] = useState("/postoffice");

  const handleChange = (event, value) => {
    setTableUrl(ROUTES[`${value}`].tableUrl);
    setTabValue(value);
  };
  return (
    <>
      <Box className={classes.headerContainer}>
        <Typography className={classes.title} variant="h5">
          Post Office
        </Typography>
        <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary">
          <Tab
            label="Queue"
            value={"/postoffice"}
            component={Link}
            to={"/postoffice"}
          />
          <Tab
            label="History"
            value={"/postoffice/history"}
            component={Link}
            to={"/postoffice/history"}
          />
        </Tabs>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
          <PostOfficeTable url={tableUrl} />
        </div>
      </LocalizationProvider>
    </>
  );
};

export default PostOffice;
