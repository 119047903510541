import { IRISGO_LABEL_TYPE } from "utils/env/urls";

/**
 *
 * @param {string} token
 * @returns {Promise<{message: string, success: boolean, result: [LabelInterface]}>}
 */
export const GET_LABEL_TYPES = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  };
  const response = await fetch(`${IRISGO_LABEL_TYPE}`, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    process.env.NODE_ENV === "development" &&
      console.log(`responseJSON: `, responseJson);
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};

/**
 *
 * @param {string} token
 * @param {LabelInterface} updatedData
 * @returns {Promise<{success: boolean, message: string, result: LabelInterface}>}
 */
export const UPDATE_LABEL_TYPE = async (token, updatedData) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(updatedData),
  };
  const response = await fetch(
    `${IRISGO_LABEL_TYPE}/${updatedData.id}/`,
    options
  );
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};

export const CREATE_LABEL_TYPE = async (token, data) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(IRISGO_LABEL_TYPE + "/", options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};

/**
 *
 * @param {string} token
 * @param {number} id label id to be deleted
 * @returns {Promise<{success: boolean, message: string, result: string}>}
 */
export const DELETE_LABEL_TYPE = async (token, id) => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  };
  const response = await fetch(`${IRISGO_LABEL_TYPE}/${id}/`, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    // responseJson = {
    //     "success": true,
    //     "message": "Label type successfully deleted!",
    //     "result": ""
    // }
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};
