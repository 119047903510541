import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { TOOL_BAR_HEIGHT } from "../../utils/theme/constrains";
import { Typography } from "@mui/material/";

const useStyles = makeStyles((theme) => ({
  form: {
    margin: "auto",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 300}px)`,
  },
  text: { textAlign: "center" },
}));

export default function RegisterDevice({ onNext, activation }) {
  const classes = useStyles();

  return (
    <div>
      <DialogTitle>Device Registered</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Please use the following activation code to start the app.
        </DialogContentText>
        <div className={classes.form}>
          <Typography variant="h5" className={classes.text}>
            {activation}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNext} variant="contained" color="primary">
          Back
        </Button>
      </DialogActions>
    </div>
  );
}
