import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  createContext,
} from "react";
import ConfirmModal from "../view/ConfirmModal";

const ConfirmContext = createContext([
  {
    /** confirmation message */
    message: "",
    /** if true, open the custom confirmation modal, false otherwise */
    isOpen: false,
    proceed: () => {},
    cancel: () => {},
  },
  () => {},
]);

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    message: "",
    isOpen: false,
    proceed: null,
    cancel: null,
  });

  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      <ConfirmModal />
      {children}
    </ConfirmContext.Provider>
  );
};

const useConfirm = () => {
  const [confirm, setConfirm] = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = useCallback(
    /**
     *
     * @param {string} message confirmation message shown on the custom modal
     * @returns Promise, either on resovle or on reject, the state of the confirm object in the context would be reset;
     * on resolve, it returns true, false otherwise
     */
    (message) => {
      const promise = new Promise((resolve, reject) => {
        setConfirm({ message, isOpen: true, proceed: resolve, cancel: reject });
        setNeedsCleanup(true);
      });

      /** reset confirm state on its context */
      const reset = () => {
        setConfirm({ message: "", proceed: null, cancel: null, isOpen: false });
        setNeedsCleanup(false);
      };

      /** callback function on user confirm */
      const onConfirmed = () => {
        reset();
        return true;
      };

      /** callback function on user refuse */
      const onRefused = () => {
        reset();
        return false;
      };

      return promise.then(onConfirmed, onRefused);
    },
    [setConfirm]
  );

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed,
  };
};

export default ConfirmContextProvider;
export { useConfirm };
