import React, { useState, useEffect, useReducer, useContext } from "react";
import { colors, IconButton, LinearProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import {
  CITY_OPTIONS,
  convertScheduleObjToString,
  convertScheduleStringToObject,
  IMAGE_INTERVAL_OPTIONS,
  IMAGE_QUALITY_OPTIONS,
  trimObject,
  TRUE_FALSE_OPTIONS,
  Y_N_OPTIONS,
} from "./utils";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";
import IrisTimePickers from "UI/muiTimePickers/IrisTimePickers";
import { AuthContext } from "context/AuthContext";
// import { updateDeviceRequest } from "api/devices/request";
import { useNotification } from "UI/global/notification/context/NotificationContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  MOBILE_DEVICE_CITY_LOOKUP_REQUEST,
  MOBILE_DEVICE_GET_API_REQUEST,
  MOBILE_DEVICE_UPDATE_API_REQUEST,
} from "utils/requests";

import DataGrid from "UI/dataGrid/DataGrid";
import { useQuery, useMutation } from "react-query";
import IrisLinearProgress from "UI/irisLinearProgress/IrisLinearProgress";
import {
  getAdminClientDetails,
  getAdminClientList,
} from "utils/irisAuthRequests";
import { updateDeviceRequest } from "api/devices/request";

/**key title pairs, where key is use to read value from object, title value is use to render readable label */
const FIXED_KEY_TITLE_MAP = {
  id: "V3 Id",
  idv2: "Id",
  activate: "Activation Code",
  device_sn: "Device SN",
  IMEI: "IMEI",
  serial_number: "Serial Number",
  hexnode_id: "Hexnode Id",
};

/**for useReducer */
const initialState = {
  tag: "default tag name",
  /** city id  */
  city: 1,
  rotate: "N",
  // enabled: "N",
  on_wifi: "N",
  image_capture_interval: 10,
  image_quality: "720p",
  /** invalid if tag name is '', should be remove when sending data */
  invalidInput: false,
};

const ACTIONS = {
  SET_DEVICE_NAME: "SET DEVICE NAME",
  SET_CITY: "SET CITY",
  SET_ROTATE: "SET ROTATE",
  // SET_ENABLED: "SET ENABLED",
  SET_UPLOAD_ON_WIFI: "SET UPLOAD ON WiFi",
  SET_IMAGE_INTERVAL: "SET IMAGE INTERVAL",
  SET_IMAGE_QUALITY: "SET IMAGE QUALITY",
  RESET: "RESET",
  SET_INVALID_INPUT: "SET INVALID INPUT",
};
/**
 * @param {initialState} state
 * @returns {initialState}
 */
const reducer = (state, action) => {
  let newState;
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_DEVICE_NAME:
      const validPayload = typeof payload === "string" && payload.trim() !== "";
      newState = { ...state, tag: payload, invalidInput: !validPayload };
      break;
    case ACTIONS.SET_CITY:
      newState = { ...state, city: payload };
      break;
    case ACTIONS.SET_ROTATE:
      newState = { ...state, rotate: payload };
      break;
    case ACTIONS.SET_UPLOAD_ON_WIFI:
      newState = { ...state, on_wifi: payload };
      break;
    case ACTIONS.SET_IMAGE_INTERVAL:
      newState = { ...state, image_capture_interval: payload };
      break;
    case ACTIONS.SET_IMAGE_QUALITY:
      newState = { ...state, image_quality: payload };
      break;
    case ACTIONS.RESET:
      newState = { ...payload, invalidInput: false };
      break;
    case ACTIONS.SET_INVALID_INPUT:
    default:
      newState = initialState;
      break;
  }

  return newState;
};

/**
 *
 * @param {object} device device object
 * @param {[id: number, name: string]} cities array of simple city object, for IrisSelect options
 * @returns
 */
const getGeneralEditableMapKey = (cities) => {
  // values should be either empty, or array of
  // object like {id:1, name: "some name"}
  // this array, for those type is "input", would be
  // pass into IrisSelect component as options
  const temp = {
    "Device Name": {
      key: "tag",
      type: "input",
      values: [],
      defaultValue: "",
      reducerAction: ACTIONS.SET_DEVICE_NAME,
    },
    City: {
      key: "city",
      type: "select",
      values: CITY_OPTIONS,
      defaultValue: 1,
      reducerAction: ACTIONS.SET_CITY,
    },
    Rotate: {
      key: "rotate",
      type: "select",
      values: Y_N_OPTIONS,
      defaultValue: true,
      reducerAction: ACTIONS.SET_ROTATE,
    },
    // Enabled: {
    //   key: "enabled",
    //   type: "select",
    //   values: TRUE_FALSE_OPTIONS,
    //   defaultValue: true
    // },
    "Upload on WiFi": {
      key: "on_wifi",
      type: "select",
      values: TRUE_FALSE_OPTIONS,
      defaultValue: true,
      reducerAction: ACTIONS.SET_UPLOAD_ON_WIFI,
    },
    "Image Interval": {
      key: "image_capture_interval",
      type: "select",
      values: IMAGE_INTERVAL_OPTIONS,
      defaultValue: 10,
      reducerAction: ACTIONS.SET_IMAGE_INTERVAL,
    },
    "Image Quality": {
      key: "image_quality",
      type: "select",
      values: IMAGE_QUALITY_OPTIONS,
      sortOptions: false, // default is true
      defaultValue: "720p",
      reducerAction: ACTIONS.SET_IMAGE_QUALITY,
    },
  };

  if (Array.isArray(cities) && cities.length > 0) {
    // overrid default values base on the cities object
    temp.City.values = [...cities];
  } else if (!cities) {
    // no cities => no selection => could remove this default value
    delete temp.City;
  }
  return temp;
};

/**
 *
 * @param {object} props
 * @param {object} props.data device data
 * @returns
 */
export default function DeviceDetails(props) {
  const { contextToken, awsToken } = useContext(AuthContext);
  const { showNotification } = useNotification();
  const [clientList, setClientList] = useState([]);

  /*================================================== states START ================================================== */
  // Used to hold data of new device api model
  const [device, setDevice] = useState({
    id: "",
    idv2: "",
    activate: "",
    device_sn: "",
    IMEI: "",
    serial_number: "",
    hexnode_id: "",
    schedule: {
      Friday: {
        end: "23:59:59",
        start: "00:00:00",
      },
      Monday: {
        end: "23:59:59",
        start: "00:00:00",
      },
      Sunday: {
        end: "23:59:59",
        start: "00:00:00",
      },
      Tuesday: {
        end: "23:59:59",
        start: "00:00:00",
      },
      Saturday: {
        end: "23:59:59",
        start: "00:00:00",
      },
      Thursday: {
        end: "23:59:59",
        start: "00:00:00",
      },
      Wednesday: {
        end: "23:59:59",
        start: "00:00:00",
      },
    },
    client_id: -1,
  });

  /**
   * selection options fir IrisSelect component
   * @type{[{id: number, name: string}]}
   */
  const [cities, setCities] = useState([]);

  const [isEditGeneralInfo, setIsEditGeneralInfo] = useState(false);
  // inital general info, when device is updated, this value MUST also be updated
  const [initialGeneralInfoState, setInitialGeneralInfoState] =
    useState(initialState);

  const [generalInfoState, dispatch] = useReducer(
    reducer,
    initialGeneralInfoState
  );

  // is update device is on going or not
  const [isSending, setIsSending] = useState(false);

  /** schedule object pass to IrisTimePickers component, which only takes time in Date object format */
  const [newSchedule, setNewSchedule] = useState({
    Friday: {
      end: "23:59:59",
      start: "00:00:00",
    },
    Monday: {
      end: "23:59:59",
      start: "00:00:00",
    },
    Sunday: {
      end: "23:59:59",
      start: "00:00:00",
    },
    Tuesday: {
      end: "23:59:59",
      start: "00:00:00",
    },
    Saturday: {
      end: "23:59:59",
      start: "00:00:00",
    },
    Thursday: {
      end: "23:59:59",
      start: "00:00:00",
    },
    Wednesday: {
      end: "23:59:59",
      start: "00:00:00",
    },
  });
  const [isEditSchedule, setIsEditSchedule] = useState(false);

  const [isScheduleInvalid, setIsScheduleInvalid] = useState(false);

  /*==================================================  states END  ================================================== */

  /** device id read from url param */
  const { id: deviceId } = useParams();

  const getClientFromCity = (clientList, cityName) => {
    let clientData = clientList.filter(
      (client) => client.name.toLowerCase() === cityName.toLowerCase()
    );
    if (clientData.length === 0)
      clientData = clientList.filter(
        (client) =>
          client.name.toLowerCase() === (cityName + "_Admin").toLowerCase()
      );
    return clientData;
  };

  /*================================================== react query useQuery START ================================================== */
  const {
    data: dd,
    status,
    isFetching,
  } = useQuery(
    ["LOOKUP_CITIES", contextToken],
    () => {
      return getAdminClientList(awsToken);
    },

    {
      refetchOnWindowFocus: false,
      onerror: (error) => {
        alert("error in getting cities");
      },
      onSuccess: (data) => {
        /**@type {Object<string, {IrisCity}} */
        const newCities = data.map((ele) => ({ id: ele.id, name: ele.name }));
        setCities(newCities);
      },
    }
  );

  /**
   * @summary callback function for send buttons
   * @param {("GENERAL_INFO" | "SCHEDULE" | "GEO_FENCING")} type
   * @see sendRequest
   */
  const updateDeviceDetails = (type) => (event) => {
    /** either general info param, or schedule param */
    let params, newDeviceInfo;

    if (type === "GENERAL_INFO") {
      params = { ...generalInfoState };
      delete params.invalidInput;
      // show edit icon button
      setIsEditGeneralInfo(false);

      const cityName = cities.filter((city) => city.id === params.city)[0].name;
      const clientData = getClientFromCity(clientList, cityName);

      newDeviceInfo = {
        ...device,
        image_capture_interval: generalInfoState["image_capture_interval"],
        image_quality: generalInfoState["image_quality"],
        on_wifi: generalInfoState["on_wifi"],
        rotate: generalInfoState["rotate"] === "Y" ? true : false,
        tag: generalInfoState["tag"],
        client_id: clientData.length > 0 ? clientData[0].id : -1,
      };
    } else if (type === "SCHEDULE") {
      // show edit icon button
      setIsEditSchedule(false);
      // server only take time as string format
      const scheduleObject = convertScheduleObjToString(newSchedule);
      params = { schedule: scheduleObject };
      newDeviceInfo = {
        ...device,
        schedule: scheduleObject,
      };
    } else if (type === "GEO_FENCING") {
    }
    if (params && newDeviceInfo) {
      Promise.all([
        MOBILE_DEVICE_UPDATE_API_REQUEST(newDeviceInfo, awsToken),
        updateDeviceRequest(device.idv2,params,contextToken)

      ]).then((results) => {
        setDevice(results[0]);
        showNotification("Device Updated", "top-center", "success");
      })
      .catch((error) => {
          showNotification("Error in updating", "top-center", "error");
        });
    }
  };

  /**
   * @summary callback function for IrisTimePickers
   * @param {string} key key values for device schedule object
   * @returns
   */
  const updateSchedule = (key) => {
    /**
     *
     * @param {string} startTime string value of start time, MUST be "HH:mm:ss" format
     * @param {string} endTime string value of end time, MUST be "HH:mm:ss" format
     */
    const temp = (startTime, endTime) => {
      setNewSchedule((prev) => ({
        ...prev,
        [key]: { start: startTime, end: endTime },
      }));
    };

    return temp;
  };

  //update both states when device is updated, ie, when updating is done, a new device object would be returnedW
  useEffect(() => {
    // TODO remove this two line, should not
    // disabled both edit modes.
    // this is a workaround, let user know that
    // his previous unsent edited info, is been fallback
    // to orgin values, due to device is updated
    if (deviceId !== -1 && cities.length > 0) {
      MOBILE_DEVICE_GET_API_REQUEST(deviceId, awsToken).then((deviceResult) => {
        setDevice(deviceResult);
      });
      getAdminClientList(awsToken).then((clientListData) => {
        setClientList([...clientListData]);
      });
    }
  }, [deviceId, cities, awsToken]);

  useEffect(() => {
    if (device.id != "" && cities.length > 0) {
      setIsEditGeneralInfo(false);
      setIsEditSchedule(false);
      setNewSchedule(convertScheduleStringToObject(device["schedule"]));
      getAdminClientDetails(awsToken, device.client_id).then((client) => {
        const generalInfoState = {
          city: device.client_id,
          image_capture_interval: device["image_capture_interval"],
          image_quality: device["image_quality"],
          invalidInput: false,
          on_wifi: device["on_wifi"],
          rotate: device["rotate"] ? "Y" : "N",
          tag: device["tag"],
        };
        setInitialGeneralInfoState(generalInfoState);
        dispatch({ type: ACTIONS.RESET, payload: generalInfoState });
      });
    }
  }, [device, cities, awsToken]);

  // check whether newSchedule is valid， ie: whether contains invalid date object
  useEffect(() => {
    let invalid = false;
    Object.values(newSchedule).forEach((scheduleObject) => {
      const { start, end } = scheduleObject;
      if (isNaN(start)) {
        invalid = true;
        return;
      }
      if (isNaN(end)) {
        invalid = true;
        return;
      }
    });
    setIsScheduleInvalid(invalid);
  }, [newSchedule]);

  // no device from server at all or not yet
  if (device.id === "") {
    return <LinearProgress />;
  }

  /**
   * data model for general info
   * @type {[DataGridDataModel]}
   */
  const generalInfoDataModel = Object.entries(
    getGeneralEditableMapKey(cities)
  ).map((pair) => {
    const [label, value] = pair;
    const { key, type, values, sortOptions, reducerAction } = value;
    return {
      key,
      label: key === "city" ? "Customer" : label,
      options: { options: values, idKey: "id", titleKey: "name" },
      sortOptions: sortOptions,
      type,
      onChange: (value) => dispatch({ type: reducerAction, payload: value }),
    };
  });

  return (
    <>
      <IrisLinearProgress isLoading={isSending} />
      <DataGrid
        title="General Information"
        isEditable={false}
        data={trimObject(device, Object.keys(FIXED_KEY_TITLE_MAP))}
        dataModel={[
          {
            key: "id",
            label: "Id",
            type: "input",
          },
          {
            key: "activate",
            label: "Activation Code",
            type: "input",
          },
          {
            key: "device_sn",
            label: "Device SN",
            type: "input",
          },
          {
            key: "IMEI",
            label: "IMEI",
            type: "input",
          },
          {
            key: "serial_number",
            label: "Serial Number",
            type: "input",
          },
          {
            key: "hexnode_id",
            label: "Hexnode Id",
            type: "input",
          },
        ]}
      />

      <DataGrid
        title="Patrol Information"
        isLoading={isSending}
        data={generalInfoState}
        dataModel={generalInfoDataModel}
        isEditable
        isEditMode={isEditGeneralInfo}
        setIsEditMode={setIsEditGeneralInfo}
        hasInvalidInput={generalInfoState.invalidInput}
        onSend={updateDeviceDetails("GENERAL_INFO")}
        onClear={() => {
          setIsEditGeneralInfo(false);
          dispatch({
            type: ACTIONS.RESET,
            payload: initialGeneralInfoState,
          });
        }}
      />

      <h3>
        Device Schedule
        <IconButrtons
          isLoading={isSending}
          invalidInput={isScheduleInvalid}
          condition={isEditSchedule}
          setter={setIsEditSchedule}
          onClear={() => {
            setIsEditSchedule(false);
            setNewSchedule(convertScheduleStringToObject(device.schedule));
          }}
          onSend={updateDeviceDetails("SCHEDULE")}
        />
      </h3>
      <Grid container spacing={4}>
        {Object.entries(newSchedule).map((pair, index) => {
          const [key, value] = pair;
          return (
            <Grid item key={index} sm="auto" xs={7}>
              <IrisTimePickers
                style={{ maxWidth: 300 }}
                startTime={value.start}
                endTime={value.end}
                disabled={!isEditSchedule}
                muiTextFieldProps={{
                  disabled: false,
                }}
                title={key}
                onChange={updateSchedule(key)}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

/**
 *
 * @param {object} props
 * @param {boolean} props.isLoading disable all icon buttons if true
 * @param {boolean} props.condition condition that the clear and send icon buttons should be shown
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setter callback function for the edit icon button, toggle the clear and send icons button to be shown
 * @param {()=>void} props.onClear callback function that when the clear button icon is clicked (reset editable values)
 * @param {()=>void} props.onSend callback function that when the send icon button is clicked (send request to notify update)
 * @param {boolean} props.invalidInput if true, the send button should be disabled
 */
const IconButrtons = (props) => {
  const { isLoading, condition, setter, onClear, onSend, invalidInput } = props;
  if (condition) {
    return (
      <>
        <IconButton
          disabled={isLoading}
          onClick={onClear}
          sx={{ color: "red" }}
        >
          <ClearIcon />
        </IconButton>
        <IconButton
          color="primary"
          onClick={onSend}
          disabled={invalidInput || isLoading}
        >
          <SendIcon />
        </IconButton>
      </>
    );
  }

  return (
    <IconButton disabled={isLoading} onClick={() => setter((prev) => !prev)}>
      <EditIcon />
    </IconButton>
  );
};
