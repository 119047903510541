import styles from "./DownloadPage.module.css";

import React, { useState, useEffect, useReducer, useContext } from "react";

import moment from "moment";
import momentTZ from "moment-timezone";

import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import SendIcon from "@mui/icons-material/Send";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { Tab } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";

import TimeInput from "components/downloadInput/timeInput/TimeInput";
import UploadFileAsChips from "components/uploadFile/UploadFileAsChips";

import { IRIS_GO_LOOKUP_REQUEST } from "utils/requests";
import { AuthContext } from "context/AuthContext";
import { getDownloadReport } from "api/download";
import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
import IrisMultiSelect from "UI/muiSelect/IrisMultiSelect";
import TabPanel from "components/mobile/TabPanel";

// tabs and tab css style
const useStyles = makeStyles((theme) => ({
  tabs: {
    color: "#757575",
    paddingBottom: 5,
    "& .MuiTabs-indicator": {
      height: 0,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#ffff",
      backgroundColor: theme.palette.primary.main,
    },
  },
  tab: {
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
}));

/** 3 different input form, "cities", "datapoint id", "datapoint id file" */
export const INPUTFORMS = {
  /** "cities" */
  cities: "cities",
  /** "datapoint id" */
  datapointId: "datapoint id",
  /** "datapoint id file" */
  datapointIdFile: "datapoint id file",
};

/** 3 different messages according to api call */
const MODAL_MESSAGES = {
  /** "No request is sent." */
  notSend: "No request is sent.",
  /** "Download request is sent. Please wait for a while." */
  sending: "Download request is sent. Please wait for a while.",
  /** "Some error occurred." */
  error: "Some error occurred.",
};

// key name for object props
const KEY_NAME_STRING_LABEL = "label";
const KEY_NAME_STRING_VALUE = "value";

// max number of data point id manually input allowed to submit, hard code
const MAX_DATAPOINT_ID_AMOUNT_MANUAL = 100;

// max number of data point id by uploaded json files allowed to submit, hard code
const MAX_DATAPOINT_ID_AMOUNT_UPLOAD = 100000;

/** download reducer actions */
const DownloadActions = {
  SET_START_TIME: "setStartTime",
  SET_END_TIME: "setEndTime",
  RESET_START_TIME: "resetStartHHmm",
  RESET_END_TIME: "resetEndHHmm",
  /** 3 different input form, "cities", "datapoint id", "datapoint id file" */
  SET_INPUT_FORM: "setInputForm",
  SET_CITY_ID: "setCityId",
  /** all cities obtained from api, prepared as options for select field */
  SET_CITY_OPTIONS: "setCityOptions",
  SET_DEVICE_ID: "setDeviceId",
  /** all devices obtained from api */
  SET_ALL_DEVICES: "setAllDevices",
  /** devices filtered from all devices, share the same city id with the selected city, prepared as options for select field */
  SET_DEVICE_OPTIONS: "setDeviceOptions",
  /** datapoint id input field set state */
  SET_DATAPOINT_ID_INPUT: "setDatapointIdInput",
  SET_DATAPOINT_ID_LIST: "setDatapointIdList",
  DELETE_DATAPOINT_ID: "deleteDatapointId",
  SET_DATAPOINT_ID_FILE_LIST: "setDatapointIdFileList",
  DELETE_DATAPOINT_ID_FILE: "deleteDatapointIdFile",
  SET_WITHLABEL: "setWithLabel",
  SET_EMAIL: "setEmail",
  SET_EMAIL_IS_VERIFIED: "setEmailIsVerified",
  SET_IS_READY: "setIsReady",
};

/**
 * down load reducer to handle dispatch
 * @param {object} action action including type and payload
 */
const downloadReducer = (downloadState, action) => {
  const { type, payload } = action;
  switch (type) {
    // update start time
    case DownloadActions.SET_START_TIME:
      return { ...downloadState, start: payload };
    // update end time
    case DownloadActions.SET_END_TIME:
      return { ...downloadState, end: payload };
    // reset start time to default hour, mimute, second and millisecond
    case DownloadActions.RESET_START_TIME:
      return {
        ...downloadState,
        start: new Date(new Date().setHours(0, 0, 0, 0)),
      };
    // reset end time to default hour, mimute, second and millisecond
    case DownloadActions.RESET_END_TIME:
      return {
        ...downloadState,
        end: new Date(new Date().setHours(23, 59, 0, 0)),
      };

    // update the input form, and empty all the other input fields
    case DownloadActions.SET_INPUT_FORM:
      switch (payload) {
        case INPUTFORMS.cities:
          return {
            ...downloadState,
            inputForm: payload,
            deviceID: [],
            datapointIdList: [],
            datapointIdFilesList: [],
          };
        case INPUTFORMS.datapointId:
          return {
            ...downloadState,
            inputForm: payload,
            cityID: "",
            deviceID: [],
            datapointIdFilesList: [],
          };
        case INPUTFORMS.datapointIdFile:
          return {
            ...downloadState,
            inputForm: payload,
            cityID: "",
            deviceID: [],
            datapointIdList: [],
          };
        default:
          return { ...downloadState, inputForm: payload };
      }
    // update city id
    case DownloadActions.SET_CITY_ID:
      return {
        ...downloadState,
        cityID: payload,
      };
    // update city options
    case DownloadActions.SET_CITY_OPTIONS:
      return {
        ...downloadState,
        cityOptions: payload,
      };
    // update device id
    case DownloadActions.SET_DEVICE_ID:
      return {
        ...downloadState,
        deviceID: payload,
      };
    // update all available device
    case DownloadActions.SET_ALL_DEVICES:
      return {
        ...downloadState,
        allDevices: payload,
      };
    // update device options
    case DownloadActions.SET_DEVICE_OPTIONS:
      return {
        ...downloadState,
        deviceOptions: payload,
      };
    // update input of data point id
    case DownloadActions.SET_DATAPOINT_ID_INPUT:
      return { ...downloadState, inputDatapointId: payload };
    // update list of data point id, using set to eliminate duplicate inputs
    case DownloadActions.SET_DATAPOINT_ID_LIST:
      const datapointIdSet = new Set([
        ...downloadState.datapointIdList,
        ...payload,
      ]);
      return {
        ...downloadState,
        inputDatapointId: "",
        datapointIdList: [...datapointIdSet],
      };
    // delete data point chip
    case DownloadActions.DELETE_DATAPOINT_ID:
      return {
        ...downloadState,
        datapointIdList: downloadState.datapointIdList.filter(
          (chip) => chip !== payload
        ),
      };
    // update list of data point id files
    case DownloadActions.SET_DATAPOINT_ID_FILE_LIST:
      return {
        ...downloadState,
        datapointIdFilesList: payload,
      };
    // delete data point id file
    case DownloadActions.DELETE_DATAPOINT_ID_FILE:
      return {
        ...downloadState,
        datapointIdFilesList: downloadState.datapointIdFilesList.filter(
          (file) => file.fileName !== payload.fileName
        ),
      };
    // upadte with label
    case DownloadActions.SET_WITHLABEL:
      return { ...downloadState, withLabel: payload };
    // update the input email and then verify
    case DownloadActions.SET_EMAIL:
      return {
        ...downloadState,
        inputEmail: payload,
      };
    // update whether the input email is ok
    case DownloadActions.SET_EMAIL_IS_VERIFIED:
      return { ...downloadState, emailVerified: payload };
    // check if ready to send request
    case DownloadActions.SET_IS_READY:
      return {
        ...downloadState,
        isReady: payload,
      };
    // return the current state
    default:
      return downloadState;
  }
};

const DownloadPage = () => {
  const classes = useStyles();

  /** intial state of download, including start time, end time, device id and city id */
  const initDownloadState = {
    start: new Date(new Date().setHours(0, 0, 0, 0)),
    end: new Date(new Date().setSeconds(0, 0)),
    inputForm: INPUTFORMS.datapointId,
    cityID: "",
    cityOptions: [],
    deviceID: [],
    allDevices: [],
    deviceOptions: [],
    inputDatapointId: "",
    datapointIdList: [],
    datapointIdFilesList: [],
    withLabel: "N/A",
    inputEmail: "",
    emailVerified: false,
    isReady: false,
  };

  // reducer constructor
  const [downloadState, dispatch] = useReducer(
    downloadReducer,
    initDownloadState
  );

  // context token to make api call, for cities options
  const { contextToken } = useContext(AuthContext);

  // set state to store the radio select value in datapoint id tab
  const [datapointIdRadioSelect, setDatapointIdRadioSelect] = useState(
    INPUTFORMS.datapointId
  );

  // set state to control the active tab, active tab indicates which input form is being used
  const [activeTabId, setActiveTabId] = useState(0);

  // number of current uploaded data point id
  const [currDatapointIdAmount, setCurrDatapointIdAmount] = useState(0);

  // handle switch tab
  const handleChangeTab = (e, newValue) => {
    setActiveTabId(newValue);
  };

  // 4 different state according to api call
  const [pageState, setPageState] = useState(SUBMIT_STATE.notSend);

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // modal content set state
  const [modalMessageContent, setModalMessageContent] = useState(
    MODAL_MESSAGES.notSend
  );

  // update email input and email verified
  const handleEmailInput = (inputEmail) => {
    dispatch({
      type: DownloadActions.SET_EMAIL,
      payload: inputEmail,
    });

    /** regex to match the string ends with "@irisradgroup.com"  */
    const emailPattern = /\S+(?=@irisradgroup.com$)/g;

    if (inputEmail.match(emailPattern) !== null) {
      // in case input more than one "@irisradgroup.com"
      if (inputEmail.match(emailPattern)[0].includes("@")) {
        dispatch({
          type: DownloadActions.SET_EMAIL_IS_VERIFIED,
          payload: false,
        });
      } else {
        dispatch({
          type: DownloadActions.SET_EMAIL_IS_VERIFIED,
          payload: true,
        });
      }
    } else {
      dispatch({ type: DownloadActions.SET_EMAIL_IS_VERIFIED, payload: false });
    }
  };

  // update list of data point id files, file name unique
  // sort the data point id file names in alphabetic order
  const handleDatapointIdFileList = (uploadFile) => {
    const reader = new FileReader();
    reader.readAsText(uploadFile, "UTF-8");

    reader.onload = (event) => {
      const jsonContent = JSON.parse(event.target.result);
      const newFile = {
        fileName: uploadFile.name,
        fileContent: jsonContent,
      };

      // check if the uploaded number of datapoint id is lower than the max number set
      if (
        jsonContent.length + currDatapointIdAmount <=
        MAX_DATAPOINT_ID_AMOUNT_UPLOAD
      ) {
        const matchFile = downloadState.datapointIdFilesList.find(
          (file) => file.fileName === newFile.fileName
        );
        if (matchFile === undefined) {
          const updatedFileList = [
            ...downloadState.datapointIdFilesList,
            newFile,
          ].sort((a, b) => {
            if (a.fileName.toLowerCase() < b.fileName.toLowerCase()) {
              return -1;
            } else {
              return 1;
            }
          });

          dispatch({
            type: DownloadActions.SET_DATAPOINT_ID_FILE_LIST,
            payload: updatedFileList,
          });
        } else {
          alert("Same file name exists.");
        }
      } else {
        alert(
          `Number of uploaded datapoint id has exceeded ${MAX_DATAPOINT_ID_AMOUNT_UPLOAD.toLocaleString()}.`
        );
      }
    };
  };

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // only when it's not sending request, the backdrop could be closed
    if (pageState !== SUBMIT_STATE.sending) {
      setOpenBackdrop(false);
      setPageState(SUBMIT_STATE.notSend);
      setModalMessageContent(MODAL_MESSAGES.notSend);
    }
  };

  // when pressing enter, add the input content to data point id list
  const handleDatapointIdKeyDown = (event) => {
    // when presssing enter
    if (event.keyCode === 13) {
      // when the input field is not empty
      if (downloadState.inputDatapointId.trim() !== "") {
        // data point id input as an array, with elements trimmed
        // filter the element as "", in case the input ends with ","
        // convert the id into number
        const temp = downloadState.inputDatapointId
          .split(",")
          .filter((element) => element !== "")
          .map((element) => Number(element.trim()));

        // datapoint id amount does not exceed max, dispatch to set state, otherwise show alert
        if (
          temp.length + downloadState.datapointIdList.length <=
          MAX_DATAPOINT_ID_AMOUNT_MANUAL
        ) {
          dispatch({
            type: DownloadActions.SET_DATAPOINT_ID_LIST,
            payload: temp,
          });
        } else {
          alert(
            `Number of uploaded datapoint id has exceeded ${MAX_DATAPOINT_ID_AMOUNT_MANUAL.toLocaleString()}.`
          );
        }
      }
    }
  };

  // get dynamic helper text for email input field
  const emailHelperText = () => {
    return (
      <span className={styles.helperText}>
        <span>Data downloaded will be sent to the input email.</span>
        {downloadState.inputEmail !== "" && (
          <span style={{ color: downloadState.emailVerified ? "" : "#c1151f" }}>
            must end with "irisradgroup.com"
          </span>
        )}
      </span>
    );
  };

  // get dynamic helper text for data point id input
  const datapointIdInputHelperText = () => {
    return (
      <span className={styles.helperText}>
        <span>Enter datapoint IDs, separated by commas.</span>
        <span>
          Max
          <span
            style={{ fontWeight: 900 }}
          >{` ${MAX_DATAPOINT_ID_AMOUNT_MANUAL.toLocaleString()} `}</span>
          pieces. Choose file-upload if more than that.
        </span>
        <span>
          <span
            style={{
              fontWeight: 900,
              color:
                downloadState.datapointIdList.length ===
                MAX_DATAPOINT_ID_AMOUNT_MANUAL
                  ? "#c1151f"
                  : "",
            }}
          >
            {`${
              downloadState.datapointIdList.length
            }/${MAX_DATAPOINT_ID_AMOUNT_MANUAL.toLocaleString()} `}
          </span>
          datapoint id is uploaded.
        </span>
        {downloadState.inputForm === INPUTFORMS.datapointId &&
          downloadState.datapointIdList.length === 0 && (
            <span style={{ color: "#c1151f" }}>
              At least 1 Datapoint Id is submitted.
            </span>
          )}
      </span>
    );
  };

  // helper text for upload datapoint id file
  const getDataPointIdFileHelperText = () => {
    return (
      <span className={`${styles.helperText} ${styles.uploadFileHelperText}`}>
        <span>
          Max number of datapoint id is
          <span
            style={{ fontWeight: 900 }}
          >{` ${MAX_DATAPOINT_ID_AMOUNT_UPLOAD.toLocaleString()}.`}</span>
        </span>
        <span>
          <span
            style={{
              fontWeight: 900,
              color:
                currDatapointIdAmount === MAX_DATAPOINT_ID_AMOUNT_UPLOAD
                  ? "#c1151f"
                  : "",
            }}
          >
            {`${currDatapointIdAmount}/${MAX_DATAPOINT_ID_AMOUNT_UPLOAD.toLocaleString()} `}
          </span>
          datapoint id is uploaded.
        </span>
        {downloadState.inputForm === INPUTFORMS.datapointIdFile &&
          currDatapointIdAmount === 0 && (
            <span style={{ color: "#c1151f" }}>
              At least 1 Datapoint Id is uploaded.
            </span>
          )}
      </span>
    );
  };

  /** time input fields, including both start and end */
  const timeInputFields = {
    start_from: {
      time: downloadState.start,
      update: (input) =>
        dispatch({ type: DownloadActions.SET_START_TIME, payload: input }),
      resetTime: () => dispatch({ type: DownloadActions.RESET_START_TIME }),
      dateLabel: "start date",
      timeLabel: "start time",
      tipsInfo: "The start time selected is",
    },
    end_before: {
      time: downloadState.end,
      update: (input) =>
        dispatch({ type: DownloadActions.SET_END_TIME, payload: input }),
      resetTime: () => dispatch({ type: DownloadActions.RESET_END_TIME }),
      dateLabel: "end date",
      timeLabel: "end time",
      tipsInfo: "The end time selected is",
    },
  };

  /** input fields accepting multiple manual input */
  const multiInputWithChipsFields = {
    "datapoint id": {
      [KEY_NAME_STRING_LABEL]: INPUTFORMS.datapointId,
      helperText: datapointIdInputHelperText(),
      inputValue: downloadState.inputDatapointId,
      chipsArray: downloadState.datapointIdList,
      // onInput method to restrict the input value to number only
      onInput: (e) => (e.target.value = e.target.value.replace(/[^0-9,]/g, "")),
      updateInput: (e) =>
        dispatch({
          type: DownloadActions.SET_DATAPOINT_ID_INPUT,
          payload: e.target.value,
        }),
      handleKeyDown: handleDatapointIdKeyDown,
      handleDeleteChip: (chip) =>
        dispatch({ type: DownloadActions.DELETE_DATAPOINT_ID, payload: chip }),
    },
  };

  /** upload field, including upload button and upload files chips */
  const uploadFileFields = {
    "datapoint id": {
      [KEY_NAME_STRING_LABEL]: INPUTFORMS.datapointIdFile,
      acceptFile: "application/JSON",
      buttonContent: "upload datapoint id file",
      helperText: getDataPointIdFileHelperText(),
      FilesList: downloadState.datapointIdFilesList,
      updateFileList: (file) => handleDatapointIdFileList(file),
      deleteFile: (file) =>
        dispatch({
          type: DownloadActions.DELETE_DATAPOINT_ID_FILE,
          payload: file,
        }),
    },
  };

  /** input fields accepting single input */
  const singleSelectInputFields = {
    "with label": {
      [KEY_NAME_STRING_LABEL]: "With Label",
      [KEY_NAME_STRING_VALUE]: downloadState.withLabel,
      update: (e) =>
        dispatch({
          type: DownloadActions.SET_WITHLABEL,
          payload: e.target.value,
        }),
      options: [
        { [KEY_NAME_STRING_LABEL]: "N/A", [KEY_NAME_STRING_VALUE]: "N/A" },
        { [KEY_NAME_STRING_LABEL]: "Yes", [KEY_NAME_STRING_VALUE]: true },
        { [KEY_NAME_STRING_LABEL]: "No", [KEY_NAME_STRING_VALUE]: false },
      ],
      optionNameKey: [KEY_NAME_STRING_LABEL],
      optionValueKey: [KEY_NAME_STRING_VALUE],
    },
  };

  // gather download data info and then call api to submit download request
  const handleDownload = () => {
    setOpenBackdrop(true);
    setPageState(SUBMIT_STATE.sending);
    setModalMessageContent(MODAL_MESSAGES.sending);

    // variable to store selected cities
    const cityIdList = [];
    // variable to store selected devices
    const deviceIdList = [];
    // variable to store datapoint id, from both input and upload files
    let datapointIdList = [];
    // selected city timezone, to parse the input time into utc, default value America/Toronto
    let selectedCityTimezone = "America/Toronto";
    // format the start time and end time into string as input to get utc time
    const startTimeStringTemp = moment(downloadState.start).format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    );
    const endTimeStringTemp = moment(downloadState.end).format(
      "YYYY-MM-DDTHH:mm:ss.SSS"
    );
    // variable to store start time & end time
    let startTimeOutput = "";
    let endTimeOutput = "";

    // read and process respective data, according to selected field
    switch (downloadState.inputForm) {
      case INPUTFORMS.cities:
        // get the selected city data object from city options
        const currCity = downloadState.cityOptions.find(
          (city) => Number(city.id) === Number(downloadState.cityID)
        );

        selectedCityTimezone = currCity.timezone;

        // convert the start time & end time to utc time according to selected city's timezone
        startTimeOutput = momentTZ
          .tz(startTimeStringTemp, selectedCityTimezone)
          .utc()
          .toISOString();
        endTimeOutput = momentTZ
          .tz(endTimeStringTemp, selectedCityTimezone)
          .utc()
          .add(59, "seconds") // add 59 seconds
          .add(999, "milliseconds") // add 999 milli seconds
          .toISOString();

        // city id array, single element in form of number
        cityIdList.push(Number(downloadState.cityID));

        // when there is input device, build the device id list, array of number
        if (downloadState.deviceID.length > 0) {
          downloadState.deviceID.forEach((device) => {
            deviceIdList.push(Number(device.id));
          });
          // city id is no longer useful when fetching data from api
          cityIdList.pop();
        }
        break;
      case INPUTFORMS.datapointId:
        datapointIdList = downloadState.datapointIdList;
        break;
      case INPUTFORMS.datapointIdFile:
        // convert the datapoint id to number
        downloadState.datapointIdFilesList.forEach((file) => {
          const tempNum = file.fileContent.map((Id) => Number(Id.id));
          datapointIdList = [...datapointIdList, ...tempNum];
        });
        break;
      default:
        break;
    }

    // prepare data for download request
    const downloadContent = {
      // timezone: selectedCityTimezone,
      startTime: startTimeOutput,
      endTime: endTimeOutput,
      inputForm: downloadState.inputForm,
      deviceId: deviceIdList,
      cityId: cityIdList,
      datapointId: datapointIdList,
      withLabel:
        downloadState.withLabel === "N/A" ? undefined : downloadState.withLabel,
      inputEmail: downloadState.inputEmail,
      // datapointIdFilesList: downloadState.datapointIdFilesList,
    };

    getDownloadReport(contextToken, downloadContent)
      .then((result) => {
        const temp = JSON.parse(result);
        if (temp.success) {
          setOpenBackdrop(true);
          setPageState(SUBMIT_STATE.ok);
          setModalMessageContent(temp.result.message);
        } else {
          setOpenBackdrop(true);
          setPageState(SUBMIT_STATE.error);
          setModalMessageContent(MODAL_MESSAGES.fail);
        }
      })
      .catch((err) => {
        setOpenBackdrop(true);
        setPageState(SUBMIT_STATE.error);
        setModalMessageContent(MODAL_MESSAGES.fail);

        console.log(err);
      });
  };

  /**
   * @typedef DownloadPageTabs
   * @property {number} tabId id of each tab
   * @property {string} tabLabel label name of the tab
   * @property {React.ReactElement} tabPanel jsx, representing the input fields diplaying when the relative tab is activated
   */
  /** @type {Array.<DownloadPageTabs>} */
  const inputByTab = [
    {
      tabId: 0,
      tabLabel: "Datapoint Id",
      tabPanel: (
        <div>
          <RadioGroup
            defaultValue={INPUTFORMS.datapointId}
            name="radio-buttons-group"
          >
            {Object.values(multiInputWithChipsFields).map((field) => (
              <div
                className={styles.multiInput}
                key={field[KEY_NAME_STRING_LABEL]}
              >
                <FormControlLabel
                  sx={{ marginTop: "0.5rem" }}
                  value={field[KEY_NAME_STRING_LABEL]}
                  label=""
                  control={<Radio />}
                  onChange={(e) => {
                    dispatch({
                      type: DownloadActions.SET_INPUT_FORM,
                      payload: e.target.value,
                    });
                    setDatapointIdRadioSelect(e.target.value);
                  }}
                />
                <div className={styles.multiInputTextfield}>
                  <TextField
                    id={field[KEY_NAME_STRING_LABEL]}
                    label={field[KEY_NAME_STRING_LABEL]}
                    variant="standard"
                    disabled={
                      downloadState.inputForm !== field[KEY_NAME_STRING_LABEL]
                    }
                    helperText={field["helperText"]}
                    onChange={field["updateInput"]}
                    onInput={field["onInput"]}
                    onKeyDown={field["handleKeyDown"]}
                    value={field["inputValue"]}
                  />
                </div>
                <div className={styles.multiInputChips}>
                  {field["chipsArray"].map((chip, index) => (
                    <div className={styles.multiInputPerChip} key={index}>
                      <Chip
                        label={chip}
                        onDelete={() => field["handleDeleteChip"](chip)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}

            {Object.values(uploadFileFields).map((field, index) => (
              <div className={styles.fileUpload} key={index}>
                <FormControlLabel
                  value={field[KEY_NAME_STRING_LABEL]}
                  control={<Radio />}
                  label=""
                  onChange={(e) => {
                    dispatch({
                      type: DownloadActions.SET_INPUT_FORM,
                      payload: e.target.value,
                    });
                    setDatapointIdRadioSelect(e.target.value);
                  }}
                />
                <UploadFileAsChips
                  acceptFile={field["acceptFile"]}
                  buttonContent={field["buttonContent"]}
                  helperText={field["helperText"]}
                  FilesList={field["FilesList"]}
                  updateFileList={field["updateFileList"]}
                  deleteFile={field["deleteFile"]}
                  isDisabled={
                    downloadState.inputForm !== field[KEY_NAME_STRING_LABEL]
                  }
                />
              </div>
            ))}
          </RadioGroup>
        </div>
      ),
    },
    {
      tabId: 1,
      tabLabel: "Cities And Devices",
      tabPanel: (
        <div>
          {Object.values(timeInputFields).map((field, index) => (
            <TimeInput
              time={field["time"]}
              key={index}
              handleChange={field["update"]}
              resetTime={field["resetTime"]}
              dateLabel={field["dateLabel"]}
              timeLabel={field["timeLabel"]}
              tipsInfo={field["tipsInfo"]}
            />
          ))}
          {/* helpter text in case input start time is after input end time */}
          {moment(downloadState.start).isAfter(downloadState.end) && (
            <span className={styles.timeHelperText}>
              Start time must be before end time.
            </span>
          )}

          <div className={styles.selectField}>
            {/* city input by single select field */}
            <div className={styles.singleSelectInput}>
              <FormControl fullWidth>
                <InputLabel>City</InputLabel>
                <Select
                  defaultValue={""}
                  value={downloadState.cityID}
                  label="City"
                  onChange={(e) => {
                    dispatch({
                      type: DownloadActions.SET_CITY_ID,
                      payload: e.target.value,
                    });
                    dispatch({
                      type: DownloadActions.SET_DEVICE_ID,
                      payload: [],
                    });
                  }}
                >
                  {downloadState.cityOptions.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* devices input by multi select field */}
            <IrisMultiSelect
              options={downloadState.deviceOptions}
              value={downloadState.deviceID}
              keyName="tag"
              labelName="Device (optional)"
              updateSelectedOptions={(updateDeviceList) =>
                dispatch({
                  type: DownloadActions.SET_DEVICE_ID,
                  payload: updateDeviceList,
                })
              }
            />
          </div>

          {/* single select input fields render below */}
          <div className={styles.selectField}>
            {Object.values(singleSelectInputFields).map((field) => (
              <div
                className={styles.singleSelectInput}
                key={field[KEY_NAME_STRING_LABEL]}
              >
                <FormControl fullWidth>
                  <InputLabel>{field[KEY_NAME_STRING_LABEL]}</InputLabel>
                  <Select
                    value={field[KEY_NAME_STRING_VALUE]}
                    label={field[KEY_NAME_STRING_LABEL]}
                    onChange={field["update"]}
                  >
                    {field["options"].map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option[field.optionValueKey]}
                      >
                        {option[field.optionNameKey]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ))}
          </div>
        </div>
      ),
    },
  ];

  // update the number of uploaded data point id
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      let num = 0;
      if (downloadState.datapointIdFilesList.length > 0) {
        downloadState.datapointIdFilesList.forEach(
          (file) => (num += file.fileContent.length)
        );
      }
      setCurrDatapointIdAmount(num);
    }
    return () => (isSubscribed = false);
  }, [downloadState.datapointIdFilesList]);

  // get options and initial value for multi-select field
  useEffect(() => {
    let isSubscribed = true;
    // active tab id indicate which input form is being used
    if (activeTabId === 0) {
      dispatch({
        type: DownloadActions.SET_INPUT_FORM,
        payload: datapointIdRadioSelect,
      });
    } else if (activeTabId === 1) {
      dispatch({
        type: DownloadActions.SET_INPUT_FORM,
        payload: INPUTFORMS.cities,
      });

      IRIS_GO_LOOKUP_REQUEST(contextToken)
        .then((result) => {
          // set state of the cities options with "city" property of fetch data
          if (isSubscribed) {
            const cityList = [];
            Object.entries(result["city"]).forEach((i) => {
              cityList.push({ id: i[0], ...i[1] });
            });

            // to arrange the cities list by "name" property, in alphabetic order
            if (cityList.length > 0) {
              cityList.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                  return -1;
                } else {
                  return 1;
                }
              });
            }

            // set city options
            dispatch({
              type: DownloadActions.SET_CITY_OPTIONS,
              payload: cityList,
            });
            // since default selected field is city, set the default value for city
            dispatch({
              type: DownloadActions.SET_CITY_ID,
              payload: cityList[0].id,
            });
          }

          // set state of the devices options with "device_info" property of fetch data
          if (isSubscribed) {
            const deviceList = [];
            Object.entries(result["device_info"]).forEach((i) => {
              deviceList.push({ id: i[0], ...i[1] });
            });

            // to arrange the devices list by "tag" property, in alphabetic order
            if (deviceList.length > 0) {
              deviceList.sort((a, b) => {
                if (a.tag.toLowerCase() < b.tag.toLowerCase()) {
                  return -1;
                } else {
                  return 1;
                }
              });
            }

            // filter devices whose name is "Disenrolled Device"
            const deviceListTemp = deviceList.filter(
              (device) => device.tag !== "Disenrolled Device"
            );

            dispatch({
              type: DownloadActions.SET_ALL_DEVICES,
              payload: deviceListTemp,
            });
          }
        })
        .catch((err) => console.log(err));
    }
    return () => (isSubscribed = false);
  }, [activeTabId, contextToken]);

  // check if isReady to send download request
  useEffect(() => {
    // flag return to set reducer state of isReady
    let flag = true;

    // input field cannot be empty
    //  0, stands for datapoint id input
    if (activeTabId === 0) {
      // selected input field cannot be empty
      switch (downloadState.inputForm) {
        case INPUTFORMS.datapointId:
          if (downloadState.datapointIdList.length === 0) {
            flag = false;
          }
          break;
        case INPUTFORMS.datapointIdFile:
          if (downloadState.datapointIdFilesList.length === 0) {
            flag = false;
          }
          break;
        default:
          break;
      }
    }
    //  1, stands for city and device id input
    else if (activeTabId === 1) {
      // city id should not be empty
      if (downloadState.cityID === "") {
        flag = false;
      }
      // start time should be before end time
      if (moment(downloadState.start).isAfter(downloadState.end)) {
        flag = false;
      }
    }

    // email verified
    if (!downloadState.emailVerified) {
      flag = false;
    }

    dispatch({ type: DownloadActions.SET_IS_READY, payload: flag });
  }, [
    downloadState.cityID,
    downloadState.deviceID,
    downloadState.datapointIdList,
    downloadState.datapointIdFilesList,
    downloadState.start,
    downloadState.end,
    downloadState.emailVerified,
    downloadState.inputForm,
    activeTabId,
  ]);

  // filter device options when updating city according to city id
  useEffect(() => {
    if (downloadState.allDevices.length > 0) {
      // filter device options according to the default selected city
      const deviceOptionsTemp = downloadState.allDevices.filter(
        (device) => device.city.id === Number(downloadState.cityID)
      );

      dispatch({
        type: DownloadActions.SET_DEVICE_OPTIONS,
        payload: deviceOptionsTemp,
      });
    }
  }, [downloadState.allDevices, downloadState.cityID]);

  return (
    <div className={styles.downloadPage}>
      <div className={styles.downloadChild}>
        <TextField
          className={styles.verifyEmailInput}
          id="email"
          label="email"
          variant="standard"
          helperText={emailHelperText()}
          type="email"
          onInput={(e) =>
            (e.target.value = e.target.value.replace(/[^0-9a-zA-Z@.]/g, ""))
          }
          onChange={(e) => handleEmailInput(e.target.value)}
          value={downloadState.inputEmail}
        />
      </div>

      <div className={styles.downloadChild}>
        <div className={styles.tabs}>
          <Tabs
            className={classes.tabs}
            value={activeTabId}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: { transition: "none" },
            }}
          >
            {inputByTab.map((tab) => (
              <Tab
                key={tab.tabId}
                className={classes.tab}
                label={tab.tabLabel}
              />
            ))}
          </Tabs>
        </div>
        <div className={styles.tabPanel}>
          {inputByTab.map((tab) => (
            <TabPanel value={activeTabId} index={tab.tabId} key={tab.tabId}>
              {tab.tabPanel}
            </TabPanel>
          ))}
        </div>
      </div>

      <div className={styles.buttons}>
        <div>
          <Button
            variant="outlined"
            startIcon={
              pageState === SUBMIT_STATE.sending ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                <SendIcon />
              )
            }
            onClick={handleDownload}
            disabled={!downloadState.isReady}
          >
            {pageState === SUBMIT_STATE.sending ? "sending..." : "send request"}
          </Button>
        </div>
      </div>

      <SubmitBackdrop
        open={openBackdrop}
        onClick={handleClickBackdrop}
        submitState={pageState}
        modalMessage={modalMessageContent}
      />
    </div>
  );
};

export default DownloadPage;
