import React from "react";
import DashBoard from "pages/Dashboard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DownloadIcon from "@mui/icons-material/Download";
import DownloadPage from "pages/downloadPage/DownloadPage";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LabelTypesTable from "pages/labelTypePage/LabelTypesFinder";
import PermDeviceInformationIcon from "@mui/icons-material/PermDeviceInformation";
import MobileDevices from "pages/MobileDevices";
import RegisterDevice from "pages/RegisterDevice";
import DefectReports from "pages/DefectReports";
import EmailIcon from "@mui/icons-material/Email";
import ReportIcon from "@mui/icons-material/Report";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import MobileDeviceInfo from "pages/MobileDevice/MobileDeviceInfo";
import Clients from "pages/clientsUsersGroups/clients/Clients";
import Users from "pages/clientsUsersGroups/users/Users";
import ClientDetails from "pages/clientsUsersGroups/clients/clientDetailsLoader/ClientDetailsLoader";
import UserDetails from "pages/clientsUsersGroups/users/userDetails/UserDetails";
import GroupList from "pages/clientsUsersGroups/groups/GroupList";
import GroupDetails from "pages/clientsUsersGroups/groups/groupDetails/GroupDetails";
import CreateUser from "pages/clientsUsersGroups/users/createUser/CreateUser";
import ResetUserPassword from "pages/clientsUsersGroups/users/resetPassword/ResetUserPassword";
import CreateGroup from "pages/clientsUsersGroups/groups/createGroup/CreateGroup";
import CreateClient from "pages/clientsUsersGroups/clients/createClient/CreateClient";
import Notifications from "pages/clientsUsersGroups/notifications/notifications";

/**
 * when adding a new route if it has side bar field
 * step1, add side bar field name to ROUTESDATA_TAB_NAME
 * step2, add side bar group name to ROUTESDATA_SIDEBAR_GROUP_NAME
 * step3, add the new route to routesData, order is important
 *
 * when adding a new route if it doesn't has side bar field
 * step1, find the comment dividing line of "routes accessible on side bar ends" in routesData, add to it after the dividing line
 */

/**
 * @typedef RoutesDataTabName
 * @property {string} DASHBOARD "Dashboard"
 * @property {string} CLIENTS "Clients"
 * @property {string} USERS "Users"
 * @property {string} GROUPS "Groups"
 * @property {string} DOWNLOAD_DATA "Download Data"
 * @property {string} LABEL_TYPES "Label Types"
 * @property {string} MOBILE_DEVICES "Mobile Devices"
 * @property {string} REGISTER_DEVICES "Register Device"
 * @property {string} DEFECT_REPORTS "Defect Reports"
 * @property {string} POST_OFFICE "Post Office"
 * @property {string} STATIC_DEVICES "Static Devices"
 * @property {string} INCIDENT_REPORTS "Incident Reports"
 * @property {string} USAGE_REPORTS "Usage Reports"
 */
/** @type {RoutesDataTabName} tab names for side bar, used for drawers and highlighted tabs */
const ROUTESDATA_TAB_NAME = {
  DASHBOARD: "Dashboard",
  CLIENTS: "Customers",
  USERS: "Users",
  GROUPS: "Groups",
  NOTIFICATIONS: "Notifications",
  DOWNLOAD_DATA: "Download Data",
  LABEL_TYPES: "Label Types",
  MOBILE_DEVICES: "Mobile Devices",
  REGISTER_DEVICES: "Register Devices",
  DEFECT_REPORTS: "Defect Reports",
  POST_OFFICE: "Post Office",
  STATIC_DEVICES: "Static Devices",
  INCIDENT_REPORTS: "Incident Reports",
  USAGE_REPORTS: "Usage Reports",
};

/** @type {RoutesDataTabName} */
const ROUTESDATA_SIDEBAR_GROUP_NAME = {
  DASHBOARD: "Dashboard",
  CLIENTS_USERS: "Clients and Users",
  NOTIFICATIONS: "Notifications",
  DOWNLOAD_DATA: "Download Data",
  LABEL_TYPES: "Label Types",
  MOBILE_DEVICES: "Mobile Devices",
  POST_OFFICE: "Post Office",
  STATIC_DEVICES: "Static Devices",
  USAGE_REPORTS: "Usage Reports",
};

/**
 * @typedef RouteData
 * @property {string} path path for this route
 * @property {boolean} showOnSidebar indicate whether this route has a correspond tab on side bar
 * @property {string} sidebarTabName tab name showing on the side bar
 * @property {string} highlightOpenTabName indicate for present route which tab will be highlighted when the side bar is open
 * @property {string} highlightCloseTabName indicate for present route which tab will be highlighted when the side bar is close
 * @property {React.ReactElement | null} sidebarIcon indicate the icon used on the sidebar, value must be either a React component or null
 * @property {string} sidebarGroup make sure the tabs sharing same group stick together, different groups' tabs will be separated by devider when the side bar is open
 * @property {Component} component component for this route
 */

/** @type {Array.<RouteData>} routes in array, sidebar indicates if the route shows on sidebar */
const routesData = [
  {
    path: "/",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.DASHBOARD,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.DASHBOARD,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.DASHBOARD,
    sidebarIcon: <DashboardIcon />,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.DASHBOARD,
    component: DashBoard,
  },
  {
    path: "/customers",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    sidebarIcon: <GroupIcon />,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.CLIENTS,
    component: Clients,
  },
  {
    path: "/users",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.USERS,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.USERS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    sidebarIcon: null,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.CLIENTS,
    component: Users,
  },
  {
    path: "/groups",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.GROUPS,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.GROUPS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    sidebarIcon: null,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.CLIENTS,
    component: GroupList,
  },
  {
    path: "/notifications",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.NOTIFICATIONS,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.NOTIFICATIONS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.NOTIFICATIONS,
    sidebarIcon: <NotificationsActiveIcon />,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.NOTIFICATIONS,
    component: Notifications,
  },
  {
    path: "/download",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.DOWNLOAD_DATA,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.DOWNLOAD_DATA,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.DOWNLOAD_DATA,
    sidebarIcon: <DownloadIcon />,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.DOWNLOAD_DATA,
    component: DownloadPage,
  },
  {
    path: "/labels",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.LABEL_TYPES,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.LABEL_TYPES,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.LABEL_TYPES,
    sidebarIcon: <BookmarkIcon />,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.LABEL_TYPES,
    component: LabelTypesTable,
  },
  {
    path: "/mobile-device",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.MOBILE_DEVICES,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.MOBILE_DEVICES,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.MOBILE_DEVICES,
    sidebarIcon: <PermDeviceInformationIcon />,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.MOBILE_DEVICES,
    component: MobileDevices,
  },
  {
    path: "/mobile/register",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.REGISTER_DEVICES,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.REGISTER_DEVICES,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.MOBILE_DEVICES,
    sidebarIcon: null,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.MOBILE_DEVICES,
    component: RegisterDevice,
  },
  {
    path: "/mobile/report",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.DEFECT_REPORTS,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.DEFECT_REPORTS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.MOBILE_DEVICES,
    sidebarIcon: null,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.MOBILE_DEVICES,
    component: DefectReports,
  },
  // postoffice does not have exact because we have an embedded page within history
  {
    path: "/postoffice",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.POST_OFFICE,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.POST_OFFICE,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.POST_OFFICE,
    sidebarIcon: <EmailIcon />,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.POST_OFFICE,
    // component: PostOffice,
    component: () => <></>,
  },
  {
    path: "/static",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.STATIC_DEVICES,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.STATIC_DEVICES,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.STATIC_DEVICES,
    sidebarIcon: <ReportIcon />,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.STATIC_DEVICES,
    // component: StaticDevices,
    component: () => <></>,
  },
  {
    path: "/static/report",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.INCIDENT_REPORTS,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.INCIDENT_REPORTS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.STATIC_DEVICES,
    sidebarIcon: null,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.STATIC_DEVICES,
    // component: IncidentReports,
    component: () => <></>,
  },
  {
    path: "/mobile/usage",
    showOnSidebar: true,
    sidebarTabName: ROUTESDATA_TAB_NAME.USAGE_REPORTS,
    highlightOpenTabName: ROUTESDATA_TAB_NAME.USAGE_REPORTS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.USAGE_REPORTS,
    sidebarIcon: <DataUsageIcon />,
    sidebarGroup: ROUTESDATA_SIDEBAR_GROUP_NAME.USAGE_REPORTS,
    // component: MobileUsage,
    component: () => <></>,
  },

  // ------------------ routes accessible on side bar ends ------------------

  {
    path: "/mobile-device/:id",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.MOBILE_DEVICES,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.MOBILE_DEVICES,
    component: MobileDeviceInfo,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },
  {
    path: "/static/usage",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.USAGE_REPORTS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.USAGE_REPORTS,
    component: () => <></>,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },
  {
    path: "/postoffice/history",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.POST_OFFICE,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.POST_OFFICE,
    component: () => <></>,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },
  {
    path: "/customer-details/:id",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    component: ClientDetails,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },
  {
    path: "/user-details/:username",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.USERS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    component: UserDetails,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },
  {
    path: "/group-details/:id",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.GROUPS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    component: GroupDetails,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },
  {
    path: "/user/signup",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.USERS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    component: CreateUser,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },
  {
    path: "/user/reset-password",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.USERS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    component: ResetUserPassword,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },
  {
    path: "/customer/create-new-customer",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    component: CreateClient,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },
  {
    path: "/group/create-new-group",
    highlightOpenTabName: ROUTESDATA_TAB_NAME.GROUPS,
    highlightCloseTabName: ROUTESDATA_TAB_NAME.CLIENTS,
    component: CreateGroup,
    showOnSidebar: false,
    sidebarTabName: "",
    sidebarIcon: null,
    sidebarGroup: "",
  },

  // "/user/signup",
  // "/user/reset-password",
  // "/user-details/:id",
  // "/user/city",
  // "/user/city-details/:id",
];

/** routes paths in array, from routesData above  */
const routesPaths = routesData.map((route) => route.path);

export { routesData, routesPaths };
