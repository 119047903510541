import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
// import MaterialTable from "material-table";
import { USER_LOOKUP_REQUEST } from "../utils/requests";
import { AuthContext } from "../context/AuthContext";
import IrisTable from "UI/muiTable/IrisTable";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  dotContainer: {
    position: "relative",
  },
  activeDot: {
    position: "absolute",
    top: "50%;",
    transform: "translateY(-50%)",
    color: theme.palette.success.main,
    fontSize: "15px",
  },
  inactiveDot: {
    position: "absolute",
    top: "50%;",
    transform: "translateY(-50%)",
    color: theme.palette.error.main,
    fontSize: "15px",
  },
}));

export default function MobileDevices() {
  const classes = useStyles();
  const { contextToken } = useContext(AuthContext);
  const [deviceList, setDeviceList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isSubscribed = true;
    USER_LOOKUP_REQUEST(contextToken)
      .then((result) => {
        if (isSubscribed) {
          const useList = result.map((r) => {
            const { id, username, profile } = r;
            return {
              id,
              username,
              city: profile?.city?.name || "",
            };
          });
          setDeviceList(useList);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (isSubscribed = false);
  }, [contextToken, classes]);

  return (
    <Box sx={{ width: { md: 600, lg: 700 } }}>
      <IrisTable
        isLoading={isLoading}
        columns={[
          { fieldName: "id", headTitle: "ID", width: 100 },
          { fieldName: "username", headTitle: "User Name", width: 300 },
          {
            fieldName: "city",
            headTitle: "City",
            width: 300,
          },
        ]}
        data={deviceList}
        idField="id"
      />
    </Box>
  );
}
