import {
  IRISGO_LOOKUP_URL,
  IRISGO_FETCH_URL,
  IRISGO_DEVICE_USAGE_URL,
  SENTRY_GATEWAY_LOOKUP_URL,
  IRISGO_NEW_DEVICE_URL,
  IRISGO_DEVICE_SNAPSHOT,
  IRISGO_DEVICE_TAKE_SNAPSHOT,
  OPEN_WEATHER_CURRENT_URL,
  IRISGO_REBOOT_URL,
  IRISGO_GET_LABEL_URL,
  IRISGO_NEW_DEVICE_API_URL,
  IRISGO_GET_DEVICE_API_URL,
  IRISGO_UPDATE_DEVICE_API_URL,
  DATA_SERVER_UPLOAD_SHAPEFILE_URL,
  GET_DEVICE_STATUS,
  IRISGO_AUTH_NOTIFICATION_GROUP_LIST__URL,
  IRISGO_AUTH_COGNITO_CUSTOMER_CONTACT_LIST__URL,
  IRISGO_AUTH_NOTIFICATION_GROUP_UPDATE_MEMBERS__URL,
  IRISGO_AUTH_NOTIFICATION_FILTERS_LIST__URL,
  IRISGO_AUTH_CREATE_NOTIFICATION_GROUP__URL,
  IRISGO_AUTH_DELETE_NOTIFICATION_GROUP__URL,
  IRISGO_AUTH_LIST_CSAM__URL,
  IRISGO_AUTH_UPDATE_CSAM_CLIENTS__URL,
  IRISGO_AUTH_UPDATE_NOTIFICATION_GROUP__URL,
  IRISGO_AUTH_SEND_NOTIFICATION__URL,
} from "./env/urls";

export const USER_LOOKUP_REQUEST = (token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_LOOKUP_URL);
    url.searchParams.append("user", true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            if (data.success === true) {
              var users = data.result.user;
              resolve(users);
            } else {
              reject(new Error("Error in getting user group data"));
            }
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_DEVICE_NEW_DEVICE_REQUEST = (newDevice, token) => {
  return new Promise((resolve, reject) => {
    fetch(IRISGO_NEW_DEVICE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify(newDevice),
    })
      .then((result) => {
        if (result.status === 200) {
          result.json().then((data) => {
            resolve(data.result.activate);
          });
        } else {
          console.log(result);
          reject("iPort New Device Creation Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// New API request
export const MOBILE_DEVICE_NEW_DEVICE_API_REQUEST = (newDevice, token) => {
  return new Promise((resolve, reject) => {
    fetch(IRISGO_NEW_DEVICE_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newDevice),
    })
      .then((result) => {
        if (result.status === 200) {
          result.json().then((data) => {
            resolve(data.result);
          });
        } else {
          console.log(result);
          reject("Device Service: New Device Creation Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// New API request
export const MOBILE_DEVICE_GET_API_REQUEST = (deviceID, token) => {
  const url = new URL(IRISGO_GET_DEVICE_API_URL + deviceID);
  url.searchParams.append("client_details", true);
  return new Promise((resolve, reject) => {
    fetch(url.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          result.json().then((data) => {
            resolve(data);
          });
        } else {
          reject("Device Service: Get Device Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// New API request
export const MOBILE_DEVICE_UPDATE_API_REQUEST = (deviceData, token) => {
  return new Promise((resolve, reject) => {
    fetch(IRISGO_UPDATE_DEVICE_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(deviceData),
    })
      .then((result) => {
        if (result.status === 200) {
          result.json().then((data) => {
            resolve(data);
          });
        } else {
          reject("Device Service: Update Device Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_REPORT_LOOKUP_REQUEST = (token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_LOOKUP_URL);
    url.searchParams.append("city", true);
    url.searchParams.append("damage_type", true);
    url.searchParams.append("label_type", true);
    url.searchParams.append("resolution_type", true);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            if (data.success === true) {
              // temp filter
              data.result.damage_type = data.result.damage_type.filter(
                (damage) => damage.enabled !== "N"
              );
              resolve(data.result);
            } else {
              reject(new Error("Error in city and damage_type lookup data"));
            }
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_REPORT_DATAPOINT_REQUEST = (date, city, token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_FETCH_URL);
    url.searchParams.append("date", date);
    if (city !== 0) url.searchParams.append("city", city);
    url.searchParams.append("with_defect", true);
    url.searchParams.append("page_size", 100000);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve(data);
          });
        } else {
          reject(new Error("ACCESS DENIED"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_REPORT_DATAPOINT_NEW_REQUEST = (date, city, token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_FETCH_URL);
    url.searchParams.append("date", date);
    if (city !== 0) url.searchParams.append("city", city);
    url.searchParams.append("with_label", true);
    url.searchParams.append("page_size", 100000);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const DEFECT_LABEL_REQUEST = (id, token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_GET_LABEL_URL);
    url.searchParams.append("datapoint", id);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            resolve(data.results);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * get all devices from iris-device server
 * @param {string} token
 * @returns {Promise<Object []>}
 */
export const MOBILE_DEVICE_LIST_V3_REQUEST = (token) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    let error;
    const response = await fetch(GET_DEVICE_STATUS, options).catch((e) => {
      error = e;
    });
    if (error) {
      // caught error due to fetch API
      reject(error);
    } else if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const { status } = response;
      const message = await response.text();
      const errorMessage = `Error ${status}: ${message}`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};

/**
 * Update CSAMS for client from iris-auth server
 * @param {string} token
 * @param {object} groupInfo
 * @returns {Promise<Object>}
 */
export const updateCSAMsForClient = (token, client, CSAMs) => {
  return new Promise((resolve, reject) => {
    return fetch(IRISGO_AUTH_UPDATE_CSAM_CLIENTS__URL + client, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(CSAMs),
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.json());
        } else {
          reject(new Error("Could not update CSAMs"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
/**
 * get all CSAMs  from iris-auth server
 * @param {string} token
 * @returns {Promise<Object []>}
 */
export const getCSAMs = (token, clientID = null) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const url = new URL(IRISGO_AUTH_LIST_CSAM__URL);
    if (clientID !== null) url.searchParams.append("clientID", clientID);

    fetch(url, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * get all Notificiation groups from iris-auth server
 * @param {string} token
 * @returns {Promise<Object []>}
 */
export const getNotificationGroups = (token, type = null) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const url = new URL(IRISGO_AUTH_NOTIFICATION_GROUP_LIST__URL);

    if (type) {
      url.searchParams.append("filter_type", type);
    }

    fetch(url, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",

    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Delete Notificiation group from iris-auth server
 * @param {string} token
 * @returns {Promise<Object []>}
 */
export const deleteNotificationGroups = (token, groupID) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    fetch(IRISGO_AUTH_DELETE_NOTIFICATION_GROUP__URL + groupID, {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 204) {
          resolve();
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Create Notificiation groups from iris-auth server
 * @param {string} token
 * @param {object} groupInfo
 * @returns {Promise<Object>}
 */
export const createNotificationGroup = (token, groupInfo) => {
  return new Promise((resolve, reject) => {
    return fetch(IRISGO_AUTH_CREATE_NOTIFICATION_GROUP__URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(groupInfo),
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.json());
        } else {
          reject(new Error("Could not create group"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};


/**
 * Create Notificiation groups from iris-auth server
 * @param {string} token
 * @param {object} data
 * @returns {Promise<Object>}
 */
export const sendNotificationGroup = (token, data) => {
  return new Promise((resolve, reject) => {
    return fetch(IRISGO_AUTH_SEND_NOTIFICATION__URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.json());
        } else {
          reject(new Error("Could not send notification"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * get all Notificiation groups filters from iris-auth server
 * @param {string} token
 * @returns {Promise<Object []>}
 */
export const getNotificationGroupFilters = (token, type = null) => {
  return new Promise((resolve, reject) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const url = new URL(IRISGO_AUTH_NOTIFICATION_FILTERS_LIST__URL);
    if (type) {
      url.searchParams.append("filter_type", type);
    }

    fetch(url, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve(data);
          });
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * get all Cognito contacts from iris-auth server
 * @param {string} token
 * @param {string} notificationGroup
 * @returns {Promise<Object>}
 */
export const getCognitoCustomerContacts = (token, notificationGroup = null) => {
  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const url = new URL(IRISGO_AUTH_COGNITO_CUSTOMER_CONTACT_LIST__URL);
    url.searchParams.append("includeEmail", true);
    url.searchParams.append("page_size", 1000);
    if (notificationGroup !== null)
      url.searchParams.append("notificationGroup", notificationGroup);

    fetch(url, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Update Notificiation groups member from iris-auth server
 * @param {string} token
 * @param {string} notificationGroup
 * @returns {Promise<Object>}
 */
export const updateNotificationGroupMemebers = (
  token,
  notificationGroup,
  usernames
) => {
  return new Promise((resolve, reject) => {
    return fetch(IRISGO_AUTH_NOTIFICATION_GROUP_UPDATE_MEMBERS__URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        notificaitionGroupID: notificationGroup,
        usernames: usernames,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.json());
        } else {
          reject(new Error("Could not updated group members"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
};
/**
 * Update Notificiation groups from iris-auth server
 * @param {string} token
 * @param {string} notificationGroup
 * @returns {Promise<Object>}
 */
export const updateNotificationGroup = (token, notificationGroup) => {
  return new Promise((resolve, reject) => {

    return fetch(IRISGO_AUTH_UPDATE_NOTIFICATION_GROUP__URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(notificationGroup),
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.json());
        } else {
          reject(new Error("Could not updated group members"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  })
};


export const STATIC_DEVICE_LIST_REQUEST = () => {
  return new Promise((resolve, reject) => {
    const url = new URL(SENTRY_GATEWAY_LOOKUP_URL);
    url.searchParams.append("device", true);
    fetch(url, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve(data.device);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_DEVICE_USAGE_REQUEST = (month, year, city, token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_DEVICE_USAGE_URL);
    if (city !== 0) url.searchParams.append("city", city);
    url.searchParams.append("month", month);
    url.searchParams.append("year", year);

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            resolve(data.result);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_USAGE_LOOKUP_REQUEST = (token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_LOOKUP_URL);
    url.searchParams.append("city", true);
    url.searchParams.append("device_info", true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            if (data.success === true) {
              resolve(data.result);
            } else {
              reject(new Error("Error in city and devices lookup data"));
            }
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_DEVICE_CITY_LOOKUP_REQUEST = (token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_LOOKUP_URL);
    url.searchParams.append("city", true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            if (data.success === true) {
              resolve(data.result);
            } else {
              reject(new Error("Error in city and devices lookup data"));
            }
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const IRIS_GO_LOOKUP_REQUEST = (token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_LOOKUP_URL);
    /** input look up queries names in the array, eg. "city", "user", "label_type" */
    const queries = ["city", "device_info"];

    // add the queries to fetch url
    queries.forEach((query) => {
      url.searchParams.append(query, true);
    });

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          reject(new Error("ACCESS DENIED"));
        } else {
          response.json().then((data) => {
            if (data.success === true) {
              resolve(data.result);
            } else {
              reject(new Error("Error in city and devices lookup data"));
            }
            resolve(data);
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_DEVICE_LATEST_SNAPSHOT_REQUEST = (token, device) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_DEVICE_SNAPSHOT);
    url.searchParams.append("latest", true);
    url.searchParams.append("device_id", device);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve(data);
          });
        } else {
          reject(new Error("ACCESS DENIED"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_DEVICE_SNAPSHOT_REQUEST = (token, device_id) => {
  return new Promise((resolve, reject) => {
    fetch(IRISGO_DEVICE_TAKE_SNAPSHOT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ device: device_id, action: 2 }),
    })
      .then((result) => {
        if (result.status === 200) {
          resolve("Request Sent");
        } else {
          reject("Request Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPLOAD_SHAPEFILE_REQUEST = (
  customer_id,
  work_order_geo_threshold,
  work_order_ownership = null,
  file,
  token
) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("customer_id", customer_id);
    formData.append("work_order_geo_threshold", work_order_geo_threshold);
    if (work_order_ownership !== null)
      formData.append("work_order_ownership", work_order_ownership);
    formData.append("file", file);
    return fetch(DATA_SERVER_UPLOAD_SHAPEFILE_URL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.json());
        } else {
          reject(new Error("Shapefile upload failed"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CURRENT_WEATHER_REQUEST = (key, lat, lon) => {
  return new Promise((resolve, reject) => {
    const url = new URL(OPEN_WEATHER_CURRENT_URL);
    url.searchParams.append("lat", lat);
    url.searchParams.append("lon", lon);
    url.searchParams.append("appid", key);
    url.searchParams.append("units", "metric");
    fetch(url, {
      method: "GET",
      redirect: "follow",
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            resolve({
              dt: data.dt,
              temp: data.main.temp,
              city: data.name,
              condition: data.weather[0].main,
              icon: data.weather[0].icon,
            });
          });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const MOBILE_DEVICE_REBOOT_REQUEST = (token, device_id) => {
  return new Promise((resolve, reject) => {
    fetch(IRISGO_REBOOT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
      body: JSON.stringify({ id: device_id }),
      redirect: "follow",
    })
      .then((result) => {
        if (result.status === 200) {
          resolve("Request Sent");
        } else {
          reject("Request Failed");
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_LOOK_UP = async (accessToken, params) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${accessToken}`,
    },
  };
  const url = new URL(IRISGO_LOOKUP_URL);
  params.forEach((param) => url.searchParams.append(param, true));
  const response = await fetch(url, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("access_denied__error_message");
    } else if (response.status === 404) {
      error = new Error("page_not_found_error_message");
    } else if (response.status >= 500) {
      error = new Error("server_error_message");
    } else {
      error = new Error("general_error_message");
    }
  }
  throw error;
};

export const LOOKUP_PARAMS = {
  USER: "user",
  PROFILE: "profile",
  CITY: "city",
  DAMAGE_TYPE: "damage_type",
  USER_TYPE: "user_type",
  RESOLUTION_TYPE: "resolution_type",
  DEVICE_INFO: "device_info",
  CURRENT_CITY: "current_city",
  LABEL_TYPE: "label_type",
};
