import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button, FormControl, TextField, Typography } from "@mui/material";
import { useStyles } from "./styles";
import ResetPasswordForm from "components/forms/resetPasswordForm/ResetPasswordForm";
import Auth from "api/awsCognito/AuthUtils";
import { useNotification } from "UI/global/notification/context/NotificationContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const STEPS_MAP = {
  Verification: {
    title: "Verification",
    stepIndex: 0,
    activeIndex: 1,
  },
  RESET_PASSWORD: {
    title: "Reset Password",
    stepIndex: 1,
    activeIndex: 2,
  },
};

export default function ForgetPasswordPage() {
  const classes = useStyles();
  const emailRef = useRef("");
  /*================================================== states START ================================================== */
  const [currentIndex, setCurrentIndex] = useState(
    STEPS_MAP.Verification.stepIndex
  );

  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  /*==================================================  states END  ================================================== */

  /**
   * see reqeust, with userName, to get a verification code
   *
   * @param {React.ChangeEvent} event
   * @returns
   */
  const handleForgetPasswordRequest = async (event) => {
    event.preventDefault();
    if (email.length < 10) return;
    setIsLoading(true);

    let isCodeSent = false;
    let err;
    try {
      const result = await Auth.forgotPassword(email);
      const {
        CodeDeliveryDetails: { AttributeName, Destination },
      } = result;

      isCodeSent = AttributeName && Destination;
      setUserName(email);
    } catch (error) {
      err = error;
    } finally {
      setIsLoading(false);
      if (err) {
        showNotification(err + "", "top-center", "error");
      }
      if (isCodeSent) {
        showNotification(
          `A verification code has been sent to: ${email}`,
          "top-center",
          "success"
        );
        setCurrentIndex(STEPS_MAP.RESET_PASSWORD.stepIndex);
        setActiveIndex(STEPS_MAP.Verification.activeIndex);
      }
    }
  };

  const { replace } = useHistory();
  const handleOnResetSuccess = () => {
    replace("/login");
  };
  return (
    <Box className={classes.boxWrapper}>
      <Typography variant="h4" className={classes.title}>
        Forget password
      </Typography>
      <Stepper
        activeStep={activeIndex}
        alternativeLabel
        style={{
          width: "100%",
        }}
      >
        {Object.values(STEPS_MAP).map((value) => {
          const { title, stepIndex } = value;
          return (
            <Step key={title} active={stepIndex === currentIndex}>
              <StepLabel>{title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {currentIndex === STEPS_MAP.Verification.stepIndex && (
        <form
          className={classes.verificationForm}
          onSubmit={handleForgetPasswordRequest}
        >
          <FormControl fullWidth style={{ marginBlock: "0.5rem" }}>
            <TextField
              placeholder="Email"
              InputProps={{
                name: "email",
              }}
              variant="standard"
              autoFocus
              disabled={isLoading}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormControl>
          <Button
            style={{
              display: "block",
              marginLeft: "auto",
            }}
            variant="contained"
            onClick={handleForgetPasswordRequest}
            disabled={email.length < 10 || isLoading}
          >
            Next
          </Button>
        </form>
      )}

      {currentIndex === STEPS_MAP.RESET_PASSWORD.stepIndex && (
        <ResetPasswordForm
          formProps={{
            className: `${classes.verificationForm} ${classes.resetPasswordForm}`,
          }}
          userName={userName}
          mode="FORGET_PASSWORD"
          onResetSuccess={handleOnResetSuccess}
          onResendVerificationCode={handleForgetPasswordRequest}
        />
      )}
    </Box>
  );
}
