import styles from "./IncrementDecrementButton.module.css";
import React, { useState, useEffect, useReducer, useContext } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

/**
 * increment and decrement button group
 * @param {Object} props
 * @param {string} [props.header] text displayed before button group
 * @param {string} [props.headerFontSize] font size of the header text, "small", "medium" or "large"
 * @param {Function} props.onIncrement handle the click event of the increment button
 * @param {Function} props.onDecrement handle the click event of the decrement button
 * @param {string} [props.counter] text displayed between the increment button and the decrement button
 * @param {string} [props.counterFontSize] font sieze of the counter text
 * @param {boolean} [props.incrementDisabled] specify if the increment button is disabled
 * @param {boolean} [props.decrementDisabled] specify if the decrement button is disabled
 */
const IncrementDecrementButton = (props) => {
  const {
    header = "",
    headerFontSize = "1rem",
    onIncrement,
    onDecrement,
    counter = "counter",
    counterFontSize = "1rem",
    buttonSize = "small",
    incrementDisabled = false,
    decrementDisabled = false,
  } = props;

  return (
    <div className={styles.incrementDecrementButtonGroup}>
      <div style={{ fontSize: headerFontSize }}>{header}</div>
      <ButtonGroup
        variant="contained"
        size={buttonSize}
        className={styles.buttonGroup}
      >
        <Button onClick={onIncrement} disabled={incrementDisabled}>
          <AddIcon />
        </Button>
        <div className={styles.counter} style={{ fontSize: counterFontSize }}>
          {counter}
        </div>
        <Button onClick={onDecrement} disabled={decrementDisabled}>
          <RemoveIcon />
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default IncrementDecrementButton;
