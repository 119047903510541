import React from "react";
import PropTypes from "prop-types";

/**
 * @param {Object} props
 * @param {React.ReactElement} props.children tab panel content
 * @param {number} props.index current tab panel id when rendering with array.map method
 * @param {number} props.value active tab panel id
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
