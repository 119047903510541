import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import IrisTableHeaderView from "./IrisTableHeaderView";
import IrisTableToolbar from "./IrisTableToolbar";
import {
  getComparator,
  getDataWithMatchedValue,
  stableSort,
} from "./tableUtils";
import { LinearProgress } from "@mui/material";

/**
 * @param {Object} props
 * @param {string} [props.tableTitle] table title
 * @param {number} [props.rowsPerPage]
 * @param {[Object]} props.data
 * @param {"asc" | "desc"} [props.initialSort] initial sorting type, default is "asc"
 * @param {boolean} props.isLoading if true, loader would be shown as toable body, false other wise
 * @param {boolean} [props.isDense] if true, rows are densed, flase otherwise, default is **false**
 * @param {string} [props.idField] unique identifier for each datum in the data, ie: "id", "production_id", "p_id", etc
 * @param {string | number} [props.fallbackValue] when rendering rows, *value = data[key]* would be used, if *element* props is not provided, in case *data[key]* is undefined, render this fallback value on the cell
 * @param {[string]} [props.ignoreFields] fields whose value could be ignored for search comparision, ie: "id"
 * @param {[TableColumnObject]} props.columns columns to be rendered on the table
 * @param {([data]) => void} [props.onRemove] callback function which would exposed a set of selected data
 * @param {Function} [props.onRefresh] callback function that would trigger the refresh of data (call api, maybe)
 * @param {Function} [props.onAdd] callback function that would trigger the add data logic (call api, maybe)
 */
function IrisTable(props) {
  const {
    tableTitle = "",
    rowsPerPage: rPerPage = 10,
    data,
    initialSort = "asc",
    isLoading: isLoadingData = false,
    isDense = false,
    idField = "",
    fallbackValue,
    ignoreFields,
    columns,
    onRemove,
    onRefresh,
    onAdd,
  } = props;
  // if (!Array.isArray(data)) {
  //   throw new Error("data array is not provided");
  // }

  // if (!Array.isArray(columns) || columns.length === 0) {
  //   throw new Error("columns array is not provided");
  // }

  // function is provided

  const [datum] = data;
  if (datum) {
    const idFieldIndex = Object.keys(datum).findIndex((key) => key === idField);
    // idField missmatched
    if (idFieldIndex < 0) {
      // with missmatched idField, target row could not be identify when it is selected to be, potentially, removed
      throw new Error(
        "[idField] is missing or it does not match any fields in element of data"
      );
    }
  }
  /** whether or not to render checkbox on the leftmost on each row and the IrisTableHeaderView */
  const isRowSelectable = typeof onRemove === "function";

  const [isLoading, setIsLoading] = useState(isLoadingData);

  const [rows, setRows] = useState(JSON.parse(JSON.stringify(data)));

  // data that are selected, potentially, to be removed
  const [selectedData, setSelectedData] = useState([]);

  // [filterRows] is a set of data which is been sorted and filtered by [searchValue]
  const [filteredRows, setFilteredRows] = useState(
    JSON.parse(JSON.stringify(data))
  );

  const [searchValue, setSearchValue] = useState("");
  const lastSearchedValue = useRef("");

  /**
   * sort order for the rows, either ascending or descending, this
   * params is used along with [filteredRows], instead of [rows]
   */
  const [sortOrder, setSortOrder] = useState(initialSort);

  /** order by one of the fields from the given data, with dedicated sort order (asc or desc)  */
  if (!columns?.length > 0) {
    throw new Error("IrisTable property [columns] is not provided");
  }
  const [orderField, setOrderField] = useState(columns[0].fieldName);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rPerPage);

  const [isDenseRow, setIsDenseRow] = useState(isDense);

  /**
   * @summary callback function that is passed into the [IrisTableHeaderView]
   *
   * @param {Event} event
   * @param {string} property object key whose value would be used to sorted
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderField === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setOrderField(property);
  };

  /**
   * @summary callback function for the checkbox of the toolbar
   *
   * @description when the checkbox is checked, all rows (data) should be marked as selected
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   *
   * @see {selectedData}
   * @see {setSelectedData}
   */
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedData(JSON.parse(JSON.stringify(filteredRows)));
      return;
    }
    setSelectedData([]);
  };

  /**
   * @summary callback function for [IrisTableToolbar]
   *
   * @description when this function is called, send the selected data to the parent component
   *
   * @see onRemove
   */
  const handleRemoveRows = () => {
    onRemove(selectedData);
  };

  /**
   * @summary callback function for checkboxes
   */
  const handleCheckboxChange = useCallback(
    (event, data) => {
      const selectedIndex = selectedData.findIndex(
        (target) => target[idField] === data[idField]
      );
      let newSelected = [];
      if (selectedIndex === -1) {
        // not in selected
        newSelected = newSelected.concat(selectedData, { ...data });
      } else if (selectedIndex === 0) {
        // first element of the selected
        newSelected = newSelected.concat(selectedData.slice(1));
      } else if (selectedIndex === selectedData.length - 1) {
        // last element of the selected
        newSelected = newSelected.concat(selectedData.slice(0, -1));
      } else if (selectedIndex > 0) {
        // between the 2nd and the last 2nd element of the selected
        newSelected = newSelected.concat(
          selectedData.slice(0, selectedIndex),
          selectedData.slice(selectedIndex + 1)
        );
      }

      setSelectedData(newSelected);
    },
    [idField, selectedData]
  );

  const handleChangeDense = (event) => {
    setIsDenseRow(event.target.checked);
  };

  /**
   * @summary callback function pass to the [*onSearch*] pros of the **IrisTableToolBar** component
   * @description when the search value is updated, keep a reference of its last value for later comparison, otherwise,
   * after filtering out the matched rows, pagination would not work properly. Check out *[useEffect]* hook which listens
   * updates on this value
   * @param {string} value
   *
   * @see IrisTableToolbar
   */
  const updateSearchValue = (value) => {
    setSearchValue((prev) => {
      lastSearchedValue.current = prev;
      return value;
    });
  };

  // callback function to render a row, column by column
  const renderRow = useCallback(
    (data, index) => {
      const isItemSelected =
        selectedData.findIndex((target) => target[idField] === data[idField]) >
        -1;
      const labelId = `enhanced-table-checkbox-${index}`;

      return (
        <TableRow
          hover
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={index}
          selected={isItemSelected}
          sx={{
            background: index % 2 === 0 ? "#fff" : "#e2e2e2",
          }}
        >
          {isRowSelectable && (
            <TableCell padding="checkbox">
              <Checkbox
                onChange={(event) => handleCheckboxChange(event, data)}
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            </TableCell>
          )}
          {columns.map(({ fieldName, element }, index) => {
            if (element) {
              // render custom JSX element
              return (
                <TableCell key={index} component="th" id={labelId} scope="row">
                  {element(data, fieldName, index)}
                </TableCell>
              );
            }
            let value = data[fieldName] ?? fallbackValue;

            if (typeof value === "boolean") {
              // trur => "true", false => "false"
              // if value = false, "false" would not be rendered, instead, it is empty
              value += "";
            }
            // render default table cell
            return <TableCell key={index}>{value}</TableCell>;
          })}
        </TableRow>
      );
    },
    [
      columns,
      handleCheckboxChange,
      idField,
      isRowSelectable,
      selectedData,
      fallbackValue,
    ]
  );

  const renderRows = () => {
    /* if you don't need to support IE11, you can replace the `h` call with:
        rows.slice().sort(getComparator(order, orderBy)) */

    return (
      <>
        {filteredRows
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(renderRow)}
        {renderEmptyRow()}
      </>
    );
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const renderEmptyRow = () => {
    const maxCount = (page + 1) * rowsPerPage;
    const emptyRows = maxCount - filteredRows.length;
    // const emptyRows = rowsPerPage - rowsInCurrentPage;
    // console.log("emptyRows", emptyRows);
    return (
      emptyRows > 0 && (
        <TableRow
          style={{
            height: (isDenseRow ? 33 : 53) * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )
    );
  };

  // pagination START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Pagination = () => (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50]}
      component="div"
      count={filteredRows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
  // pagination END

  useEffect(() => {
    setIsLoading(isLoadingData);
  }, [isLoadingData]);

  useEffect(() => {
    // console.log("data updated", data);
    const pageNum = Math.floor(data.length / rowsPerPage);

    // wrong page number would cause UI (pagination) warnning
    if (pageNum < page) {
      setPage(pageNum);
    }
    setRows(JSON.parse(JSON.stringify(data)));
  }, [data, rowsPerPage, page]);
  // listener for searchValue, sortOrder (asc or desc), rowsPerPage, and rows
  // when either one of them changed, the entired [fitleredRows] must be updated
  useEffect(() => {
    let updatedFilteredRows = [];
    if (searchValue === "") {
      // not search value, only sort the rows
      updatedFilteredRows = stableSort(
        rows,
        getComparator(sortOrder, orderField)
      );
    } else {
      /** rows (data) whose value matches the search value */
      const filterRows = getDataWithMatchedValue(
        rows,
        searchValue,
        ignoreFields
      );
      updatedFilteredRows = stableSort(
        filterRows,
        getComparator(sortOrder, orderField)
      );
    }

    /**
     * if search value is updated, set to first page, do nothing otherwise
     */
    if (searchValue !== lastSearchedValue.current) {
      // make both value as the most updated search value
      lastSearchedValue.current = searchValue;
      // set page to the first page
      setPage(0);
    }
    setFilteredRows(updatedFilteredRows);
  }, [
    sortOrder,
    orderField,
    page,
    rowsPerPage,
    rows,
    renderRow,
    searchValue,
    ignoreFields,
  ]);

  return (
    <Box sx={{ width: "100%" }}>
      <FormControlLabel
        control={<Switch checked={isDenseRow} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <IrisTableToolbar
          tableTitle={tableTitle}
          numSelected={selectedData.length}
          onRemove={handleRemoveRows}
          onSearch={updateSearchValue}
          onRefresh={onRefresh}
          onAdd={onAdd}
          isLoading={isLoading}
        />
        {isLoading ? <LinearProgress /> : <div style={{ height: 4 }}></div>}
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 600 }}
            aria-labelledby="tableTitle"
            size={isDenseRow ? "small" : "medium"}
          >
            <IrisTableHeaderView
              numSelected={selectedData.length}
              sortOrder={sortOrder}
              orderField={orderField}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
              isRowSelectable={isRowSelectable}
            />
            <TableBody>{renderRows()}</TableBody>
            {/* <TableBody>{isLoading ? renderLoader() : renderRows()}</TableBody> */}
          </Table>
        </TableContainer>
        <Pagination />
      </Paper>
    </Box>
  );
}

export default IrisTable;
