import styles from "./SubmitBackdrop.module.css";
import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import Backdrop from "@mui/material/Backdrop";

/** 4 different state according to api call of submitting request */
export const SUBMIT_STATE = {
  /** "not send" */
  notSend: "not send",
  /** "sending request" */
  sending: "sending request",
  /** "error" */
  error: "error",
  /** "error" */
  error_in_assign_group: "error in assigning group(s)",
  /** "ok" */
  ok: "ok",
};

/**
 *
 * @param {Object} props
 * @param {boolean} props.open indicate the backdrop open or not
 * @param {Function} props.onClick handle close both backdrop and modal when clicking on backdrop itself
 * @param {string} submitState 4 different state according to api call of submitting request, "not send", "sending request", "error", "ok"
 * @param {string} modalMessage text message displaying on modal
 * @returns
 */
const SubmitBackdrop = (props) => {
  const { open, onClick, submitState, modalMessage } = props;

  const handleClickBackdrop = (e) => {
    // material icon className is an object rather than string
    if (typeof e.target.className === "string") {
      if (e.target.className.includes("MuiBackdrop")) {
        onClick();
      }
    }
  };

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClickBackdrop}
      transitionDuration={0}
    >
      <div className={styles.modal}>
        {submitState === SUBMIT_STATE.sending && (
          <CircularProgress size={20} color="primary" />
        )}
        {submitState === SUBMIT_STATE.ok && (
          <CheckCircleOutlineIcon color="success" />
        )}
        {submitState === SUBMIT_STATE.error && (
          <ErrorOutlineIcon color="error" />
        )}
        {modalMessage}
      </div>
    </Backdrop>
  );
};

export default SubmitBackdrop;
