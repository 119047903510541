import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import IrisTable from "UI/muiTable/IrisTable";
import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { AuthContext } from "context/AuthContext";

import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
import { getAdminUserList } from "utils/irisAuthRequests";
import { ERROR_MESSAGE } from "utils/irisAuthRequests";

const Users = () => {
  const history = useHistory();

  // get token to make api call
  const { contextToken, awsToken, onLogout } = useContext(AuthContext);

  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // modal displays different info according to the states
  const [getUserListState, setGetUserListState] = useState(SUBMIT_STATE.ok);
  // when there's an error, set state of the error message
  const [errorMessage, setErrorMessage] = useState("");

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // when there's an error
    if (getUserListState === SUBMIT_STATE.error) {
      // when the token is not valid, click the backdrop to logout
      if (errorMessage === ERROR_MESSAGE.token) {
        onLogout();
      }
    }
  };

  // load user list when component mounts
  useEffect(() => {
    let isSubscribed = true;

    setIsLoading(true);

    getAdminUserList(awsToken)
      .then((result) => {
        if (isSubscribed) {
          setUserList(result);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setOpenBackdrop(true);
        setErrorMessage(error.message);
        setGetUserListState(SUBMIT_STATE.error);
      });

    return () => (isSubscribed = false);
  }, [awsToken]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Link
          to="/user/signup"
          style={{ textDecoration: "none", margin: "0.5rem 0 1rem 1rem" }}
        >
          <Button variant="outlined" startIcon={<PersonAddIcon />}>
            Add User
          </Button>
        </Link>
      </div>
      <IrisTable
        isLoading={isLoading}
        columns={[
          {
            fieldName: "Username",
            headTitle: "User Name",
            width: 300,
            element: (data, key, index) => (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  fontSize: 14,
                }}
                onClick={() => {
                  history.push(`/user-details/${data.Username}`);
                }}
              >
                {data[key]}
              </Button>
            ),
          },
        ]}
        data={userList}
        idField="Username"
      />
      <SubmitBackdrop
        open={openBackdrop}
        onClick={handleClickBackdrop}
        submitState={getUserListState}
        modalMessage={errorMessage}
      />
    </div>
  );
};

export default Users;
