import React from "react";
import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import DashBoard from "../pages/Dashboard";
import MobileDevices from "../pages/MobileDevices";
import StaticDevices from "../pages/StaticDevices";
import MobileUsage from "../pages/MobileUsage";
import StaticUsage from "../pages/StaticUsage";
import IncidentReports from "../pages/IncidentReports";
import DefectReports from "../pages/DefectReports";
import { Route } from "react-router-dom";
import Header from "../layout/Header";
import SideMenu from "../layout/SideMenu";
import { SIDE_MENU_WIDTH, TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import RegisterDevice from "../pages/RegisterDevice";
import UserProfiles from "../pages/UserProfiles";
import CitysInfo from "../pages/CitysInfo";
import PostOffice from "../pages/PostOffice";
import LabelTypesTable from "../pages/labelTypePage/LabelTypesFinder";

import MobileDeviceInfo from "pages/MobileDevice/MobileDeviceInfo";
import AddNewLabelModal from "pages/labelTypePage/AddOrEditLabelModal/AddOrEditLabelModal";
import { ROUTES } from "routes/routes";
import DownloadPage from "pages/downloadPage/DownloadPage";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: TOOL_BAR_HEIGHT, // cause toolbar is set to "fixed"
    display: "flex",
    // width: `calc(100% - ${SIDE_MENU_WIDTH}px)`,
    marginLeft: 240,
  },
  sideMenu: {
    flexShrink: 0,
  },
  content: {
    padding: theme.spacing(1),
    width: "100%",
  },
}));

export default function AdminConsole() {
  const classes = useStyles();

  return (
    <div>
      <CssBaseline />
      <Header />
      <SideMenu className={classes.sideMenu} />
      <main className={classes.main}>
        <div className={classes.content}>
          <Route exact path="/" component={DashBoard} />
          <Route exact path="/mobile-device" component={MobileDevices} />

          <Route exact path="/mobile-device/:id" component={MobileDeviceInfo} />

          <Route
            exact
            path={ROUTES.LABELS_PAGE.path}
            component={LabelTypesTable}
          />
          <Route exact path="/mobile/register" component={RegisterDevice} />
          <Route exact path="/mobile/report" component={DefectReports} />
          {/* <Route exact path="/mobile/usage" component={MobileUsage} /> */}
          {/* <Route exact path="/static" component={StaticDevices} /> */}
          {/* <Route exact path="/static/usage" component={StaticUsage} /> */}
          {/* <Route exact path="/static/report" component={IncidentReports} /> */}

          <Route exact path="/download" component={DownloadPage} />
          <Route exact path="/user" component={UserProfiles} />
          <Route exact path="/user/city" component={CitysInfo} />
          {/* /postoffice does not have exact because we have an embedded page within history */}
          {/* <Route path="/postoffice" component={PostOffice} /> */}
        </div>
      </main>
    </div>
  );
}
