import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import MomentUtils from "@date-io/moment";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Typography from "@mui/material/Typography";
import GetAppIcon from "@mui/icons-material/GetApp";
import Paper from "@mui/material/Paper";
import moment from "moment";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../components/mobile/TabPanel";
import { TOOL_BAR_HEIGHT } from "../utils/theme/constrains";
import {
  MOBILE_USAGE_LOOKUP_REQUEST,
  MOBILE_DEVICE_USAGE_REQUEST,
} from "../utils/requests";
import { GET_YESTERDAY, PROCESS_MOBILE_USAGE_DATA } from "../utils/utils";
import { AuthContext } from "../context/AuthContext";
import LinearProgress from "@mui/material/LinearProgress";
import MobileUsageSummaryTable from "../components/mobile/MobileUsageSummaryTable";
import MobileUsageDeviceTable from "../components/mobile/MobileUsageDeviceTable";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  main: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  naviContainer: {
    padding: theme.spacing(2),
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pickerContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    order: 3,
    [theme.breakpoints.up("lg")]: {
      order: 1,
    },
  },
  title: { margin: theme.spacing(1) },
  picker: {
    margin: "auto",
    width: 220,
  },
  pickerText: {
    margin: "auto",
    textAlign: "center",
    ...theme.typography.h6,
    color: theme.palette.text.primary,
  },
  arrowIcons: {
    fontSize: "40px",
  },
  exportIcon: {
    fontSize: "25px",
  },
  exportButton: {
    marginTop: "12px",
  },
  selectForm: {
    width: 100,
  },
  formGroup: {
    float: "right",
  },
  formContainer: {
    order: 2,
  },
  tabsContainer: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.default,
  },
  loadingBar: {
    width: "100%",
  },
  tableContainer: {
    flexDirection: "column",
    padding: theme.spacing(2),
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 225}px)`,
  },
  table: {
    width: "100%",
  },
  summaryContainer: {
    flexDirection: "row",
  },
  summaryTextContainer: {
    padding: theme.spacing(2),
    textAlign: "right",
  },
}));

export default function DefectReports() {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(GET_YESTERDAY);
  const [city, setCity] = React.useState(0);
  const [cityList, setCityList] = React.useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [usageData, setUsageData] = React.useState([
    { tag: "", usage: [], summary: { distance: 0 } },
  ]);
  const [isLoading, setIsLoading] = useState();
  const { contextToken } = useContext(AuthContext);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) setIsLoading(true);
    MOBILE_USAGE_LOOKUP_REQUEST(contextToken)
      .then((lookupResult) => {
        if (isSubscribed) setCityList(Object.entries(lookupResult.city));
        MOBILE_DEVICE_USAGE_REQUEST(
          moment(selectedDate).month() + 1,
          moment(selectedDate).year(),
          city,
          contextToken
        )
          .then((usageResult) => {
            if (isSubscribed) {
              setUsageData(
                PROCESS_MOBILE_USAGE_DATA(usageResult, lookupResult.device_info)
              );
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    return () => (isSubscribed = false);
  }, [contextToken, city, selectedDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Paper>
        <Grid container className={classes.main}>
          <Grid item xs={12} className={classes.loadingBar}>
            <LinearProgress
              value={100}
              variant={isLoading ? "indeterminate" : "determinate"}
            />
          </Grid>
          <Grid item container className={classes.naviContainer}>
            <Grid item lg={3} xs={6} className={classes.titleContainer}>
              <Typography variant="h6" className={classes.title}>
                Usage Reports
              </Typography>
            </Grid>
            <Grid
              item
              container
              lg={6}
              xs={12}
              className={classes.pickerContainer}
            >
              <IconButton
                onClick={() =>
                  handleDateChange(moment(selectedDate).subtract(1, "M"))
                }
              >
                <KeyboardArrowLeftIcon className={classes.arrowIcons} />
              </IconButton>
              <DatePicker
                className={classes.picker}
                autoOk
                views={["year", "month"]}
                value={selectedDate}
                disableToolbar
                maxDate={GET_YESTERDAY}
                onChange={(date) => {
                  handleDateChange(date);
                }}
                labelFunc={(date) => date.format("MMMM, YYYY")}
                variant={"inline"}
                inputProps={{ className: classes.pickerText }}
              />
              <IconButton
                onClick={() =>
                  handleDateChange(moment(selectedDate).add(1, "M"))
                }
                disabled={
                  moment(GET_YESTERDAY).isSameOrBefore(selectedDate, "day")
                    ? true
                    : false
                }
              >
                <KeyboardArrowRightIcon className={classes.arrowIcons} />
              </IconButton>
            </Grid>
            <Grid item lg={3} xs={6} className={classes.formContainer}>
              <div className={classes.formGroup}>
                <FormControl className={classes.selectForm}>
                  <InputLabel id="demo-controlled-open-select-label">
                    Select a City
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={city}
                    onChange={(event) => {
                      setCity(event.target.value);
                    }}
                  >
                    <MenuItem value={0}>
                      <Typography variant="subtitle2">All Citys</Typography>
                    </MenuItem>
                    {cityList.map((cityEntry) => {
                      return (
                        <MenuItem value={cityEntry[0]} key={cityEntry[0]}>
                          <Typography variant="subtitle2">
                            {cityEntry[1].name}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <CSVLink
                  filename={`${usageData[tabValue].tag}.csv`}
                  data={usageData[tabValue].usage}
                  target="_blank"
                >
                  <IconButton className={classes.exportButton}>
                    <GetAppIcon className={classes.exportIcon} />
                  </IconButton>
                </CSVLink>
              </div>
            </Grid>
          </Grid>
          <Grid item className={classes.tabsContainer}>
            <AppBar position="static" color="default">
              <Tabs
                value={tabValue}
                onChange={(event, newValue) => {
                  setTabValue(newValue);
                }}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="on"
              >
                {usageData.map((usageEntry, i) => {
                  return (
                    <Tab
                      key={i}
                      value={i}
                      label={
                        <Typography variant="overline">
                          {`${usageEntry.tag}`}
                        </Typography>
                      }
                    />
                  );
                })}
              </Tabs>
            </AppBar>
            {usageData.map((usageEntry, i) => {
              return (
                <TabPanel value={tabValue} index={i} key={i}>
                  <Grid container className={classes.tableContainer}>
                    <Grid item className={classes.table}>
                      {tabValue === 0 ? (
                        <MobileUsageSummaryTable data={usageEntry.usage} />
                      ) : (
                        <MobileUsageDeviceTable data={usageEntry.usage} />
                      )}
                    </Grid>
                    {usageEntry.usage.length === 0 ? (
                      <Typography color="textSecondary">
                        No record is found.
                      </Typography>
                    ) : null}
                    <Grid container item className={classes.summaryContainer}>
                      <Grid item xs={12} lg={6}>
                        <Typography
                          variant={"subtitle1"}
                          className={classes.summaryTextContainer}
                        >
                          Total Distance:{" "}
                          {Math.round(usageEntry.summary.distance * 100) / 100}{" "}
                          km
                        </Typography>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Typography
                          variant={"subtitle1"}
                          className={classes.summaryTextContainer}
                        >
                          Total Data Usage:{" "}
                          {Math.round(usageEntry.summary.data * 100) / 100} MB
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  );
}
