import { Grid, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import styles from "../common/ClientDetails.module.css";
import { AuthContext } from "context/AuthContext";
import { getAdminUserList } from "utils/irisAuthRequests";
import { MOBILE_DEVICE_LIST_V3_REQUEST, getNotificationGroupFilters } from "utils/requests";


import NotificationInfo from "./NotificationInfo";
import ManageNotificationGroup from "./ManageNotificationGroup";

const NotificationGroup = (props) => {
    const { clientID, clientName } = props;
    const [notificationSelectedMenue, setNotificationSelectedMenue] = useState(0);
    const { awsToken } = useContext(AuthContext);

    const [emails, setEmails] = useState([])
    const [userContacts, setUserContacts] = useState(new Map())

    const [deviceList, setDeviceList] = useState([]);
    const [filterGroupTypes, setFilterGroupTypes] = useState([]);

    useEffect(() => {
        let isSubscribed = true;

        Promise.all([
            getAdminUserList(awsToken, clientName),
            getNotificationGroupFilters(awsToken, 'device'),
            MOBILE_DEVICE_LIST_V3_REQUEST(awsToken)
        ]).then((results) => {
            if (isSubscribed) {
                if (results[0]) {
                    const userContacts = new Map()
                    const emails = [];
                    results[0].forEach(user => {

                        const username = user.Username
                        const emailAttr = user.Attributes.find(attr => attr.Name === 'email')
                        if (emailAttr) {
                            emails.push(emailAttr.Value)
                            userContacts.set(username, emailAttr.Value)
                        }

                    })
                    setEmails(emails)
                    setUserContacts(userContacts)
                }
                if (results[1]) {
                    setFilterGroupTypes(results[1])
                }
                if (results[2]) {
                    const devices = results[2]
                    const filteredDevices = devices.filter(device => device?.client_id == clientID)
                    setDeviceList(filteredDevices)
                }
            }
        }).catch((err) => {
            // console.log(err)
        })
        return () => (isSubscribed = false);
    }, [awsToken, clientID, clientName])


    return (

        <Grid container spacing={1}>
            <Grid item xs={2}>
                <MenuList style={{ paddingTop: '2vh' }}>
                    <MenuItem onClick={(ev) => {
                        ev.preventDefault()
                        setNotificationSelectedMenue(0)

                    }} selected={notificationSelectedMenue == 0}>
                        <ListItemIcon>
                            <GroupIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Manage Groups</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={(ev) => {
                        ev.preventDefault()
                        setNotificationSelectedMenue(1)

                    }} selected={notificationSelectedMenue == 1} >
                        <ListItemIcon>
                            <GroupAddIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Create Group</ListItemText>
                    </MenuItem>
                </MenuList>
            </Grid>
            {notificationSelectedMenue === 0 && (
                <Grid item xs={10}>
                    <div className={styles.pageChild} >
                        <div className={styles.workOrderProfile}>
                            <NotificationInfo
                                clientID={clientID}
                                clientName={clientName}
                                emails={emails}
                                userContacts={userContacts}
                            ></NotificationInfo>
                        </div>
                    </div>
                </Grid>
            )
            }
            {notificationSelectedMenue === 1 && (
                <Grid item xs={10}>
                    <div className={styles.pageChild}>
                        <ManageNotificationGroup
                            clientID={clientID}
                            deviceList={deviceList}
                            filterGroupTypes={filterGroupTypes}
                        ></ManageNotificationGroup>
                    </div>
                </Grid>
            )
            }

        </Grid>
    )
}


export default NotificationGroup;