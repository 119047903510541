import { Grid, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import styles from "./notifications.module.css";
import { AuthContext } from "context/AuthContext";
import { getAdminClientList } from "utils/irisAuthRequests";
import { getNotificationGroupFilters } from "utils/requests";


import NotificationInfo from "./NotificationInfo";
import ManageNotificationGroup from "./ManageNotificationGroup";



const NotificationGroup = (props) => {
    const [notificationSelectedMenue, setNotificationSelectedMenue] = useState(0);
    const [customers, setCustomers] = useState([]);
    const { awsToken } = useContext(AuthContext);

    const [filterGroupTypes, setFilterGroupTypes] = useState([]);


    useEffect(() => {
        let isSubscribed = true;

        Promise.all([
            getAdminClientList(awsToken),
            getNotificationGroupFilters(awsToken, 'notification')
        ]).then((results) => {
            if (isSubscribed) {
                if (results[0]) {
                    const customers = results[0].sort((x, y) => {
                        if (x.name < y.name) {
                            return -1;
                        }
                        if (x.name > y.name) {
                            return 1;
                        }
                        return 0;
                    })
                    setCustomers(customers)
                }
                if (results[1]) {
                    setFilterGroupTypes(results[1])
                }
            }
        }).catch((err) => {
            // console.log(err)
        })
        return () => (isSubscribed = false);
    }, [awsToken])


    return (

        <Grid container spacing={1}>
            <Grid item xs={2}>
                <MenuList style={{ paddingTop: '2vh' }}>
                    <MenuItem onClick={(ev) => {
                        ev.preventDefault()
                        setNotificationSelectedMenue(0)

                    }} selected={notificationSelectedMenue == 0}>
                        <ListItemIcon>
                            <GroupIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Manage Groups</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={(ev) => {
                        ev.preventDefault()
                        setNotificationSelectedMenue(1)

                    }} selected={notificationSelectedMenue == 1} >
                        <ListItemIcon>
                            <GroupAddIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Create Group</ListItemText>
                    </MenuItem>
                </MenuList>
            </Grid>
            {notificationSelectedMenue === 0 && (
                <Grid item xs={10}>
                    <div className={styles.pageChild} >
                        <div className={styles.workOrderProfile}>
                            <NotificationInfo></NotificationInfo>
                        </div>
                    </div>
                </Grid>
            )
            }
            {notificationSelectedMenue === 1 && (
                <Grid item xs={10}>
                    <div className={styles.pageChild}>
                        <ManageNotificationGroup
                            filterGroupTypes={filterGroupTypes}
                            customers={customers}
                        ></ManageNotificationGroup>
                    </div>
                </Grid>
            )
            }

        </Grid>
    )
}


export default NotificationGroup;