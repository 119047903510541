import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import FormControl from "@mui/material/FormControl";

/**
 * @summary get hours and minutes in "HH:mm:00" format
 *
 * @description in design, practically, the seconds value should be trivial, so its value would be cut
 *
 * @example if value is a date object of 2020-01-01 12:45:02 (local date time)
 * getHHmm(value) returns "12:45:00"
 * @param {Date} date
 * @returns {string} hours and minutes string in "HH:mm:00" format
 */
const getHHmmss = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const hourString = hours >= 10 ? hours + "" : "0" + hours;
  const minuteString = minutes >= 10 ? minutes + "" : "0" + minutes;
  return `${hourString}:${minuteString}:00`;
};

/**
 * @param {object} props
 * @param {string} props.title title for the time pickers, ie: Schedule, etc
 * @param {boolean} [props.disabled] if true, time picker would not be toggleable, false other wise
 * @param {Date} props.value date obejct where the time part would be extracted
 * @param {(value: string) => {}} props.onChange callback function when time is updated
 * @param {import("@mui/material").TextFieldProps} props.inputProps MUI InputProps
 *
 * @example
 * // only time part is shown on TextInput as string value
 * const [time, setTime] = useState(newDate);
 * <IrisTimePicker
      title="Monday"
      value={time}
      onChange={(value) => {
        console.log("value", value);
        setTime(value);
      }}
      inputProps={{
        variant: "standard"
      }}
    />
 */
export default function IrisTimePicker(props) {
  const { title, value, disabled, onChange, inputProps: ipProps } = props;

  let inputProps = ipProps || {
    variant: "standard",
    disabled: disabled,
  };

  return (
    <FormControl fullWidth>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label={title}
          disabled={disabled}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField {...params} {...inputProps} fullWidth />
          )}
          inputProps={{
            readOnly: true,
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
