import styles from "./ClientDetails.module.css";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment/moment";
import momentTZ from "moment-timezone";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";

import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";
import IncrementDecrementButton from "components/incrementDecrementButton/IncrementDecrementButton";
import TabPanel from "components/mobile/TabPanel";

import { SEARCH_FIELDS_OPTIONS } from "../clientDetailsLoader/ClientDetailsLoader";
import HelpIcon from "@mui/icons-material/Help";

import { IconButrtons } from "UI/dataGrid/DataGrid";
import IrisTextField from "UI/muiTextField/IrisTextField";
import IrisSelect from "UI/muiSelect/IrisSelect";
import IrisTimeRangePickers from "UI/muiTimeRangePickers/IrisTimeRangePickers";
import IrisMultiSelect from "UI/muiSelect/IrisMultiSelect";

import {
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CLIENT_WIDGETS,
  getHardCodedDefects,
} from "pages/clientsUsersGroups/statics";
import { useRef } from "react";
import KeyValueList from "./KeyValueList.component";
import NotificationGroup from "../components/NotificationGroup";
import { getCSAMs } from "utils/requests";
import { AuthContext } from "context/AuthContext";

export const DEFAULT_ASSIGNEE_DEFECT_OBJECT = {
  name: "",
  interested_defects: [],
};

// construct timezone options with array data from moment-timezone
const timezoneOptions = momentTZ.tz
  .names()
  .map((name, index) => ({ id: index.toString(), name: name }));

// tabs css styles
const useStyles = makeStyles((theme) => ({
  tabs: {
    color: "#757575",
    "& .MuiTabs-indicator": {
      height: 0,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#ffff",
      backgroundColor: theme.palette.primary.main,
    },
  },
  tab: {
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
}));

/**
 * both CreateClient and ClientDetails share this UI structure
 * @param {Object} props
 * @param {string} [props.clientID] only when client details page is applied, client id is imported
 * @param {string} props.pageHeader page heading text
 * @param {Object} props.clientDetailsState state of all info displaying on the page
 * @param {Function} props.updateClientName function to update client name
 * @param {Function} props.updateDeviceCount function to update device count
 * @param {Function} props.updateMonthlyLimit function to update client month limit
 * @param {Function} props.updateStartTimeEndTime function to update shifts start time and end time
 * @param {Function} props.updateShifts function to update shifts
 * @param {Function} props.updateGeoName function to update geo name
 * @param {Function} props.updateGeoLat function to update latitude
 * @param {Function} props.updateGeoLon function to update longitude
 * @param {Function} props.updateGeoProvince function to update province
 * @param {Function} props.updateTimezone function to update timezone
 * @param {Function} props.updateGeoFencing function to update geo fencing
 * @param {Function} props.updateWidgets function to update widgets
 * @param {Function} props.updateWOP function to update work order profile
 * @param {Function} props.resetTo function to reset the state to the previous one
 * @param {boolean} [props.isEditMode] indicate if the page is editable or not
 * @param {Function} [props.setIsEditMode] function to toggle the state of isEditMode
 * @param {boolean} props.openBackdrop indicate if the backdrop is open or not
 * @param {string} props.submitState indicate which step in on going during submit
 * @param {string} props.modalMessage text shown on modal
 * @param {Function} props.handleClickBackdrop function triggered when clicking on backdrop
 * @param {Objcet} props.tempStore object to store previous state in case cancel during editing
 * @param {Function} [props.handleUpdate] function to update client info of an existing client
 * @param {Function} [props.handleSubmit] function to create a new client
 */
const ClientDetails = (props) => {
  const {
    clientID = "",
    pageHeader,
    clientDetailsState,
    updateClientName,
    updateMonthlyLimit,
    updateDeviceCount,
    updateStartTimeEndTime,
    updateShifts,
    updateGeoName,
    updateLogo,
    updateGeoLat,
    updateGeoLon,
    updateGeoProvince,
    updateTimezone,
    updateCSAMS,
    updateGeoFencing,
    updateWidgets,
    updateWOP,
    updateManualAppProfile,
    resetTo,
    isEditMode,
    setIsEditMode,
    openBackdrop,
    submitState,
    modalMessage,
    handleClickBackdrop,
    tempStore,
    handleUpdate,
    handleSubmit,
  } = props;

  const history = useHistory();
  const classes = useStyles();
  const { awsToken } = useContext(AuthContext);

  const [CSAMList, setCSAMList] = useState([]);

  // set state to control the active tab
  const [activeTabId, setActiveTabId] = useState(0);

  // set state to verify if geo fencing inputs are ok
  const [geoFencingOK, setGeoFencingOK] = useState(true);
  // set state to verify if widgets inputs are ok
  const [widgetsOK, setWidgetsOK] = useState(true);
  // set state to verify if assignees are ok
  const [assigneesOK, setAssigneesOK] = useState(true);

  // set state to indicate if all of the input on the page all ok
  const [allInputOK, setAllInputOK] = useState(false);

  // set state to verify if input latitude and longitude is ok
  const [latVerified, setLatVerified] = useState(true);
  const [lonVerified, setLonVerified] = useState(true);

  // set state to calculate the total minutes of selected shifts
  const [shiftsMin, setShiftsMin] = useState(1439);
  // set state to indicate if input shifts come to make 24 hours totally
  const [shiftsVerified, setShiftsVerified] = useState(false);

  // set state to indicate if widgets input is required
  const [widgetsChecked, setWidgetsChecked] = useState(false);

  // set state to indicate if work order profile input is required
  const [wopChecked, setWopChecked] = useState(false);

  // set state to indicate if manual app profile input is required
  const [manualAppChecked, setManualAppChecked] = useState(false);

  // set state to store checked state of both widgets checked and work order profile checked
  const [checkedState, setCheckedState] = useState({
    currWidgetsChecked: widgetsChecked,
    currWOPChecked: wopChecked,
  });

  const widgetKeys =
    clientDetailsState?.widgets.map((widget) => widget.key) || [];
  const selectedWidgetKeys = useRef(widgetKeys);

  // handle switch tab
  const handleChangeTab = (e, newValue) => {
    setActiveTabId(newValue);
  };

  /**
   * triggers when inputting latitude
   * Also, check out {@link https://www.pgc.umn.edu/apps/convert/}
   */
  const handleLatInput = (e) => {
    /** regex to set latitude input rules  */
    const latPattern = /^[-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/g;

    const inputStr = e.target.value;

    // only when the input latitude is not empty string
    if (inputStr !== "") {
      if (inputStr.match(latPattern) !== null) {
        // round input number to 4 decimal digits
        const roundNum = Number(parseFloat(inputStr).toFixed(4));
        // latitude decimal degrees is accepted from -90 to 90
        setLatVerified(roundNum >= -90 && roundNum <= 90);
      } else {
        setLatVerified(false);
      }
    } else {
      setLatVerified(true);
    }

    updateGeoLat(e.target.value);
  };

  /**
   * triggers when inputting longitude
   * Also, check out {@link https://www.pgc.umn.edu/apps/convert/}
   */
  const handleLonInput = (e) => {
    /** regex to set longitude input rules  */
    const longPattern = /^[-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/g;

    const inputStr = e.target.value;

    // only when the input longitude is not empty string
    if (inputStr !== "") {
      if (inputStr.match(longPattern) !== null) {
        // round input number to 4 decimal digits
        const roundNum = Number(parseFloat(inputStr).toFixed(4));
        // longitude decimal degrees is accepted from -180 to 180
        setLonVerified(roundNum >= -180 && roundNum <= 180);
      } else {
        setLonVerified(false);
      }
    } else {
      setLonVerified(true);
    }

    updateGeoLon(e.target.value);
  };

  // get dynamic helper text for latitude input field
  const latHelperText = () => {
    return (
      <span className={styles.helperText}>
        <span>input as decimal degrees</span>
        {clientDetailsState.geo_lat !== "" && !latVerified && (
          <span style={{ color: "#c1151f" }}>latitude not valid</span>
        )}
      </span>
    );
  };
  // get dynamic helper text for longitude input field
  const lonHelperText = () => {
    return (
      <span className={styles.helperText}>
        <span>input as decimal degrees</span>
        {clientDetailsState.geo_lon !== "" && !lonVerified && (
          <span style={{ color: "#c1151f" }}>longitude not valid</span>
        )}
      </span>
    );
  };

  // submit create client data
  const handleCreateClient = () => {
    // construct the service object to submit
    let submitService = {
      iris_city: {
        widgets: [],
        w_o_profile: {},
        name: clientDetailsState.name,
      },
      name: `${clientDetailsState.name} Services`,
    };
    if (widgetsChecked) {
      submitService = {
        ...submitService,
        iris_city: {
          ...submitService.iris_city,
          widgets: clientDetailsState.widgets,
        },
      };
    }
    if (wopChecked) {
      const tempWOP = {
        ...clientDetailsState.w_o_profile,
        search_fields: clientDetailsState.w_o_profile.search_fields.map(
          (field) => field.id
        ),
      };
      submitService = {
        ...submitService,
        iris_city: {
          ...submitService.iris_city,
          w_o_profile: tempWOP,
        },
      };
    }

    if (manualAppChecked) {
      const additional_setting = {};
      additional_setting["mdss"] = enableMDSS;
      additional_setting["show_workorder"] = showWorkOrders;
      additional_setting["routes"] = routes;
      additionalSettings.forEach((keyValue) => {
        const key = keyValue[0];
        const value = keyValue[1];
        if (key.trim() !== "" && value.trim() !== "")
          additional_setting[key] = value;
      });
      submitService = {
        ...submitService,
        iris_city: {
          ...submitService.iris_city,
          manual_app_profile: {
            shift: clientDetailsState.m_a_profile.shift.split(","),
            coverage_layer: clientDetailsState.m_a_profile.coverage_layer,
            additional_setting: additional_setting,
          },
        },
      };
    }

    if (!widgetsChecked && !wopChecked) {
      submitService = {};
    }

    handleSubmit({ ...clientDetailsState, service: submitService });
  };

  /**
   * callback function for the text field for entering assignee's name
   * 1. update corresponding (by indexing) value under **w_o_profile#assignees** array
   * 2. update corresponding object (by indexing), namely **target**, under **w_o_profile#options#assignee_defect_array**
   * so that **target#name** = input_value
   * @param {number} index
   * @returns
   */
  const handleUpdateAssigneeName = (index) => (e) => {
    const { w_o_profile: workorderProfile } = clientDetailsState;

    const assigneesTemp = JSON.parse(
      JSON.stringify(workorderProfile.assignees)
    );
    assigneesTemp[index] = e.target.value;

    const assignee_defect_array = JSON.parse(
      JSON.stringify(workorderProfile.options.assignee_defect_array || [])
    );

    const target = assignee_defect_array[index] || {
      ...DEFAULT_ASSIGNEE_DEFECT_OBJECT,
    };
    target.name = e.target.value;
    assigneesTemp[index] = e.target.value;
    assignee_defect_array[index] = target;

    updateWOP({
      ...workorderProfile,
      assignees: assigneesTemp,
      options: {
        ...workorderProfile.options,
        assignee_defect_array,
      },
    });
  };

  /**
   * function to handle update of an assignee's interested defects under **w_o_profile#options#assignee_defect_array**
   * @param {number} index
   * @returns
   */
  const handleUpdateAssigneeInterestedDefect = (index) => (e, values) => {
    const w_o_profile = clientDetailsState.w_o_profile;
    const assignees = JSON.parse(JSON.stringify(w_o_profile.assignees));
    const assignee_defect_array = JSON.parse(
      JSON.stringify(w_o_profile.options.assignee_defect_array || [])
    );
    assignee_defect_array[index] = {
      name: assignees[index],
      interested_defects: values,
    };

    const workorderProfile = JSON.parse(JSON.stringify(w_o_profile));
    const options = workorderProfile.options || {};
    options.assignee_defect_array = assignee_defect_array;
    workorderProfile.options = options;
    updateWOP(workorderProfile);
  };

  /**
   * callback function of the remove assignee button
   * 1. remove assigne from **w_o_profile#assignees** array
   * 2. remove object, namely **target**, from the **w_o_profile#options#assignee_defect_array** array
   * where **target#name** equals **removed_assignee**
   * @param {number} index
   * @returns
   */
  const handleRemoveAssignee = (index) => () => {
    const tempAssignees = clientDetailsState.w_o_profile.assignees.filter(
      (assignee, tempIndex) => tempIndex !== index
    );

    const assignee_defect_array = JSON.parse(
      JSON.stringify(
        clientDetailsState.w_o_profile.options.assignee_defect_array
      )
    );

    assignee_defect_array.splice(index, 1);
    updateWOP({
      ...clientDetailsState.w_o_profile,
      assignees: tempAssignees,
      options: {
        ...clientDetailsState.w_o_profile.options,
        assignee_defect_array,
      },
    });
  };

  // submit updated client info data
  const handleUpdateClient = () => {
    // construct the service object to submit
    let submitService = {
      iris_city: {
        id: clientDetailsState.iris_city_id,
        widgets: [],
        w_o_profile: {},
        name: clientDetailsState.city_name,
      },
      id: clientDetailsState.service_id,
      name: `${clientDetailsState.name} Services`,
    };
    if (widgetsChecked) {
      submitService = {
        ...submitService,
        iris_city: {
          ...submitService.iris_city,
          widgets: clientDetailsState.widgets,
        },
      };
    }
    if (wopChecked) {
      const tempWOP = {
        ...clientDetailsState.w_o_profile,
        search_fields: clientDetailsState.w_o_profile.search_fields.map(
          (field) => field.id
        ),
      };
      submitService = {
        ...submitService,
        iris_city: {
          ...submitService.iris_city,
          w_o_profile: tempWOP,
        },
      };
    }

    if (manualAppChecked) {
      const additional_setting = {};
      additional_setting["mdss"] = enableMDSS;
      additional_setting["show_workorder"] = showWorkOrders;
      additional_setting["routes"] = routes;
      additionalSettings.forEach((keyValue) => {
        const key = keyValue[0];
        const value = keyValue[1];
        if (key.trim() !== "" && value.trim() !== "")
          additional_setting[key] = value;
      });
      submitService = {
        ...submitService,
        iris_city: {
          ...submitService.iris_city,
          manual_app_profile: {
            id: clientDetailsState.m_a_profile.id,
            shift: clientDetailsState.m_a_profile.shift.split(","),
            coverage_layer: clientDetailsState.m_a_profile.coverage_layer,
            additional_setting: additional_setting,
          },
        },
      };
    }

    if (!widgetsChecked && !wopChecked) {
      submitService = {};
    }

    handleUpdate({ ...clientDetailsState, service: submitService });
  };

  // datagrid data model prop, general info
  const generalInfo = [
    {
      editable: clientID === "" ? true : false,
      key: "name",
      label: "Name",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "name" },
      onChange: (value) => {
        updateClientName(value);
      },
      helperText: "User name should be one word",
    },
    {
      editable: true,
      key: "device_count",
      label: "Device Count",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "name" },
      onInput: (e) => (e.target.value = e.target.value.replace(/[^0-9]/g, "")),
      onChange: (value) => {
        updateDeviceCount(value);
      },
    },
    // {
    //   editable: true,
    //   key: "logo",
    //   label: "No File Found",
    //   type: "file-input",
    //   onChange: (event) => {
    //     const file = event.target.files[0]
    //     console.log(file)
    //     updateLogo(file)
    //   },
    //   helperText: "Upload Logo",
    // },
    {
      editable: true,
      key: "monthly_limit",
      label: "Monthly Limit",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "name" },
      onInput: (e) => (e.target.value = e.target.value.replace(/[^0-9]/g, "")),
      onChange: (value) => {
        updateMonthlyLimit(value);
      },
      helperText: "Monthly Coverage Limit in KMs",
    },
    {
      editable: true,
      key: "CSAMS",
      label: "Assigned CSAMs",
      type: "multi-select",
      options: {
        options: CSAMList,
        idKey: "id",
        titleKey: "firstName",
        sx: { width: "15vw" },
      },
      onChange: (value) => {
        updateCSAMS(value);
      },
    },
  ];

  // datagrid data model prop, geo info
  const geoInfo = [
    {
      editable: false,
      key: "geo_name",
      label: "Geography Information Title",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "name" },
    },
    {
      editable: true,
      key: "geo_province",
      label: "Address",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "name" },
      onChange: (e) => {
        const value = e.target.value;
        updateGeoProvince(value);
      },
    },
    {
      editable: true,
      key: "geo_lat",
      label: "Latitude",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "name" },
      onInput: (e) =>
        (e.target.value = e.target.value.replace(/[^0-9-.]/g, "")),
      onChange: handleLatInput,
      helperText: latHelperText(),
    },
    {
      editable: true,
      key: "geo_lon",
      label: "Longitude",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "name" },
      onInput: (e) =>
        (e.target.value = e.target.value.replace(/[^0-9-.]/g, "")),
      onChange: handleLonInput,
      helperText: lonHelperText(),
    },
    {
      editable: true,
      key: "geo_timezone",
      label: "Timezone",
      type: "select",
      options: {
        options: [...timezoneOptions],
        idKey: "name",
        titleKey: "name",
      },
      onChange: (e) => {
        const value = e.target.value;
        updateTimezone(value);
      },
    },
  ];

  // datagrid data model prop, work order profile info
  const workOrderProfileInfo = [
    {
      editable: false,
      key: "name",
      label: "Work Order Profile Name",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "name" },
    },
    {
      editable: true,
      key: "search_fields",
      label: "Search Fields",
      type: "multi-select",
      options: {
        options: SEARCH_FIELDS_OPTIONS,
        idKey: "id",
        titleKey: "fieldName",
      },
      onChange: (value) => {
        updateWOP({
          ...clientDetailsState.w_o_profile,
          search_fields: value,
        });
      },
    },
  ];

  if (pageHeader !== "Create a new customer")
    workOrderProfileInfo.push({
      editable: true,
      key: "shape_file_url",
      label: "No File Found",
      type: "file-input",

      onChange: (event) => {
        const file = event.target.files[0];
        console.log(file);
        updateWOP({
          ...clientDetailsState.w_o_profile,
          shape_file_url: file.name,
          shapefile: file,
        });
      },
      helperText: "Upload Shapefile",
      accept: ".zip",
    });

  // datagrid data model prop, work order profile info
  const maunalAppProfileInfo = [
    {
      editable: true,
      key: "shift",
      label: "Shift",
      type: "input-tooltip",
      options: { options: [], idKey: "id", titleKey: "shift" },
      onChange: (value) => {
        updateManualAppProfile({
          ...clientDetailsState.m_a_profile,
          shift: value,
        });
      },
    },
    {
      editable: true,
      key: "coverage_layer",
      label: "Coverage Map URL",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "coverage_layer" },
      onChange: (value) => {
        updateManualAppProfile({
          ...clientDetailsState.m_a_profile,
          coverage_layer: value,
        });
      },
    },
  ];

  const [additionalSettings, setAdditionalSettings] = React.useState([]);
  const [enableMDSS, setEnableMDSS] = React.useState(false);
  const [showWorkOrders, setshowWorkOrders] = React.useState(false);
  const [routes, setRoutes] = React.useState([]);

  const onAddAdditionalSettings = () => {
    setAdditionalSettings([...additionalSettings, ["", ""]]);
  };
  const onDeleteAdditionalSettings = (index) => {
    additionalSettings.splice(index, 1);
    setAdditionalSettings([...additionalSettings]);
  };
  const onUpdateAdditionalSettings = (value, index, PROP) => {
    const newArr = [...additionalSettings];
    newArr[index][PROP === "KEY" ? 0 : 1] = value;
    setAdditionalSettings(newArr);
  };

  const onAddRoutes = () => {
    setRoutes([...routes, ["", ""]]);
  };
  const onDeleteRoutes = (index) => {
    routes.splice(index, 1);
    setRoutes([...routes]);
  };
  const onUpdateRoutes = (value, index, PROP) => {
    const newArr = [...routes];
    newArr[index][PROP === "KEY" ? 0 : 1] = value;
    setRoutes(newArr);
  };

  /**
   * callback function for adding a widget from the list
   */
  const onAddWidget = () => {
    const widgetKeys = clientDetailsState.widgets.map((widget) => widget.key);
    selectedWidgetKeys.current = widgetKeys;

    updateWidgets([
      ...clientDetailsState.widgets,
      {
        key: "",
        title: "",
        link: "",
        city: clientDetailsState.iris_city_id,
      },
    ]);
  };

  /**
   * callback function for removing a widget from the list
   */
  const onRemoveWidget = () => {
    if (clientDetailsState.widgets.length > 1) {
      const widgetsTemp = JSON.parse(
        JSON.stringify(clientDetailsState.widgets)
      );
      widgetsTemp.pop();
      updateWidgets(widgetsTemp);
    }
  };

  /**
   * callback function for removing the the current widget
   */
  const onRemoveWidgetByButton = (index) => () => {
    const tempWidgets = clientDetailsState.widgets.filter(
      (widget, tempIndex) => tempIndex !== index
    );
    updateWidgets(tempWidgets);

    const widgetKeys = tempWidgets.map((widget) => widget.key);
    selectedWidgetKeys.current = widgetKeys;
  };
  // each section stands for one tab content
  const sections = [
    {
      name: "General Information",
      content: (
        <div>
          <div className={`${styles.pageChild} ${styles.generalInfo}`}>
            {generalInfo.map((model, index) => {
              const {
                editable,
                key,
                label,
                type,
                options,
                onChange,
                onInput,
                helperText,
                accept,
              } = model;

              if (key === "groupList") {
                return (
                  <div className={styles.groupList} key={index}>
                    {label}:{" "}
                    <div>
                      {clientDetailsState[key].map((oneGroup) => (
                        <Button
                          className={styles.muiButton}
                          key={oneGroup.id}
                          disabled={isEditMode}
                          variant="outlined"
                          onClick={() =>
                            history.push(`/group-details/${oneGroup.id}`)
                          }
                        >
                          {oneGroup[options.titleKey]}
                        </Button>
                      ))}
                    </div>
                  </div>
                );
              }

              return (
                <div className={styles.generalInfoInput} key={index}>
                  {type === "input" && (
                    <IrisTextField
                      disabled={editable ? !isEditMode : true}
                      fullWidth
                      required
                      label={label}
                      variant="standard"
                      value={clientDetailsState[key]}
                      onInput={(e) => {
                        if (onInput) {
                          onInput(e);
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value;
                        onChange(value);
                      }}
                      helperText={helperText}
                    />
                  )}
                  {type === "select" && (
                    <IrisSelect
                      fullWidth
                      disabled={editable ? !isEditMode : true}
                      options={options.options}
                      keyField={options.idKey}
                      titleField={options.titleKey}
                      selectProps={{
                        value: clientDetailsState[key],

                        variant: "standard",
                        disabled: !isEditMode,
                        onChange: (event) => {
                          const value = event.target.value;
                          onChange(value);
                        },
                      }}
                      label={label}
                    />
                  )}
                  {type === "file-input" && (
                    <div
                      style={{
                        paddingTop: "2vh",
                      }}
                    >
                      <Grid container>
                        <Grid item={true} columns={6}>
                          <IrisTextField
                            disabled={true}
                            fullWidth
                            required
                            variant="standard"
                            value={
                              clientDetailsState[key]
                                ? clientDetailsState[key].name
                                : label
                            }
                          />
                        </Grid>
                        <Grid item={true} columns={1}>
                          &nbsp;
                        </Grid>
                        <Grid item={true} columns={5}>
                          <Button
                            variant="contained"
                            component="label"
                            size="small"
                            disabled={!isEditMode}
                          >
                            {helperText}
                            <input
                              type="file"
                              hidden
                              onChange={onChange}
                              accept={accept}
                              style={{ display: "none" }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {type === "multi-select" && (
                    <IrisMultiSelect
                      textFieldStyle="standard"
                      labelName={label}
                      disabled={!isEditMode}
                      options={options.options}
                      value={clientDetailsState[key]}
                      keyName={options.titleKey}
                      updateSelectedOptions={onChange}
                      sx={options.sx}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.pageChild}>
            <IncrementDecrementButton
              header="Shifts"
              headerFontSize="1.1rem"
              incrementDisabled={!isEditMode}
              decrementDisabled={
                !isEditMode || clientDetailsState.shifts.length <= 1
              }
              onIncrement={() => {
                updateStartTimeEndTime([
                  ...clientDetailsState.shifts_start_time_end_time,
                  ["00:00:00", "23:59:00"],
                ]);
                updateShifts([...clientDetailsState.shifts, "00:00-23:59"]);
              }}
              onDecrement={() => {
                if (clientDetailsState.shifts.length > 1) {
                  if (clientDetailsState.shifts.length === 2) {
                    updateStartTimeEndTime([["00:00:00", "23:59:00"]]);
                    updateShifts(["00:00-23:59"]);
                  } else {
                    const tempShiftsStartTimeEndTime = JSON.parse(
                      JSON.stringify(
                        clientDetailsState.shifts_start_time_end_time
                      )
                    );
                    const tempShifts = JSON.parse(
                      JSON.stringify(clientDetailsState.shifts)
                    );
                    tempShiftsStartTimeEndTime.pop();
                    tempShifts.pop();
                    updateStartTimeEndTime(tempShiftsStartTimeEndTime);
                    updateShifts(tempShifts);
                  }
                }
              }}
              counter={clientDetailsState.shifts.length}
            />
            {clientDetailsState.shifts.length > 1 && (
              <div
                style={{
                  color: "#bdbdbd",
                }}
              >
                <span
                  style={{
                    color: shiftsVerified ? "" : "#c1151f",
                  }}
                >
                  {`${(shiftsMin - (shiftsMin % 60)) / 60}h ${
                    shiftsMin % 60
                  }min is
            selected. `}
                </span>
                <span
                  style={{
                    color: shiftsVerified ? "" : "#424242",
                  }}
                >
                  Input shifts should be 24h 0min totally.
                </span>
              </div>
            )}
            <div className={styles.timePickers}>
              {clientDetailsState.shifts_start_time_end_time.map(
                (stet, index) => (
                  <div key={index} className={styles.oneTimePicker}>
                    <IrisTimeRangePickers
                      style={{ marginRight: "2rem" }}
                      startTime={stet[0]}
                      endTime={stet[1]}
                      disabled={
                        !isEditMode || clientDetailsState.shifts.length <= 1
                      }
                      onChange={(st, et) => {
                        // to update certain element of the array, deep copy the prev state
                        const startTimeEndTimeTemp = JSON.parse(
                          JSON.stringify(
                            clientDetailsState.shifts_start_time_end_time
                          )
                        );
                        startTimeEndTimeTemp[index][0] = st;
                        startTimeEndTimeTemp[index][1] = et;
                        updateStartTimeEndTime(startTimeEndTimeTemp);

                        const temp = `${moment(st, "HH:mm:ss").format(
                          "HH:mm"
                        )}-${moment(et, "HH:mm:ss").format("HH:mm")}`;
                        const shiftsTemp = JSON.parse(
                          JSON.stringify(clientDetailsState.shifts)
                        );
                        shiftsTemp[index] = temp;
                        updateShifts(shiftsTemp);
                      }}
                    />
                    <Button
                      variant="outlined"
                      disabled={
                        !isEditMode || clientDetailsState.shifts.length <= 1
                      }
                      onClick={() => {
                        if (clientDetailsState.shifts.length === 2) {
                          updateStartTimeEndTime([["00:00:00", "23:59:00"]]);
                          updateShifts(["00:00-23:59"]);
                        } else {
                          const tempSTET =
                            clientDetailsState.shifts_start_time_end_time.filter(
                              (stet, tempIndex) => tempIndex !== index
                            );
                          updateStartTimeEndTime(tempSTET);
                          const tempShifts = clientDetailsState.shifts.filter(
                            (shift, tempIndex) => tempIndex !== index
                          );
                          updateShifts(tempShifts);
                        }
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Geographic Information",
      content: (
        <div>
          <div className={`${styles.pageChild} ${styles.geoInfo}`}>
            {geoInfo.map((model, index) => {
              const { editable, key, label, type, options, onChange } = model;

              return (
                <div className={styles.geoInfoInput} key={index}>
                  {type === "input" && (
                    <IrisTextField
                      disabled={editable ? !isEditMode : true}
                      required
                      fullWidth
                      label={label}
                      variant="standard"
                      value={clientDetailsState[key]}
                      onInput={model?.onInput}
                      onChange={onChange}
                      helperText={model?.helperText}
                    />
                  )}
                  {type === "select" && (
                    <IrisSelect
                      fullWidth
                      required
                      disabled={editable ? !isEditMode : true}
                      options={options.options}
                      keyField={options.idKey}
                      titleField={options.titleKey}
                      selectProps={{
                        value: clientDetailsState[key],
                        variant: "standard",
                        disabled: !isEditMode,
                        onChange: onChange,
                      }}
                      label={label}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ),
    },
    {
      name: "Widgets",
      content: (
        <div className={styles.pageChild}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!isEditMode}
                checked={widgetsChecked}
                onChange={(e) => {
                  setWidgetsChecked(e.target.checked);
                  if (e.target.checked) {
                    if (clientDetailsState.widgets.length === 0) {
                      updateWidgets([{ key: "", title: "", link: "" }]);
                    }
                  }
                }}
              />
            }
            label={<h4>Widgets</h4>}
          />

          {widgetsChecked && (
            <div>
              <div className={styles.widgetsHeading}>
                <IncrementDecrementButton
                  header="Widgets"
                  headerFontSize="1.1rem"
                  onIncrement={onAddWidget}
                  onDecrement={onRemoveWidget}
                  counter={clientDetailsState.widgets.length}
                  incrementDisabled={!isEditMode}
                  decrementDisabled={!isEditMode}
                />
              </div>
              {clientDetailsState.widgets.length > 0 &&
                clientDetailsState.widgets.map((widget, index) => {
                  const { key, title, link } = widget;
                  return (
                    <div key={key} className={styles.oneWidget}>
                      <FormControl
                        sx={{
                          marginInline: 1,
                          marginTop: "3px",
                          minWidth: 240,
                        }}
                      >
                        <InputLabel>Widget</InputLabel>
                        <Select
                          size="small"
                          disabled={!isEditMode}
                          value={key}
                          label="Widget"
                          variant="standard"
                          onChange={(e) => {
                            const widgetKey = e.target.value;

                            const target = CLIENT_WIDGETS[widgetKey];
                            if (target) {
                              const { key, label } = target;

                              const widgetsTemp = JSON.parse(
                                JSON.stringify(clientDetailsState.widgets)
                              );
                              widgetsTemp[index].key = key;
                              widgetsTemp[index].title = label;
                              updateWidgets(widgetsTemp);
                            }
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {Object.values(CLIENT_WIDGETS).map((widget) => {
                            return (
                              <MenuItem
                                disabled={
                                  selectedWidgetKeys.current.indexOf(
                                    widget.key
                                  ) > -1
                                }
                                key={widget.key}
                                value={widget.key}
                              >
                                {widget.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <div className={styles.widgetTextField}>
                        <TextField
                          fullWidth
                          variant="standard"
                          label="link"
                          required={true}
                          value={link}
                          disabled={!isEditMode}
                          type="text"
                          onChange={(e) => {
                            const widgetsTemp = JSON.parse(
                              JSON.stringify(clientDetailsState.widgets)
                            );
                            widgetsTemp[index].link = e.target.value;
                            updateWidgets(widgetsTemp);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <Button
                        variant="outlined"
                        disabled={
                          !isEditMode || clientDetailsState.widgets.length <= 1
                        }
                        onClick={onRemoveWidgetByButton(index)}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Work Order Profile",
      content: (
        <div className={styles.pageChild}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!isEditMode}
                checked={wopChecked}
                onChange={(e) => {
                  setWopChecked(e.target.checked);
                  if (e.target.checked) {
                  }
                }}
              />
            }
            label={<h4>Work Order Profile</h4>}
          />
          {wopChecked && (
            <>
              <div className={styles.workOrderProfile}>
                {typeof clientDetailsState.w_o_profile.name === "string" &&
                  workOrderProfileInfo.map((model, index) => {
                    const {
                      editable,
                      key,
                      label,
                      type,
                      options,
                      onChange,
                      onInput,
                      helperText,
                      accept,
                    } = model;

                    return (
                      <div className={styles.geoInfoInput} key={index}>
                        {type === "input" && (
                          <IrisTextField
                            disabled={editable ? !isEditMode : true}
                            fullWidth
                            label={label}
                            required
                            variant="standard"
                            value={clientDetailsState.w_o_profile[key]}
                            onInput={(e) => {
                              if (onInput) {
                                onInput(e);
                              }
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              onChange(value);
                            }}
                          />
                        )}
                        {type === "text Area" && (
                          <IrisTextField
                            disabled={editable ? !isEditMode : true}
                            fullWidth
                            label={label}
                            required
                            variant="standard"
                            value={clientDetailsState.w_o_profile[key]}
                            onInput={(e) => {
                              if (onInput) {
                                onInput(e);
                              }
                            }}
                            onChange={(e) => {
                              const value = e.target.value;
                              onChange(value);
                            }}
                          />
                        )}
                        {type === "select" && (
                          <IrisSelect
                            fullWidth
                            required
                            disabled={editable ? !isEditMode : true}
                            options={options.options}
                            keyField={options.idKey}
                            titleField={options.titleKey}
                            selectProps={{
                              value: clientDetailsState.w_o_profile[key],
                              variant: "standard",
                              disabled: !isEditMode,
                              onChange: (event) => {
                                const value = event.target.value;
                                onChange(value);
                              },
                            }}
                            label={label}
                          />
                        )}
                        {type === "multi-select" && (
                          <IrisMultiSelect
                            textFieldStyle="standard"
                            labelName={label}
                            disabled={!isEditMode}
                            options={options.options}
                            value={clientDetailsState.w_o_profile[key]}
                            keyName={options.titleKey}
                            updateSelectedOptions={onChange}
                          />
                        )}
                        {type === "file-input" && (
                          <div
                            style={{
                              paddingTop: "2vh",
                            }}
                          >
                            <Grid container>
                              <Grid item={true} columns={6}>
                                <IrisTextField
                                  disabled={true}
                                  fullWidth
                                  required
                                  variant="standard"
                                  value={clientDetailsState.w_o_profile[key]}
                                />
                              </Grid>
                              <Grid item={true} columns={1}>
                                &nbsp;
                              </Grid>
                              <Grid item={true} columns={5}>
                                <Button
                                  variant="contained"
                                  component="label"
                                  size="small"
                                  disabled={!isEditMode}
                                >
                                  {helperText}
                                  <input
                                    type="file"
                                    hidden
                                    onChange={onChange}
                                    accept={accept}
                                    style={{ display: "none" }}
                                  />
                                </Button>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className={styles.assignees}>
                {clientDetailsState.w_o_profile.assignees && (
                  <IncrementDecrementButton
                    header="Assignees"
                    headerFontSize="1.1rem"
                    onIncrement={() => {
                      updateWOP({
                        ...clientDetailsState.w_o_profile,
                        assignees: [
                          ...clientDetailsState.w_o_profile.assignees,
                          "",
                        ],
                      });
                    }}
                    onDecrement={() => {
                      if (clientDetailsState.w_o_profile.assignees.length > 0) {
                        const assigneesTemp = JSON.parse(
                          JSON.stringify(
                            clientDetailsState.w_o_profile.assignees
                          )
                        );
                        assigneesTemp.pop();
                        updateWOP({
                          ...clientDetailsState.w_o_profile,
                          assignees: assigneesTemp,
                        });
                      }
                    }}
                    counter={clientDetailsState.w_o_profile.assignees.length}
                    incrementDisabled={!isEditMode || !assigneesOK}
                    decrementDisabled={
                      !isEditMode ||
                      clientDetailsState.w_o_profile.assignees.length < 1
                    }
                  />
                )}
                {clientDetailsState.w_o_profile.assignees &&
                  clientDetailsState.w_o_profile.assignees.map(
                    (assignee, index) => {
                      const res =
                        clientDetailsState.w_o_profile?.options
                          ?.assignee_defect_array || [];
                      const target = res[index];
                      let interested_defects = target?.interested_defects || [];
                      return (
                        <div key={index} className={styles.oneAssignee}>
                          <IrisTextField
                            disabled={!isEditMode}
                            className={styles.assigneeInput}
                            variant="standard"
                            required
                            value={assignee}
                            onChange={handleUpdateAssigneeName(index)}
                          />
                          <Autocomplete
                            multiple
                            style={{
                              flex: 1,
                              marginRight: "0.5rem",
                            }}
                            sx={{
                              ".MuiFormControl-root": {
                                marginBottom: 0,
                              },
                            }}
                            disabled={!isEditMode}
                            options={getHardCodedDefects()}
                            onChange={handleUpdateAssigneeInterestedDefect(
                              index
                            )}
                            value={interested_defects}
                            filterSelectedOptions
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                margin="normal"
                                variant="standard"
                              />
                            )}
                          />
                          <Button
                            variant="outlined"
                            className={styles.assigneeDeleteButton}
                            disabled={!isEditMode}
                            onClick={handleRemoveAssignee(index)}
                          >
                            <CloseIcon />
                          </Button>
                        </div>
                      );
                    }
                  )}
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      name: "Manual App Profile",
      content: (
        <div className={styles.pageChild}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={!isEditMode}
                checked={manualAppChecked}
                onChange={(e) => {
                  setManualAppChecked(e.target.checked);
                  if (e.target.checked) {
                  }
                }}
              />
            }
            label={<h4>Manual App Profile</h4>}
          />
          {manualAppChecked && (
            <>
              <div className={styles.workOrderProfile}>
                {maunalAppProfileInfo.map((model, index) => {
                  const {
                    editable,
                    key,
                    label,
                    type,
                    options,
                    onChange,
                    onInput,
                  } = model;

                  return (
                    <div className={styles.geoInfoInput} key={index}>
                      {type === "input" && (
                        <IrisTextField
                          disabled={editable ? !isEditMode : true}
                          fullWidth
                          label={label}
                          required
                          variant="standard"
                          value={clientDetailsState.m_a_profile[key]}
                          onInput={(e) => {
                            if (onInput) {
                              onInput(e);
                            }
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            onChange(value);
                          }}
                        />
                      )}
                      {type === "input-tooltip" && (
                        <Grid container>
                          <Grid item xs={10}>
                            <IrisTextField
                              disabled={editable ? !isEditMode : true}
                              fullWidth
                              label={label}
                              required
                              variant="standard"
                              value={clientDetailsState.m_a_profile[key]}
                              onInput={(e) => {
                                if (onInput) {
                                  onInput(e);
                                }
                              }}
                              onChange={(e) => {
                                const value = e.target.value;
                                onChange(value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              style={{ transform: "translateY(36%)" }}
                              title={
                                <React.Fragment>
                                  <Typography color="inherit">
                                    How to use
                                  </Typography>
                                  Please use comma separated values eg:
                                  Morning,Afternoon,Night
                                </React.Fragment>
                              }
                            >
                              <IconButton color="primary">
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      )}
                      {type === "select" && (
                        <IrisSelect
                          fullWidth
                          required
                          disabled={editable ? !isEditMode : true}
                          options={options.options}
                          keyField={options.idKey}
                          titleField={options.titleKey}
                          selectProps={{
                            value: clientDetailsState.m_a_profile[key],
                            variant: "standard",
                            disabled: !isEditMode,
                            onChange: (event) => {
                              const value = event.target.value;
                              onChange(value);
                            },
                          }}
                          label={label}
                        />
                      )}
                      {type === "multi-select" && (
                        <IrisMultiSelect
                          textFieldStyle="standard"
                          labelName={label}
                          disabled={!isEditMode}
                          options={options.options}
                          value={clientDetailsState.m_a_profile[key]}
                          keyName={options.titleKey}
                          updateSelectedOptions={onChange}
                        />
                      )}
                    </div>
                  );
                })}

                <div style={{ padding: "10px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isEditMode}
                        checked={showWorkOrders}
                        onChange={(e) => {
                          setshowWorkOrders(e.target.checked);
                        }}
                      />
                    }
                    label="Show Work Order"
                  />
                </div>
                <div style={{ padding: "10px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isEditMode}
                        checked={enableMDSS}
                        onChange={(e) => {
                          setEnableMDSS(e.target.checked);
                        }}
                      />
                    }
                    label="Enable MDSS"
                  />
                </div>
              </div>

                <KeyValueList
                  isEditMode={isEditMode}
                  values={routes}
                  title="Routes"
                  onAdd={onAddRoutes}
                  onDelete={onDeleteRoutes}
                  onUpdate={onUpdateRoutes}
                />

                <KeyValueList
                  isEditMode={isEditMode}
                  values={additionalSettings}
                  title="Additional Settings"
                  onAdd={onAddAdditionalSettings}
                  onDelete={onDeleteAdditionalSettings}
                  onUpdate={onUpdateAdditionalSettings}
                />
            </>
          )}
        </div>
      ),
    },
  ];
  // !TODO Disbaled for release with Iris-auth
  if (pageHeader === "Customer Information")
    sections.push({
      name: "Notifications",
      content: (
        <NotificationGroup
          clientID={clientID}
          clientName={clientDetailsState.name}
        ></NotificationGroup>
      ),
    });

  useEffect(() => {
    if (clientID !== "" || pageHeader === "Create a new customer") {
      let isSubscribed = true;
      if (isSubscribed) {
        getCSAMs(awsToken).then((data) => {
          setCSAMList(data.results);
        });
      }
    }
  }, [clientID, pageHeader, awsToken]);
  // check if input shifts come to make 24 hours and set state of shifts
  useEffect(() => {
    // variable as accumulator to calculate the selected shifts minutes
    let shiftsMinAcc = 0;

    clientDetailsState.shifts_start_time_end_time.forEach((oneSTET) => {
      // to calculate the selected shifts minutes
      const tempStartTime = moment(oneSTET[0], "HH:mm:ss");
      const tempEndTime = moment(oneSTET[1], "HH:mm:ss");
      const momentDiff = tempEndTime.diff(tempStartTime, "minutes");
      // when momentDiff is smaller than 0, end time is one day after start time
      if (momentDiff >= 0) {
        shiftsMinAcc += momentDiff;
      } else {
        shiftsMinAcc += momentDiff + 1440;
      }
    });
    setShiftsMin(shiftsMinAcc);

    if (clientDetailsState.shifts.length > 1) {
      setShiftsVerified(shiftsMinAcc === 1440);
    } else if (clientDetailsState.shifts.length === 1) {
      setShiftsVerified(true);
    }
  }, [
    clientDetailsState.shifts_start_time_end_time,
    clientDetailsState.shifts,
    updateShifts,
  ]);

  // diplay initial widgets and work order profile
  useEffect(() => {
    if (clientDetailsState.widgets.length > 0) {
      if (widgetsChecked === false) {
        setWidgetsChecked(true);
      }
      selectedWidgetKeys.current = clientDetailsState.widgets.map(
        (widget) => widget.key
      );
    }
  }, [clientDetailsState.widgets]);
  useEffect(() => {
    if (
      clientDetailsState.w_o_profile.name ||
      clientDetailsState.w_o_profile.search_fields.length > 0 ||
      clientDetailsState.w_o_profile.assignees.length > 0
    ) {
      if (wopChecked === false) {
        setWopChecked(true);
      }
    }
  }, [clientDetailsState.w_o_profile]);

  useEffect(() => {
    if (
      clientDetailsState.m_a_profile &&
      clientDetailsState.m_a_profile.shift !== "" &&
      clientDetailsState.m_a_profile.coverage_layer !== ""
    ) {
      if (manualAppChecked === false) {
        setManualAppChecked(true);
      }
      if (additionalSettings.length === 0) {
        const additionalSettingsRaw =
          clientDetailsState.m_a_profile["additional_setting"];
        for (let key in additionalSettingsRaw) {
          if (key == "mdss") setEnableMDSS(additionalSettingsRaw[key]);
          else if (key == "show_workorder")
            setshowWorkOrders(additionalSettingsRaw[key]);
          else if (key == "routes") setRoutes(additionalSettingsRaw[key]);
          else additionalSettings.push([key, additionalSettingsRaw[key]]);
        }
        setAdditionalSettings([...additionalSettings]);
      }
    }
  }, [clientDetailsState.m_a_profile]);

  // when switch to edit mode, record the widgets checked state and wop checked state
  // and when switch back, set state of checked state to previous for both of them
  useEffect(() => {
    if (isEditMode) {
      setCheckedState({
        currWidgetsChecked: widgetsChecked,
        currWOPChecked: wopChecked,
      });
    }
  }, [isEditMode]);

  // check if all the geo fencing input fields are filled
  useEffect(() => {
    if (clientDetailsState.geo_fencing.length > 0) {
      let flag = true;
      clientDetailsState.geo_fencing.forEach((oneGeo) => {
        flag = flag && oneGeo.lat !== "" && oneGeo.lon !== "";
      });
      if (flag !== geoFencingOK) {
        setGeoFencingOK(flag);
      }
    } else if (clientDetailsState.geo_fencing.length === 0) {
      setGeoFencingOK(true);
    }
  }, [clientDetailsState.geo_fencing]);
  // check if all the widgets input fields are filled
  useEffect(() => {
    if (clientDetailsState.widgets.length > 0) {
      let flag = true;
      clientDetailsState.widgets.forEach((widget) => {
        flag =
          flag &&
          widget.key.trim() !== "" &&
          widget.title.trim() !== "" &&
          widget.link.trim() !== "";
      });
      if (flag !== widgetsOK) {
        setWidgetsOK(flag);
      }
    }
  }, [clientDetailsState.widgets]);
  // check if all assignees input fields are filled
  useEffect(() => {
    if (clientDetailsState.w_o_profile.assignees.length === 0) {
      setAssigneesOK(true); // make sure the add button works
    }
    if (clientDetailsState.w_o_profile.assignees.length > 0) {
      let flag = true;
      clientDetailsState.w_o_profile.assignees.forEach((assignee) => {
        flag = flag && assignee.trim() !== "";
      });
      if (flag !== assigneesOK) {
        setAssigneesOK(flag);
      }
    }
  }, [clientDetailsState.w_o_profile]);

  // check if all the required fields are filled as required and set state of isReady
  useEffect(() => {
    let flag =
      clientDetailsState.name !== "" &&
      clientDetailsState.device_count !== "" &&
      clientDetailsState.geo_name !== "" &&
      clientDetailsState.geo_province !== "" &&
      clientDetailsState.shifts.length > 0 &&
      clientDetailsState.CSAMS.length > 0 &&
      // shiftsVerified &&
      clientDetailsState.geo_lat !== "" &&
      latVerified &&
      clientDetailsState.geo_lon !== "" &&
      lonVerified;

    if (clientDetailsState.geo_fencing.length > 0) {
      clientDetailsState.geo_fencing.forEach((oneGeo) => {
        flag = flag && oneGeo.lat !== "" && oneGeo.lon !== "";
      });
    }

    if (widgetsChecked) {
      clientDetailsState.widgets.forEach((widget) => {
        flag =
          flag &&
          widget.key.trim() !== "" &&
          widget.title.trim() !== "" &&
          widget.link.trim() !== "";
      });
    }

    if (wopChecked) {
      flag = flag && clientDetailsState.w_o_profile.name !== "";
      flag = flag && clientDetailsState.w_o_profile.search_fields.length > 0;
      clientDetailsState.w_o_profile.assignees.forEach((assignee) => {
        flag = flag && assignee.trim() !== "";
      });
    }

    if (flag !== allInputOK) {
      setAllInputOK(flag);
    }
  }, [
    clientDetailsState.name,
    clientDetailsState.device_count,
    clientDetailsState.shifts,
    shiftsVerified,
    clientDetailsState.geo_lat,
    clientDetailsState.geo_lon,
    clientDetailsState.geo_name,
    clientDetailsState.geo_province,
    clientDetailsState.geo_fencing,
    latVerified,
    lonVerified,
    clientDetailsState.widgets,
    clientDetailsState.w_o_profile,
    widgetsChecked,
    wopChecked,
    clientDetailsState.CSAMS,
  ]);

  return (
    <div className={styles.clientDetails}>
      <h2 className={`${styles.pageChild} ${styles.header}`}>
        {pageHeader}
        {clientID !== "" && (
          <IconButrtons
            isLoading={false}
            isEdit={isEditMode}
            setIsEditMode={setIsEditMode}
            onClear={() => {
              setIsEditMode(false);
              resetTo(tempStore.current);
              setWidgetsChecked(checkedState.currWidgetsChecked);
              setWopChecked(checkedState.currWOPChecked);
              // Set additional settings back to default here as state is handled internally
              const newAdditonalSettings = [];
              const additionalSettingsRaw =
                tempStore.current.m_a_profile["additional_setting"];
              for (let key in additionalSettingsRaw) {
                if (key == "mdss") setEnableMDSS(additionalSettingsRaw[key]);
                else if (key == "show_workorder")
                  setshowWorkOrders(additionalSettingsRaw[key]);
                else if (key == "routes") setRoutes(additionalSettingsRaw[key]);
                else
                  newAdditonalSettings.push([key, additionalSettingsRaw[key]]);
              }
              setAdditionalSettings([...newAdditonalSettings]);
            }}
            onSend={() => {
              handleUpdateClient();
              setIsEditMode(false);
              // useRef to store the current useReducer state, in case onClear triggers the previous state to cover the new state
              tempStore.current = clientDetailsState; // this step is supposed to be done when receiving data from api call
            }}
            disabledSendButton={!allInputOK}
          />
        )}
      </h2>

      <div className={styles.tabs}>
        <Tabs
          className={classes.tabs}
          value={activeTabId}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: { transition: "none" },
          }}
        >
          {sections.map((section, index) => (
            <Tab key={index} className={classes.tab} label={section.name} />
          ))}
        </Tabs>
      </div>

      {sections.map((section, index) => (
        <TabPanel
          key={index}
          value={activeTabId}
          index={index}
          className={styles.tabPanel}
        >
          {section.content}
        </TabPanel>
      ))}

      <div className={`${styles.pageChild} ${styles.buttons}`}>
        {pageHeader === "Create a new customer" && (
          <Button
            variant="outlined"
            startIcon={<PublishIcon />}
            disabled={!allInputOK}
            onClick={handleCreateClient}
          >
            Submit
          </Button>
        )}
      </div>

      <SubmitBackdrop
        open={openBackdrop}
        onClick={handleClickBackdrop}
        submitState={submitState}
        modalMessage={modalMessage}
      />
    </div>
  );
};

export default ClientDetails;
