import React, { useState, useReducer, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext } from "context/AuthContext";

import momentTZ from "moment-timezone";

import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
import { createGroup, ERROR_MESSAGE } from "utils/irisAuthRequests";
import { handleClient, HANDLE_TYPE } from "utils/irisAuthRequests";

import ClientDetails from "../common/ClientDetails";
import { DEFECTS } from "pages/clientsUsersGroups/statics";
import { updateCSAMsForClient } from "utils/requests";

/** 3 different messages according to api call */
const CREATE_CLIENT_MODAL_MESSAGES = {
  /** "No request is sent." */
  notSend: "No request is sent.",
  /** "Create client request is sent. Please wait for a while." */
  sending: "Create client request is sent. Please wait for a while.",
  /** "New client is created successfully." */
  success: "New client is created successfully.",
};

// construct timezone options with array data from moment-timezone
const timezoneOptions = momentTZ.tz
  .names()
  .map((name, index) => ({ id: index.toString(), name: name }));

/** reducer actions */
const ACTIONS = {
  UPDATE_CLIENT_NAME: "update client name",
  UPDATE_DEVICE_COUNT: "update device count",
  UPDATE_MONTHLY_LIMIT: "update monthly limit",
  UPDATE_GEO_NAME: "update geo name",
  UPDATE_GEO_PROVINCE: "update geo province",
  UPDATE_LAT: "update client latitude",
  VERIFY_LAT: "verify client latitude",
  UPDATE_LON: "update client longitude",
  VERIFY_LON: "verify client longitude",
  UPDATE_TIMEZONE: "update timezone",
  UPDATE_SHIFTS_START_TIME_END_TIME: "update shifts start time and end time",
  UPDATE_SHIFTS: "update shifts",
  UPDATE_SHIFTS_MINUTES: "update shifts minutes", // to display the selected period in minutes
  VERIFY_SHIFTS: "verify shifts",
  UPDATE_LOGO: "update logo",
  UPDATE_WIDGETS: "update widgets",
  UPDATE_WORK_ORDER_PROFILE: "update work order profile",
  UPDATE_GEO_FENCING_FILE_LIST: "update geo_fencing file list",
  UPDATE_GEO_FENCING: "update geo_fencing input",
  UPDATE_READY_TO_SUBMIT: "update ready to submit",
  SET_CSAMS:"set CSAM"
};

/** create client reducer to handle dispatch */
const createClientReducer = (createClientState, action) => {
  const { type, payload } = action;
  switch (type) {
    default:
      return createClientState;
    case ACTIONS.UPDATE_CLIENT_NAME:
      return { ...createClientState, name: payload };
    case ACTIONS.UPDATE_DEVICE_COUNT:
      return { ...createClientState, device_count: payload };
    case ACTIONS.UPDATE_MONTHLY_LIMIT:
      return {...createClientState, monthly_limit: payload};
    case ACTIONS.UPDATE_GEO_NAME:
      return { ...createClientState, geo_name: payload };
    case ACTIONS.UPDATE_GEO_PROVINCE:
      return { ...createClientState, geo_province: payload };
    case ACTIONS.UPDATE_LAT:
      return { ...createClientState, geo_lat: payload };
    case ACTIONS.VERIFY_LAT:
      return { ...createClientState, latVerified: payload };
    case ACTIONS.UPDATE_LON:
      return { ...createClientState, geo_lon: payload };
    case ACTIONS.VERIFY_LON:
      return { ...createClientState, lonVerified: payload };
    case ACTIONS.UPDATE_TIMEZONE:
      return { ...createClientState, geo_timezone: payload };
    case ACTIONS.UPDATE_GEO_FENCING:
      return { ...createClientState, geo_fencing: payload };
    case ACTIONS.UPDATE_SHIFTS_START_TIME_END_TIME:
      return { ...createClientState, shifts_start_time_end_time: payload };
    case ACTIONS.UPDATE_SHIFTS:
      return { ...createClientState, shifts: payload };
    case ACTIONS.UPDATE_SHIFTS_MINUTES:
      return { ...createClientState, shiftsMin: payload };
    case ACTIONS.VERIFY_SHIFTS:
      return { ...createClientState, shiftsVerified: payload };
    case ACTIONS.UPDATE_LOGO:
      return { ...createClientState, logo: payload };
    case ACTIONS.UPDATE_WIDGETS:
      return { ...createClientState, widgets: payload };
    case ACTIONS.UPDATE_WORK_ORDER_PROFILE:
      return { ...createClientState, w_o_profile: payload };
    case ACTIONS.UPDATE_GEO_FENCING_FILE_LIST:
      return { ...createClientState, geoFencingFilesList: payload };
    case ACTIONS.UPDATE_READY_TO_SUBMIT:
      return { ...createClientState, isReady: payload };
    case ACTIONS.SET_CSAMS:
      return { ...createClientState, CSAMS: payload };
    
    case ACTIONS.SET_M_A_PROFILE:
        return { ...createClientState, m_a_profile: payload };
  }
};

/** initial state of create client page */
const initCreateClientState = {
  name: "",
  device_count: "1",
  monthly_limit: "-1",
  geo_name: "",
  geo_province: "",
  geo_lat: "",
  geo_lon: "",
  geo_timezone: timezoneOptions.find(
    (match) => match.name === "America/Toronto"
  ).name,
  shifts_start_time_end_time: [["00:00:00", "23:59:00"]],
  shifts: ["00:00-23:59"],
  widgets: [],
  w_o_profile: {
    name: "",
    search_fields: [{ id: "open_datetime", fieldName: "open date time" }],
    defects: [],
    assignees: [],
  },
  m_a_profile: {shift:"", coverage_layer:"",additional_setting:""},
  geoFencingFilesList: [],
  geo_fencing: [],
  CSAMS: []
};

const CreateClient = () => {
  const history = useHistory();

  // get token to make api call
  const { awsToken, onLogout } = useContext(AuthContext);

  // reducer constructor
  const [createClientState, dispatch] = useReducer(
    createClientReducer,
    initCreateClientState
  );

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // when clicking the submit button, set state, modal will display different info according to the states
  const [createClientSubmitState, setCreateClientSubmitState] = useState(
    SUBMIT_STATE.notSend
  );
  // modal content set state
  const [modalMessageContent, setModalMessageContent] = useState(
    CREATE_CLIENT_MODAL_MESSAGES.notSend
  );

  // error type, to indicate what operations should be taken when error ocurrs
  const [errorType, setErrorType] = useState("");

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // only when it's not sending request, close the backdrop and modal
    if (createClientSubmitState !== SUBMIT_STATE.sending) {
      // when there's an error
      if (createClientSubmitState === SUBMIT_STATE.error) {
        // when the token is not valid, click the backdrop to logout
        if (errorType === ERROR_MESSAGE.token) {
          onLogout();
        } else {
          setOpenBackdrop(false);
          setCreateClientSubmitState(SUBMIT_STATE.notSend);
          setErrorType("");
        }
      }
      // when there's no error, redirect to /clients
      else {
        history.push(`/customers`);
        // setOpenBackdrop(false);
        // setCreateClientSubmitState(SUBMIT_STATE.notSend);
        // setErrorType("");
      }
    }
  };

  // submit the sign up data
  const handleSubmit = (submitCreateClientState) => {
    // triggers the backdrop to open
    setOpenBackdrop(true);
    setCreateClientSubmitState(SUBMIT_STATE.sending);
    setModalMessageContent(CREATE_CLIENT_MODAL_MESSAGES.sending);

    // when input number of shifts is 1, modify the shifts string
    let submitShifts = [];
    if (submitCreateClientState.shifts.length === 1) {
      submitShifts = ["00:00-24:00"];
    } else if (submitCreateClientState.shifts.length > 1) {
      submitShifts = submitCreateClientState.shifts;
    }

    // api takes an object
    const submitProps = {
      token: awsToken,
      clientName: submitCreateClientState.name,
      deviceCount: submitCreateClientState.device_count,
      monthlyLimit: submitCreateClientState.monthly_limit,
      shifts: submitShifts,
      timezone: submitCreateClientState.geo_timezone,
      latitude: submitCreateClientState.geo_lat,
      longitude: submitCreateClientState.geo_lon,
      geoName: submitCreateClientState.geo_name,
      service: submitCreateClientState.service,
      geoFencing: submitCreateClientState.geo_fencing,
      province: submitCreateClientState.geo_province,
    };

    handleClient(submitProps, HANDLE_TYPE.create)
      .then((result) => {
        // triggers the backdrop to show message of submit info
        setCreateClientSubmitState(SUBMIT_STATE.ok);
        setModalMessageContent(CREATE_CLIENT_MODAL_MESSAGES.success);

        // create group
        createGroup({
          token: awsToken,
          groupName: `${submitCreateClientState.name}`,
        });

        updateCSAMsForClient(awsToken, result.id,submitCreateClientState.CSAMS)
      })
      .catch((err) => {
        setErrorType(err.message);
        setModalMessageContent(err.message);
        setCreateClientSubmitState(SUBMIT_STATE.error);
      });
  };

  const updateWorkOrderSearchFields = (wop) => {
    const payload = { ...wop, defects: DEFECTS };
    if (
      !payload.search_fields.find((option) => option.id === "open_datetime")
    ) {
      payload.search_fields.push({
        id: "open_datetime",
        fieldName: "open date time",
      });
    }
    dispatch({
      type: ACTIONS.UPDATE_WORK_ORDER_PROFILE,
      payload: payload,
    });
  };

  return (
    <ClientDetails
      pageHeader="Create a new customer"
      clientDetailsState={createClientState}
      updateClientName={(clientname) => {
        const cityName = clientname.trim();
        dispatch({
          type: ACTIONS.UPDATE_CLIENT_NAME,
          payload: cityName,
        });

        dispatch({
          type: ACTIONS.UPDATE_GEO_NAME,
          payload: `${cityName} - geo info`,
        });

        const wop = { ...createClientState.w_o_profile };
        wop.name = `${cityName} - work order profile`;

        dispatch({ type: ACTIONS.UPDATE_WORK_ORDER_PROFILE, payload: wop });
      }}
      updateDeviceCount={(deviceCount) =>
        dispatch({ type: ACTIONS.UPDATE_DEVICE_COUNT, payload: deviceCount })
      }
      updateMonthlyLimit={(monthlyLimit) =>
        dispatch({ type: ACTIONS.UPDATE_MONTHLY_LIMIT, payload: monthlyLimit })
      }
      updateStartTimeEndTime={(startTimeEndTime) => {
        dispatch({
          type: ACTIONS.UPDATE_SHIFTS_START_TIME_END_TIME,
          payload: startTimeEndTime,
        });
      }}
      updateShifts={(shifts) =>
        dispatch({ type: ACTIONS.UPDATE_SHIFTS, payload: shifts })
      }
      updateGeoLat={(lat) =>
        dispatch({ type: ACTIONS.UPDATE_LAT, payload: lat })
      }
      updateGeoLon={(lon) =>
        dispatch({ type: ACTIONS.UPDATE_LON, payload: lon })
      }
      updateGeoProvince={(province) =>
        dispatch({ type: ACTIONS.UPDATE_GEO_PROVINCE, payload: province })
      }
      updateTimezone={(timezone) =>
        dispatch({ type: ACTIONS.UPDATE_TIMEZONE, payload: timezone })
      }
      updateGeoFencing={(geoFencing) =>
        dispatch({ type: ACTIONS.UPDATE_GEO_FENCING, payload: geoFencing })
      }
      updateWidgets={(widgets) =>
        dispatch({ type: ACTIONS.UPDATE_WIDGETS, payload: widgets })
      }

      updateManualAppProfile={(m_a_profile) =>
        dispatch({ type: ACTIONS.SET_M_A_PROFILE, payload: m_a_profile })
      }

      updateCSAMS={(data)=>
        dispatch({ type: ACTIONS.SET_CSAMS, payload: data })
      }

      updateWOP={updateWorkOrderSearchFields}
      
      isEditMode={true}
      openBackdrop={openBackdrop}
      submitState={createClientSubmitState}
      modalMessage={modalMessageContent}
      handleClickBackdrop={handleClickBackdrop}
      handleSubmit={handleSubmit}
    />
  );
};

export default CreateClient;
