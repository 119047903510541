
import React, { useContext, useEffect, useState } from "react";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
    Autocomplete,
    Button,
    Checkbox,
    Grid,
    TextField,
} from "@mui/material";
import { AuthContext } from "context/AuthContext";
import { getCognitoCustomerContacts } from "utils/requests";

const EditNotificationGroup = (props) => {

    const {
        id,
        clientName,
        handleClose,
        emails,
        userContacts,
        throttle
    } = props

    const { awsToken } = useContext(AuthContext);


    const [selectedEmails, setSelectedEmails] = useState([]);
    const [selectedThrottle, setSelectedThrottle] = useState(throttle);


    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    useEffect(() => {
        let isSubscribed = true;
        getCognitoCustomerContacts(awsToken, id).then((result) => {
            if (isSubscribed) {
                if (result.results)
                    setSelectedEmails(result.results.map(user => user.email))
            }
        }).catch((err) => {
            // console.log(err)
        })
        return () => (isSubscribed = false);
    }, [id, clientName,awsToken])


    const handleCloseWithData = ()=>{
        const data = [...userContacts].filter(([k, v]) => selectedEmails.includes(v)).map(data=> data[0])
        handleClose({
            usernames: data,
            throttle:Math.trunc(selectedThrottle)
        });
    }

    return (
        <Grid container >
            <Grid container justifyContent="flex-start" alignItems="center" marginTop={'2vh'} marginBottom={'2vh'} >
                <TextField
                    id="outlined-number"
                    label="Throttle(Seconds)"
                    type="number"
                    value={selectedThrottle}
                    InputProps={{
                        inputProps: { 
                            min: -1 
                        }
                    }}
                    onChange={(event) => {
                        const value = event.target.value;
                        if (!isNaN(value) && Number(value)>= -1)
                            setSelectedThrottle(value);
                    }}

                />
            </Grid>
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={emails}
                value={selectedEmails}
                disableCloseOnSelect
                getOptionLabel={(option) => option}

                onChange={(event, newValue) => {
                    setSelectedEmails([
                        ...newValue
                    ]);
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option}
                    </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                    <TextField {...params} label="Assigned Users" placeholder="Users" />
                )}
            />
            <Grid container justifyContent="space-evenly" alignItems="center" marginTop={'5vh'}>
                <Grid item xs={2} >
                    <Button variant="contained" onClick={()=>handleCloseWithData()} color="primary" >Confirm</Button>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" onClick={()=>handleClose()} color="secondary">Cancel</Button>
                </Grid>

            </Grid>
        </Grid>
    )
}


export default EditNotificationGroup