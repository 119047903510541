const DEFAULT_ANCESSTOR_KEY_NAME = "__ancesstors__";
const DEFAULT_CHILDREN_KEY_NAME = "__children__";

export { DEFAULT_ANCESSTOR_KEY_NAME, DEFAULT_CHILDREN_KEY_NAME };

/**
 * @typedef TreeKeyInterface
 *
 * @property {[Object<string, any]} __ancesstors__
 * @property {[Object<string, any]} __children__
 */

/**
 * @typedef {(Object<string, any> & TreeKeyInterface)} TreeInterface
 */

/**
 *
 * @param {object<string, any>} data
 * @param {[string | number]} ancesstorList a list of number or string that represents the set of ancesstors of a current data object
 * @param {string} nameField whose value would be part of an object which would be appended to the *ancesstorList*
 * @param {string} idField whose value would be part of an object which would be appended to the *ancesstorList*
 *
 * @returns {TreeInterface []}
 */
const appendAncesstors = (data, ancesstorList, nameField, idField) => {
  data.__ancesstors__ = [...ancesstorList];

  /**@type {TreeInterface []} */
  const children = data.__children__;

  if (!Array.isArray(children)) {
    throw new Error("Error, children is not an array");
  }
  if (children.length > 0) {
    const sortedChildren = children.sort((a, b) => {
      const nameA = a[nameField];
      const nameB = b[nameField];
      if (nameA > nameB) return 1;
      else if (nameA < nameB) return -1;
      return 0;
    });
    const copyList = [
      ...ancesstorList,
      { [idField]: data[idField], [nameField]: data[nameField] },
    ];
    sortedChildren.forEach((child) => {
      appendAncesstors(child, copyList, nameField, idField);
    });
  }
};

/**
   *
   * @param {Object<string, any>} data
   * @param {string | number} parentCondition condition that tell a data object is a node, rather than a leaf, ie: id should be *0*, or category should be *""*
   * @param {string} idFieldName whose value could uniquely identify each data object, ie **"id"**, *"product_id"*
   * @param {string} parentFieldName whose value points to a data object's parent, ie: *"parent"*, *"category"*
   * @param {string} nameField whose value is a readable title for each data object, ie *"name"*, *"product_name"*
   * 
   * @returns {TreeInterface []}
   * 
   * 
   * @example 
    const components = [
      {id: 1, name: "CPU", parent: 0},
      {id: 2, name: "Graphic", parent: 0},
      {id: 4, name: "AMD", parent: 1},
      {id: 5, name: "Intel", parent: 1},
      {id: 6, name: "Ryzen 3", parent: 4},
      {id: 7, name: "Ryzen 5", parent: 4},
      {id: 8, name: "i3", parent: 5},
      {id: 9, name: "i5", parent: 5},
      {id: 10, name: "i7", parent: 5},
      {id: 11, name: "A card", parent: 2},
      {id: 12, name: "N card", parent: 2},
      {id: 13, name: "3xxx", parent: 12},
      {id: 14, name: "2xxx", parent: 12},
      {id: 15, name: "2060", parent: 14},
      {id: 16, name: "2070", parent: 14},
      {id: 17, name: "2080", parent: 14},
      {id: 18, name: "3050", parent: 13},
      {id: 19, name: "3060", parent: 13},
      {id: 20, name: "3090", parent: 13},
      {id: 21, name: "5xxx", parent: 11},
      {id: 22, name: "xxx", parent: 11},
      {id: 23, name: "5600x", parent: 21},
      {id: 24, name: "5700x", parent: 21},
      {id: 25, name: "5800x", parent: 21},
      {id: 26, name: "480", parent: 22},
      {id: 27, name: "580", parent: 22},
      {id: 28, name: "550", parent: 22},
    ]
  
    const result = createTree(components, 0, "id", "parent", "name");
  
    return
    [
        {
            id: 1,
            name: "CPU",
            parent: 0,
            __ancesstors__: [{ id: -1, name: "" }],
            __children__: [
            {
                id: 4,
                name: "AMD",
                parent: 1,
                __ancesstors__: [
                { id: -1, name: "" },
                { id: 1, name: "CPU" },
                ],
                __children__: [
                {
                    id: 6,
                    name: "Ryzen 3",
                    parent: 4,
                    __ancesstors__: [
                    { id: -1, name: "" },
                    { id: 1, name: "CPU" },
                    { id: 4, name: "AMD" },
                    ],
                    __children__: [],
                },
                {
                    id: 7,
                    name: "Ryzen 5",
                    parent: 4,
                    __ancesstors__: [
                    { id: -1, name: "" },
                    { id: 1, name: "CPU" },
                    { id: 4, name: "AMD" },
                    ],
                    __children__: [],
                },
                ],
            },
            {
                id: 5,
                name: "Intel",
                parent: 1,
                __ancesstors__: [
                { id: -1, name: "" },
                { id: 1, name: "CPU" },
                ],
                __children__: [
                {
                    id: 8,
                    name: "i3",
                    parent: 5,
                    __ancesstors__: [
                    { id: -1, name: "" },
                    { id: 1, name: "CPU" },
                    { id: 5, name: "Intel" },
                    ],
                    __children__: [],
                },
                {
                    id: 9,
                    name: "i5",
                    parent: 5,
                    __ancesstors__: [
                    { id: -1, name: "" },
                    { id: 1, name: "CPU" },
                    { id: 5, name: "Intel" },
                    ],
                    __children__: [],
                },
                {
                    id: 10,
                    name: "i7",
                    parent: 5,
                    __ancesstors__: [
                    { id: -1, name: "" },
                    { id: 1, name: "CPU" },
                    { id: 5, name: "Intel" },
                    ],
                    __children__: [],
                },
                ],
            },
            ],
        },
        {
            id: 2,
            name: "Graphic",
            parent: 0,
            __ancesstors__: [{ id: -1, name: "" }],
            __children__: [
            {
                id: 11,
                name: "A card",
                parent: 2,
                __ancesstors__: [
                { id: -1, name: "" },
                { id: 2, name: "Graphic" },
                ],
                __children__: [
                {
                    id: 21,
                    name: "5xxx",
                    parent: 11,
                    __ancesstors__: [
                    { id: -1, name: "" },
                    { id: 2, name: "Graphic" },
                    { id: 11, name: "A card" },
                    ],
                    __children__: [
                    {
                        id: 23,
                        name: "5600x",
                        parent: 21,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 11, name: "A card" },
                        { id: 21, name: "5xxx" },
                        ],
                        __children__: [],
                    },
                    {
                        id: 24,
                        name: "5700x",
                        parent: 21,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 11, name: "A card" },
                        { id: 21, name: "5xxx" },
                        ],
                        __children__: [],
                    },
                    {
                        id: 25,
                        name: "5800x",
                        parent: 21,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 11, name: "A card" },
                        { id: 21, name: "5xxx" },
                        ],
                        __children__: [],
                    },
                    ],
                },
                {
                    id: 22,
                    name: "xxx",
                    parent: 11,
                    __ancesstors__: [
                    { id: -1, name: "" },
                    { id: 2, name: "Graphic" },
                    { id: 11, name: "A card" },
                    ],
                    __children__: [
                    {
                        id: 26,
                        name: "480",
                        parent: 22,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 11, name: "A card" },
                        { id: 22, name: "xxx" },
                        ],
                        __children__: [],
                    },
                    {
                        id: 28,
                        name: "550",
                        parent: 22,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 11, name: "A card" },
                        { id: 22, name: "xxx" },
                        ],
                        __children__: [],
                    },
                    {
                        id: 27,
                        name: "580",
                        parent: 22,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 11, name: "A card" },
                        { id: 22, name: "xxx" },
                        ],
                        __children__: [],
                    },
                    ],
                },
                ],
            },
            {
                id: 12,
                name: "N card",
                parent: 2,
                __ancesstors__: [
                { id: -1, name: "" },
                { id: 2, name: "Graphic" },
                ],
                __children__: [
                {
                    id: 14,
                    name: "2xxx",
                    parent: 12,
                    __ancesstors__: [
                    { id: -1, name: "" },
                    { id: 2, name: "Graphic" },
                    { id: 12, name: "N card" },
                    ],
                    __children__: [
                    {
                        id: 15,
                        name: "2060",
                        parent: 14,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 12, name: "N card" },
                        { id: 14, name: "2xxx" },
                        ],
                        __children__: [],
                    },
                    {
                        id: 16,
                        name: "2070",
                        parent: 14,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 12, name: "N card" },
                        { id: 14, name: "2xxx" },
                        ],
                        __children__: [],
                    },
                    {
                        id: 17,
                        name: "2080",
                        parent: 14,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 12, name: "N card" },
                        { id: 14, name: "2xxx" },
                        ],
                        __children__: [],
                    },
                    ],
                },
                {
                    id: 13,
                    name: "3xxx",
                    parent: 12,
                    __ancesstors__: [
                    { id: -1, name: "" },
                    { id: 2, name: "Graphic" },
                    { id: 12, name: "N card" },
                    ],
                    __children__: [
                    {
                        id: 18,
                        name: "3050",
                        parent: 13,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 12, name: "N card" },
                        { id: 13, name: "3xxx" },
                        ],
                        __children__: [],
                    },
                    {
                        id: 19,
                        name: "3060",
                        parent: 13,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 12, name: "N card" },
                        { id: 13, name: "3xxx" },
                        ],
                        __children__: [],
                    },
                    {
                        id: 20,
                        name: "3090",
                        parent: 13,
                        __ancesstors__: [
                        { id: -1, name: "" },
                        { id: 2, name: "Graphic" },
                        { id: 12, name: "N card" },
                        { id: 13, name: "3xxx" },
                        ],
                        __children__: [],
                    },
                    ],
                },
                ],
            },
            ],
        },
    ]
  
   */
export function createTree(
  data,
  parentCondition,
  idFieldName,
  parentFieldName,
  nameField
) {
  /**@type {TreeInterface []} */
  const copyData = JSON.parse(JSON.stringify(data));
  const idMapping = copyData.reduce((acc, ele, i) => {
    ele.__ancesstors__ = [];
    ele.__children__ = [];
    acc[ele[idFieldName]] = i;

    return acc;
  }, {});

  let root = [];
  copyData.forEach((element) => {
    if (element[parentFieldName] === parentCondition) {
      root.push(element);
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = copyData[idMapping[element[parentFieldName]]];
    // Add our current el to its parent's `children` array
    parentEl.__children__ = [...(parentEl.__children__ || []), element];
  });

  root.forEach((node) => {
    appendAncesstors(
      node,
      [{ [idFieldName]: -1, [nameField]: "" }],
      nameField,
      idFieldName
    );
  });

  // sort to root node
  root.sort((a, b) => {
    const nameA = a[nameField];
    const nameB = b[nameField];

    if (nameA < nameB) return -1;
    else if (nameA > nameB) return 1;
    return 0;
  });
  return root;
}

/**
 * 
 * @param {object} data 
 * @param {string} key 
 * @param {[object]} [array] 
 * @returns {[object]}
 * 
 * @example 
 * const obj_to_be_flatted = {
        name: "CPU",
        id: 10,
        children: [
            {
                name: "AMD",
                id: 12,
                children: [{ name: "ryzen 3", id: 22, children: [] }],
            },
            { name: "Intel", id: 13, children: [{ name: "i5", id: 33, children: [] }] },
        ],
    };

    flatObject(obj_to_be_flatted, "children")
    returns 
    [
        { name: 'CPU', id: 10, children: [ [Object], [Object] ] },
        { name: 'AMD', id: 12, children: [ [Object] ] },
        { name: 'ryzen 3', id: 22, children: [] },
        { name: 'Intel', id: 13, children: [ [Object] ] },
        { name: 'i5', id: 33, children: [] }
    ]

 */
function flatObject(data, key, array = []) {
  const children = data[key];
  array.push(data);
  if (Array.isArray(children) && children.length > 0) {
    children.forEach((child) => flatObject(child, key, array));
  }
  return array;
}

/**
 *
 * @param {[object]} arr
 * @param {string} key
 * 
 * @returns {[Object]}
 * 
 * @example
 *
 const data =  [
  {
    name: "CPU",
    id: 10,
    children: [
      {
        name: "AMD",
        id: 12,
        children: [{ name: "ryzen 3", id: 22, children: [] }],
      },
      {
        name: "Intel",
        id: 13,
        children: [{ name: "i5", id: 33, children: [] }],
      },
    ],
  },
  {
    name: "Graphics",
    id: 100,
    children: [
      {
        name: "A card",
        id: 101,
        children: [{ name: "6500XT", id: 102, children: [] }],
      },
      {
        name: "N card",
        id: 201,
        children: [
          { name: "2060", id: 202, children: [] },
          { name: "2070", id: 302, children: [] },
        ],
      },
    ],
  },
]

flatArrayOfObject(data, "children");

returns [
    { name: 'CPU', id: 10, children: [ [Object], [Object] ] },
    { name: 'AMD', id: 12, children: [ [Object] ] },
    { name: 'ryzen 3', id: 22, children: [] },
    { name: 'Intel', id: 13, children: [ [Object] ] },
    { name: 'i5', id: 33, children: [] },
    { name: 'Graphics', id: 100, children: [ [Object], [Object] ] },
    { name: 'A card', id: 101, children: [ [Object] ] },
    { name: '6500XT', id: 102, children: [] },
    { name: 'N card', id: 201, children: [ [Object], [Object] ] },
    { name: '2060', id: 202, children: [] },
    { name: '2070', id: 302, children: [] }
]
 */
export function flatArrayOfObject(arr, key) {
  const flatArray = arr.reduce((acc, ele) => {
    const temp = flatObject(ele, key);
    const updatedAcc = acc.concat(temp);
    return updatedAcc;
  }, []);

  return flatArray;
}
