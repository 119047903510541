/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Apr 11, 2021
 *
 * Description: A component uses a MaterialTable component to demonstrate the label types to administrators.
 * The administrators are allowed to create and update label types.
 *
 * DELETION of a label type is not allowed
 */
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Box } from "@mui/material/";
import AddOrEditLabelModal from "./AddOrEditLabelModal/AddOrEditLabelModal";
import IrisFinder from "UI/irisFinder/IrisFinder";
import {
  DELETE_LABEL_TYPE,
  GET_LABEL_TYPES,
  UPDATE_LABEL_TYPE,
} from "api/labels/request";
import { useNotification } from "UI/global/notification/context/NotificationContext";
import { useMutation, useQuery } from "react-query";
import { useConfirm } from "UI/global/confirm/context/ConfirmContext";

import IrisLinearProgress from "UI/irisLinearProgress/IrisLinearProgress";
import { DisabledByDefault } from "@mui/icons-material";

import Tooltip from "@mui/material/Tooltip";
export default function LabelTypesFinder() {
  const { contextToken } = useContext(AuthContext);

  /*================================================== states START ================================================== */
  /**@type {[[LabelInterface], React.Dispatch<React.SetStateAction<[LabelInterface]>>]} */
  const [labelTypes, setLabelTypes] = useState([]);

  /**@type {[{mode: import("./AddOrEditLabelModal/AddOrEditLabelModal").EDIT_MODE, data: LabelInterface}, React.Dispatch<{mode: import("./AddOrEditLabelModal/AddOrEditLabelModal").EDIT_MODE, data: LabelInterface}>]} */
  const [modalDetails, setModalDetails] = useState(null);
  const [newLabelId, setNewLabelId] = useState(-1);
  /*==================================================  states END  ================================================== */

  /*================================================== global UI START ================================================== */
  const { showNotification } = useNotification();
  const { isConfirmed } = useConfirm();
  /*==================================================  global UI END  ================================================== */

  /*================================================== react-query START ================================================== */
  const { data: response, isFetching } = useQuery(
    ["LOOKUP_LABEL", contextToken],
    () => {
      return GET_LABEL_TYPES(contextToken);
    },
    {
      onSettled: (response, error) => {
        if (error) {
          console.error("Error in loading labels", error);
          showNotification("Error in loading labels", "top-center", "error");
        } else if (response) {
          const { result } = response;
          if (result) {
            setLabelTypes(result);
          }
        }
      },
    }
  );

  // remove label
  // const { mutate, isLoading } = useMutation(
  //   (variables) => {
  //     const { token, label: data } = variables;
  //     return DELETE_LABEL_TYPE(token, data.id);
  //   },
  //   {
  //     onSettled: (response, error, variables, context) => {
  //       if (error) {
  //         showNotification("Error in deleting label", "top-center", "error");
  //       } else if (response.success) {
  //         const { label: removedLabel } = variables;

  //         const { name, parent: parentId, id } = removedLabel;
  //         showNotification(
  //           `Label: "${name}" is REMOVED`,
  //           "top-center",
  //           "success"
  //         );

  //         const updatedLabels = labelTypes.filter((label) => label.id !== id);
  //         setNewLabelId(parentId);
  //         setLabelTypes(updatedLabels);
  //       }
  //     },
  //   }
  // );

  const { mutate, isLoading } = useMutation(
    /**
     *
     * @param {{token: string, data: LabelInterface}} variables
     * @returns
     */
    (variables) => {
      const { token, data } = variables;
      // process.env.NODE_ENV === "development" && console.log(`token: `, token);
      // process.env.NODE_ENV === "development" && console.log(`data: `, data);
      return UPDATE_LABEL_TYPE(token, data);
    },
    {
      onSettled: (data, error, variables, context) => {
        let message = "";
        // ERROR
        if (error) {
          message = "Error in toggling label";

          showNotification(message, "top-center", "error");
        }
        // SUCCESS
        else if (data) {
          const label = data.result;
          let message = "";

          if (label.enabled.toLowerCase() === "y") {
            message = `label ${label.name} is now ENABLED`;
          } else if (label.enabled.toLowerCase() === "n") {
            message = `label ${label.name} is now DISABLED`;
          }

          const updatedLabels = [...labelTypes];
          const index = updatedLabels.findIndex(
            (element) => label.id === element.id
          );

          if (index > -1) {
            updatedLabels[index] = label;

            setLabelTypes(updatedLabels);
            showNotification(message, "top-center", "success");
          }
        }
      },
    }
  );
  /*==================================================  react-query END  ================================================== */

  /**
   * @summary callback function pass to the *onAdd* prop of **IrisFinder**
   * @param {LabelInterface} parentNode
   *
   * @see IrisFinder
   */
  const handleAddNewLabel = (parentNode) => {
    setModalDetails({ mode: "ADD_NEW", data: { ...parentNode } });
  };

  /**
   * @summary callback function pass to the *onEdit* prop of **IrisFinder**
   * @param {LabelInterface} label
   *
   * @see IrisFinder
   */
  const showEditLabelModel = (label) => {
    setModalDetails({ mode: "EDIT", data: { ...label } });
  };

  /**
   * @summary callback function pass to the *onClose* prop of **AddOrEditLabelModal**
   * @param {object} [details]
   * @param {import("./AddOrEditLabelModal/AddOrEditLabelModal").EDIT_MODE} details.mode
   * @param {LabelInterface} details.label
   *
   * @see AddOrEditLabelModal
   */
  const onModelClose = (details) => {
    setModalDetails(null);

    // modal is closed due to label is update successfully
    if (details) {
      const { mode, label } = details;

      if (mode === "ADD_NEW") {
        const updatedLabels = labelTypes.concat(label);
        // make finder focus on this label and all its ancesstors
        setNewLabelId(label.id);
        setLabelTypes(updatedLabels);
      } else if (mode === "EDIT") {
        const updatedLabels = [...labelTypes];
        const index = updatedLabels.findIndex(
          (element) => element.id === label.id
        );
        if (index > -1) {
          // update label on found index
          updatedLabels[index] = label;
          setLabelTypes(updatedLabels);
        }
      }
    }
  };

  // /**
  //  *
  //  * @param {LabelInterface} target
  //  */
  // const handleRemoveLabel = (target) => {
  //   // process.env.NODE_ENV === "development" &&
  //   //   console.log(`[handleRemoveLabel]: target `, target);

  //   /**index of target's children */
  //   const childIndex = labelTypes.findIndex(
  //     (label) => label.parent === target.id
  //   );

  //   if (childIndex > -1) {
  //     // do not remove a label which is a parent of other label
  //     showNotification(
  //       "Cannot remove label with children",
  //       "top-center",
  //       "warning"
  //     );
  //   } else {
  //     isConfirmed(`Removing ${target.name}? it cannot be UNDONE`).then(
  //       (sure) => {
  //         if (sure) {
  //           mutate({ token: contextToken, label: target });
  //         }
  //       }
  //     );
  //   }
  // };

  /**
   *
   * @param {LabelInterface} target
   */
  const handleToggleLabel = (target) => {
    const label = labelTypes.find((label) => label.id === target.id);

    if (label) {
      const toggleValue = label.enabled === "Y" ? "N" : "Y";
      mutate({
        token: contextToken,
        data: { id: label.id, enabled: toggleValue },
      });
    }
  };

  useEffect(() => {
    setLabelTypes(response?.result || []);
  }, [response]);
  return (
    <Box sx={{ width: { sm: 600, md: 800, lg: 1000, xl: 1400 } }}>
      <IrisLinearProgress isLoading={isFetching || isLoading} />
      <IrisFinder
        data={labelTypes}
        idKey="id"
        nameKey="name"
        parentKey="parent"
        rootCondition={0}
        onAdd={handleAddNewLabel}
        onEdit={showEditLabelModel}
        // onRemove={handleRemoveLabel}
        onToggle={handleToggleLabel}
        height={600}
        highlightId={newLabelId}
        listItemSecondIcon={{
          key: "enabled",
          condition: "N",
          onMatchIcon: (
            <Tooltip title="Disabled">
              <DisabledByDefault sx={{ color: "warning.main" }} />
            </Tooltip>
          ),
        }}
      />

      <AddOrEditLabelModal details={modalDetails} onClose={onModelClose} />
    </Box>
  );
}

/**
 * Change Log:
 *
 * Change Date: Apr 11, 2021
 *
 * Description: component is created and documentation is added
 */

/**
 * Change Log:
 *
 * Change Date: Mar 30, 2022
 *
 * Description:
 * 1. repalce table with mimic Mac OS finder element for user to navigate between label tyes
 * 2. ues react-query library to handle create and update label types
 * 3. label deletion is NOT ALLOWED
 */
