import React from "react";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";
import IrisTextField from "UI/muiTextField/IrisTextField";
import IrisSelect from "UI/muiSelect/IrisSelect";
import IrisTimePicker from "UI/muiTimePicker/IrisTimePicker";
import IrisDatePicker from "UI/muiDatePicker/IrisDatePicker";
import IrisTimeRangePickers from "UI/muiTimeRangePickers/IrisTimeRangePickers";
import IrisDateRangePickers from "UI/muiDateRangePicker/IrisDateRangePickers";
import IrisMultiSelect from "UI/muiMulMultiSelect/IrisMultiSelect";
import Tooltip from "@mui/material/Tooltip";

/**
 *
 * @example <option value = "10">Ten</option>
 * @typedef DataGridSelectOptions
 * @property {[Object<string, string>]} options options for select elements
 * @property {string} idKey value for an option, ie if option is {id: "10", value: "Ten"}, idKey is "id"
 * @property {string} titleKey display option value for an option, ie if option is {id: "10", value: "Ten"}, titleKey is "value" for the value of this value, which is "Ten" would be rendered to user. IOW, user should see "Ten", instead of "10"
 */

/**
 * @typedef DataGridDataModel
 * @property {string} key key name where value coud be referenced, ie: key is "tag"
 * @property {string} label input label for the key, ie: key is "tag", then label could be sth readable, like "Device Name"
 * @property {("date" | "date-range" | "input" | "multi-select" | "select" | "time" | "time-range")} type
 * @property {[DataGridSelectOptions]} [options]
 * @property {(string | number | Date)} [min] min value for the current type, ie: number, date, etc
 * @property {(string | number | Date)} [max] max value for the current type, ie: number, date, etc
 * @property {(value0: string | Date, value1: string | Date) => {}} [onChange]
 */

/**
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.isLoading if true, all icon buttons, if they are displayed, would be disabled, ie: when sending data via API, false otherwise
 * @param {import("@mui/material").TextFieldProps} [props.inputProps]
 * @param {[Object<string, any]} props.data
 * @param {[DataGridDataModel]} props.dataModel
 * @param {boolean} props.isEditable if true, edit mode would be toggleable, false otherwise
 * @param {boolean} [props.isEditMode] if true, input elements would be editable, false otherwise
 * @param {boolean} props.hasInvalidInput if true, the send button should be disabled, on eidt mode
 * @param {React.Dispatch<React.SetStateAction<boolean>>} [props.setIsEditMode] setState function for isEditMode, must be set if isEditable is true
 * @param {() => {}} [props.onSend] callback function for the send button, must be set if isEditable is true
 * @param {() => {}} [props.onClear] callback function for the clear button, must be set if isEditable is true
 * @example
 * // basic
 * <DataGrid
      title="General Information"
      data={{
        IMEI: "359221107877903",
        activate: "F7BA904479",
        device_sn: "59dcb6756f261dc4",
        hexnode_id: 67,
        id: 13,
        serial_number: "01EAY1TEJG",
      }}
      dataModel={[
        {
          key: "id",
          label: "Id",
          type: "input",
        },
        {
          key: "activate",
          label: "Activation Code",
          type: "input",
        },
        {
          key: "device_sn",
          label: "Device SN",
          type: "input",
        },
        {
          key: "IMEI",
          label: "IMEI",
          type: "input",
        },
        {
          key: "serial_number",
          label: "Serial Number",
          type: "input",
        },
        {
          key: "hexnode_id",
          label: "Hexnode Id",
          type: "input",
        },
      ]}
    />
  // edit mode
  const [hasInvalidInput, setHasInvalidInput] = useState(false);
  const [data, setData] = useState({
    tag: "Lucien",
    rotate: "Y",
    monday_schedule: new Date(),
    date: new Date(),
    dateRange: { // must be object form, first key's value as start date, 2nd key's value as end date
      startDate: new Date("2022-03-03"),
      endDate: new Date("2022-03-13")
    },
    timeRange: {// must be object form, first key's value as start time, 2nd key's value as end time
      startTime: "23:00:00",
      endTime: "10:00:00"
    }
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const minDate = new Date();
  minDate.setMonth(minDate.getMonth() - 1);

  const [dateRange, setDateRange] = useState({
    startDate: new Date("2022-03-02"),
    endDate: new Date("2022-03-15")
  });
  const [dateRangeMinMax] = useState({
    min: new Date("2022-03-02"),
    max: new Date("2022-03-15")
  });

  return (
    <>
      <IrisTimeRangePickers
        style={{ maxWidth: 260 }}
        startTime="23:00:00"
        endTime="18:00:00"
        muiTextFieldProps={{
          disabled: false
        }}
        title="Monday"
        onChange={(startTime, endTime) =>
          console.log("startTime, endTime", startTime, endTime)
        }
      />
      <DataGrid
        title="Data Grid Demo editable"
        isLoading={isSending} // send data via API
        data={data}
        isEditable={true}
        isEditMode={isEdit}
        hasInvalidInput={hasInvalidInput}
        setIsEditMode={setIsEdit}
        dataModel={[
          {
            key: "tag",
            label: "Tag",
            type: "input",
            options: [],
            onChange: (value) => {
              console.log("value", value);
              setData({ ...data, tag: value });
            }
          },
          {
            key: "rotate",
            label: "Rotate",
            type: "select",
            options: { options: Y_N_OPTIONS, idKey: "id", titleKey: "name" },
            onChange: (value) => {
              console.log("value", value);
              setData({ ...data, rotate: value });
            }
          },
          {
            key: "monday_schedule",
            label: "Monday Schedule",
            type: "time",
            options: [],
            onChange: (value) => {
              console.log("value", value);
              const isInvalid = isNaN(Date.parse(value));
              console.log("isInvalid", isInvalid);
              setHasInvalidInput(isInvalid);
              setData({ ...data, monday_schedule: value });
            }
          },
          {
            key: "date",
            label: "Date Picker Demo",
            type: "date",
            min: minDate,
            max: new Date(),
            options: [],
            onChange: (value) => {
              console.log("value", value);
              setData({ ...data, date: value });
            }
          },
          {
            key: "dateRange",
            label: "Date Range Picker Demo",
            type: "date-range",
            min: dateRangeMinMax.min,
            max: dateRangeMinMax.max,
            options: [],
            onChange: (startDate, endDate) => {
              console.log("startDate", startDate);
              console.log("endDate", endDate);
            }
          },
          {
            key: "timeRange",
            label: "Time Range Demo",
            type: "time-range",
            options: [],
            onChange: (startTime, endTime) => {
              console.log("startTime", startTime);
              console.log("endTime", endTime);
            }
          }
        ]}
        onSend={() => alert("SEND")}
        onClear={() => {
          setHasInvalidInput(false);
          const temp = new Date();
          temp.setHours(12);
          setData({
            tag: "Lucien",
            rotate: "Y",
            monday_schedule: temp,
            date: new Date(),
            dateRange: {
              startDate: new Date("2022-03-03"),
              endDate: new Date("2022-03-13")
            },
            timeRange: {
              startTime: "23:00:00",
              endTime: "10:00:00"
            }
          }); // reset data back to before eidt
          setIsEdit(false);
        }}
      />
    </>
 */
export default function DataGrid(props) {
  const {
    title,
    isLoading,
    inputProps,
    data,
    dataModel,
    isEditable,
    isEditMode,
    hasInvalidInput,
    setIsEditMode,
    onSend,
    onClear,
  } = props;

  const variant = inputProps?.variant || "standard";

  // if editable, setIsEditModem, onSendand and onClear must be set up properly
  if (isEditable) {
    if (!(typeof onSend === "function")) {
      throw new Error(
        "[isEditable] is true but [onSend] is not provided or it is not a function"
      );
    }
    if (!(typeof onClear === "function")) {
      throw new Error(
        "[isEditable] is true but [onClear] is not provided or it is not a function"
      );
    }
  }

  return (
    <Grid container>
      <Grid item>
        <h3>
          {title}
          {isEditable && (
            <IconButrtons
              isLoading={isLoading}
              isEdit={isEditMode}
              setIsEditMode={setIsEditMode}
              onClear={() => {
                setIsEditMode(false);
                onClear();
              }}
              onSend={() => {
                setIsEditMode(false);
                onSend();
              }}
              disabledSendButton={hasInvalidInput}
            />
          )}
        </h3>
      </Grid>
      {/* <Grid container columnSpacing={{ xs: 2, md: 3 }} rowSpacing={1}> */}
      <Grid
        container
        sx={{
          "& > *": {
            padding: "0.5rem 0.3rem",
            // boxSizing: "border-box"
          },
        }}
      >
        {dataModel.map((model, index) => {
          const { key, label, type, min, max, options, sortOptions, onChange } =
            model;

          if (type === "time-range" || type === "date-range") {
            return (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                {type === "time-range" && (
                  <IrisTimeRangePickers
                    disabled={!isEditMode}
                    title={label}
                    value={data[key]}
                    onChange={(startTime, endTime) => {
                      onChange(startTime, endTime);
                    }}
                    inputProps={{
                      variant: "standard",
                    }}
                    startTime={Object.values(data[key])[0]}
                    endTime={Object.values(data[key])[1]}
                  />
                )}
                {type === "date-range" && (
                  <IrisDateRangePickers
                    disabled={!isEditMode}
                    min={min}
                    max={max}
                    title={label}
                    startDate={Object.values(data[key])[0]}
                    endDate={Object.values(data[key])[1]}
                    onChange={onChange}
                  />
                )}
              </Grid>
            );
          }
          return (
            <Grid item key={index} xs={12} sm={6} md={4} lg={2}>
              {type === "input" && (
                <IrisTextField
                  disabled={!isEditMode}
                  fullWidth
                  label={label}
                  variant={variant}
                  value={data[key]}
                  onChange={(e) => {
                    const value = e.target.value;
                    onChange(value);
                  }}
                />
              )}
              {type === "select" && (
                <IrisSelect
                  options={options.options}
                  keyField={options.idKey}
                  titleField={options.titleKey}
                  sortOptions={sortOptions}
                  selectProps={{
                    value: data[key],

                    variant: variant,
                    disabled: !isEditMode,
                    onChange: (event) => {
                      const value = event.target.value;
                      onChange(value);
                    },
                  }}
                  label={label}
                />
              )}
              {type === "multi-select" && (
                <IrisMultiSelect
                  options={options.options}
                  keyField={options.idKey}
                  titleField={options.titleKey}
                  muiSelectProps={{
                    value: data[key],
                    variant: variant,
                    disabled: !isEditMode,
                  }}
                  onChange={(values) => onChange(values)}
                  label={label}
                />
              )}
              {type === "time" && (
                <IrisTimePicker
                  disabled={!isEditMode}
                  title={label}
                  value={data[key]}
                  onChange={(value) => {
                    onChange(value);
                  }}
                  inputProps={{
                    variant: "standard",
                  }}
                />
              )}
              {type === "date" && (
                <IrisDatePicker
                  disabled={!isEditMode}
                  min={min}
                  max={max}
                  title={label}
                  value={data[key]}
                  onChange={onChange}
                  inputProps={{
                    variant: "standard",
                  }}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

/**
 *
 * @param {object} props
 * @param {boolean} props.isLoading disable all icon buttons if true
 * @param {boolean} props.isEdit condition that the clear and send icon buttons should be shown
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsEditMode callback function for the edit icon button, toggle the clear and send icons button to be shown
 * @param {()=>void} props.onClear callback function that when the clear button icon is clicked (reset editable values)
 * @param {()=>void} props.onSend callback function that when the send icon button is clicked (send request to notify update)
 * @param {boolean} props.disabledSendButton if true, the send button should be disabled
 */
export const IconButrtons = (props) => {
  const {
    isEdit,
    isLoading,
    setIsEditMode,
    onClear,
    onSend,
    disabledSendButton,
  } = props;
  if (isEdit) {
    return (
      <>
        <Tooltip title="cancel" placement="top">
          <span>
            <IconButton
              disabled={isLoading}
              onClick={onClear}
              sx={{ color: "red" }}
            >
              <ClearIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="submit" placement="top">
          <span>
            <IconButton
              color="primary"
              onClick={onSend}
              disabled={disabledSendButton || isLoading}
            >
              <SendIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  }

  return (
    <Tooltip title="edit" placement="top">
      <IconButton
        disabled={isLoading}
        onClick={() => setIsEditMode((prev) => !prev)}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );
};
