export const CITY_OPTIONS = [
  { id: 1, name: "London" },
  { id: 2, name: "Guelph" },
  { id: 3, name: "Hamilton" },
  { id: 4, name: "Muskoka" },
  { id: 5, name: "TestCity" },
  { id: 6, name: "Orangeville" },
  { id: 7, name: "Owen Sound" },
  { id: 8, name: "Vaughan" },
  { id: 9, name: "Sagamihara" },
  { id: 10, name: "Curitiba" },
];
export const TRUE_FALSE_OPTIONS = [
  { id: true, name: "True" },
  { id: false, name: "False" },
];

export const Y_N_OPTIONS = [
  { id: "Y", name: "True" },
  { id: "N", name: "False" },
];

export const IMAGE_INTERVAL_OPTIONS = [
  { id: -1, name: "Time base (per second)" },
  { id: 10, name: "10 m" },
  { id: 15, name: "15 m" },
  { id: 20, name: "20 m" },
  { id: 25, name: "25 m" },
  { id: 30, name: "30 m" },
  { id: 35, name: "35 m" },
  { id: 40, name: "40 m" },
];

export const IMAGE_QUALITY_OPTIONS = [
  { id: "720p", name: "720p (default)" },
  { id: "1080p", name: "1080p" },
  { id: "4k", name: "4k" },
];

/**
 * 
 * @param {object} obj 
 * @param {[string]} keys a set of keys whose value would be kept on the return object 
 * @returns {object}
 * 
 * @example 
 * const obj = {id: 1, name: "Shanghai", provice: "nice"}
 
  trimObject(obj, ["id", "name"]) returns {id: 1, name: "Shanghai"}
 */
export const trimObject = (obj, keys) => {
  let temp = {};
  keys.forEach((k) => {
    const v = obj[k];
    temp = { ...temp, [k]: v };
  });
  return temp;
};

/**
 * 
 * @param {[object]} data 
 * @param {[string]} keys a set of keys whose value would be kept on the return object 
 * @returns {object}
 * 
 * @example 
 * const cities = [
    {id: 1, name: "Shanghai", provice: "nice"},
    {id: 2, name: "Guandong", provice: "nice"}
  ]
  trimObjects(cities, ["id", "name"]) returns
  [
    {id: 1, name: "Shanghai"}, 
    {id: 2, name: "Guandong"}
  ] 
 */
export const trimObjects = (data, keys) => {
  const result = data.map((datum) => {
    return trimObject(datum, keys);
  });

  return result;
};

/**
 * @summary sort an array of objects by values corresponding to the given key
 * @param {[object]} obj
 * @param {string} key value, object[key], would be used to sort
 * @param {boolean} [acc]
 * @returns {[object]} sorted objects
 */
export const sortObjectByField = (obj, key, acc = true) => {
  obj.sort((a, b) => {
    const fieldA = a[key];
    const fieldB = b[key];
    if (fieldA < fieldB) {
      return acc ? -1 : 1;
    } else if (fieldA > fieldB) {
      return acc ? 1 : -1;
    }
    return 0;
  });

  return obj;
};

/**
 * @summary get hours and minutes in "HH:mm:00" format
 *
 * @description in design, practically, the seconds value should be trivial, so its value would be cut
 *
 * @example if value is a date object of 2020-01-01 12:45:02 (local date time)
 * getHHmm(value) returns "12:45:00"
 * @param {Date} date
 * @returns {string} hours and minutes string in "HH:mm:00" format
 */
const getHHmmss = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const hourString = hours >= 10 ? hours + "" : "0" + hours;
  const minuteString = minutes >= 10 ? minutes + "" : "0" + minutes;
  return `${hourString}:${minuteString}:00`;
};

/**
 * @summary get a converted schedule object whose values could be passed to TimePicker element
 * 
 * @description for now, TimePicker element from MUI only takes Date object as value, instead of string
 * 
 * @param {IrisDeviceSchedule} schedule
 * 
 * @returns {IrisDeviceSchedule}
 * @example
 * 
 * //FROM 
  {
    "Friday": {start: '09:00:00', end: '17:00:00'}
    "Monday": {start: '01:05:00', end: '17:00:00'}
    "Saturday": {start: '00:00:00', end: '00:00:00'}
    "Sunday": {start: '00:00:00', end: '00:00:00'}
    "Thursday": {start: '09:00:00', end: '17:00:00'}
    "Tuesday": {start: '09:00:00', end: '17:00:00'}
    "Wednesday": {start: '09:00:00', end: '17:00:00'}
  }

  //TO

  {
    Friday: {
      start: Thu Jan 01 1970 09:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
    Monday: {
      start: Thu Jan 01 1970 01:05:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
    Saturday: {
      start: Thu Jan 01 1970 00:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 00:00:00 GMT-0500 (Eastern Standard Time)
    }
    Sunday: {
      start: Thu Jan 01 1970 00:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 00:00:00 GMT-0500 (Eastern Standard Time)
    }
    Thursday: {
      start: Thu Jan 01 1970 09:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
    Tuesday: {
      start: Thu Jan 01 1970 09:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
    Wednesday: {
      start: Thu Jan 01 1970 09:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
  } 
 */
export const convertScheduleStringToObject = (schedule) => {
  const result = Object.keys(schedule).reduce((acc, key) => {
    /**@type {IrisDeviceDaySchedule} */
    const newEle = { ...schedule[key] };
    // only time part matters, so set to default date
    newEle.start = new Date(Date.parse("1970-01-01T" + newEle.start));
    newEle.end = new Date(Date.parse("1970-01-01T" + newEle.end));
    acc[key] = newEle;
    return acc;
  }, {});
  return result;
};

/**
 * 
 * @summary get a converted schedule object whose values is in string format
 * 
 * @description the server only takes schedule as string format, conversion is necessary before sending api
 * 
 * @param {IrisDeviceSchedule} shcedule
 * 
 * @returns {IrisDeviceSchedule}
 * @example
 * //FROM
  {
    Friday: {
      start: Thu Jan 01 1970 09:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
    Monday: {
      start: Thu Jan 01 1970 01:05:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
    Saturday: {
      start: Thu Jan 01 1970 00:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 00:00:00 GMT-0500 (Eastern Standard Time)
    }
    Sunday: {
      start: Thu Jan 01 1970 00:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 00:00:00 GMT-0500 (Eastern Standard Time)
    }
    Thursday: {
      start: Thu Jan 01 1970 09:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
    Tuesday: {
      start: Thu Jan 01 1970 09:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
    Wednesday: {
      start: Thu Jan 01 1970 09:00:00 GMT-0500 (Eastern Standard Time), 
      end: Thu Jan 01 1970 17:00:00 GMT-0500 (Eastern Standard Time)
    }
  }


  * //TO 
  {
    "Friday": {start: '09:00:00', end: '17:00:00'}
    "Monday": {start: '01:05:00', end: '17:00:00'}
    "Saturday": {start: '00:00:00', end: '00:00:00'}
    "Sunday": {start: '00:00:00', end: '00:00:00'}
    "Thursday": {start: '09:00:00', end: '17:00:00'}
    "Tuesday": {start: '09:00:00', end: '17:00:00'}
    "Wednesday": {start: '09:00:00', end: '17:00:00'}
  }
 */
export const convertScheduleObjToString = (shcedule) => {
  const temp = Object.keys(shcedule).reduce((acc, key) => {
    /**@type {IrisDeviceDaySchedule} */
    const value = { ...shcedule[key] };

    // only time part matters, ie: "12:34:00", date part does not
    value.start = getHHmmss(value.start);
    value.end = getHHmmss(value.end);
    acc[key] = value;
    return acc;
  }, {});

  return temp;
};
