function descendingComparator(a, b, sortField) {
  let valueA = a[sortField];
  let valueB = b[sortField];

  const typeA = typeof valueA;
  const typeB = typeof valueB;

  const validTypes = ["string", "number", "boolean", "undefined"];
  const isTypeAValid = validTypes.indexOf(typeA) >= 0;
  const isTypeBValid = validTypes.indexOf(typeB) >= 0;
  if (!isTypeAValid || !isTypeBValid) {
    // process.env.NODE_ENV === "development" &&
    //   console.log(`AAAA: `, valueA, typeA, valueB, typeB);
    return 0;
  }
  // process.env.NODE_ENV === "development" &&
  //   console.log(`valueA, valueB: `, valueA, valueB);

  // compare in string format if one of them is string
  if (typeA !== "number" || typeB !== "number") {
    valueA = (valueA + "").toLocaleLowerCase();
    valueB = (valueB + "").toLocaleLowerCase();
  }

  // comparision for number
  if (valueB < valueA) {
    if (typeA === "boolean" && typeB === "boolean") return 1;
    return -1;
  }
  if (valueB > valueA) {
    if (typeA === "boolean" && typeB === "boolean") return -1;
    return 1;
  }
  return 0;
}

function getComparator(sortOrder, sortField) {
  return sortOrder === "desc"
    ? (a, b) => descendingComparator(a, b, sortField)
    : (a, b) => -descendingComparator(a, b, sortField);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

/**
 *
 * @summary give a set of json data and a value, return another set of data, whose each element (dataum) has at least one matched value
 
 * @param {[Object]} data array of data, in json format
 * @param {(string | number)} value value to be compared
 * @param {[string]} excludeFields keys whose values could be ignored for comparision, ie: "id"
 */
function getDataWithMatchedValue(data, value, excludeFields = []) {
  const type = typeof value;
  if (type !== "string" && type !== "number") {
    // thow error for developer sth is wrong, save debug time
    throw new Error(
      `in [getDataWithMatchedValue], param[value] is neither "string" nor "number" format, please double check`
    );
  }
  const temp = [];
  if (value === "") {
    // no comparison with empty string
    return temp;
  }

  /** target value (value to be compared) in lowercase format */
  const target = (value + "").toLowerCase();

  // loop each object within data
  for (let obj of data) {
    const keys = Object.keys(obj);

    /** keys for looping */
    const filteredKeys = keys.filter((k) => excludeFields.indexOf(k) < 0);

    for (let i = 0; i < filteredKeys.length; i++) {
      const value = obj[filteredKeys[i]];

      const tValue = typeof value;

      if (tValue === "string" || tValue === "number") {
        // convert value into string type, lowercase , for easier comparison
        const strValue = (value + "").toLowerCase();

        if (strValue.includes(target)) {
          // console.log("strValue", strValue, "value", value);
          temp.push(JSON.parse(JSON.stringify(obj)));
          // mach found, break out current loop (object), move to next one
          // otherwise, if object contains serval matched values, temp
          // would contain duplicates
          break;
        }
      }
    }
  }

  return temp;
}

export {
  descendingComparator,
  getComparator,
  stableSort,
  getDataWithMatchedValue,
};
