import {
  IRISGO_AUTH_SERVER_LIST_USER_URL,
  IRISGO_AUTH_SERVER_USER_DETAILS_URL,
  IRISGO_AUTH_SERVER_USER_SIGNUP_URL,
  IRISGO_AUTH_SERVER_LIST_GROUP_URL,
  IRISGO_AUTH_SERVER_CREATE_CLIENT_URL,
  IRISGO_AUTH_SERVER_UPDATE_CLIENT_URL,
  IRISGO_AUTH_SERVER_LIST_CLIENT_URL,
  IRISGO_AUTH_SERVER_CLIENT_DETAILS_URL,
  IRISGO_AUTH_SERVER_CREATE_GROUP_URL,
  IRISGO_AUTH_SERVER_ADD_USER_TO_GROUP_URL,
} from "./env/urls";

/**
 * api call to get user list
 * @param {string} token only when the token is valid, can make the api call
 * @returns {Promise}
 */
const getAdminUserList = async (token, group=null,clientID=null) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = new URL(IRISGO_AUTH_SERVER_LIST_USER_URL);
  if(group !== null)
    url.searchParams.append("by_group", group);
  if(clientID !== null)
    url.searchParams.append("by_client_id", clientID);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let error;

  try {
    const response = await fetch(
      url,
      requestOptions
    );

    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      if (response.status === 401) {
        error = new Error(ERROR_MESSAGE.token);
      } else if (response.status >= 500) {
        error = new Error(ERROR_MESSAGE.server);
      } else {
        error = new Error(ERROR_MESSAGE.general);
      }
    }
    throw error;
  } catch (err) {
    error = err;
    throw error;
  }
};

/**
 * api call to get user details
 * @param {string} token only when the token is valid, can make the api call
 * @param {string} userName indicate which user's details to fetch
 * @returns {Promise}
 */
const getAdminUserDetails = async (token, userName) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let error;

  try {
    const response = await fetch(
      `${IRISGO_AUTH_SERVER_USER_DETAILS_URL}${userName}`,
      requestOptions
    );

    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      if (response.status === 401) {
        error = new Error(ERROR_MESSAGE.token);
      } else if (response.status >= 500) {
        error = new Error(ERROR_MESSAGE.server);
      } else {
        error = new Error(ERROR_MESSAGE.general);
      }
    }
    throw error;
  } catch (err) {
    error = err;
    throw error;
  }
};

/**
 * api call to get user list
 * @param {string} token only when the token is valid, can make the api call
 * @returns {Promise}
 */
const getAdminGroupList = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let error;

  try {
    const response = await fetch(
      IRISGO_AUTH_SERVER_LIST_GROUP_URL,
      requestOptions
    );

    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      if (response.status === 401) {
        error = new Error(ERROR_MESSAGE.token);
      } else if (response.status >= 500) {
        error = new Error(ERROR_MESSAGE.server);
      } else {
        error = new Error(ERROR_MESSAGE.general);
      }
    }
    throw error;
  } catch (err) {
    error = err;
    throw error;
  }
};

/**
 * api call to create a new user
 * @param {Object} props
 * @param {string} props.token only when the token is valid, can make the api call
 * @param {string} props.userName user name of the new user to be created
 * @param {string} props.password password of the new user to be created
 * @param {string} [props.phoneNumber] phone number of the new user to be created
 * @param {string} [props.email] email of the new user to be created
 * @returns {Promise}
 */
const createUser = async (props) => {
  const { token, userName,name, password, phoneNumber = "", email = "" } = props;

  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("username", userName);
  formdata.append("name", name);
  formdata.append("password", password);
  formdata.append("phone_number", phoneNumber);
  formdata.append("email", email);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  let error;

  try {
    const response = await fetch(
      IRISGO_AUTH_SERVER_USER_SIGNUP_URL,
      requestOptions
    );

    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      if (response.status === 400) {
        if (responseJson.email) {
          error = new Error(responseJson.email[0]);
        } else {
          error = new Error(ERROR_MESSAGE.request);
        }
      } else if (response.status === 401) {
        error = new Error(ERROR_MESSAGE.token);
      } else if (response.status >= 500) {
        error = new Error(ERROR_MESSAGE.server);
      } else {
        error = new Error(ERROR_MESSAGE.general);
      }
    }
    throw error;
  } catch (err) {
    error = err;
    throw error;
  }
};

/**
 * api call to add user to groups
 * @param {Object} props
 * @param {string} props.token only when the token is valid, can make the api call
 * @param {string} props.userName indicate the user is being added to groups
 * @param {Array.<string>} props.groups array of group name
 * @returns {Promise}
 */
const addUserToGroup = async (props) => {
  const { token, userName, groups } = props;

  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("username", userName);
  groups.forEach((group) => {
    formdata.append("groups", group);
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  let error;

  try {
    const response = await fetch(
      IRISGO_AUTH_SERVER_ADD_USER_TO_GROUP_URL,
      requestOptions
    );

    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      if (response.status === 400) {
        if (responseJson.email) {
          error = new Error(responseJson.email[0]);
        } else {
          error = new Error(ERROR_MESSAGE.request);
        }
      } else if (response.status === 401) {
        error = new Error(ERROR_MESSAGE.token);
      } else if (response.status >= 500) {
        error = new Error(ERROR_MESSAGE.server);
      } else {
        error = new Error(ERROR_MESSAGE.general);
      }
    }
    throw error;
  } catch (err) {
    error = err;
    throw error;
  }
};

/**
 * api call to get client list
 * @param {string} token only when the token is valid, can make the api call
 * @returns {Promise}
 */
const getAdminClientList = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let error;

  try {
    const response = await fetch(
      IRISGO_AUTH_SERVER_LIST_CLIENT_URL,
      requestOptions
    );

    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      if (response.status === 401) {
        error = new Error(ERROR_MESSAGE.token);
      } else if (response.status >= 500) {
        error = new Error(ERROR_MESSAGE.server);
      } else {
        error = new Error(ERROR_MESSAGE.general);
      }
    }
    throw error;
  } catch (err) {
    error = err;
    throw error;
  }
};

/**
 * api call to get user details
 * @param {string} token only when the token is valid, can make the api call
 * @param {string} clientID indicate which client's details to fetch
 * @returns {Promise}
 */
const getAdminClientDetails = async (token, clientID, csamDetails=false) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = new URL(IRISGO_AUTH_SERVER_CLIENT_DETAILS_URL);
  url.searchParams.append("id", clientID);
  if(csamDetails)
    url.searchParams.append("csamDetails", true);
  
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let error;

  try {
    const response = await fetch(
      url,
      requestOptions
    );

    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      if (response.status === 401) {
        error = new Error(ERROR_MESSAGE.token);
      } else if (response.status >= 500) {
        error = new Error(ERROR_MESSAGE.server);
      } else {
        error = new Error(ERROR_MESSAGE.general);
      }
    }
    throw error;
  } catch (err) {
    error = err;
    throw error;
  }
};

/**
 * api call to handle client
 * @param {Object} props
 * @param {string} props.token only when the token is valid, can make the api call
 * @param {number} [props.id] when making api call of update client info, client id is required
 * @param {string} props.clientName client name, required
 * @param {string} props.deviceCount device count, required
 * @param {Array.<string>} props.shifts shifts, required
 * @param {string} props.timezone timezone, required
 * @param {string} props.latitude latitude, required
 * @param {string} props.longitude longitude, required
 * @param {string} props.province province, required
 * @param {string} props.geoName value of the name property of geo_info, required
 * @param {number} [props.geoId] when making api call of update client info, id of geo_info is required
 * @param {Object} props.service service, including service id, service name and iris_city object
 * @param {Array.<Object>} props.geoFencing array of geo_fencing objects
 * @param {string} handle_type indicate which api call is to make, either to create a new client or to update an existing client
 * @returns {Promise}
 */
const handleClient = async (props, handle_type) => {
  const {
    token,
    id,
    clientName,
    deviceCount,
    monthlyLimit,
    shifts,
    timezone,
    latitude,
    longitude,
    province,
    geoName,
    geoId,
    service = {},
    geoFencing = [],
  } = props;

  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  const requestBody = {
    geo_info: {
      name: geoName,
      timezone: timezone,
      lat: Number(latitude),
      lon: Number(longitude),
      province: province,
    },
    name: clientName,
    device_count: Number(deviceCount),
    monthly_limit: Number(monthlyLimit),
    shifts: shifts,
    service: service,
    geo_fencing: geoFencing,
  };

  if (handle_type === HANDLE_TYPE.update) {
    requestBody.id = id;
    requestBody.geo_info.id = geoId;
  }

  const raw = JSON.stringify(requestBody);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let error;

  let url;

  switch (handle_type) {
    case HANDLE_TYPE.create:
      url = IRISGO_AUTH_SERVER_CREATE_CLIENT_URL;
      break;
    case HANDLE_TYPE.update:
      url = IRISGO_AUTH_SERVER_UPDATE_CLIENT_URL;
      break;
    default:
      url = "";
  }

  try {
    const response = await fetch(url, requestOptions);

    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      if (response.status === 401) {
        error = new Error(ERROR_MESSAGE.token);
      } else if (response.status >= 500) {
        error = new Error(ERROR_MESSAGE.server);
      } else {
        error = new Error(ERROR_MESSAGE.general);
      }
    }
    throw error;
  } catch (err) {
    error = err;
    throw error;
  }
};

/**
 * api call to create a new group
 * @param {Object} props
 * @param {string} props.token only when the token is valid, can make the api call
 * @param {string} props.groupName group name of the group being created
 * @returns {Promise}
 */
const createGroup = async (props) => {
  const { token, groupName } = props;
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("group", groupName);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  let error;

  try {
    const response = await fetch(
      IRISGO_AUTH_SERVER_CREATE_GROUP_URL,
      requestOptions
    );

    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      if (response.status === 400) {
        if (responseJson.email) {
          error = new Error(responseJson.email[0]);
        } else {
          error = new Error(ERROR_MESSAGE.request);
        }
      } else if (response.status === 401) {
        error = new Error(ERROR_MESSAGE.token);
      } else if (response.status >= 500) {
        error = new Error(ERROR_MESSAGE.server);
      } else {
        error = new Error(ERROR_MESSAGE.general);
      }
    }
    throw error;
  } catch (err) {
    error = err;
    throw error;
  }
};

/** 4 different messages according to api call */
const ERROR_MESSAGE = {
  /** "Bad request" */
  request: "Bad request",
  /** "Invalid token error" */
  token: "Invalid token error",
  /** "Server error" */
  server: "Server error",
  /** "General error" */
  general: "General error",
};

/** 2 types of handle clients */
const HANDLE_TYPE = {
  /** to create a new client */
  create: "create",
  /** to modify and then update an existing client */
  update: "update",
};

export {
  getAdminUserList,
  getAdminUserDetails,
  createUser,
  getAdminGroupList,
  handleClient,
  getAdminClientList,
  getAdminClientDetails,
  createGroup,
  addUserToGroup,
  ERROR_MESSAGE,
  HANDLE_TYPE,
};
