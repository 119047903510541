import styles from "./GroupDetails.module.css";
import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useContext,
} from "react";
import { useParams, useHistory } from "react-router-dom";

import { AuthContext } from "context/AuthContext";

import Button from "@mui/material/Button";
import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";

import { IconButrtons } from "UI/dataGrid/DataGrid";
import IrisTextField from "UI/muiTextField/IrisTextField";
import IrisSelect from "UI/muiSelect/IrisSelect";
import IrisMultiSelect from "UI/muiSelect/IrisMultiSelect";

import { SUBMIT_STATE } from "components/submitBackdrop/SubmitBackdrop";
import { getAdminGroupList } from "utils/irisAuthRequests";
import { ERROR_MESSAGE } from "utils/irisAuthRequests";
import SubmitBackdrop from "components/submitBackdrop/SubmitBackdrop";

// initial state for reducer
const groupDetailsDefaultState = {
  id: 2,
  groupName: "Test_Group_2",
  clientId: 2,
  clientName: "test-company-1",
  userList: [
    { id: "test-user-1", userName: "test user 1" },
    { id: "test-user-2", userName: "test user 2" },
  ],
  /** invalid if tag name is '', should be remove when sending data */
  invalidInput: false,
};

// reducer actions
const ACTIONS = {
  SET_GROUPNAME: "SET GROUP NAME",
  SET_CLIENTID: "SET CLIENT ID",
  SET_CLIENTNAME: "SET CLIENT NAME",
  SET_USERLIST: "SET USER LIST",
  RESET: "RESET",
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    // update groupName input
    case ACTIONS.SET_GROUPNAME:
      return { ...state, groupName: payload };
    // update clientId
    case ACTIONS.SET_CLIENTID:
      return { ...state, clientId: payload };
    // update clientname input
    case ACTIONS.SET_CLIENTNAME:
      return { ...state, clientName: payload };
    // update user list
    case ACTIONS.SET_USERLIST:
      return { ...state, userList: payload };
    // reset to previous state, which is stored with useRef
    case ACTIONS.RESET:
      return { ...payload, invalidInput: false };
    default:
      return groupDetailsDefaultState;
  }
};

const GroupDetails = () => {
  const history = useHistory();
  const { id } = useParams();

  // get token to make api call
  const { awsToken, onLogout } = useContext(AuthContext);

  // reducer constructor
  const [groupDetailsState, dispatch] = useReducer(
    reducer,
    groupDetailsDefaultState
  );

  // indicate if it is during the api call
  const [isLoading, setIsLoading] = useState(true);

  // state to switch editable or not
  const [isEditMode, setIsEditMode] = useState(false);

  // useRef to store state info before editing, in case restore data by clicking clear button
  const tempStore = useRef();

  // backdrop set state
  const [openBackdrop, setOpenBackdrop] = useState(false);

  // when clicking the submit button, set state, modal will display different info according to the states
  const [getGroupListState, setGetGroupListState] = useState(SUBMIT_STATE.ok);
  // when there's an error, set state of the error message
  const [errorMessage, setErrorMessage] = useState("");

  // user list options
  const [userListOptions, setUserListOptions] = useState([]);

  // triggers when clicking on backdrop itself
  const handleClickBackdrop = () => {
    // when there's an error
    if (getGroupListState === SUBMIT_STATE.error) {
      // when the token is not valid, click the backdrop to logout
      if (errorMessage === ERROR_MESSAGE.token) {
        onLogout();
      }
    }
  };

  // datagrid data prop
  const groupInfoData = {
    groupName: groupDetailsState.groupName,
    clientName: groupDetailsState.clientName,
    userList: groupDetailsState.userList,
  };

  // datagrid data model prop
  const infoDataModel = [
    {
      editable: true,
      key: "groupName",
      label: "Group Name",
      type: "input",
      options: { options: [], idKey: "id", titleKey: "name" },
      onChange: (value) => {
        dispatch({ type: ACTIONS.SET_GROUPNAME, payload: value });
      },
    },
    // {
    //   editable: false,
    //   key: "clientName",
    //   label: "Client Name",
    //   type: "input",
    //   options: { options: [], idKey: "id", titleKey: "name" },
    //   onChange: (value) => {},
    // },
    // {
    //   editable: true,
    //   key: "userList",
    //   label: "User List",
    //   type: "multi-select",
    //   options: {
    //     options: [...userListOptions],
    //     idKey: "id",
    //     titleKey: "userName",
    //   },
    //   onChange: (updatedUserList) =>
    //     dispatch({ type: ACTIONS.SET_USERLIST, payload: updatedUserList }),
    // },
  ];

  useEffect(() => {
    let isSubscribed = true;

    setIsLoading(true);

    getAdminGroupList(awsToken)
      .then((result) => {
        setIsLoading(false);

        if (result.length > 0) {
          const currGroup = result.find((group) => group.GroupName === id);
          if (isSubscribed) {
            dispatch({
              type: ACTIONS.SET_GROUPNAME,
              payload: currGroup.GroupName,
            });

            // useRef to store the current useReducer state, in case onClear triggers the previous state to cover the new state
            const groupStateCopy = {
              groupName: currGroup.GroupName,
              // clientId: ,
              // clientName: ,
              // userList: usersList,
            };
            tempStore.current = groupStateCopy;
          }
        }
      })
      .catch((err) => {
        setOpenBackdrop(true);
        setErrorMessage(err.message);
        setGetGroupListState(SUBMIT_STATE.error);
      });

    return () => (isSubscribed = false);
  }, [id, awsToken]);

  if (isLoading) {
    return (
      <div className={styles.groupDetails}>
        <h2 className={`${styles.pageChild} ${styles.header}`}>
          Group Information
        </h2>
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
        <SubmitBackdrop
          open={openBackdrop}
          onClick={handleClickBackdrop}
          submitState={getGroupListState}
          modalMessage={errorMessage}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.groupDetails}>
        <h2 className={`${styles.pageChild} ${styles.header}`}>
          Group Information
          <IconButrtons
            isLoading={false}
            isEdit={isEditMode}
            setIsEditMode={setIsEditMode}
            onClear={() => {
              setIsEditMode(false);
              dispatch({
                type: ACTIONS.RESET,
                payload: tempStore.current,
              });
            }}
            onSend={() => {
              setIsEditMode(false);
              // useRef to store the current useReducer state, in case onClear triggers the previous state to cover the new state
              tempStore.current = groupDetailsState; // this step is supposed to be done when receiving data from api call
            }}
            disabledSendButton={groupDetailsState.invalidInput}
          />
        </h2>
        <div className={`${styles.pageChild} ${styles.content}`}>
          {infoDataModel.map((model, index) => {
            const { editable, key, label, type, options, onChange } = model;

            if (key === "clientName") {
              return (
                <div className={styles.eachItem} key={index}>
                  {label}:{" "}
                  <div className={styles.linkButtonGroup}>
                    <Button
                      className={styles.muiButton}
                      variant="outlined"
                      disabled={isEditMode}
                      onClick={() =>
                        history.push(
                          `/client-details/${groupDetailsState.clientId}`
                        )
                      }
                    >
                      {groupDetailsState.clientName}
                    </Button>
                  </div>
                </div>
              );
            }

            if (key === "userList") {
              return (
                <div className={styles.eachItem} key={index}>
                  {isEditMode ? (
                    <IrisMultiSelect
                      disabled={editable ? !isEditMode : true}
                      options={options.options}
                      value={groupInfoData[key]}
                      keyName={options.titleKey}
                      labelName={label}
                      updateSelectedOptions={onChange}
                    />
                  ) : (
                    <div>
                      {label}:{" "}
                      <div className={styles.linkButtonGroup}>
                        {groupInfoData[key].map((oneGroup) => (
                          <Button
                            className={styles.muiButton}
                            key={oneGroup.id}
                            variant="outlined"
                            disabled
                            onClick={() => {
                              history.push(`/user-details/${oneGroup.id}`);
                            }}
                          >
                            {oneGroup[options.titleKey]}
                          </Button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              );
            }

            return (
              <div className={styles.eachItem} key={index}>
                {type === "input" && (
                  <IrisTextField
                    disabled={editable ? !isEditMode : true}
                    fullWidth
                    label={label}
                    variant="standard"
                    value={groupInfoData[key]}
                    onChange={(e) => {
                      const value = e.target.value;
                      onChange(value);
                    }}
                  />
                )}
                {type === "select" && (
                  <IrisSelect
                    fullWidth
                    disabled={editable ? !isEditMode : true}
                    options={options.options}
                    keyField={options.idKey}
                    titleField={options.titleKey}
                    selectProps={{
                      value: groupInfoData[key],
                      variant: "standard",
                      disabled: !isEditMode,
                      onChange: (event) => {
                        const value = event.target.value;
                        onChange(value);
                      },
                    }}
                    label={label}
                  />
                )}
                {type === "multi-select" && (
                  <IrisMultiSelect
                    disabled={editable ? !isEditMode : true}
                    options={options.options}
                    value={groupInfoData[key]}
                    keyName={options.titleKey}
                    labelName={label}
                    updateSelectedOptions={onChange}
                  />
                )}
              </div>
            );
          })}
        </div>
        <SubmitBackdrop
          open={openBackdrop}
          onClick={handleClickBackdrop}
          submitState={getGroupListState}
          modalMessage={errorMessage}
        />
      </div>
    );
  }
};

export default GroupDetails;
