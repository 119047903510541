import { INPUTFORMS } from "pages/downloadPage/DownloadPage";
import { IRISGO_DOWNLOAD_URL } from "utils/env/urls";

export const getDownloadReport = (token, downloadContent) => {
  const {
    startTime,
    endTime,
    inputForm,
    deviceId,
    cityId,
    datapointId,
    withLabel,
    inputEmail,
  } = downloadContent;

  return new Promise((resolve, reject) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `${token}`);

    const tempContent = {
      email: inputEmail, // "liyangz@irisradgroup.com",
      // start_time: startTime, // "2021-04-10T04:00:00.000Z",
      // end_time: endTime, // "2022-02-10T03:59:59.999Z",
      //   with_label: false,
      //   data_point_ids: [1438776, 2774816],
      //   city_ids: [8, 1],
      //   device_ids: [27, 6],
    };

    switch (inputForm) {
      case INPUTFORMS.cities:
        // only when input with city and devices, start time and end time will be needed
        tempContent.start_time = startTime;
        tempContent.end_time = endTime;

        // when with label field is selected, add with_label param
        if (withLabel !== undefined) {
          tempContent.with_label = withLabel;
        }
        // when device is selected, add device_ids param, and break, city id is no longer useful
        if (deviceId.length > 0) {
          tempContent.device_ids = deviceId;
          break;
        }
        // when no device is selected, add city_ids param
        tempContent.city_ids = cityId;
        break;
      case INPUTFORMS.datapointId:
        tempContent.data_point_ids = datapointId;
        break;
      case INPUTFORMS.datapointIdFile:
        tempContent.data_point_ids = datapointId;
        break;
      default:
        return;
    }

    const raw = JSON.stringify(tempContent);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(IRISGO_DOWNLOAD_URL, requestOptions)
      .then((response) => response.text())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};
