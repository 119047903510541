import React from "react";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { IRIS_RED, IRIS_BLUE } from "./colors";
const theme = createTheme({
  palette: {
    primary: {
      main: IRIS_BLUE,
    },
    secondary: {
      main: IRIS_RED,
    },
  },
  typography: {
    button: {
      fontSize: "1.15rem",
      textTransform: "none",
    },
    overline: {
      lineHeight: 1.85,
      letterSpacing: "0.00938em",
      fontSize: "0.65rem",
    },
    caption: {
      lineHeight: 2.5,
      fontSize: "0.72rem",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: "1.125rem",
        "@media (max-width: 1440px)": {
          fontSize: "1rem",
        },
        "@media (max-width: 1024px)": {
          fontSize: "0.875rem",
        },
      },
    },
  },
});

const IRISThemeProvider = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default IRISThemeProvider;
