import React from "react";
import ReportCard from "./ReportCard";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { TOOL_BAR_HEIGHT } from "../../utils/theme/constrains";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  container: { padding: theme.spacing(1) },
  root: {
    padding: theme.spacing(1),
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 225}px)`,
  },
  noDataContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${TOOL_BAR_HEIGHT + 225}px)`,
  },
}));

function DefectReportCards({ datapoints }) {
  const classes = useStyles();
  return (
    <div>
      {datapoints.length > 0 ? (
        <Grid container className={classes.root}>
          {datapoints.map((data) => {
            return (
              <Grid
                item
                lg={3}
                md={6}
                xs={12}
                className={classes.container}
                key={data.id}
              >
                <ReportCard datapoint={data} />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <div className={classes.noDataContainer}>
          <Typography color="textSecondary">No record is found.</Typography>
        </div>
      )}
    </div>
  );
}

export default DefectReportCards;
