import { Breadcrumbs, Button } from "@mui/material";
import { getLogStreams as getLogStreamsRequest } from "api/cloudWatchLogs/request";
import { AuthContext } from "context/AuthContext";
import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNotification } from "UI/global/notification/context/NotificationContext";
import IrisLinearProgress from "UI/irisLinearProgress/IrisLinearProgress";
import IrisTable from "UI/muiTable/IrisTable";
import LogEventList from "./logEvent/LogEventList";

const IRIS_ACTIVITY_LOG_GROUP_NAME_PREFIX = "IrisGOActivityLogs/";
const IRIS_HEALTH_LOG_GROUP_NAME_PREFIX = "IrisGOHealthCheckLogs/";

/**
 *
 * @param {[object]} data
 * @param {string} key which value of data[key] could uniquely identify an object, ie: **"id"**
 * 
 * @returns {Object<string, object>}
 * 
 * @example 
 * const data = [
    { id: 1, name: "Lucien" },
    { id: 2, name: "Lily" },
  ]

  flatArrayIntoObject(data, "id") 
  returns 
  *{ 
    1: { id: 1, name: "Lucien" },
    2: { id: 2, name: "Lily" }
  }
  */
function flatArrayIntoObject(data, key) {
  const copy = JSON.parse(JSON.stringify(data));
  const result = copy.reduce((acc, ele) => {
    const newKey = ele[key];
    acc[newKey] = ele;
    return acc;
  }, {});

  return result;
}

/**
 *
 * get log group name based on log type and device_sn or device_id
 *
 * @param {string} title log table title, ie "xx logs"
 * @param {"HEALTH_CHECK_LOG" | "ACTIVITY_LOG"} eventType
 * @param {IrisDevice} device
 * @returns
 */
export const getLogGroupName = (eventType, deviceId) => {
  if (eventType === "ACTIVITY_LOG") {
    return IRIS_ACTIVITY_LOG_GROUP_NAME_PREFIX + deviceId + "/";
  } else {
    return IRIS_HEALTH_LOG_GROUP_NAME_PREFIX + deviceId + "/";
  }
};
/**
 * @description - render a table, each row represent a log stream name;
 * - log stream name is clickable, then the table component would be replaced by a log event list;
 * - a breadcrumbs would be rendered on top, by pressing the first element, the table would be render again, serve like a back button from log event list to log stream table.
 *
 * @param {Object} props
 * @param {"HEALTH_CHECK_LOG" | "ACTIVITY_LOG"} props.logEventType
 * @param {IrisDevice} props.device
 * @returns
 */
const DeviceLogs = (props) => {
  const [logStreamName, setLogStreamName] = useState("");
  const logStreamMapRef = useRef();
  const { showNotification } = useNotification();
  const { awsToken } = useContext(AuthContext);
  const { title, logEventType, device } = props;

  let logGroupName = "";
  if (device) {
    logGroupName = getLogGroupName(logEventType, device.id);
  }

  // https://react-query.tanstack.com/reference/useQuery#_top
  const {
    data,
    error,
    isFetching, // whether a query is on the way and awaiting response
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(
    ["GET_DEVICE_LOG_STREAMS", logGroupName, awsToken],
    async () => {
      try {
        const data = await getLogStreamsRequest(
          { logGroupName: logGroupName, descending: true },
          awsToken
        );

        const map = flatArrayIntoObject(data.logStreams, "logStreamName");
        logStreamMapRef.current = map;
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: false, // Set this to false to disable this query from automatically running.
      refetchInterval: false,
    }
  );

  useEffect(() => {
    if (device) {
      refetch();
    }
  }, [device, awsToken, showNotification, refetch]);

  const columns = [
    {
      fieldName: "logStreamName",
      headTitle: "Log Stream",
      element: (data, key, index) => (
        <Button
          color="primary"
          variant="outlined"
          size="small"
          sx={{
            fontSize: 14,
          }}
          onClick={() => setLogStreamName(data[key])}
        >
          {data[key]}
        </Button>
      ),
    },
    {
      fieldName: "firstEventTimestamp",
      headTitle: "First Log",
      element: (data, key) => new Date(data[key]).toLocaleString(),
    },
    {
      fieldName: "lastEventTimestamp",
      headTitle: "Last Log",
      element: (data, key) => new Date(data[key]).toLocaleString(),
    },
  ];

  const RenderLogStreamsTable = () => {
    return (
      <IrisTable
        tableTitle={title || "Logs"}
        onRefresh={refetch}
        data={data?.logStreams || []}
        columns={columns}
        idField="logStreamName"
        rowsPerPage={25}
        isDense={true}
        initialSort="desc"
        isLoading={isFetching}
      />
    );
  };

  const RenderEventList = () => {
    /** @type {CloudWatchLogStreamInterface} */
    const logStreamObject = logStreamMapRef.current[logStreamName];
    if (!logStreamObject) return;

    const {
      creationTime,
      firstEventTimestamp,
      lastEventTimestamp,
      lastIngestionTime,
    } = logStreamObject;

    const startTime = Math.min(creationTime, firstEventTimestamp);
    const endTime = Math.max(lastEventTimestamp, lastIngestionTime);
    // lastIngestionTime
    return (
      <div style={{ height: "80vh", overflowY: "auto" }}>
        <LogEventList
          logGroupName={logGroupName}
          logStreamName={logStreamName}
          startTime={startTime}
          endTime={endTime}
          LogEventType={logEventType}
          timezone={
            device?.client_details.geo_info?.timezone || "America/Toronto"
          }
        />
      </div>
    );
  };
  if (isFetching) {
    return <IrisLinearProgress isLoading={isFetching} />;
  } else if (error) {
    return <div>{error.message}</div>;
  }

  if (data?.logStreams?.length > 0) {
    return (
      <>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            paddingBlock: "0.5em",
          }}
        >
          <Button
            disabled={logStreamName === ""}
            onClick={() => setLogStreamName("")}
          >
            {logGroupName}
          </Button>
          {logStreamName && (
            <Button
              underline="hover"
              color="inherit"
              href="/material-ui/getting-started/installation/"
              disabled
            >
              {logStreamName}
            </Button>
          )}
        </Breadcrumbs>

        {logStreamName === "" ? <RenderLogStreamsTable /> : <RenderEventList />}
      </>
    );
  }

  return <IrisLinearProgress isLoading={true} />;
};

export default DeviceLogs;
