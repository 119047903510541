import React, { useState, useEffect, useContext, useMemo } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { AuthContext } from "../../context/AuthContext";

import momentTZ from "moment-timezone";

import Map from "google-map-react";
import { GOOGLE_MAP_KEY, OPEN_WEATHER_API_KEY } from "../../utils/env/keys";
import mapStyles from "../../utils/theme/mapStyles";
import {
  MOBILE_DEVICE_LATEST_SNAPSHOT_REQUEST,
  MOBILE_DEVICE_SNAPSHOT_REQUEST,
  CURRENT_WEATHER_REQUEST,
  MOBILE_DEVICE_REBOOT_REQUEST,
  MOBILE_DEVICE_GET_API_REQUEST,
} from "../../utils/requests";
import WelcomeLogo from "../../assets/WelcomeLogo.png";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RefreshIcon from "@mui/icons-material/Refresh";

// material ui icon needs to be put in a react component
// because the library expects react components, instead of html elements

//MUI v5 makeStyles hook
import { makeStyles } from "@mui/styles";
import DeviceHealthCheckLogs from "components/mobile/DeviceHealthCheckLogs";
import { useQuery, useQueryClient } from "react-query";
import { getLogGroupName } from "./DeviceLogs";
import { getLogEvents, getLogStreams } from "api/cloudWatchLogs/request";
import PermDeviceInformationIcon from "@mui/icons-material/PermDeviceInformation";

/** css styles */
const useStyles = makeStyles({
  root: {
    // the grid container's width is calc(100% + columnSpacing * 8px)
    // material ui adjusts the grid container's position according to the spacing
    //eg. {columnSpacing: 2} will lead to a marginLeft -16px, which will make the grid container looks ok
    // if not specified, the specified margin left will push the grid container out of the view port
    width: "100%",
    // backgroundColor: "#4444",
  },
});

/** material ui variable for grid */
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "start",
  color: theme.palette.text.primary,
}));

/** rendering content in current info */
const CurrentInfo = () => {
  /** css styles */
  const classes = useStyles();
  /** route id, equals to data id */
  const { id: routeID } = useParams();

  const { contextToken, awsToken } = useContext(AuthContext);

  const [deviceDetails, setDeviceDetails] = useState(null);

  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });

  // data after processing, by adding customized properties and filtering
  const [lastHealthCheckData, setLastHealthCheckData] = useState(null);

  // every 5 seconds call snapshot api
  const [countDown, setCountDown] = useState(5);

  // snap shot data to render
  const [snapshotData, setSnapshotData] = useState({
    image: WelcomeLogo,
    request_time: "-",
    create_time: "-",
    latitude: 0,
    longitude: 0,
    heading: 0,
    speed: 0,
  });
  // weather data to render
  const [weatherData, setWeatherData] = useState({
    city: "-",
    condition: "-",
    temp: "-",
  });

  const QUERY_KEY = useMemo(
    () => ["DEVICE_HEALTH_LOG", awsToken, routeID],
    [awsToken, routeID]
  );

  /**
   * manually cancle the pending queries so
   * that when component is mounted again, all
   * API calls will be executed again
   */
  const queryCLient = useQueryClient();
  useEffect(() => {
    return () => {
      // https://tanstack.com/query/v4/docs/framework/react/guides/query-cancellation#manual-cancellation
      queryCLient.cancelQueries({
        queryKey: QUERY_KEY,
      });
    };
  }, [queryCLient, QUERY_KEY]);

  const { data, refetch, isFetching, status } = useQuery(
    QUERY_KEY,
    async ({ signal }) => {
      const logGroupName = getLogGroupName("HEALTH_CHECK_LOG", routeID);

      try {
        const deviceData = await MOBILE_DEVICE_GET_API_REQUEST(
          routeID,
          awsToken
        );

        const customerLatLng = {
          lat: deviceData.client_details.geo_info.lat,
          lng: deviceData.client_details.geo_info.lon,
        };
        setMapCenter(customerLatLng);
        setDeviceDetails(deviceData);

        let deviceLatLng = null;

        const lastLogGroupName = await getLogStreams(
          {
            logGroupName: logGroupName,
            limit: 1,
            descending: true,
          },
          awsToken,
          signal
        );
        const logStreamName = lastLogGroupName.logStreams[0].logStreamName;

        let nextToken = false;
        let shouldStop = false;
        let healthCheckEvents = [];
        let latestHealthCheckLog;
        do {
          const payload = {
            logGroupName: logGroupName,
            logStreamName: logStreamName,
            limit: 100,
          };
          if (nextToken) {
            payload.nextToken = nextToken;
          }
          try {
            const last100Logs = await getLogEvents(payload, awsToken, signal);
            const returnToken = last100Logs?.nextBackwardToken;
            healthCheckEvents = healthCheckEvents.concat(last100Logs.events);
            if (!returnToken || returnToken === nextToken) {
              healthCheckEvents = healthCheckEvents.slice(0, 100);
              shouldStop = true;
            }
            nextToken = returnToken;
            if (healthCheckEvents.length > 0 && !latestHealthCheckLog) {
              // the returned first log is the latest one. setState now so that info will be display right away
              latestHealthCheckLog = JSON.parse(healthCheckEvents[0].message);
              setLastHealthCheckData(latestHealthCheckLog);

              if (
                latestHealthCheckLog.latitude &&
                latestHealthCheckLog.longitude
              ) {
                deviceLatLng = {
                  lat: latestHealthCheckLog.latitude,
                  lng: latestHealthCheckLog.longitude,
                };
                setMapCenter(deviceLatLng);
              }
            }
          } catch (error) {
            console.error(error);
            shouldStop = true;
          }
        } while (!shouldStop);

        if (!latestHealthCheckLog) {
          setLastHealthCheckData(undefined);
        }

        const last100HealthCheckData = healthCheckEvents.map(({ message }) =>
          JSON.parse(message)
        );
        return last100HealthCheckData;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    {
      refetchInterval: 30 * 1000,
    }
  );

  // deal with the data for both snapshot & weather
  useEffect(() => {
    const timezone = deviceDetails?.client_details?.geo_info?.timezone;
    if (!timezone) {
      return;
    }
    let isSubscribed = true;
    // show the refresh time dynamically
    const countInterval = setInterval(() => {
      setCountDown((i) => (i === 0 ? (i = 5) : i - 1));
    }, 1000);

    // every 5 seconds, call api and update data for snap shot
    const requestInterval = setInterval(() => {
      MOBILE_DEVICE_LATEST_SNAPSHOT_REQUEST(awsToken, routeID)
        .then((snapshotInfo) => {
          if (isSubscribed && snapshotInfo.length === 1) {
            /** temp object to add customized properties, eg. ... KM/H, ...°C */
            const receivedSnapshotData = snapshotInfo[0];

            // timezone of the city, obtained from the device api call

            receivedSnapshotData["Create Time"] =
              receivedSnapshotData.create_time === "-"
                ? "-"
                : momentTZ
                    .utc(receivedSnapshotData.create_time)
                    .tz(timezone)
                    .format("YYYY-MM-DD HH:mm:ssZ");
            receivedSnapshotData["Request Time"] =
              receivedSnapshotData.request_time === "-"
                ? "-"
                : momentTZ
                    .utc(receivedSnapshotData.request_time)
                    .tz(timezone)
                    .format("YYYY-MM-DD HH:mm:ssZ");
            receivedSnapshotData[
              "Speed"
            ] = `${receivedSnapshotData["speed"]} KM/H`;
            receivedSnapshotData[
              "Longitude"
            ] = `${receivedSnapshotData["longitude"]}°`;
            receivedSnapshotData[
              "Latitude"
            ] = `${receivedSnapshotData["latitude"]}°`;
            receivedSnapshotData[
              "Heading"
            ] = `${receivedSnapshotData["heading"]}°`;

            setSnapshotData((prev) => ({
              ...prev,
              ...receivedSnapshotData,
            }));
            return CURRENT_WEATHER_REQUEST(
              OPEN_WEATHER_API_KEY,
              snapshotInfo[0].latitude,
              snapshotInfo[0].longitude
            );
          }
        })
        .then((weatherInfo) => {
          if (!weatherInfo) {
            return;
          } else if (isSubscribed) {
            /** temp object to add customized properties, eg. ... KM/H, ...°C */
            const receivedWeatherData = weatherInfo;

            receivedWeatherData[
              "Ambient Temperature"
            ] = `${receivedWeatherData["temp"]}°C`;

            setWeatherData((prev) => ({
              ...prev,
              ...receivedWeatherData,
            }));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 5 * 1000);

    return () => {
      isSubscribed = false;
      clearInterval(countInterval);
      clearInterval(requestInterval);
    };
  }, [awsToken, routeID, deviceDetails]);

  /** health check info rendered, text render to the page : property name in data object */
  const healthCheckRender = {
    "Power Source": "power_source",
    "Free Memory": "free_memory",
    "Battery Level": "battery_level",
    "Free Storage": "free_storage",
    "Camera Angle": "camera_angle",
    "Local Datapoints": "remaining_data",
  };

  /** snap shot info rendered, text render to the page : property name in data object */
  const snapShotRender = {
    "Request Time": "Request Time",
    Longitude: "Longitude",
    "Create Time": "Create Time",
    Heading: "Heading",
    Latitude: "Latitude",
    Speed: "Speed",
  };

  /** weather info rendered, text render to the page : property name in data object */
  const weatherRender = {
    "Weather Condition": "condition",
    "Ambient Temperature": "Ambient Temperature",
  };

  /** columns for grid container of health check, snap shot, and the logo image */
  const gridContainer = {
    xs: 12,
  };
  /** columns for grid container of data, for both health check data and snap shot info */
  const gridDataContainer = {
    xs: 12,
    md: 12,
    lg: 6,
    xl: 5,
  };
  /** columns for grid container of image, for both health check data and snap shot info */
  const gridImageContainer = { xs: 12, md: 12, lg: 6, xl: 7 };
  /** columns for grid item of data, for both health check data and snap shot info */
  const gridDataItem = { xs: 12, md: 6, lg: 12 };
  /** spacing between grid items, LC means large container, 1 stands for 8px */
  const gridLCSpacing = {
    rowSpacing: { xs: 2, lg: 5 },
    columnSpacing: { xs: 2, lg: 12 },
  };
  /** spacing between grid items, SC means small container, 1 stands for 8px */
  const gridSCSpacing = { rowSpacing: { xs: 1 }, columnSpacing: { xs: 5 } };

  if (lastHealthCheckData === null && status === "loading") {
    return (
      <div>
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    );
  }
  return (
    <div>
      <div
        style={{
          color: "#4444",
          textAlign: "right",
        }}
      >
        Refresh in {countDown} seconds
      </div>
      <Grid
        container
        className={classes.root}
        rowSpacing={{
          xs: gridLCSpacing.rowSpacing.xs,
          lg: gridLCSpacing.rowSpacing.lg,
        }}
        columnSpacing={{
          xs: gridLCSpacing.columnSpacing.xs,
          lg: gridLCSpacing.columnSpacing.lg,
        }}
        sx={{
          marginLeft: {
            xs: -gridLCSpacing.columnSpacing.xs * 0.2,
            lg: -gridLCSpacing.columnSpacing.lg * 0.6,
          },
          marginTop: {
            xs: -gridLCSpacing.rowSpacing.xs,
            lg: -gridLCSpacing.rowSpacing.lg,
          },
        }}
      >
        <Grid
          item
          xs={gridDataContainer.xs}
          md={gridDataContainer.md}
          lg={gridDataContainer.lg}
          xl={gridDataContainer.xl}
        >
          <h2>Health Check Info:</h2>
          {lastHealthCheckData && (
            <Grid
              container
              rowSpacing={gridSCSpacing.rowSpacing.xs}
              columnSpacing={gridSCSpacing.columnSpacing.xs}
            >
              {Object.keys(healthCheckRender)
                // arrange the contents in alphabetic order
                .sort((a, b) => {
                  if (a.toLowerCase() < b.toLowerCase()) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((oneData) => (
                  <Grid
                    item
                    xs={gridDataItem.xs}
                    md={gridDataItem.md}
                    lg={gridDataItem.lg}
                    key={oneData}
                  >
                    <Item
                      sx={{
                        fontSize: { xs: "1rem", lg: "1.2rem" },
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{ justifyContent: "flex-start" }}
                      >{`${oneData}:`}</div>
                      <div style={{ justifyContent: "flex-end" }}>{`${
                        lastHealthCheckData[healthCheckRender[oneData]]
                      }`}</div>
                    </Item>
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={gridImageContainer.xs}
          md={gridImageContainer.md}
          lg={gridImageContainer.lg}
          xl={gridImageContainer.xl}
        >
          <h2>{`Last Health Check Location:`}</h2>
          <div
            style={{
              alignSelf: "center",
              minHeight: "240px",
              height: "80%",
              width: "95%",
            }}
          >
            <Map
              bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
              center={mapCenter}
              defaultZoom={12}
              options={{ styles: mapStyles }}
            >
              <PermDeviceInformationIcon
                lat={mapCenter.lat}
                lng={mapCenter.lng}
              />
            </Map>
          </div>
        </Grid>

        <Grid item xs={12}>
          <DeviceHealthCheckLogs
            data={data}
            refetch={refetch}
            isFetching={isFetching}
            status={status}
          />
        </Grid>
        <Grid item xs={gridContainer.xs}>
          <div style={{ textAlign: "left", display: "flex" }}>
            <h2>Snapshot Info:</h2>
            <div
              style={{
                display: "flex",
                alignSelf: "center",
              }}
            >
              <Tooltip title="Take a Snapshot" placement="top">
                <IconButton
                  onClick={() =>
                    MOBILE_DEVICE_SNAPSHOT_REQUEST(awsToken, routeID)
                      .then(() => {
                        alert("Snapshot Request Sent");
                      })
                      .catch((error) => {
                        console.error(error);
                      })
                  }
                >
                  <CameraAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reboot the Device" placement="top">
                <IconButton
                  onClick={() =>
                    MOBILE_DEVICE_REBOOT_REQUEST(
                      contextToken,
                      lastHealthCheckData.id
                    )
                      .then(() => {
                        alert("Reboot Request Sent");
                      })
                      .catch((error) => {
                        console.error(error);
                      })
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <Grid
            container
            rowSpacing={{
              xs: gridLCSpacing.rowSpacing.xs,
              lg: gridLCSpacing.rowSpacing.lg,
            }}
            columnSpacing={{
              xs: gridLCSpacing.columnSpacing.xs,
              lg: gridLCSpacing.columnSpacing.lg,
            }}
          >
            <Grid
              item
              xs={gridDataContainer.xs}
              md={gridDataContainer.md}
              lg={gridDataContainer.lg}
              xl={gridDataContainer.xl}
            >
              <Grid
                container
                rowSpacing={gridSCSpacing.rowSpacing.xs}
                columnSpacing={gridSCSpacing.columnSpacing.xs}
              >
                {Object.keys(snapShotRender)
                  // arrange the contents in alphabetic order
                  .sort((a, b) => {
                    if (a.toLowerCase() < b.toLowerCase()) {
                      return -1;
                    } else {
                      return 1;
                    }
                  })
                  .map((oneInfo) => (
                    <Grid
                      item
                      xs={gridDataItem.xs}
                      md={gridDataItem.md}
                      lg={gridDataItem.lg}
                      key={oneInfo}
                    >
                      <Item
                        sx={{
                          fontSize: { xs: "1rem", lg: "1.2rem" },
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ justifyContent: "flex-start" }}
                        >{`${oneInfo}:`}</div>
                        <div style={{ justifyContent: "flex-end" }}>
                          {snapshotData[snapShotRender[oneInfo]]
                            ? `${snapshotData[snapShotRender[oneInfo]]}`
                            : "-"}
                        </div>
                      </Item>
                    </Grid>
                  ))}
                {Object.keys(weatherRender)
                  // arrange the contents in alphabetic order
                  .sort((a, b) => {
                    if (a.toLowerCase() < b.toLowerCase()) {
                      return -1;
                    } else {
                      return 1;
                    }
                  })
                  .map((oneInfo) => (
                    <Grid
                      item
                      xs={gridDataItem.xs}
                      md={gridDataItem.md}
                      lg={gridDataItem.lg}
                      key={oneInfo}
                    >
                      <Item
                        sx={{
                          fontSize: { xs: "1rem", lg: "1.2rem" },
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{ justifyContent: "flex-start" }}
                        >{`${oneInfo}:`}</div>
                        <div style={{ justifyContent: "flex-end" }}>
                          {weatherData[weatherRender[oneInfo]]
                            ? `${weatherData[weatherRender[oneInfo]]}`
                            : "-"}
                        </div>
                      </Item>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Grid
              item
              xs={gridImageContainer.xs}
              md={gridImageContainer.md}
              lg={gridImageContainer.lg}
              xl={gridImageContainer.xl}
            >
              <div
                style={{
                  alignSelf: "center",

                  minHeight: "240px",
                  height: "80%",
                  width: "95%",
                }}
              >
                <img
                  src={snapshotData.image ? snapshotData.image : WelcomeLogo}
                  alt="Snapshot"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CurrentInfo;
