import { useQuery } from "react-query";
import { GET_LOOK_UP, LOOKUP_PARAMS } from "utils/requests";

/**
 *
 * @param {string} accessToken
 * @param {Function} onSuccess
 * @param {Function} onError
 * @param {Function} onSettled
 */
export default function useFetchCities(
  accessToken,
  onSuccess,
  onError,
  onSettled
) {
  const cities = useQuery(
    ["LOOK_UP", accessToken, LOOKUP_PARAMS.CITY],
    () => {
      return GET_LOOK_UP(accessToken, [LOOKUP_PARAMS.CITY]);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: onSuccess,
      onError: onError,
      onSettled: onSettled,
    }
  );

  return cities;
}
