import React from "react";
import { NavLink, matchPath } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Typography } from "@mui/material";

/** drawer width when open */
const DRAWER_OPEN_WIDTH = 240;
/** drawer width when close, calc(${theme.spacing(8)} + 1px) */
const DRAWER_CLOSE_WIDTH = 65;
/** drawer header height, for both drawer and content, to leave space for header, since header is position fixed */
const DRAWER_HEADER_HEIGHT = 60;

/** drawer open params, including width, open and close transition, overflow */
const openedMixin = (theme) => ({
  width: DRAWER_OPEN_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
/** drawer close params, including width, open and close transition, overflow */
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: DRAWER_CLOSE_WIDTH,
  //   [theme.breakpoints.up("sm")]: {
  //     width: DRAWER_CLOSE_WIDTH,
  //   },
});

/** drawer tag, react component
 * @see {@link https://mui.com/material-ui/react-drawer/#persistent-drawer}
 */
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_OPEN_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/** for both drawer and content, to leave space for header, since header is position fixed */
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: DRAWER_HEADER_HEIGHT,
  // necessary for content displaying below app bar
  ...theme.mixins.toolbar,
}));

/**
 * React component for switching routes and highlighting the current route, can be either open or close
 * @param {Object} props
 * @param {boolean} props.open indicate whether the sidebar is open or not
 * @param {import("routes/routesData").RouteData []} props.routesData all routes data, array of routes
 */
const SideBar = (props) => {
  const { open, routesData } = props;

  // theme from material ui to have the primary color
  const theme = useTheme();
  const activeBackground = theme.palette.primary.main;

  /** current url, to apply the css style to activate sidebar */
  const currentPath = window.location.pathname;

  // console.log("original current path", currentPath);

  /** highlight tab when the side bar is open, tab name, initial value is "" */
  let highLightOpenTab = "";
  /** highlight tab when the side bar is close, tab name, initial value is "" */
  let highLightCloseTab = "";

  // loop routes data with current path, to find the match route and get the highlight tab
  routesData.forEach((route) => {
    // target path set with the routes data
    const path = route.path;
    const targetObj = { path: path, exact: true, strict: false };
    const match = matchPath(currentPath, targetObj);
    // console.log("path: ", path, " match result: ", match);

    // when match is not null, current path matches the route data, then override highlight tab
    if (match !== null) {
      highLightOpenTab = route.highlightOpenTabName;
      highLightCloseTab = route.highlightCloseTabName;
    }
  });

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader />
      {open ? (
        <List role="navigation">
          {routesData
            .filter((route) => route.showOnSidebar)
            .map((oneRoute, index) => {
              /** boolean, indicate the sidebar tab is active or not */
              let isActive;

              // when the match route is found, highlight the correspond sidebar drawer tab
              if (highLightOpenTab !== "") {
                isActive = oneRoute.highlightOpenTabName === highLightOpenTab;
              }

              return (
                <div key={index}>
                  {index > 0 &&
                    routesData[index].sidebarGroup !==
                      routesData[index - 1].sidebarGroup && <Divider />}
                  <NavLink
                    to={oneRoute.path}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    <ListItem
                      disablePadding
                      sx={{
                        display: "block",
                        backgroundColor: isActive && activeBackground,
                        color: isActive && "#fff",
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "initial",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: "center",
                            color: isActive && "#fff",
                          }}
                        >
                          {oneRoute.sidebarIcon === null ? (
                            <BookmarkBorderIcon sx={{ opacity: 0 }} />
                          ) : (
                            oneRoute.sidebarIcon
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              sx={{
                                fontWeight:
                                  oneRoute.sidebarIcon === null ? 100 : 600,
                              }}
                            >
                              {oneRoute.sidebarTabName}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                </div>
              );
            })}
        </List>
      ) : (
        <List>
          {routesData
            .filter((route) => route.sidebarIcon !== null)
            .map((oneRoute, index) => {
              /** boolean, indicate the sidebar tab is active or not */
              let isActive;

              // when the match route is found, highlight the correspond sidebar drawer tab
              if (highLightCloseTab !== "") {
                isActive = oneRoute.highlightCloseTabName === highLightCloseTab;
              }

              return (
                <NavLink
                  key={index}
                  to={oneRoute.path}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ListItem
                    disablePadding
                    sx={{
                      display: "block",
                      backgroundColor: isActive && activeBackground,
                      color: isActive && "#fff",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: "auto",
                          justifyContent: "center",
                          color: isActive && "#fff",
                        }}
                      >
                        <Tooltip
                          title={oneRoute.sidebarTabName}
                          placement="right"
                        >
                          {oneRoute.sidebarIcon}
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText
                        primary={oneRoute.sidebarTabName}
                        sx={{ opacity: 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </NavLink>
              );
            })}
        </List>
      )}
    </Drawer>
  );
};

export default SideBar;

export {
  DRAWER_OPEN_WIDTH,
  DRAWER_CLOSE_WIDTH,
  DRAWER_HEADER_HEIGHT,
  DrawerHeader,
};
