import React, {
  useCallback,
  useContext,
  useEffect,
  createContext,
  useState,
} from "react";
import AlertModal from "../view/AlertModal";

/*================================================== CONTEXT START ================================================== */

const AlertContext = createContext([
  {
    /** alert message */
    message: "",
    /** true if custom modal should be shown, false otherwise */
    isOpen: false,
    /** function to close openend custom modal */
    dismiss: null,
  },
  () => {},
]);

const AlertContextProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    message: "",
    isOpen: false,
    dismiss: null,
  });

  return (
    <AlertContext.Provider value={[alert, setAlert]}>
      <AlertModal />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
/*==================================================  CONTEXT END  ================================================== */

/*================================================== HOOK START ================================================== */

export const useAlert = () => {
  const [alert, setAlert] = useContext(AlertContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const showAlert = useCallback(
    /**
     * @summary set up alert message on the custom alert modal
     *
     * @param {string} message alert message to be shown on the custom alert modal
     * @returns A promise, resolve or not, the modal would be dismissed
     */
    (message) => {
      const promise = new Promise((resolve, reject) => {
        setAlert({ message, isOpen: true, dismiss: resolve });
        setNeedsCleanup(true);
      });

      const reset = () => {
        setAlert({ message: "", dismiss: null, isOpen: false });
        setNeedsCleanup(false);
      };

      return promise.then(
        () => {
          reset();
          return true;
        },
        () => {
          reset();
          return false;
        }
      );
    },
    [setAlert]
  );

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (alert.dismiss && needsCleanup) {
        alert.dismiss();
      }
    };
  }, [alert, needsCleanup]);

  return {
    ...alert,
    showAlert,
  };
};
/*==================================================  HOOK END  ================================================== */
