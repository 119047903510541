// Set to Production Server
// const IRISGO_BASE_URL = new URL("https://post.api.irisradgroup.com/api/");

// Set to Test Server
const IRISGO_BASE_URL = new URL(`${process.env.REACT_APP_SERVER_ROOT_URL}`);

const IRISGO_LOGIN_URL = `${IRISGO_BASE_URL}token/`;
const IRISGO_LOOKUP_URL = `${IRISGO_BASE_URL}lookup/v1/`;
const IRISGO_DEVICE_UPDATE_URL = `${IRISGO_BASE_URL}device/`;
const IRISGO_FETCH_URL = `${IRISGO_BASE_URL}datapoint/`;
const IRISGO_DISTANCE_URL = `${IRISGO_BASE_URL}report/distance/`;
const IRISGO_LABELER_URL = `${IRISGO_BASE_URL}report/view/`;
const IRISGO_UPLOAD_URL = `${IRISGO_BASE_URL}geo_info/`;
const IRISGO_UPDATE_URL = `${IRISGO_BASE_URL}datapoint`;
const IRISGO_DOWNLOAD_URL = `${IRISGO_BASE_URL}datapoint/report/`;
const IRISGO_DEVICE_USAGE_URL = `${IRISGO_BASE_URL}report/device/`;
const IRISGO_NEW_DEVICE_URL = `${IRISGO_BASE_URL}device/new/`;
const IRISGO_REBOOT_URL = `${IRISGO_BASE_URL}device/reboot/`;
const IRISGO_GET_LABEL_URL = `${IRISGO_BASE_URL}label/`;
const IRISGO_LABEL_TYPE = `${IRISGO_BASE_URL}label_type/`;

const IRISGO_AUTH_ROOT_URL = process.env.REACT_APP_IRIS_AUTH_ROOT_URL; // production

const IRISGO_AUTH_SERVER_VERIFY_TOKEN_URL = `${IRISGO_AUTH_ROOT_URL}/verify/`;
const IRISGO_AUTH_SERVER_LIST_USER_URL = `${IRISGO_AUTH_ROOT_URL}/admin/users/`;
const IRISGO_AUTH_SERVER_USER_DETAILS_URL = `${IRISGO_AUTH_ROOT_URL}/admin/get_user/?username=`;
const IRISGO_AUTH_SERVER_USER_SIGNUP_URL = `${IRISGO_AUTH_ROOT_URL}/admin/signup/`;
const IRISGO_AUTH_SERVER_LIST_GROUP_URL = `${IRISGO_AUTH_ROOT_URL}/admin/groups/`;
const IRISGO_AUTH_SERVER_CREATE_CLIENT_URL = `${IRISGO_AUTH_ROOT_URL}/admin/create_client/`;
const IRISGO_AUTH_SERVER_UPDATE_CLIENT_URL = `${IRISGO_AUTH_ROOT_URL}/admin/update_client/`;
const IRISGO_AUTH_SERVER_LIST_CLIENT_URL = `${IRISGO_AUTH_ROOT_URL}/admin/clients/`;
const IRISGO_AUTH_SERVER_CLIENT_DETAILS_URL = `${IRISGO_AUTH_ROOT_URL}/admin/get_client/?id=`;
const IRISGO_AUTH_SERVER_CREATE_GROUP_URL = `${IRISGO_AUTH_ROOT_URL}/admin/create_group/`;
const IRISGO_AUTH_SERVER_ADD_USER_TO_GROUP_URL = `${IRISGO_AUTH_ROOT_URL}/admin/user_add_groups/`;
const IRISGO_AUTH_SERVER_UPDATE_CLIENT_WIDGET_URL = `${IRISGO_AUTH_ROOT_URL}/admin/widget/update/`;
const IRISGO_AUTH_SERVER_CREATE_CLIENT_WIDGET_URL = `${IRISGO_AUTH_ROOT_URL}/admin/widget/create/`;
const IRISGO_AUTH_SERVER_DELETE_CLIENT_WIDGET_URL = `${IRISGO_AUTH_ROOT_URL}/admin/widget/delete/`;
const IRISGO_AUTH_NOTIFICATION_GROUP_LIST__URL = `${IRISGO_AUTH_ROOT_URL}/admin/notification/group/list/`;
const IRISGO_AUTH_COGNITO_CUSTOMER_CONTACT_LIST__URL = `${IRISGO_AUTH_ROOT_URL}/admin/notification/contact/`;
const IRISGO_AUTH_NOTIFICATION_GROUP_UPDATE_MEMBERS__URL = `${IRISGO_AUTH_ROOT_URL}/admin/notification/contact/updateNotificationGroupMembers/`;
const IRISGO_AUTH_NOTIFICATION_FILTERS_LIST__URL = `${IRISGO_AUTH_ROOT_URL}/admin/notification/group/filter/list/`;
const IRISGO_AUTH_CREATE_NOTIFICATION_GROUP__URL = `${IRISGO_AUTH_ROOT_URL}/admin/notification/group/create/`;
const IRISGO_AUTH_UPDATE_NOTIFICATION_GROUP__URL = `${IRISGO_AUTH_ROOT_URL}/admin/notification/group/update/`;
const IRISGO_AUTH_DELETE_NOTIFICATION_GROUP__URL = `${IRISGO_AUTH_ROOT_URL}/admin/notification/group/delete/`;
const IRISGO_AUTH_LIST_NOTIFICATION_GROUP__URL = `${IRISGO_AUTH_ROOT_URL}/admin/notification/group/list/`;
const IRISGO_AUTH_SEND_NOTIFICATION__URL = `${IRISGO_AUTH_ROOT_URL}/admin/notification/send/`;
const IRISGO_AUTH_LIST_CSAM__URL = `${IRISGO_AUTH_ROOT_URL}/admin/csam/`;
const IRISGO_AUTH_UPDATE_CSAM_CLIENTS__URL = `${IRISGO_AUTH_ROOT_URL}/admin/csam/update/client/`;

// device logs
const IRISGO_DEVICE_LOGS_URL = `${IRISGO_BASE_URL}logs`;

const IRISGO_VERIFY_TOKEN_URL = `${IRISGO_BASE_URL}token/verify/`;

// Sentry API Gateway
const SENTRY_GATEWAY_URL = new URL("https://sentry.api.irisradgroup.com/");
const SENTRY_GATEWAY_LOOKUP_URL = `${SENTRY_GATEWAY_URL}lookup`;

// Hexnode API Base
const HEXNODE_URL = new URL("https://irisradgroup.hexnodemdm.com/api/v1/");
const HEXNODE_UPDATE_DEVICE_NAME_URL = `${HEXNODE_URL}actions/save_friendly_name/`;
const HEXNODE_LOOKUP_URL = `${HEXNODE_URL}devices/`;
const HEXNODE_REBOOT_URL = `${HEXNODE_URL}actions/reboot/`;

// Open Weather API
const OPEN_WEATHER_URL = new URL("https://api.openweathermap.org/data/2.5/");
const OPEN_WEATHER_CURRENT_URL = `${OPEN_WEATHER_URL}weather`;

// Post Office URL
const POST_OFFICE_URL = new URL("https://post-office.api.irisradgroup.com");

const COGNITO_AUTH_URL = process.env.REACT_APP_AWS_COGNITO_DEFAULT_AUTH_URL;

// cloud watch logs
const API_GATEWAY_CLOUD_WATCH_LOG_ROOT_URL =
  process.env.REACT_APP_API_GATEWAY_CLOUD_WATCH_LOGS_ROOT_URL;

// New Device Sservice API URL
const IRISGO_DEVICE_BASE_API_URL =
  process.env.REACT_APP_DEVICE_SERVER_ROOT_URL;
const IRISGO_NEW_DEVICE_API_URL =
  IRISGO_DEVICE_BASE_API_URL + "/device/create/";
const IRISGO_GET_DEVICE_API_URL = IRISGO_DEVICE_BASE_API_URL + "/device/";
const IRISGO_UPDATE_DEVICE_API_URL =
  IRISGO_DEVICE_BASE_API_URL + "/device/update/";
const GET_DEVICE_STATUS = IRISGO_DEVICE_BASE_API_URL + "/device/status/";
const IRISGO_DEVICE_SNAPSHOT = `${IRISGO_DEVICE_BASE_API_URL}/device/snapshot/fetch/`;
const IRISGO_DEVICE_TAKE_SNAPSHOT = `${IRISGO_DEVICE_BASE_API_URL}/device/action/`;

const IRIS_DATA_URL = process.env.REACT_APP_IRIS_DATA_SERVER_URL;
const DATA_SERVER_UPLOAD_SHAPEFILE_URL = `${IRIS_DATA_URL}/api/workorder/profile/shapefile/update`;

export {
  COGNITO_AUTH_URL,
  IRISGO_AUTH_SERVER_VERIFY_TOKEN_URL,
  IRISGO_AUTH_SERVER_LIST_USER_URL,
  IRISGO_AUTH_SERVER_USER_DETAILS_URL,
  IRISGO_AUTH_SERVER_USER_SIGNUP_URL,
  IRISGO_AUTH_SERVER_LIST_GROUP_URL,
  IRISGO_AUTH_SERVER_LIST_CLIENT_URL,
  IRISGO_AUTH_SERVER_CLIENT_DETAILS_URL,
  IRISGO_AUTH_SERVER_CREATE_CLIENT_URL,
  IRISGO_AUTH_SERVER_UPDATE_CLIENT_URL,
  IRISGO_AUTH_SERVER_CREATE_GROUP_URL,
  IRISGO_AUTH_SERVER_ADD_USER_TO_GROUP_URL,
  IRISGO_GET_LABEL_URL,
  IRISGO_BASE_URL,
  IRISGO_LOGIN_URL,
  IRISGO_LOOKUP_URL,
  IRISGO_FETCH_URL,
  IRISGO_DISTANCE_URL,
  IRISGO_LABELER_URL,
  IRISGO_UPLOAD_URL,
  IRISGO_UPDATE_URL,
  IRISGO_DOWNLOAD_URL,
  IRISGO_DEVICE_USAGE_URL,
  SENTRY_GATEWAY_LOOKUP_URL,
  HEXNODE_UPDATE_DEVICE_NAME_URL,
  HEXNODE_LOOKUP_URL,
  IRISGO_NEW_DEVICE_URL,
  IRISGO_DEVICE_SNAPSHOT,
  IRISGO_DEVICE_TAKE_SNAPSHOT,
  OPEN_WEATHER_CURRENT_URL,
  HEXNODE_REBOOT_URL,
  IRISGO_REBOOT_URL,
  POST_OFFICE_URL,
  IRISGO_LABEL_TYPE,
  IRISGO_VERIFY_TOKEN_URL,
  IRISGO_DEVICE_LOGS_URL,
  API_GATEWAY_CLOUD_WATCH_LOG_ROOT_URL,
  IRISGO_AUTH_SERVER_UPDATE_CLIENT_WIDGET_URL,
  IRISGO_AUTH_SERVER_CREATE_CLIENT_WIDGET_URL,
  IRISGO_AUTH_SERVER_DELETE_CLIENT_WIDGET_URL,
  IRISGO_NEW_DEVICE_API_URL,
  IRISGO_GET_DEVICE_API_URL,
  IRISGO_UPDATE_DEVICE_API_URL,
  DATA_SERVER_UPLOAD_SHAPEFILE_URL,
  GET_DEVICE_STATUS,
  IRISGO_AUTH_NOTIFICATION_GROUP_LIST__URL,
  IRISGO_AUTH_COGNITO_CUSTOMER_CONTACT_LIST__URL,
  IRISGO_AUTH_NOTIFICATION_GROUP_UPDATE_MEMBERS__URL,
  IRISGO_AUTH_NOTIFICATION_FILTERS_LIST__URL,
  IRISGO_AUTH_CREATE_NOTIFICATION_GROUP__URL,
  IRISGO_AUTH_DELETE_NOTIFICATION_GROUP__URL,
  IRISGO_AUTH_UPDATE_NOTIFICATION_GROUP__URL,
  IRISGO_AUTH_LIST_CSAM__URL,
  IRISGO_AUTH_UPDATE_CSAM_CLIENTS__URL,
  IRISGO_AUTH_LIST_NOTIFICATION_GROUP__URL,
  IRISGO_AUTH_SEND_NOTIFICATION__URL,
  IRISGO_DEVICE_UPDATE_URL
};
