import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DatePicker } from "@mui/lab";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { IRIS_POSTOFFICE_API_KEY } from "../../utils/env/keys";
import { POST_OFFICE_URL } from "../../utils/env/urls";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles({
  textArea: {
    minHeight: 300,
    backgroundColor: "#efefef",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const PostOfficeEmailFormModal = (props) => {
  const classes = useStyles();
  const { row, modalOpen, handleModalClose, handleModalUpdateClose } = props;
  // const initialDateTime = new Date(finalDateTime).setHours(finalDateTime.getHours());
  const [emailStatus, setEmailStatus] = useState(row.status);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [textMessage, setTextMessage] = useState(row.email_content);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [adamsFormObject, setAdamsFormObject] = useState({
    sender_email: row.sender_email,
    recipient_email: row.recipient_email,
    scheduled_time: row.scheduled_time,
    subject: row.subject,
    email_content: row.email_content,
  });
  const [finalDateTime, setFinalDateTime] = useState(row.scheduled_time);
  // MyTable -> EmailRow -> EmailFormModal
  const handleEmailChange = () => {
    const newForm = { ...adamsFormObject, scheduled_time: finalDateTime }; // adamsFormObject is either being appended or overwritten by "schedule_time"

    fetch(POST_OFFICE_URL + `/email/${row.id}/`, {
      method: "put",
      headers: {
        Authorization: IRIS_POSTOFFICE_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newForm),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
      .then(handleModalUpdateClose());
  };

  const handleEmailDelete = () => {
    fetch(POST_OFFICE_URL + `/email/${row.id}/`, {
      method: "delete",
      headers: {
        Authorization: IRIS_POSTOFFICE_API_KEY,
        "Content-Type": "application/json",
      },
    })
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
      .then(handleModalUpdateClose());
  };
  // for some reason when the props is passed, the const is null and not intially set
  useEffect(() => {
    setFinalDateTime(row.scheduled_time);
    setTextMessage(row.email_content);
    setEmailStatus(row.status);
  }, [row]);

  useEffect(() => {
    if (emailStatus === "sent") {
      setIsEmailSent(true);
    } else {
      setIsEmailSent(false);
    }
  }, [emailStatus]);

  const updateAdamsForm = (e, key) => {
    const value = e.target.value;
    const newForm = { ...adamsFormObject };
    newForm[key] = value;
    setAdamsFormObject(newForm);
  };

  return (
    <Dialog
      id="adamsModal"
      open={modalOpen}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
        Edit Email
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are now editing the current email, double-check to make you are
          happy with the current changes changing anything will automatically
          increase the time sent out by 5 minutes.
        </DialogContentText>
        <TextField
          disabled={isEmailSent}
          defaultValue={row.sender_email}
          autoFocus
          margin="dense"
          label="Sender Email Address"
          type="email"
          fullWidth
          onChange={(e) => updateAdamsForm(e, "sender_email")}
        />
        <TextField
          disabled={isEmailSent}
          defaultValue={row.subject}
          margin="dense"
          label="Subject Line"
          type="text"
          fullWidth
          onChange={(e) => updateAdamsForm(e, "subject")}
        />
        <TextField
          disabled={isEmailSent}
          defaultValue={row.recipient_email}
          margin="dense"
          label="Recipient Email Address"
          type="email"
          fullWidth
          onChange={(e) => updateAdamsForm(e, "recipient_email")}
        />

        <DatePicker
          disabled={isEmailSent}
          value={finalDateTime}
          onChange={(value) => setFinalDateTime(value)}
          label="Set Scheduled Time"
          disablePast="true"
          format="yyyy-MM-dd hh:mm a"
        ></DatePicker>
        <br />
        <div>
          {emailStatus !== "sent" && (
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={10}
              defaultValue={textMessage}
              variant="outlined"
              fullWidth
              onChange={(e) => {
                updateAdamsForm(e, "email_content");
                setTextMessage(e.target.value);
              }}
            />
          )}
        </div>

        <div
          dangerouslySetInnerHTML={{ __html: textMessage }}
          className={classes.textArea}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEmailDelete} color="secondary">
          Delete
        </Button>
        <Button onClick={handleEmailChange} color="primary">
          Change
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostOfficeEmailFormModal;
