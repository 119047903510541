import {
  IRISGO_LOGIN_URL,
  IRISGO_LOOKUP_URL,
  IRISGO_VERIFY_TOKEN_URL,
  IRISGO_AUTH_SERVER_VERIFY_TOKEN_URL,
} from "../../utils/env/urls";
import { API_ERROR_MESSAGES, getRequestError } from "../apiUtils";

/**
 *
 * @param {string} token
 * @returns {Promise<TypeIrisUser>}
 */
export const getIrisUser = async (token) => {
  const options = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return new Promise(async (resolve, reject) => {
    // fetch API error, ie: Failed to fetch due to CORS.
    let fetchError;
    const response = await fetch(
      IRISGO_AUTH_SERVER_VERIFY_TOKEN_URL,
      options
    ).catch((e) => {
      fetchError = e;
    });

    if (fetchError) {
      reject(fetchError);
    } else {
      if (response.ok) {
        const responseJson = await response.json();
        resolve(responseJson);
      } else {
        let error;
        const { status } = response;
        const message = await response.text();
        const errorMessage = `Error ${status}: ${message}`;
        if (status === 401 || status === 403) {
          error = new Error(errorMessage);
        } else if (status === 404) {
          error = new Error(errorMessage);
        } else if (status >= 500) {
          error = new Error(errorMessage);
        } else {
          error = new Error(errorMessage);
        }
        reject(error);
      }
    }
  });
};

export const LOGIN_REQUEST = (username, password) => {
  return new Promise((resolve, reject) => {
    fetch(IRISGO_LOGIN_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username, password: password }),
    })
      .then((result) => {
        if (result.status === 200) {
          result.json().then((data) => {
            // resolve all log in info
            USERGROUP_REQUEST(username, data.access).then((groupInfo) => {
              resolve({
                token: data.access,
                group: groupInfo,
              });
            });
          });
        } else if (result.status === 401) {
          reject(new Error("Your account or password is incorrect."));
        } else {
          reject(new Error("Server Error"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const USERGROUP_REQUEST = (username, token) => {
  return new Promise((resolve, reject) => {
    const url = new URL(IRISGO_LOOKUP_URL);
    url.searchParams.append("user", true);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        const error = getRequestError(response);
        if (!error) {
          response.json().then((data) => {
            if (data.success === true) {
              var users = data.result.user;
              const foundUser = users.find(
                (user) => user.username === username
              );
              resolve(foundUser.groups);
            } else {
              reject(new Error("Error in getting user group data"));
            }
          });
        } else {
          reject(error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 *
 * @param {string} token
 * @returns A promise, on resulve, token is verified, false otherwise
 */
export const verifyAccessToken = async (token) => {
  const formdata = new FormData();
  formdata.append("token", token);
  const options = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  return new Promise(async (resolve, reject) => {
    const response = await fetch(IRISGO_VERIFY_TOKEN_URL, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const { status } = response;
      const message = await response.text();
      const errorMessage = `Error ${status}: ${message}`;
      if (status === 401 || status === 403) {
        error = new Error(errorMessage);
      } else if (status === 404) {
        error = new Error(errorMessage);
      } else if (status >= 500) {
        error = new Error(errorMessage);
      } else {
        error = new Error(errorMessage);
      }
      reject(error);
    }
  });
};
