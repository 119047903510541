import React, { useState, useEffect } from "react";

/**
 *
 * @param {string} string
 * @returns {boolean} indicate if the input string contains any of the specified special characters
 */
const containsSpecialChars = (string) => {
  return /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(string);
};
/**
 *
 * @param {string} string
 * @returns {boolean} indicate if the input string contains any of the characters of a - z in lowercase
 */
const containsOneLowerCase = (string) => {
  return /[a-z]/.test(string);
};
/**
 *
 * @param {string} string
 * @returns {boolean} indicate if the input string contains any of the characters of a - z in uppercase
 */
const containsOneUpperCase = (string) => {
  return /[A-Z]/.test(string);
};
/**
 *
 * @param {string} string
 * @returns {boolean} indicate if the input string contains any number characters of 0 - 9
 */
const containsOneNumericChar = (string) => {
  return /[0-9]/.test(string);
};

/**
 *
 * @param {Object} props
 * @param {string} props.inputPassword password input to be checked if it meets the regex requirements
 * @param {Function} props.setPasswordOK handle checking the password meets the regex requirements
 * @returns
 */
const PasswordHelperText = (props) => {
  const { inputPassword, setPasswordOK } = props;

  /** boolean, indicates if the input string has less than 8 characters */
  const isTooShort = inputPassword.length < 8;
  /** boolean, indicates if the input string contains number characters, 0 - 9 */
  const hasOneNumericChar = containsOneNumericChar(inputPassword);
  /** boolean, indicates if the input string contains upper case characters, A - Z */
  const hasOneUppercaseChar = containsOneUpperCase(inputPassword);
  /** boolean, indicates if the input string contains lower case characters, a - z */
  const hasOneLowercaseChar = containsOneLowerCase(inputPassword);
  /** boolean, indicates if the input string contains specified special characters */
  const hasOneSpecialChar = containsSpecialChars(inputPassword);

  // display helperText dynamically, and return value to parent component
  useEffect(() => {
    setPasswordOK(
      inputPassword.length >= 8 &&
        containsOneNumericChar(inputPassword) &&
        containsOneUpperCase(inputPassword) &&
        containsOneLowerCase(inputPassword) &&
        containsSpecialChars(inputPassword)
    );
  }, [inputPassword, setPasswordOK]);

  return (
    <span>
      <span style={{ color: isTooShort ? "#c1151f" : "" }}>
        at least 8 characters
      </span>
      <br />
      <span style={{ color: hasOneNumericChar ? "" : "#c1151f" }}>
        must have 1 number
      </span>
      <br />
      <span style={{ color: hasOneUppercaseChar ? "" : "#c1151f" }}>
        must have 1 upper case
      </span>
      <br />
      <span style={{ color: hasOneLowercaseChar ? "" : "#c1151f" }}>
        must have 1 lower case
      </span>
      <br />
      <span style={{ color: hasOneSpecialChar ? "" : "#c1151f" }}>
        must have 1 special charater
      </span>
    </span>
  );
};

export default PasswordHelperText;
