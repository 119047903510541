import { LinearProgress } from "@mui/material";
import React from "react";

/**
 *
 * @param {object} props
 * @param {boolean} props.isLoading
 * @returns
 */
function IrisLinearProgress(props) {
  const { isLoading } = props;
  return <div style={{ height: 4 }}>{isLoading && <LinearProgress />}</div>;
}

export default IrisLinearProgress;
