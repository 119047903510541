import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AuthContext } from "../../context/AuthContext";
import { DEFECT_LABEL_REQUEST } from "../../utils/requests";
import LinearProgress from "@mui/material/LinearProgress";
const useStyles = makeStyles((theme) => ({
  modalContainer: {
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 1200,
    height: 800,
  },
  modalImg: {
    border: "2px solid #ddd",
    background: "#fff",
    borderRadius: 4,
    padding: 5,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  modalButton: {
    color: theme.palette.background.default,
    position: "fixed",
    top: 15,
    right: 15,
    borderRadius: 0,
  },
}));

const MediaCard = (props, ref) => {
  const { data, close } = props;
  const classes = useStyles();
  const { contextToken } = useContext(AuthContext);
  const [url, setUrl] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   let isSubscribed = true;
  //   if (data.labels.length > 0) {
  //     DEFECT_LABEL_REQUEST(data.id, contextToken)
  //       .then((labelDetail) => {
  //         if (isSubscribed) {
  //           setUrl(labelDetail[0].url);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else {
  //     if (isSubscribed) setUrl(data.image);
  //   }
  //   return () => (isSubscribed = false);
  // }, [contextToken, data, setUrl]);

  return (
    <div className={classes.modalContainer} ref={ref} tabIndex={-1}>
      <img
        src={data.all_labels_image}
        alt="labeled"
        className={classes.modalImg}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
      {isLoading && <LinearProgress />}
      <IconButton className={classes.modalButton} onClick={() => close(false)}>
        <CloseIcon className={classes.buttonIcon} />
      </IconButton>
    </div>
  );
};
export default React.forwardRef(MediaCard);
