import React from "react";
import IrisTable from "UI/muiTable/IrisTable";
import moment from "moment";

const MIN_WIDTH = 180;

/** fall back value for COLUMNS when property undefined*/
const fallBack = "N/A";

/**
 * colums for the health check log table
 * @type {[TableColumnObject]}
 */
const COLUMNS = [
  {
    fieldName: "timestamp",
    headTitle: "Timestamp",
    width: 200,
    element: (data, key) =>
      moment(data[key] * 1000).format("yyyy-MM-DD hh:mm:ss a"),
  },
  { fieldName: "ip_address", headTitle: "IP", width: MIN_WIDTH },
  {
    fieldName: "schedule_details",
    headTitle: "Schedule",
    width: MIN_WIDTH,
    element: (data, key) => data[key].replace(/is currently/g, ""),
  },
  {
    fieldName: "geo_fence_info",
    headTitle: "Geo Fence",
    width: MIN_WIDTH,
    element: (data, key) => data[key].replace(/is currently/g, ""),
  },
  { fieldName: "latitude", headTitle: "Latitude", width: MIN_WIDTH },
  {
    fieldName: "longitude",
    headTitle: "Longitude",
    width: MIN_WIDTH,
  },
  {
    fieldName: "distance_from_last_Location",
    headTitle: "Distance from Last Health Check (m)",
    width: MIN_WIDTH,
    element: (data, key) => {
      // incase the data is not available
      if (typeof data[key] === "number") {
        return data[key].toFixed(2);
      } else {
        return fallBack;
      }
    },
  },
  {
    fieldName: "location_source",
    headTitle: "GPS Source",
    width: MIN_WIDTH,
  },
  {
    fieldName: "battery_level",
    headTitle: "Battery (%)",
    width: MIN_WIDTH,
  },
  {
    fieldName: "power_source",
    headTitle: "Power Source",
    width: MIN_WIDTH,
  },
  {
    fieldName: "free_memory",
    headTitle: "Free Memory (MB)",
    width: MIN_WIDTH,
  },
  {
    fieldName: "free_storage",
    headTitle: "Free Storage (MB)",
    width: MIN_WIDTH,
  },
  {
    fieldName: "remaining_data",
    headTitle: "Remaing Data",
    width: MIN_WIDTH,
  },
  {
    fieldName: "speed_threshold",
    headTitle: "Speed Threshold (m/s)",
    width: MIN_WIDTH,
    element: (data, key) => {
      // in case the data is not available
      if (typeof data[key] === "number") {
        return data[key].toFixed(2);
      } else {
        return fallBack;
      }
    },
  },
  {
    fieldName: "image_min_distance",
    headTitle: "Image Min Distance (m)",
    width: MIN_WIDTH,
  },
  {
    fieldName: "is_upload_on_WiFi",
    headTitle: "Upload Via WiFi?",
    width: MIN_WIDTH,
    element: (data, key) => data[key] + "",
  },
];

/**
 *
 * @param {object} props
 * @param {number} props.deviceId device id from which the health check logs would be loded
 * @returns
 */
function DeviceHealthCheckLogs(props) {
  const { data, status, refetch, isFetching } = props;

  /**@type {{data: [DeviceLogInterface]}} */
  const healthCheckLogs = data || [];

  if (status === "error") {
    return <h3>Error in loading device health check log</h3>;
  }

  return (
    <>
      <IrisTable
        tableTitle="Last 100 Records"
        onRefresh={refetch}
        data={healthCheckLogs}
        isLoading={isFetching}
        initialSort="desc"
        columns={COLUMNS}
        idField="timestamp"
        isDense={true}
        fallbackValue="data not available"
      />
    </>
  );
}

const isEqual = (prevPros, nextProps) => {
  return JSON.stringify(prevPros) === JSON.stringify(nextProps);
};
// export default React.memo(DeviceHealthCheckLogs);
export default React.memo(DeviceHealthCheckLogs);
