import { Button, TextField, Typography } from "@mui/material";
import { getLogEvents } from "api/cloudWatchLogs/request";
import { AuthContext } from "context/AuthContext";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import IrisLinearProgress from "UI/irisLinearProgress/IrisLinearProgress";

/**
 *
 * @summary List of events within a given log stream
 *
 * @description - fetch events based on log group name and log stream name;
 * - allow load more log events based on *nextToken*
 * - allow filter log events by *search term*
 * - if event's message is a regular string, render it out directly
 * - if event's message is stringified json string, convert it back to JSON object and use html **pre** tag to render it out properly.
 *
 * @param {Object} props
 * @param {"HEALTH_CHECK_LOG" | "ACTIVITY_LOG"} props.LogEventType
 * @param {string} props.logGroupName
 * @param {string} props.logStreamName
 * @param {number} props.startTime
 * @param {number} props.endTime
 * @param {string} props.timezone timezone used to convert utc string to local time string (local to given timezone)
 * @returns
 */
export default function LogEventList(props) {
  const {
    LogEventType,
    logGroupName,
    startTime,
    endTime,
    logStreamName,
    timezone,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  /**@type {[LogEventAPIReturnPayloadInterface]} */
  const [logEventObj, setLogEventObj] = useState(null);

  /**@type {[LogEventInterface []]} */
  const [allEvents, setAllEvents] = useState([]);

  const [noMoreData, setNoMoreData] = useState(false);

  const [nextToken, setNextToken] = useState("");
  const { awsToken } = useContext(AuthContext);

  const {
    error,
    isFetching, // whether a query is on the way and awaiting response
    isLoading,
    refetch,
  } = useQuery(
    [
      "GET_CLOUD_WATCH_LOGS_EVENT",
      logGroupName,
      logStreamName,
      awsToken,
      nextToken,
    ],
    async () => {
      const payload = {
        logGroupName,
        logStreamName,
        startTime,
        endTime,
        limit: 100,
      };
      if (nextToken) {
        payload.nextToken = nextToken;
      }
      const data = await getLogEvents(payload, awsToken);

      if (LogEventType === "HEALTH_CHECK_LOG") {
        data.events.forEach((event) => {
          const obj = JSON.parse(event.message);
          delete obj["timestamp"];
          //object whose value would be render via <pre> tag
          event.messageObj = obj;
        });
      }
      return data;
    },
    {
      onSuccess: (data) => {
        setLogEventObj(data);
      },

      refetchInterval: false,
      refetchIntervalInBackground: false,
      // refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (logEventObj) {
      const { events, nextBackwardToken } = logEventObj;

      const isSameToken = nextBackwardToken === nextToken;
      const noData = events.length === 0;
      const isNoMoreData = noData && isSameToken;

      setNoMoreData(isNoMoreData);

      if (isSameToken) {
        return;
      }
      // returns empty data but different token, update
      // the token so that next fetch will be executed automatically
      if (!isSameToken && noData) {
        setNextToken(nextBackwardToken);
      } else if (!noData) {
        setAllEvents((prev) => {
          const temp = events.concat(prev);
          return temp;
        });
      }
    }
  }, [logEventObj, nextToken]);

  if (isFetching && allEvents.length === 0) {
    return <IrisLinearProgress isLoading={true} />;
  } else if (error) {
    return <div>{error.message}</div>;
  } else if (allEvents.length > 0) {
    const { nextBackwardToken } = logEventObj;

    const renderEvents = allEvents.filter((event) => {
      if (searchTerm === "") {
        return true;
      }
      return event.message.toLowerCase().includes(searchTerm);
    });

    return (
      <div>
        <TextField
          type="search"
          variant="standard"
          fullWidth
          placeholder="Search"
          onChange={(event) => setSearchTerm(event.target.value)}
        />
        {/* loading indicator for subsequent fetch - loading more */}
        <IrisLinearProgress isLoading={isFetching} />
        {/* load more btn */}
        <Typography variant="h6">
          {renderEvents.length} logs
          <Button
            sx={{
              ml: "0.2rem",
            }}
            size="small"
            variant="outlined"
            onClick={() => {
              setNextToken(nextBackwardToken);
            }}
            disabled={noMoreData}
          >
            Load more
          </Button>
        </Typography>

        {/* render log event message, either direct string or proper JSON format string, with <pre> tag */}
        {renderEvents.map((event, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                background: index % 2 === 0 ? "" : "rgba(0,0,0,0.1)",
                padding: "0.2em",
              }}
            >
              <div
                style={{
                  width: 250,
                }}
              >
                {moment(event.timestamp)
                  .tz(timezone)
                  .format("YYYY-MM-DD HH:mm:ss Z")}
              </div>
              <div
                style={{
                  flex: 1,
                }}
              >
                {event.messageObj
                  ? JSON.stringify(event.messageObj, undefined, 2)
                  : event.message}
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <IrisLinearProgress isLoading={true} />;
  }
}
