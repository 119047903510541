import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import InputLabel from "@mui/material/InputLabel";

// /** regex for "HH:mm:ss" string */
// const timeRegex = /^(?:(?:([01]?\d|2[0-3]):)([0-5]\d):)([0-5]\d)$/;

// /**
//  * @summary get hours and minutes in "HH:mm:00" format
//  *
//  * @description in design, practically, the seconds value should be trivial, so its value would be cut
//  *
//  * @example if value is a date object of 2020-01-01 12:45:02 (local date time)
//  * getHHmm(value) returns "12:45:00"
//  * @param {Date} date
//  * @returns {string} hours and minutes string in "HH:mm:00" format
//  */
// const getHHmmss = (date) => {
//   const hours = date.getHours();
//   const minutes = date.getMinutes();

//   const hourString = hours >= 10 ? hours + "" : "0" + hours;
//   const minuteString = minutes >= 10 ? minutes + "" : "0" + minutes;
//   return `${hourString}:${minuteString}:00`;
// };

// /**
//  * @param {object} props
//  * @param {React.CSSProperties} props.style style for the outmost wrapper
//  * @param {string} props.title title for the time pickers, ie: Schedule, etc
//  * @param {boolean} [props.disabled] if true, time picker would not be toggleable, false other wise
//  * @param {string} props.startTime start time string, MUST be HH:mm:ss" format
//  * @param {string} props.endTime end time string, MUST be HH:mm:ss" format
//  * @param {(startTime: string, endTime: string) => {}} props.onChange callback function (listener) for time range change
//  * @param {import("@mui/material").TextFieldProps} props.muiTextFieldProps
//  *
//  * @example
//  * cosnt value = {start: "23:00", end: "18:00"}
//  * <IrisTimePickers
//       style={{ maxWidth: 300 }}
//       startTime={value.start}
//       endTime={value.end}
//       disabled={!isEditSchedule}
//       muiTextFieldProps={{
//         disabled: false,
//       }}
//       title="Monday"
//       onChange={(startTime, endTime) => console.log("startTime, endTime", startTime, endTime)}
//     />
//  */
// export default function IrisTimePickers(props) {
//   const {
//     style,
//     title,
//     disabled = false,
//     startTime: st,
//     endTime: et,
//     onChange,
//     muiTextFieldProps,
//   } = props;

//   if (!timeRegex.test(st)) {
//     throw new Error("[startTime] is not in 'HH:mm:ss' format");
//   }

//   if (!timeRegex.test(et)) {
//     throw new Error("[endTime] is not in 'HH:mm:ss' format");
//   }

//   /** "1970-01-01T00:00:00"  */
//   const dummyDateString = new Date(0).toJSON().split(".")[0];

//   const [timeRange, setTimeRange] = useState({
//     startTime: dummyDateString,
//     endTime: dummyDateString,
//   });

//   useEffect(() => {
//     setTimeRange((prev) => {
//       const { startTime, endTime } = prev;
//       const newStartTime = startTime.split("T")[0] + "T" + st;
//       const newEndTime = endTime.split("T")[0] + "T" + et;
//       return { startTime: newStartTime, endTime: newEndTime };
//     });
//   }, [st, et]);

//   /**
//    * @summary callback function for onChange property of each pickers (start time & end time) pickers
//    * @param {Date} [newStartTime] new date object from start time picker
//    * @param {Date} [newEndTime] new date object from end time picker
//    *
//    * @see onChange
//    */
//   const updateTimeRange = (newStartTime, newEndTime) => {
//     const { startTime: prevStartTime, endTime: prevEndTime } = timeRange;

//     const startTime = newStartTime
//       ? getHHmmss(newStartTime)
//       : prevStartTime.split("T")[1];
//     const endTime = newEndTime
//       ? getHHmmss(newEndTime)
//       : prevEndTime.split("T")[1];

//     onChange(startTime, endTime);
//   };

//   return (
//     <div style={style}>
//       <InputLabel>{title}</InputLabel>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           marginTop: "1rem",
//         }}
//       >
//         <div style={{ maxWidth: 135 }}>
//           <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <TimePicker
//               label="From"
//               disabled={disabled}
//               value={timeRange.startTime}
//               onChange={(newValue) => {
//                 updateTimeRange(newValue, null);
//               }}
//               renderInput={(params) => (
//                 <TextField {...params} {...muiTextFieldProps} fullWidth />
//               )}
//             />
//           </LocalizationProvider>
//         </div>
//         <div style={{ maxWidth: 135 }}>
//           <LocalizationProvider dateAdapter={AdapterDateFns}>
//             <TimePicker
//               label="To"
//               disabled={disabled}
//               value={timeRange.endTime}
//               onChange={(newValue) => {
//                 updateTimeRange(null, newValue);
//               }}
//               renderInput={(params) => (
//                 <TextField {...params} {...muiTextFieldProps} fullWidth />
//               )}
//             />
//           </LocalizationProvider>
//         </div>
//       </div>
//     </div>
//   );
// }

/**
 * @param {object} props
 * @param {React.CSSProperties} props.style style for the outmost wrapper
 * @param {string} props.title title for the time pickers, ie: Schedule, etc
 * @param {boolean} [props.disabled] if true, time picker would not be toggleable, false other wise
 * @param {Date} props.startTime start time object
 * @param {Date} props.endTime end time object
 * @param {(startTime: string, endTime: string) => {}} props.onChange callback function (listener) for time range change
 * @param {import("@mui/material").TextFieldProps} props.muiTextFieldProps
 */
export default function IrisTimePickers(props) {
  const {
    style,
    title,
    disabled = false,
    startTime,
    endTime,
    onChange,
    muiTextFieldProps,
  } = props;

  /**
   * @summary callback function for onChange property of each pickers (start time & end time) pickers
   * @param {Date} [newStartTime] new date object from start time picker
   * @param {Date} [newEndTime] new date object from end time picker
   *
   * @see onChange
   */
  const updateTimeRange = (newStartTime, newEndTime) => {
    // console.log("newStartTime, newEndTime", newStartTime, newEndTime);

    const st = newStartTime || startTime;
    const et = newEndTime || endTime;

    onChange(st, et);
  };

  return (
    <div style={style}>
      <InputLabel>{title}</InputLabel>
      <div style={{ display: "flex", marginTop: "1rem" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="From"
            disabled={disabled}
            value={startTime}
            onChange={(newValue) => {
              updateTimeRange(newValue, null);
            }}
            renderInput={(params) => (
              <TextField {...params} {...muiTextFieldProps} fullWidth />
            )}
            inputProps={{
              readOnly: true,
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="To"
            disabled={disabled}
            value={endTime}
            onChange={(newValue) => {
              updateTimeRange(null, newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} {...muiTextFieldProps} fullWidth />
            )}
            inputProps={{
              readOnly: true,
            }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
