export const GET_YESTERDAY = new Date() - 3600000 * 24;

export const PROCESS_DEFECT_DATA = (datapoints, damagelist) => {
  // for each damage type add related data to it
  let processedList = damagelist;
  for (const i in processedList) {
    if (processedList[i].enabled === "Y") {
      processedList[i].datapoints = [];
      processedList[i].count = 0;
      datapoints.results.forEach((data) => {
        if (data.damage_types.includes(processedList[i].id)) {
          processedList[i].datapoints = [...processedList[i].datapoints, data];
          processedList[i].count++;
        }
      });
    }
  }

  // create RRI as one damage type and find all related data
  let roadIssueType = {
    id: 99,
    name: "Road Related Issues",
    datapoints: [],
    count: 0,
  };
  datapoints.results.forEach((data) => {
    if (
      data.road_related_issues.length > 0 &&
      data.road_related_issues.some((issue) => issue.enabled === "Y")
    ) {
      roadIssueType.datapoints = [...roadIssueType.datapoints, data];
      roadIssueType.count++;
    }
  });

  // combine and return results
  return (damagelist = [
    {
      id: 0,
      name: "All",
      datapoints: datapoints.results,
      count: datapoints.count,
    },
    ...damagelist,
    roadIssueType,
  ]);
};

export const PROCESS_LABEL_DATA = (datapoints, labelList) => {
  // for each label type add related data to it
  // let processedList = [];
  let processedList = [];

  for (const i in labelList) {
    if (labelList[i].enabled === "Y") {
      let same_label_datapoint = [];
      let same_label_datapoint_count = 0;
      datapoints.results.forEach((data) => {
        if (data.labels.some((label) => label.label_type === labelList[i].id)) {
          same_label_datapoint_count = same_label_datapoint.push(data);
        }
      });
      if (same_label_datapoint_count > 0) {
        processedList.push({
          ...labelList[i],
          count: same_label_datapoint_count,
          datapoints: same_label_datapoint,
        });
      }
    }
  }

  // combine and return results
  return [
    {
      id: 0,
      name: "All",
      datapoints: datapoints.results,
      count: datapoints.count,
    },
    ...processedList,
  ];
};

export const PROCESS_MOBILE_USAGE_DATA = (usageData, deviceList) => {
  // for each damage type add related data to it
  console.log(deviceList);
  let processedList = deviceList;
  for (const i in processedList) {
    processedList[i].usage = [];
    processedList[i].summary = { distance: 0, data: 0 };
    usageData.forEach((data) => {
      if (data.device_id === processedList[i].id) {
        data.data = data.data / 1048576;
        processedList[i].usage = [...processedList[i].usage, data];
        processedList[i].summary.distance += data.distance;
        processedList[i].summary.data += data.data;
      }
    });
  }
  let filteredList = processedList.filter(
    (value) => value.summary.distance !== 0 || value.summary.data !== 0
  );

  let usageSummary = {
    id: 0,
    tag: "Summary",
    usage: [],
    summary: { distance: 0, data: 0 },
  };

  filteredList.forEach(function (device) {
    usageSummary.usage.push({
      name: device.tag,
      city: device.city.name,
      distance: device.summary.distance,
      data: device.summary.data,
    });
    usageSummary.summary.distance += device.summary.distance;
    usageSummary.summary.data += device.summary.data;
  });

  // combine and return results
  return [usageSummary, ...filteredList];
};
