import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Box, Chip, FilledInput, Input } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

/**
 * @param {object} props
 * @param {boolean} [props.fullWidth]
 * @param {string} props.label
 * @param {("standard"|"chip")} props.renderVariant render options vairant, default is "standard"
 * @param {[number|string]} props.values subset of options
 * @param {[number|string]} props.options all potential options
 * @param {(value: [string|number])=> {}} props.onChange callback functions that exposes the selected values
 * @param {import("@mui/material").SelectProps} props.muiSelectProps
 *
 * @example   
 * <IrisMultiSelect
      label="Example"
      options={["One", "Two", "Three"]}
      onChange={(values) => console.log("values", values)}
      muiSelectProps={{
        value: ["One", "Three"],
        variant: "standard"
      }}
      renderVariant="standard"
  />
 */
export default function IrisMultiSelect(props) {
  const {
    fullWidth = true,
    label,
    renderVariant = "standard",
    options,
    onChange,
    muiSelectProps,
  } = props;
  const { value, variant = "standard" } = muiSelectProps;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(value);
  };

  /**
   * @summary get the input element for the select element, either "FilledInput", "OutlinedInput", or "Input"
   * @param {import("@mui/material").TextFieldProps.variant} variant
   * @param {string} label
   */
  const getSelectInput = (variant, label) => {
    if (variant === "filled") {
      return <FilledInput label={label} />;
    } else if (variant === "outlined") {
      return <OutlinedInput label={label} />;
    }
    return <Input label={label} />;
  };

  /**
   * @summary callback function for the renderValue prop of Select element
   */
  const renderSelectedValue = (selected) => {
    const isStandardStyle = renderVariant === "standard";

    if (isStandardStyle) {
      return selected.join(", ");
    }

    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {selected.map((value) => (
          <Chip key={value} label={value} />
        ))}
      </Box>
    );
  };
  return (
    <div>
      <FormControl fullWidth={fullWidth}>
        <InputLabel variant={variant}>{label}</InputLabel>
        <Select
          {...muiSelectProps}
          multiple
          value={value}
          onChange={handleChange}
          input={getSelectInput(variant, label)}
          renderValue={renderSelectedValue}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          {options.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={value.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
